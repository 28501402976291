import React, { useState } from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col, Accordion, Alert } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { HSRMydeiDPS } from '../dps-rankings/mydei-array';

export const ProfileMydeiDPS: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTab, setCurrentTab] = useState('V1');
  return (
    <>
      <Alert variant="primary alert-red ">
        <p>
          <strong>
            Don't use the damage output calculations as an indication on who is
            worth pulling or investing as those are just simulations and in
            day-to-day gameplay there's a lot variables
          </strong>
          . Use the numbers only to supplement your knowledge and treat them as
          the 'floor' of a character's damage output which you can further scale
          with proper team and investment.
        </p>
      </Alert>
      <h5>Disclaimers:</h5>
      <ul>
        <li>
          The simulation showcases Mydei's{' '}
          <strong>
            damage potential in the 5 cycle turn limit of Memory of Chaos
            against 1, 3 and 5 targets
          </strong>{' '}
          when using his Signature Light Cone (S1 Flame of Blood, Blaze My Path)
          and the best F2P option (S5 Ninja Record: Sound Hunt). The section
          below has all the rules listed.
        </li>
      </ul>
      <Accordion className="sim-rules">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Simulation rules</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>General:</strong>
            </p>
            <ul>
              <li>
                Combat is simulated at 5 cycles of in-game time or 550 Action
                Points,
              </li>
              <li>
                Units are considered to gain 5 additional energy per rotation
                through combat (taking a hit, killing an enemy or through party
                members and their Light Cone),
              </li>
              <li>
                Characters and Light Cones are calculated at maximum output
                starting battle with ramp time removed from stacking buffs
                (intended to give a constant evaluation of power not dependant
                on length of fight that can be used to assess maximum power).
                Additional information about loss of damage due to ramp time can
                be round attached to related Light Cones, relics and characters,
              </li>
              <li>
                Character rotations are smoothed removing breakpoints relating
                to Speed and Energy (finishing a fight with 90% energy full or 2
                AV off next ability leading to skewed results).
              </li>
            </ul>
            <p>
              <strong>Enemies:</strong>
            </p>
            <ul>
              <li>
                Enemies are considered to always have a Break Bar resulting in
                units all doing 90% of real damage,
              </li>
              <li>Enemies speed is set to 134,</li>
              <li>
                Enemies are assumed to be at 100% of HP at start of battle and
                to die at exactly at the end of combat. Which means halfway
                through the fight they are at 50% HP,
              </li>
              <li>Enemies are assumed to have 0 Resistance,</li>
              <li>
                Enemies are always considered vulnerable to the attackers
                element,
              </li>
              <li>Enemies are considered level 95 and with DEF included.</li>
            </ul>
            <p>
              <strong>Units</strong>:
            </p>
            <ul>
              <li>All units are level 80,</li>
              <li>
                Traces are all unlocked, skills at Level 10 and Basic at Level
                6,
              </li>
              <li>
                All units have fully upgraded 5 star relics with 24 desired sub
                stats out of a possible 54,
              </li>
              <li>All units use their Best In Slot 2 and 4 piece sets,</li>
              <li>
                All units use Light Cones that are realistically obtainable by
                all players (including those in Herta and FH Stores and Battle
                Pass),
              </li>
              <ul>
                <li>
                  Because Mydei Store and farmable Light Cones are easily
                  accessible to playerbase, we are simulating them at
                  Superimposition 5.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Mydei [1 target]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e6 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e6 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e6 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e5 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e5 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e5 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e5 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e4 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e4 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e4 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e4 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e3 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e3 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e3 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e3 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e2 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e2 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e2 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e2 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e1 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e1 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e1 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e1 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e0 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].sig_e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e0 /
                            HSRMydeiDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e0 /
                            HSRMydeiDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Mydei [3 targets]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e6 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e6 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e6 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e5 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e5 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e5 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e5 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e4 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e4 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e4 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e4 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e3 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e3 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e3 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e3 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e2 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e2 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e2 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e2 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e1 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e1 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e1 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_e1 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_sig_e0 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_sig_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].alt_sig_e0 /
                            HSRMydeiDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].alt_e0 /
                            HSRMydeiDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].alt_e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Mydei [5 targets]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e6_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e6_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e6_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e6_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e6_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e5_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e5_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e5_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e5_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e5_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e5_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e4_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e4_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e4_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e4_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e4_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e4_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e3_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e3_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e3_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e3_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e3_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e3_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e2_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e2_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e2_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e2_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e2_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e2_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e1_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e1_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e1_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e1_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e1_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].e1_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="mydei" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].sig_e0_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Flame of Blood, Blaze My Path"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[
                          currentTab
                        ].sig_e0_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRMydeiDPS[currentTab].sig_e0_5targets /
                            HSRMydeiDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRMydeiDPS[currentTab].e0_5targets /
                            HSRMydeiDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Ninja Record: Sound Hunt"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRMydeiDPS[currentTab].e0_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Scholar Lost in Erudition" compactMode />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet
                  name="Bone Collection's Serene Demesne"
                  compactMode
                />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Speed" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Imaginary DMG" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>CRIT Rate (40.8%), CRIT DMG (88.2%)</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
