export const PFDuos = [
  {
    char: 'the-herta',
    app: 72.0,
    char_1: 'herta',
    app_rate_1: '60.78%',
    avg_round_1: 39148,
    app_flat_1: 3649,
    char_2: 'tribbie',
    app_rate_2: '49.76%',
    avg_round_2: 40000,
    app_flat_2: 2649,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '33.89%',
    avg_round_3: 38509,
    app_flat_3: 2255,
    char_4: 'jade',
    app_rate_4: '25.39%',
    avg_round_4: 39966,
    app_flat_4: 1288,
    char_5: 'aventurine',
    app_rate_5: '25.3%',
    avg_round_5: 39099,
    app_flat_5: 1505,
    char_6: 'lingsha',
    app_rate_6: '20.54%',
    avg_round_6: 39693,
    app_flat_6: 1175,
    char_7: 'robin',
    app_rate_7: '15.0%',
    avg_round_7: 38246,
    app_flat_7: 691,
    char_8: 'huohuo',
    app_rate_8: '14.55%',
    avg_round_8: 39522,
    app_flat_8: 799,
    char_9: 'serval',
    app_rate_9: '9.59%',
    avg_round_9: 38689,
    app_flat_9: 487,
    char_10: 'gallagher',
    app_rate_10: '9.07%',
    avg_round_10: 38961,
    app_flat_10: 628,
    char_11: 'fu-xuan',
    app_rate_11: '8.39%',
    avg_round_11: 39218,
    app_flat_11: 386,
    char_12: 'sunday',
    app_rate_12: '6.82%',
    avg_round_12: 36903,
    app_flat_12: 354,
    char_13: 'ruan-mei',
    app_rate_13: '6.67%',
    avg_round_13: 37606,
    app_flat_13: 335,
    char_14: 'luocha',
    app_rate_14: '5.28%',
    avg_round_14: 35842,
    app_flat_14: 306,
    char_15: 'argenti',
    app_rate_15: '2.61%',
    avg_round_15: 39764,
    app_flat_15: 130,
    char_16: 'himeko',
    app_rate_16: '1.92%',
    avg_round_16: 34098,
    app_flat_16: 108,
    char_17: 'sparkle',
    app_rate_17: '1.88%',
    avg_round_17: 34728,
    app_flat_17: 58,
    char_18: 'pela',
    app_rate_18: '0.36%',
    avg_round_18: 35709,
    app_flat_18: 19,
    char_19: 'rappa',
    app_rate_19: '0.35%',
    avg_round_19: 34450,
    app_flat_19: 12,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.28%',
    avg_round_20: 32089,
    app_flat_20: 13,
    char_21: 'bailu',
    app_rate_21: '0.2%',
    avg_round_21: 37996,
    app_flat_21: 9,
    char_22: 'bronya',
    app_rate_22: '0.18%',
    avg_round_22: 34160,
    app_flat_22: 5,
    char_23: 'jiaoqiu',
    app_rate_23: '0.16%',
    avg_round_23: 31907,
    app_flat_23: 3,
    char_24: 'lynx',
    app_rate_24: '0.12%',
    avg_round_24: 38504,
    app_flat_24: 7,
    char_25: 'jing-yuan',
    app_rate_25: '0.1%',
    avg_round_25: 35800,
    app_flat_25: 3,
    char_26: 'tingyun',
    app_rate_26: '0.1%',
    avg_round_26: 36407,
    app_flat_26: 6,
    char_27: 'march-7th',
    app_rate_27: '0.09%',
    avg_round_27: 35352,
    app_flat_27: 5,
    char_28: 'gepard',
    app_rate_28: '0.08%',
    avg_round_28: 31280,
    app_flat_28: 4,
    char_29: 'acheron',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'yunli',
    app_rate_30: '0.05%',
    avg_round_30: 31560,
    app_flat_30: 2
  },
  {
    char: 'tribbie',
    app: 65.7,
    char_1: 'the-herta',
    app_rate_1: '54.53%',
    avg_round_1: 40000,
    app_flat_1: 2649,
    char_2: 'herta',
    app_rate_2: '36.28%',
    avg_round_2: 40000,
    app_flat_2: 1797,
    char_3: 'aventurine',
    app_rate_3: '22.14%',
    avg_round_3: 39868,
    app_flat_3: 981,
    char_4: 'jade',
    app_rate_4: '21.7%',
    avg_round_4: 40000,
    app_flat_4: 923,
    char_5: 'huohuo',
    app_rate_5: '17.03%',
    avg_round_5: 39213,
    app_flat_5: 707,
    char_6: 'lingsha',
    app_rate_6: '16.13%',
    avg_round_6: 40000,
    app_flat_6: 707,
    char_7: 'sunday',
    app_rate_7: '15.33%',
    avg_round_7: 35454,
    app_flat_7: 498,
    char_8: 'fu-xuan',
    app_rate_8: '13.32%',
    avg_round_8: 38930,
    app_flat_8: 439,
    char_9: 'acheron',
    app_rate_9: '12.4%',
    avg_round_9: 35818,
    app_flat_9: 305,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '10.9%',
    avg_round_10: 39682,
    app_flat_10: 505,
    char_11: 'robin',
    app_rate_11: '9.4%',
    avg_round_11: 37205,
    app_flat_11: 284,
    char_12: 'gallagher',
    app_rate_12: '8.0%',
    avg_round_12: 39545,
    app_flat_12: 399,
    char_13: 'serval',
    app_rate_13: '7.87%',
    avg_round_13: 39628,
    app_flat_13: 371,
    char_14: 'jiaoqiu',
    app_rate_14: '6.19%',
    avg_round_14: 39022,
    app_flat_14: 150,
    char_15: 'aglaea',
    app_rate_15: '6.18%',
    avg_round_15: 35568,
    app_flat_15: 235,
    char_16: 'jing-yuan',
    app_rate_16: '5.16%',
    avg_round_16: 34181,
    app_flat_16: 172,
    char_17: 'pela',
    app_rate_17: '4.37%',
    avg_round_17: 34670,
    app_flat_17: 122,
    char_18: 'luocha',
    app_rate_18: '4.14%',
    avg_round_18: 38813,
    app_flat_18: 180,
    char_19: 'argenti',
    app_rate_19: '3.76%',
    avg_round_19: 39671,
    app_flat_19: 163,
    char_20: 'sparkle',
    app_rate_20: '3.56%',
    avg_round_20: 32362,
    app_flat_20: 60,
    char_21: 'yunli',
    app_rate_21: '3.55%',
    avg_round_21: 37366,
    app_flat_21: 115,
    char_22: 'ruan-mei',
    app_rate_22: '3.31%',
    avg_round_22: 37511,
    app_flat_22: 107,
    char_23: 'himeko',
    app_rate_23: '2.04%',
    avg_round_23: 34444,
    app_flat_23: 85,
    char_24: 'mydei',
    app_rate_24: '1.7%',
    avg_round_24: 32340,
    app_flat_24: 41,
    char_25: 'kafka',
    app_rate_25: '1.52%',
    avg_round_25: 31396,
    app_flat_25: 29,
    char_26: 'feixiao',
    app_rate_26: '1.39%',
    avg_round_26: 33249,
    app_flat_26: 38,
    char_27: 'tingyun-fugue',
    app_rate_27: '1.37%',
    avg_round_27: 34197,
    app_flat_27: 35,
    char_28: 'bronya',
    app_rate_28: '0.69%',
    avg_round_28: 30017,
    app_flat_28: 19,
    char_29: 'clara',
    app_rate_29: '0.68%',
    avg_round_29: 31177,
    app_flat_29: 23,
    char_30: 'blade',
    app_rate_30: '0.66%',
    avg_round_30: 34438,
    app_flat_30: 20
  },
  {
    char: 'herta',
    app: 60.95,
    char_1: 'the-herta',
    app_rate_1: '71.8%',
    avg_round_1: 39148,
    app_flat_1: 3649,
    char_2: 'tribbie',
    app_rate_2: '39.11%',
    avg_round_2: 40000,
    app_flat_2: 1797,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '35.79%',
    avg_round_3: 37712,
    app_flat_3: 1984,
    char_4: 'aventurine',
    app_rate_4: '26.76%',
    avg_round_4: 37709,
    app_flat_4: 1262,
    char_5: 'robin',
    app_rate_5: '25.62%',
    avg_round_5: 33392,
    app_flat_5: 945,
    char_6: 'ruan-mei',
    app_rate_6: '14.83%',
    avg_round_6: 32351,
    app_flat_6: 598,
    char_7: 'lingsha',
    app_rate_7: '14.13%',
    avg_round_7: 39021,
    app_flat_7: 716,
    char_8: 'huohuo',
    app_rate_8: '10.88%',
    avg_round_8: 38878,
    app_flat_8: 518,
    char_9: 'gallagher',
    app_rate_9: '8.75%',
    avg_round_9: 38427,
    app_flat_9: 500,
    char_10: 'jade',
    app_rate_10: '7.93%',
    avg_round_10: 36449,
    app_flat_10: 327,
    char_11: 'fu-xuan',
    app_rate_11: '7.5%',
    avg_round_11: 38049,
    app_flat_11: 278,
    char_12: 'sunday',
    app_rate_12: '7.29%',
    avg_round_12: 34149,
    app_flat_12: 314,
    char_13: 'himeko',
    app_rate_13: '6.36%',
    avg_round_13: 27717,
    app_flat_13: 269,
    char_14: 'luocha',
    app_rate_14: '4.91%',
    avg_round_14: 34122,
    app_flat_14: 249,
    char_15: 'tingyun-fugue',
    app_rate_15: '3.31%',
    avg_round_15: 28862,
    app_flat_15: 122,
    char_16: 'sparkle',
    app_rate_16: '3.18%',
    avg_round_16: 31016,
    app_flat_16: 80,
    char_17: 'feixiao',
    app_rate_17: '2.22%',
    avg_round_17: 29065,
    app_flat_17: 56,
    char_18: 'rappa',
    app_rate_18: '1.0%',
    avg_round_18: 29920,
    app_flat_18: 48,
    char_19: 'trailblazer-harmony',
    app_rate_19: '0.99%',
    avg_round_19: 29477,
    app_flat_19: 35,
    char_20: 'yunli',
    app_rate_20: '0.79%',
    avg_round_20: 30342,
    app_flat_20: 26,
    char_21: 'argenti',
    app_rate_21: '0.71%',
    avg_round_21: 31031,
    app_flat_21: 35,
    char_22: 'pela',
    app_rate_22: '0.7%',
    avg_round_22: 32795,
    app_flat_22: 33,
    char_23: 'tingyun',
    app_rate_23: '0.57%',
    avg_round_23: 30143,
    app_flat_23: 30,
    char_24: 'firefly',
    app_rate_24: '0.56%',
    avg_round_24: 26290,
    app_flat_24: 4,
    char_25: 'acheron',
    app_rate_25: '0.51%',
    avg_round_25: 27640,
    app_flat_25: 15,
    char_26: 'mydei',
    app_rate_26: '0.47%',
    avg_round_26: 31553,
    app_flat_26: 10,
    char_27: 'bronya',
    app_rate_27: '0.47%',
    avg_round_27: 27984,
    app_flat_27: 17,
    char_28: 'serval',
    app_rate_28: '0.42%',
    avg_round_28: 32002,
    app_flat_28: 17,
    char_29: 'jiaoqiu',
    app_rate_29: '0.34%',
    avg_round_29: 28540,
    app_flat_29: 6,
    char_30: 'clara',
    app_rate_30: '0.32%',
    avg_round_30: 30250,
    app_flat_30: 8
  },
  {
    char: 'aventurine',
    app: 46.36,
    char_1: 'the-herta',
    app_rate_1: '39.3%',
    avg_round_1: 39099,
    app_flat_1: 1505,
    char_2: 'herta',
    app_rate_2: '35.19%',
    avg_round_2: 37709,
    app_flat_2: 1262,
    char_3: 'tribbie',
    app_rate_3: '31.37%',
    avg_round_3: 39868,
    app_flat_3: 981,
    char_4: 'robin',
    app_rate_4: '29.38%',
    avg_round_4: 31822,
    app_flat_4: 708,
    char_5: 'acheron',
    app_rate_5: '29.24%',
    avg_round_5: 29741,
    app_flat_5: 613,
    char_6: 'sunday',
    app_rate_6: '19.75%',
    avg_round_6: 30710,
    app_flat_6: 441,
    char_7: 'jiaoqiu',
    app_rate_7: '19.23%',
    avg_round_7: 30781,
    app_flat_7: 403,
    char_8: 'jade',
    app_rate_8: '16.52%',
    avg_round_8: 39327,
    app_flat_8: 515,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '13.74%',
    avg_round_9: 36047,
    app_flat_9: 568,
    char_10: 'pela',
    app_rate_10: '11.76%',
    avg_round_10: 29185,
    app_flat_10: 338,
    char_11: 'jing-yuan',
    app_rate_11: '10.74%',
    avg_round_11: 30433,
    app_flat_11: 258,
    char_12: 'sparkle',
    app_rate_12: '7.54%',
    avg_round_12: 27942,
    app_flat_12: 59,
    char_13: 'ruan-mei',
    app_rate_13: '5.0%',
    avg_round_13: 28753,
    app_flat_13: 128,
    char_14: 'kafka',
    app_rate_14: '3.7%',
    avg_round_14: 25975,
    app_flat_14: 101,
    char_15: 'feixiao',
    app_rate_15: '3.5%',
    avg_round_15: 25568,
    app_flat_15: 48,
    char_16: 'black-swan',
    app_rate_16: '3.18%',
    avg_round_16: 25979,
    app_flat_16: 83,
    char_17: 'serval',
    app_rate_17: '3.1%',
    avg_round_17: 38101,
    app_flat_17: 115,
    char_18: 'himeko',
    app_rate_18: '2.56%',
    avg_round_18: 25235,
    app_flat_18: 77,
    char_19: 'aglaea',
    app_rate_19: '2.05%',
    avg_round_19: 31319,
    app_flat_19: 54,
    char_20: 'argenti',
    app_rate_20: '1.95%',
    avg_round_20: 33987,
    app_flat_20: 55,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.9%',
    avg_round_21: 27677,
    app_flat_21: 64,
    char_22: 'yunli',
    app_rate_22: '1.7%',
    avg_round_22: 31122,
    app_flat_22: 39,
    char_23: 'rappa',
    app_rate_23: '1.28%',
    avg_round_23: 25749,
    app_flat_23: 49,
    char_24: 'clara',
    app_rate_24: '0.92%',
    avg_round_24: 26894,
    app_flat_24: 28,
    char_25: 'bronya',
    app_rate_25: '0.9%',
    avg_round_25: 25953,
    app_flat_25: 6,
    char_26: 'topaz',
    app_rate_26: '0.82%',
    avg_round_26: 24031,
    app_flat_26: 9,
    char_27: 'silver-wolf',
    app_rate_27: '0.75%',
    avg_round_27: 24651,
    app_flat_27: 21,
    char_28: 'tingyun',
    app_rate_28: '0.65%',
    avg_round_28: 25992,
    app_flat_28: 15,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.47%',
    avg_round_29: 23618,
    app_flat_29: 18,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.47%',
    avg_round_30: 23806,
    app_flat_30: 13
  },
  {
    char: 'robin',
    app: 45.74,
    char_1: 'sunday',
    app_rate_1: '37.28%',
    avg_round_1: 30298,
    app_flat_1: 883,
    char_2: 'herta',
    app_rate_2: '34.14%',
    avg_round_2: 33392,
    app_flat_2: 945,
    char_3: 'aventurine',
    app_rate_3: '29.77%',
    avg_round_3: 31822,
    app_flat_3: 708,
    char_4: 'the-herta',
    app_rate_4: '23.61%',
    avg_round_4: 38246,
    app_flat_4: 691,
    char_5: 'huohuo',
    app_rate_5: '19.32%',
    avg_round_5: 30427,
    app_flat_5: 493,
    char_6: 'jade',
    app_rate_6: '18.85%',
    avg_round_6: 38261,
    app_flat_6: 520,
    char_7: 'aglaea',
    app_rate_7: '17.6%',
    avg_round_7: 31230,
    app_flat_7: 436,
    char_8: 'jing-yuan',
    app_rate_8: '16.51%',
    avg_round_8: 28680,
    app_flat_8: 401,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '15.16%',
    avg_round_9: 32960,
    app_flat_9: 404,
    char_10: 'tribbie',
    app_rate_10: '13.5%',
    avg_round_10: 37205,
    app_flat_10: 284,
    char_11: 'lingsha',
    app_rate_11: '10.08%',
    avg_round_11: 37380,
    app_flat_11: 274,
    char_12: 'fu-xuan',
    app_rate_12: '7.86%',
    avg_round_12: 31603,
    app_flat_12: 163,
    char_13: 'gallagher',
    app_rate_13: '7.81%',
    avg_round_13: 31919,
    app_flat_13: 195,
    char_14: 'yunli',
    app_rate_14: '6.36%',
    avg_round_14: 30426,
    app_flat_14: 148,
    char_15: 'feixiao',
    app_rate_15: '5.5%',
    avg_round_15: 27779,
    app_flat_15: 90,
    char_16: 'acheron',
    app_rate_16: '4.62%',
    avg_round_16: 28354,
    app_flat_16: 54,
    char_17: 'luocha',
    app_rate_17: '4.08%',
    avg_round_17: 29947,
    app_flat_17: 110,
    char_18: 'ruan-mei',
    app_rate_18: '3.53%',
    avg_round_18: 30741,
    app_flat_18: 76,
    char_19: 'sparkle',
    app_rate_19: '2.72%',
    avg_round_19: 25623,
    app_flat_19: 35,
    char_20: 'jiaoqiu',
    app_rate_20: '2.62%',
    avg_round_20: 31042,
    app_flat_20: 25,
    char_21: 'himeko',
    app_rate_21: '2.55%',
    avg_round_21: 23962,
    app_flat_21: 70,
    char_22: 'argenti',
    app_rate_22: '2.51%',
    avg_round_22: 32740,
    app_flat_22: 55,
    char_23: 'clara',
    app_rate_23: '1.72%',
    avg_round_23: 26501,
    app_flat_23: 46,
    char_24: 'serval',
    app_rate_24: '1.49%',
    avg_round_24: 29488,
    app_flat_24: 37,
    char_25: 'bronya',
    app_rate_25: '1.45%',
    avg_round_25: 30114,
    app_flat_25: 25,
    char_26: 'pela',
    app_rate_26: '1.18%',
    avg_round_26: 25808,
    app_flat_26: 20,
    char_27: 'kafka',
    app_rate_27: '1.13%',
    avg_round_27: 25217,
    app_flat_27: 13,
    char_28: 'topaz',
    app_rate_28: '0.93%',
    avg_round_28: 24025,
    app_flat_28: 8,
    char_29: 'tingyun',
    app_rate_29: '0.91%',
    avg_round_29: 25667,
    app_flat_29: 24,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.88%',
    avg_round_30: 24185,
    app_flat_30: 24
  },
  {
    char: 'ruan-mei',
    app: 44.24,
    char_1: 'tingyun-fugue',
    app_rate_1: '59.94%',
    avg_round_1: 31323,
    app_flat_1: 1186,
    char_2: 'lingsha',
    app_rate_2: '40.46%',
    avg_round_2: 30726,
    app_flat_2: 711,
    char_3: 'rappa',
    app_rate_3: '32.77%',
    avg_round_3: 31410,
    app_flat_3: 1097,
    char_4: 'firefly',
    app_rate_4: '32.12%',
    avg_round_4: 25610,
    app_flat_4: 231,
    char_5: 'trailblazer-harmony',
    app_rate_5: '22.26%',
    avg_round_5: 30450,
    app_flat_5: 480,
    char_6: 'herta',
    app_rate_6: '20.43%',
    avg_round_6: 32351,
    app_flat_6: 598,
    char_7: 'gallagher',
    app_rate_7: '13.47%',
    avg_round_7: 27084,
    app_flat_7: 370,
    char_8: 'himeko',
    app_rate_8: '11.01%',
    avg_round_8: 29116,
    app_flat_8: 272,
    char_9: 'the-herta',
    app_rate_9: '10.85%',
    avg_round_9: 37606,
    app_flat_9: 335,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '10.1%',
    avg_round_10: 34638,
    app_flat_10: 304,
    char_11: 'aventurine',
    app_rate_11: '5.23%',
    avg_round_11: 28753,
    app_flat_11: 128,
    char_12: 'tribbie',
    app_rate_12: '4.92%',
    avg_round_12: 37511,
    app_flat_12: 107,
    char_13: 'sunday',
    app_rate_13: '4.31%',
    avg_round_13: 28456,
    app_flat_13: 78,
    char_14: 'jade',
    app_rate_14: '3.89%',
    avg_round_14: 34613,
    app_flat_14: 108,
    char_15: 'robin',
    app_rate_15: '3.65%',
    avg_round_15: 30741,
    app_flat_15: 76,
    char_16: 'huohuo',
    app_rate_16: '3.0%',
    avg_round_16: 25626,
    app_flat_16: 55,
    char_17: 'acheron',
    app_rate_17: '2.93%',
    avg_round_17: 29583,
    app_flat_17: 41,
    char_18: 'kafka',
    app_rate_18: '2.37%',
    avg_round_18: 23425,
    app_flat_18: 21,
    char_19: 'fu-xuan',
    app_rate_19: '2.01%',
    avg_round_19: 26908,
    app_flat_19: 32,
    char_20: 'luocha',
    app_rate_20: '1.97%',
    avg_round_20: 27220,
    app_flat_20: 48,
    char_21: 'black-swan',
    app_rate_21: '1.88%',
    avg_round_21: 22513,
    app_flat_21: 11,
    char_22: 'mydei',
    app_rate_22: '1.36%',
    avg_round_22: 26751,
    app_flat_22: 17,
    char_23: 'sparkle',
    app_rate_23: '1.15%',
    avg_round_23: 27373,
    app_flat_23: 15,
    char_24: 'jiaoqiu',
    app_rate_24: '1.13%',
    avg_round_24: 30490,
    app_flat_24: 12,
    char_25: 'argenti',
    app_rate_25: '1.12%',
    avg_round_25: 31082,
    app_flat_25: 38,
    char_26: 'pela',
    app_rate_26: '0.98%',
    avg_round_26: 30901,
    app_flat_26: 17,
    char_27: 'bronya',
    app_rate_27: '0.87%',
    avg_round_27: 29765,
    app_flat_27: 15,
    char_28: 'jing-yuan',
    app_rate_28: '0.75%',
    avg_round_28: 26470,
    app_flat_28: 14,
    char_29: 'serval',
    app_rate_29: '0.73%',
    avg_round_29: 31604,
    app_flat_29: 21,
    char_30: 'aglaea',
    app_rate_30: '0.73%',
    avg_round_30: 28971,
    app_flat_30: 14
  },
  {
    char: 'sunday',
    app: 40.67,
    char_1: 'robin',
    app_rate_1: '41.92%',
    avg_round_1: 30298,
    app_flat_1: 883,
    char_2: 'jing-yuan',
    app_rate_2: '30.97%',
    avg_round_2: 29536,
    app_flat_2: 669,
    char_3: 'aglaea',
    app_rate_3: '28.6%',
    avg_round_3: 32296,
    app_flat_3: 644,
    char_4: 'huohuo',
    app_rate_4: '26.04%',
    avg_round_4: 30897,
    app_flat_4: 598,
    char_5: 'tribbie',
    app_rate_5: '24.77%',
    avg_round_5: 35454,
    app_flat_5: 498,
    char_6: 'aventurine',
    app_rate_6: '22.51%',
    avg_round_6: 30710,
    app_flat_6: 441,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '14.25%',
    avg_round_7: 31317,
    app_flat_7: 358,
    char_8: 'the-herta',
    app_rate_8: '12.07%',
    avg_round_8: 36903,
    app_flat_8: 354,
    char_9: 'herta',
    app_rate_9: '10.93%',
    avg_round_9: 34149,
    app_flat_9: 314,
    char_10: 'fu-xuan',
    app_rate_10: '9.43%',
    avg_round_10: 29821,
    app_flat_10: 185,
    char_11: 'gallagher',
    app_rate_11: '9.15%',
    avg_round_11: 31768,
    app_flat_11: 197,
    char_12: 'lingsha',
    app_rate_12: '8.88%',
    avg_round_12: 34735,
    app_flat_12: 202,
    char_13: 'jade',
    app_rate_13: '7.38%',
    avg_round_13: 37199,
    app_flat_13: 193,
    char_14: 'acheron',
    app_rate_14: '7.31%',
    avg_round_14: 25358,
    app_flat_14: 68,
    char_15: 'luocha',
    app_rate_15: '6.72%',
    avg_round_15: 29182,
    app_flat_15: 139,
    char_16: 'jiaoqiu',
    app_rate_16: '5.9%',
    avg_round_16: 26123,
    app_flat_16: 52,
    char_17: 'mydei',
    app_rate_17: '4.78%',
    avg_round_17: 26344,
    app_flat_17: 76,
    char_18: 'ruan-mei',
    app_rate_18: '4.69%',
    avg_round_18: 28456,
    app_flat_18: 78,
    char_19: 'argenti',
    app_rate_19: '4.0%',
    avg_round_19: 32200,
    app_flat_19: 98,
    char_20: 'sparkle',
    app_rate_20: '3.91%',
    avg_round_20: 25434,
    app_flat_20: 76,
    char_21: 'bronya',
    app_rate_21: '2.81%',
    avg_round_21: 31193,
    app_flat_21: 49,
    char_22: 'yunli',
    app_rate_22: '2.24%',
    avg_round_22: 28238,
    app_flat_22: 40,
    char_23: 'pela',
    app_rate_23: '1.78%',
    avg_round_23: 24260,
    app_flat_23: 18,
    char_24: 'tingyun',
    app_rate_24: '1.54%',
    avg_round_24: 26929,
    app_flat_24: 31,
    char_25: 'imbibitor-lunae',
    app_rate_25: '1.4%',
    avg_round_25: 23740,
    app_flat_25: 2,
    char_26: 'serval',
    app_rate_26: '1.37%',
    avg_round_26: 30276,
    app_flat_26: 36,
    char_27: 'tingyun-fugue',
    app_rate_27: '0.82%',
    avg_round_27: 28788,
    app_flat_27: 13,
    char_28: 'blade',
    app_rate_28: '0.61%',
    avg_round_28: 30711,
    app_flat_28: 9,
    char_29: 'rappa',
    app_rate_29: '0.61%',
    avg_round_29: 29680,
    app_flat_29: 18,
    char_30: 'bailu',
    app_rate_30: '0.46%',
    avg_round_30: 27126,
    app_flat_30: 11
  },
  {
    char: 'trailblazer-remembrance',
    app: 40.47,
    char_1: 'the-herta',
    app_rate_1: '60.29%',
    avg_round_1: 38509,
    app_flat_1: 2255,
    char_2: 'herta',
    app_rate_2: '53.9%',
    avg_round_2: 37712,
    app_flat_2: 1984,
    char_3: 'tribbie',
    app_rate_3: '17.7%',
    avg_round_3: 39682,
    app_flat_3: 505,
    char_4: 'robin',
    app_rate_4: '17.13%',
    avg_round_4: 32960,
    app_flat_4: 404,
    char_5: 'aventurine',
    app_rate_5: '15.74%',
    avg_round_5: 36047,
    app_flat_5: 568,
    char_6: 'sunday',
    app_rate_6: '14.32%',
    avg_round_6: 31317,
    app_flat_6: 358,
    char_7: 'huohuo',
    app_rate_7: '12.95%',
    avg_round_7: 34009,
    app_flat_7: 432,
    char_8: 'lingsha',
    app_rate_8: '11.79%',
    avg_round_8: 38087,
    app_flat_8: 430,
    char_9: 'jade',
    app_rate_9: '11.35%',
    avg_round_9: 39426,
    app_flat_9: 367,
    char_10: 'ruan-mei',
    app_rate_10: '11.04%',
    avg_round_10: 34638,
    app_flat_10: 304,
    char_11: 'aglaea',
    app_rate_11: '9.33%',
    avg_round_11: 30603,
    app_flat_11: 220,
    char_12: 'gallagher',
    app_rate_12: '8.83%',
    avg_round_12: 36442,
    app_flat_12: 330,
    char_13: 'fu-xuan',
    app_rate_13: '6.35%',
    avg_round_13: 33762,
    app_flat_13: 174,
    char_14: 'luocha',
    app_rate_14: '5.93%',
    avg_round_14: 32487,
    app_flat_14: 181,
    char_15: 'serval',
    app_rate_15: '5.49%',
    avg_round_15: 34652,
    app_flat_15: 173,
    char_16: 'acheron',
    app_rate_16: '4.52%',
    avg_round_16: 29343,
    app_flat_16: 79,
    char_17: 'argenti',
    app_rate_17: '4.18%',
    avg_round_17: 34379,
    app_flat_17: 131,
    char_18: 'mydei',
    app_rate_18: '3.83%',
    avg_round_18: 29090,
    app_flat_18: 58,
    char_19: 'sparkle',
    app_rate_19: '3.15%',
    avg_round_19: 31271,
    app_flat_19: 66,
    char_20: 'jiaoqiu',
    app_rate_20: '2.75%',
    avg_round_20: 30176,
    app_flat_20: 47,
    char_21: 'yunli',
    app_rate_21: '2.46%',
    avg_round_21: 30156,
    app_flat_21: 57,
    char_22: 'pela',
    app_rate_22: '2.25%',
    avg_round_22: 30756,
    app_flat_22: 57,
    char_23: 'himeko',
    app_rate_23: '1.98%',
    avg_round_23: 31739,
    app_flat_23: 74,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.93%',
    avg_round_24: 32841,
    app_flat_24: 38,
    char_25: 'jing-yuan',
    app_rate_25: '1.7%',
    avg_round_25: 26619,
    app_flat_25: 41,
    char_26: 'bronya',
    app_rate_26: '1.62%',
    avg_round_26: 30919,
    app_flat_26: 31,
    char_27: 'tingyun',
    app_rate_27: '1.39%',
    avg_round_27: 30497,
    app_flat_27: 44,
    char_28: 'feixiao',
    app_rate_28: '1.22%',
    avg_round_28: 30803,
    app_flat_28: 24,
    char_29: 'rappa',
    app_rate_29: '1.13%',
    avg_round_29: 38497,
    app_flat_29: 26,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.59%',
    avg_round_30: 22060,
    app_flat_30: 2
  },
  {
    char: 'lingsha',
    app: 39.67,
    char_1: 'ruan-mei',
    app_rate_1: '45.13%',
    avg_round_1: 30726,
    app_flat_1: 711,
    char_2: 'tingyun-fugue',
    app_rate_2: '41.56%',
    avg_round_2: 31100,
    app_flat_2: 621,
    char_3: 'the-herta',
    app_rate_3: '37.28%',
    avg_round_3: 39693,
    app_flat_3: 1175,
    char_4: 'tribbie',
    app_rate_4: '26.72%',
    avg_round_4: 40000,
    app_flat_4: 707,
    char_5: 'firefly',
    app_rate_5: '22.36%',
    avg_round_5: 25736,
    app_flat_5: 149,
    char_6: 'herta',
    app_rate_6: '21.72%',
    avg_round_6: 39021,
    app_flat_6: 716,
    char_7: 'jade',
    app_rate_7: '19.54%',
    avg_round_7: 39941,
    app_flat_7: 539,
    char_8: 'rappa',
    app_rate_8: '17.53%',
    avg_round_8: 31774,
    app_flat_8: 501,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '12.03%',
    avg_round_9: 38087,
    app_flat_9: 430,
    char_10: 'robin',
    app_rate_10: '11.62%',
    avg_round_10: 37380,
    app_flat_10: 274,
    char_11: 'trailblazer-harmony',
    app_rate_11: '9.67%',
    avg_round_11: 26041,
    app_flat_11: 140,
    char_12: 'sunday',
    app_rate_12: '9.11%',
    avg_round_12: 34735,
    app_flat_12: 202,
    char_13: 'acheron',
    app_rate_13: '4.86%',
    avg_round_13: 30671,
    app_flat_13: 89,
    char_14: 'lingsha',
    app_rate_14: '4.71%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'serval',
    app_rate_15: '3.25%',
    avg_round_15: 38688,
    app_flat_15: 98,
    char_16: 'himeko',
    app_rate_16: '2.35%',
    avg_round_16: 29577,
    app_flat_16: 52,
    char_17: 'jiaoqiu',
    app_rate_17: '2.14%',
    avg_round_17: 32495,
    app_flat_17: 34,
    char_18: 'aglaea',
    app_rate_18: '2.08%',
    avg_round_18: 33565,
    app_flat_18: 60,
    char_19: 'sparkle',
    app_rate_19: '1.77%',
    avg_round_19: 31735,
    app_flat_19: 21,
    char_20: 'jing-yuan',
    app_rate_20: '1.58%',
    avg_round_20: 30069,
    app_flat_20: 35,
    char_21: 'pela',
    app_rate_21: '1.54%',
    avg_round_21: 28614,
    app_flat_21: 35,
    char_22: 'argenti',
    app_rate_22: '1.01%',
    avg_round_22: 39229,
    app_flat_22: 27,
    char_23: 'kafka',
    app_rate_23: '0.95%',
    avg_round_23: 26050,
    app_flat_23: 16,
    char_24: 'yunli',
    app_rate_24: '0.68%',
    avg_round_24: 32573,
    app_flat_24: 19,
    char_25: 'black-swan',
    app_rate_25: '0.6%',
    avg_round_25: 24648,
    app_flat_25: 10,
    char_26: 'bronya',
    app_rate_26: '0.56%',
    avg_round_26: 32740,
    app_flat_26: 6,
    char_27: 'feixiao',
    app_rate_27: '0.54%',
    avg_round_27: 34453,
    app_flat_27: 6,
    char_28: 'tingyun',
    app_rate_28: '0.29%',
    avg_round_28: 32967,
    app_flat_28: 6,
    char_29: 'blade',
    app_rate_29: '0.19%',
    avg_round_29: 33264,
    app_flat_29: 5,
    char_30: 'clara',
    app_rate_30: '0.19%',
    avg_round_30: 28890,
    app_flat_30: 4
  },
  {
    char: 'acheron',
    app: 32.67,
    char_1: 'jiaoqiu',
    app_rate_1: '57.09%',
    avg_round_1: 30400,
    app_flat_1: 838,
    char_2: 'aventurine',
    app_rate_2: '41.49%',
    avg_round_2: 29741,
    app_flat_2: 613,
    char_3: 'pela',
    app_rate_3: '37.71%',
    avg_round_3: 28733,
    app_flat_3: 739,
    char_4: 'tribbie',
    app_rate_4: '24.93%',
    avg_round_4: 35818,
    app_flat_4: 305,
    char_5: 'fu-xuan',
    app_rate_5: '23.53%',
    avg_round_5: 26694,
    app_flat_5: 290,
    char_6: 'sparkle',
    app_rate_6: '18.81%',
    avg_round_6: 26974,
    app_flat_6: 58,
    char_7: 'kafka',
    app_rate_7: '15.86%',
    avg_round_7: 25225,
    app_flat_7: 278,
    char_8: 'gallagher',
    app_rate_8: '13.63%',
    avg_round_8: 28906,
    app_flat_8: 246,
    char_9: 'black-swan',
    app_rate_9: '12.05%',
    avg_round_9: 24469,
    app_flat_9: 206,
    char_10: 'sunday',
    app_rate_10: '9.1%',
    avg_round_10: 25358,
    app_flat_10: 68,
    char_11: 'robin',
    app_rate_11: '6.47%',
    avg_round_11: 28354,
    app_flat_11: 54,
    char_12: 'lingsha',
    app_rate_12: '5.91%',
    avg_round_12: 30671,
    app_flat_12: 89,
    char_13: 'tingyun-fugue',
    app_rate_13: '5.69%',
    avg_round_13: 29720,
    app_flat_13: 110,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '5.6%',
    avg_round_14: 29343,
    app_flat_14: 79,
    char_15: 'ruan-mei',
    app_rate_15: '3.97%',
    avg_round_15: 29583,
    app_flat_15: 41,
    char_16: 'silver-wolf',
    app_rate_16: '3.47%',
    avg_round_16: 24054,
    app_flat_16: 59,
    char_17: 'bronya',
    app_rate_17: '2.72%',
    avg_round_17: 26920,
    app_flat_17: 1,
    char_18: 'huohuo',
    app_rate_18: '2.55%',
    avg_round_18: 25989,
    app_flat_18: 29,
    char_19: 'gepard',
    app_rate_19: '1.96%',
    avg_round_19: 24494,
    app_flat_19: 26,
    char_20: 'luocha',
    app_rate_20: '1.84%',
    avg_round_20: 26071,
    app_flat_20: 23,
    char_21: 'jade',
    app_rate_21: '1.04%',
    avg_round_21: 30851,
    app_flat_21: 14,
    char_22: 'herta',
    app_rate_22: '0.95%',
    avg_round_22: 27640,
    app_flat_22: 15,
    char_23: 'himeko',
    app_rate_23: '0.66%',
    avg_round_23: 29333,
    app_flat_23: 9,
    char_24: 'guinaifen',
    app_rate_24: '0.38%',
    avg_round_24: 23960,
    app_flat_24: 13,
    char_25: 'serval',
    app_rate_25: '0.33%',
    avg_round_25: 26910,
    app_flat_25: 4,
    char_26: 'march-7th',
    app_rate_26: '0.31%',
    avg_round_26: 27280,
    app_flat_26: 3,
    char_27: 'yunli',
    app_rate_27: '0.26%',
    avg_round_27: 26053,
    app_flat_27: 3,
    char_28: 'bailu',
    app_rate_28: '0.21%',
    avg_round_28: 21480,
    app_flat_28: 1,
    char_29: 'firefly',
    app_rate_29: '0.17%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'welt',
    app_rate_30: '0.17%',
    avg_round_30: 24240,
    app_flat_30: 1
  },
  {
    char: 'tingyun-fugue',
    app: 30.73,
    char_1: 'ruan-mei',
    app_rate_1: '86.28%',
    avg_round_1: 31323,
    app_flat_1: 1186,
    char_2: 'lingsha',
    app_rate_2: '53.65%',
    avg_round_2: 31100,
    app_flat_2: 621,
    char_3: 'firefly',
    app_rate_3: '42.05%',
    avg_round_3: 25808,
    app_flat_3: 226,
    char_4: 'rappa',
    app_rate_4: '39.54%',
    avg_round_4: 32805,
    app_flat_4: 885,
    char_5: 'trailblazer-harmony',
    app_rate_5: '19.52%',
    avg_round_5: 37374,
    app_flat_5: 252,
    char_6: 'himeko',
    app_rate_6: '14.22%',
    avg_round_6: 29852,
    app_flat_6: 214,
    char_7: 'gallagher',
    app_rate_7: '14.04%',
    avg_round_7: 27721,
    app_flat_7: 268,
    char_8: 'herta',
    app_rate_8: '6.56%',
    avg_round_8: 28862,
    app_flat_8: 122,
    char_9: 'acheron',
    app_rate_9: '6.05%',
    avg_round_9: 29720,
    app_flat_9: 110,
    char_10: 'tribbie',
    app_rate_10: '2.94%',
    avg_round_10: 34197,
    app_flat_10: 35,
    char_11: 'aventurine',
    app_rate_11: '2.86%',
    avg_round_11: 27677,
    app_flat_11: 64,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '2.54%',
    avg_round_12: 32841,
    app_flat_12: 38,
    char_13: 'jiaoqiu',
    app_rate_13: '1.71%',
    avg_round_13: 30925,
    app_flat_13: 32,
    char_14: 'huohuo',
    app_rate_14: '1.38%',
    avg_round_14: 26542,
    app_flat_14: 26,
    char_15: 'pela',
    app_rate_15: '1.23%',
    avg_round_15: 26803,
    app_flat_15: 26,
    char_16: 'fu-xuan',
    app_rate_16: '1.13%',
    avg_round_16: 27035,
    app_flat_16: 16,
    char_17: 'sunday',
    app_rate_17: '1.08%',
    avg_round_17: 28788,
    app_flat_17: 13,
    char_18: 'luocha',
    app_rate_18: '0.75%',
    avg_round_18: 27289,
    app_flat_18: 17,
    char_19: 'the-herta',
    app_rate_19: '0.65%',
    avg_round_19: 32089,
    app_flat_19: 13,
    char_20: 'jade',
    app_rate_20: '0.6%',
    avg_round_20: 34709,
    app_flat_20: 11,
    char_21: 'bronya',
    app_rate_21: '0.55%',
    avg_round_21: 33413,
    app_flat_21: 6,
    char_22: 'robin',
    app_rate_22: '0.55%',
    avg_round_22: 27575,
    app_flat_22: 8,
    char_23: 'kafka',
    app_rate_23: '0.5%',
    avg_round_23: 25473,
    app_flat_23: 11,
    char_24: 'xueyi',
    app_rate_24: '0.35%',
    avg_round_24: 25960,
    app_flat_24: 5,
    char_25: 'argenti',
    app_rate_25: '0.3%',
    avg_round_25: 29620,
    app_flat_25: 6,
    char_26: 'boothill',
    app_rate_26: '0.18%',
    avg_round_26: 26800,
    app_flat_26: 2,
    char_27: 'gepard',
    app_rate_27: '0.18%',
    avg_round_27: 24293,
    app_flat_27: 3,
    char_28: 'serval',
    app_rate_28: '0.15%',
    avg_round_28: 25630,
    app_flat_28: 4,
    char_29: 'sparkle',
    app_rate_29: '0.15%',
    avg_round_29: 28980,
    app_flat_29: 2,
    char_30: 'black-swan',
    app_rate_30: '0.1%',
    avg_round_30: 23560,
    app_flat_30: 3
  },
  {
    char: 'jade',
    app: 29.37,
    char_1: 'the-herta',
    app_rate_1: '62.23%',
    avg_round_1: 39966,
    app_flat_1: 1288,
    char_2: 'tribbie',
    app_rate_2: '48.54%',
    avg_round_2: 40000,
    app_flat_2: 923,
    char_3: 'robin',
    app_rate_3: '29.36%',
    avg_round_3: 38261,
    app_flat_3: 520,
    char_4: 'lingsha',
    app_rate_4: '26.39%',
    avg_round_4: 39941,
    app_flat_4: 539,
    char_5: 'aventurine',
    app_rate_5: '26.07%',
    avg_round_5: 39327,
    app_flat_5: 515,
    char_6: 'herta',
    app_rate_6: '16.45%',
    avg_round_6: 36449,
    app_flat_6: 327,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '15.64%',
    avg_round_7: 39426,
    app_flat_7: 367,
    char_8: 'huohuo',
    app_rate_8: '15.43%',
    avg_round_8: 39611,
    app_flat_8: 309,
    char_9: 'fu-xuan',
    app_rate_9: '12.88%',
    avg_round_9: 38760,
    app_flat_9: 221,
    char_10: 'sunday',
    app_rate_10: '10.22%',
    avg_round_10: 37199,
    app_flat_10: 193,
    char_11: 'ruan-mei',
    app_rate_11: '5.86%',
    avg_round_11: 34613,
    app_flat_11: 108,
    char_12: 'luocha',
    app_rate_12: '5.12%',
    avg_round_12: 37128,
    app_flat_12: 106,
    char_13: 'gallagher',
    app_rate_13: '4.34%',
    avg_round_13: 39128,
    app_flat_13: 108,
    char_14: 'sparkle',
    app_rate_14: '2.63%',
    avg_round_14: 33491,
    app_flat_14: 32,
    char_15: 'jing-yuan',
    app_rate_15: '2.5%',
    avg_round_15: 29499,
    app_flat_15: 47,
    char_16: 'feixiao',
    app_rate_16: '1.71%',
    avg_round_16: 35233,
    app_flat_16: 6,
    char_17: 'argenti',
    app_rate_17: '1.6%',
    avg_round_17: 38165,
    app_flat_17: 29,
    char_18: 'aglaea',
    app_rate_18: '1.52%',
    avg_round_18: 31865,
    app_flat_18: 19,
    char_19: 'blade',
    app_rate_19: '1.5%',
    avg_round_19: 33497,
    app_flat_19: 21,
    char_20: 'yunli',
    app_rate_20: '1.42%',
    avg_round_20: 38597,
    app_flat_20: 27,
    char_21: 'acheron',
    app_rate_21: '1.16%',
    avg_round_21: 30851,
    app_flat_21: 14,
    char_22: 'serval',
    app_rate_22: '1.02%',
    avg_round_22: 31037,
    app_flat_22: 23,
    char_23: 'himeko',
    app_rate_23: '0.74%',
    avg_round_23: 32991,
    app_flat_23: 18,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.63%',
    avg_round_24: 34709,
    app_flat_24: 11,
    char_25: 'clara',
    app_rate_25: '0.6%',
    avg_round_25: 31443,
    app_flat_25: 12,
    char_26: 'tingyun',
    app_rate_26: '0.55%',
    avg_round_26: 34144,
    app_flat_26: 10,
    char_27: 'jiaoqiu',
    app_rate_27: '0.53%',
    avg_round_27: 32504,
    app_flat_27: 5,
    char_28: 'kafka',
    app_rate_28: '0.5%',
    avg_round_28: 30309,
    app_flat_28: 7,
    char_29: 'lynx',
    app_rate_29: '0.47%',
    avg_round_29: 34240,
    app_flat_29: 10,
    char_30: 'bronya',
    app_rate_30: '0.45%',
    avg_round_30: 33806,
    app_flat_30: 7
  },
  {
    char: 'huohuo',
    app: 26.73,
    char_1: 'tribbie',
    app_rate_1: '41.84%',
    avg_round_1: 39213,
    app_flat_1: 707,
    char_2: 'sunday',
    app_rate_2: '39.62%',
    avg_round_2: 30897,
    app_flat_2: 598,
    char_3: 'the-herta',
    app_rate_3: '39.19%',
    avg_round_3: 39522,
    app_flat_3: 799,
    char_4: 'robin',
    app_rate_4: '33.06%',
    avg_round_4: 30427,
    app_flat_4: 493,
    char_5: 'aglaea',
    app_rate_5: '25.47%',
    avg_round_5: 31234,
    app_flat_5: 397,
    char_6: 'herta',
    app_rate_6: '24.81%',
    avg_round_6: 38878,
    app_flat_6: 518,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '19.61%',
    avg_round_7: 34009,
    app_flat_7: 432,
    char_8: 'jade',
    app_rate_8: '16.95%',
    avg_round_8: 39611,
    app_flat_8: 309,
    char_9: 'jing-yuan',
    app_rate_9: '10.54%',
    avg_round_9: 28236,
    app_flat_9: 157,
    char_10: 'yunli',
    app_rate_10: '6.5%',
    avg_round_10: 31199,
    app_flat_10: 85,
    char_11: 'serval',
    app_rate_11: '5.54%',
    avg_round_11: 37664,
    app_flat_11: 96,
    char_12: 'ruan-mei',
    app_rate_12: '4.97%',
    avg_round_12: 25626,
    app_flat_12: 55,
    char_13: 'argenti',
    app_rate_13: '4.65%',
    avg_round_13: 34955,
    app_flat_13: 80,
    char_14: 'sparkle',
    app_rate_14: '4.04%',
    avg_round_14: 26266,
    app_flat_14: 33,
    char_15: 'kafka',
    app_rate_15: '3.73%',
    avg_round_15: 24157,
    app_flat_15: 23,
    char_16: 'acheron',
    app_rate_16: '3.12%',
    avg_round_16: 25989,
    app_flat_16: 29,
    char_17: 'black-swan',
    app_rate_17: '3.03%',
    avg_round_17: 23040,
    app_flat_17: 15,
    char_18: 'tingyun',
    app_rate_18: '1.93%',
    avg_round_18: 28910,
    app_flat_18: 25,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.59%',
    avg_round_19: 26542,
    app_flat_19: 26,
    char_20: 'rappa',
    app_rate_20: '1.41%',
    avg_round_20: 26126,
    app_flat_20: 27,
    char_21: 'jiaoqiu',
    app_rate_21: '1.27%',
    avg_round_21: 28897,
    app_flat_21: 11,
    char_22: 'himeko',
    app_rate_22: '1.21%',
    avg_round_22: 29322,
    app_flat_22: 22,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.9%',
    avg_round_23: 22960,
    app_flat_23: 1,
    char_24: 'bronya',
    app_rate_24: '0.87%',
    avg_round_24: 26953,
    app_flat_24: 10,
    char_25: 'mydei',
    app_rate_25: '0.66%',
    avg_round_25: 24084,
    app_flat_25: 9,
    char_26: 'clara',
    app_rate_26: '0.66%',
    avg_round_26: 26150,
    app_flat_26: 8,
    char_27: 'pela',
    app_rate_27: '0.52%',
    avg_round_27: 26450,
    app_flat_27: 8,
    char_28: 'trailblazer-harmony',
    app_rate_28: '0.4%',
    avg_round_28: 25418,
    app_flat_28: 9,
    char_29: 'blade',
    app_rate_29: '0.38%',
    avg_round_29: 33920,
    app_flat_29: 1,
    char_30: 'feixiao',
    app_rate_30: '0.29%',
    avg_round_30: 26080,
    app_flat_30: 2
  },
  {
    char: 'gallagher',
    app: 21.92,
    char_1: 'the-herta',
    app_rate_1: '29.8%',
    avg_round_1: 38961,
    app_flat_1: 628,
    char_2: 'ruan-mei',
    app_rate_2: '27.19%',
    avg_round_2: 27084,
    app_flat_2: 370,
    char_3: 'herta',
    app_rate_3: '24.34%',
    avg_round_3: 38427,
    app_flat_3: 500,
    char_4: 'tribbie',
    app_rate_4: '23.99%',
    avg_round_4: 39545,
    app_flat_4: 399,
    char_5: 'acheron',
    app_rate_5: '20.32%',
    avg_round_5: 28906,
    app_flat_5: 246,
    char_6: 'tingyun-fugue',
    app_rate_6: '19.69%',
    avg_round_6: 27721,
    app_flat_6: 268,
    char_7: 'sunday',
    app_rate_7: '16.98%',
    avg_round_7: 31768,
    app_flat_7: 197,
    char_8: 'robin',
    app_rate_8: '16.31%',
    avg_round_8: 31919,
    app_flat_8: 195,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '16.31%',
    avg_round_9: 36442,
    app_flat_9: 330,
    char_10: 'rappa',
    app_rate_10: '16.27%',
    avg_round_10: 27419,
    app_flat_10: 318,
    char_11: 'jiaoqiu',
    app_rate_11: '13.6%',
    avg_round_11: 30006,
    app_flat_11: 174,
    char_12: 'firefly',
    app_rate_12: '10.25%',
    avg_round_12: 24202,
    app_flat_12: 43,
    char_13: 'trailblazer-harmony',
    app_rate_13: '9.97%',
    avg_round_13: 24797,
    app_flat_13: 146,
    char_14: 'pela',
    app_rate_14: '8.42%',
    avg_round_14: 28452,
    app_flat_14: 148,
    char_15: 'jing-yuan',
    app_rate_15: '6.69%',
    avg_round_15: 30886,
    app_flat_15: 81,
    char_16: 'jade',
    app_rate_16: '5.81%',
    avg_round_16: 39128,
    app_flat_16: 108,
    char_17: 'serval',
    app_rate_17: '5.46%',
    avg_round_17: 36794,
    app_flat_17: 99,
    char_18: 'aglaea',
    app_rate_18: '4.83%',
    avg_round_18: 32803,
    app_flat_18: 57,
    char_19: 'kafka',
    app_rate_19: '3.77%',
    avg_round_19: 25496,
    app_flat_19: 44,
    char_20: 'sparkle',
    app_rate_20: '3.73%',
    avg_round_20: 27640,
    app_flat_20: 17,
    char_21: 'himeko',
    app_rate_21: '3.17%',
    avg_round_21: 27751,
    app_flat_21: 52,
    char_22: 'black-swan',
    app_rate_22: '2.96%',
    avg_round_22: 24349,
    app_flat_22: 34,
    char_23: 'argenti',
    app_rate_23: '2.08%',
    avg_round_23: 39362,
    app_flat_23: 42,
    char_24: 'mydei',
    app_rate_24: '1.76%',
    avg_round_24: 26502,
    app_flat_24: 9,
    char_25: 'bronya',
    app_rate_25: '1.27%',
    avg_round_25: 25693,
    app_flat_25: 5,
    char_26: 'tingyun',
    app_rate_26: '0.85%',
    avg_round_26: 25380,
    app_flat_26: 16,
    char_27: 'feixiao',
    app_rate_27: '0.77%',
    avg_round_27: 26040,
    app_flat_27: 10,
    char_28: 'yunli',
    app_rate_28: '0.74%',
    avg_round_28: 31606,
    app_flat_28: 7,
    char_29: 'silver-wolf',
    app_rate_29: '0.56%',
    avg_round_29: 23422,
    app_flat_29: 9,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.46%',
    avg_round_30: 24000,
    app_flat_30: 1
  },
  {
    char: 'fu-xuan',
    app: 20.89,
    char_1: 'tribbie',
    app_rate_1: '41.91%',
    avg_round_1: 38930,
    app_flat_1: 439,
    char_2: 'acheron',
    app_rate_2: '36.81%',
    avg_round_2: 26694,
    app_flat_2: 290,
    char_3: 'the-herta',
    app_rate_3: '28.94%',
    avg_round_3: 39218,
    app_flat_3: 386,
    char_4: 'herta',
    app_rate_4: '21.88%',
    avg_round_4: 38049,
    app_flat_4: 278,
    char_5: 'sunday',
    app_rate_5: '18.37%',
    avg_round_5: 29821,
    app_flat_5: 185,
    char_6: 'jade',
    app_rate_6: '18.11%',
    avg_round_6: 38760,
    app_flat_6: 221,
    char_7: 'jiaoqiu',
    app_rate_7: '17.92%',
    avg_round_7: 27687,
    app_flat_7: 144,
    char_8: 'robin',
    app_rate_8: '17.22%',
    avg_round_8: 31603,
    app_flat_8: 163,
    char_9: 'sparkle',
    app_rate_9: '14.93%',
    avg_round_9: 25880,
    app_flat_9: 60,
    char_10: 'pela',
    app_rate_10: '14.23%',
    avg_round_10: 26701,
    app_flat_10: 149,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '12.31%',
    avg_round_11: 33762,
    app_flat_11: 174,
    char_12: 'aglaea',
    app_rate_12: '7.91%',
    avg_round_12: 31600,
    app_flat_12: 82,
    char_13: 'kafka',
    app_rate_13: '7.69%',
    avg_round_13: 24633,
    app_flat_13: 86,
    char_14: 'jing-yuan',
    app_rate_14: '7.65%',
    avg_round_14: 27658,
    app_flat_14: 73,
    char_15: 'black-swan',
    app_rate_15: '5.25%',
    avg_round_15: 24190,
    app_flat_15: 54,
    char_16: 'serval',
    app_rate_16: '4.51%',
    avg_round_16: 34439,
    app_flat_16: 59,
    char_17: 'ruan-mei',
    app_rate_17: '4.25%',
    avg_round_17: 26908,
    app_flat_17: 32,
    char_18: 'yunli',
    app_rate_18: '2.48%',
    avg_round_18: 32068,
    app_flat_18: 29,
    char_19: 'silver-wolf',
    app_rate_19: '2.29%',
    avg_round_19: 23684,
    app_flat_19: 20,
    char_20: 'argenti',
    app_rate_20: '1.77%',
    avg_round_20: 35021,
    app_flat_20: 28,
    char_21: 'himeko',
    app_rate_21: '1.7%',
    avg_round_21: 29339,
    app_flat_21: 19,
    char_22: 'tingyun-fugue',
    app_rate_22: '1.66%',
    avg_round_22: 27035,
    app_flat_22: 16,
    char_23: 'bronya',
    app_rate_23: '1.44%',
    avg_round_23: 25260,
    app_flat_23: 4,
    char_24: 'qingque',
    app_rate_24: '1.37%',
    avg_round_24: 24080,
    app_flat_24: 7,
    char_25: 'tingyun',
    app_rate_25: '1.29%',
    avg_round_25: 24629,
    app_flat_25: 13,
    char_26: 'clara',
    app_rate_26: '1.07%',
    avg_round_26: 27632,
    app_flat_26: 10,
    char_27: 'blade',
    app_rate_27: '0.96%',
    avg_round_27: 36728,
    app_flat_27: 7,
    char_28: 'feixiao',
    app_rate_28: '0.85%',
    avg_round_28: 29160,
    app_flat_28: 10,
    char_29: 'seele',
    app_rate_29: '0.81%',
    avg_round_29: 29880,
    app_flat_29: 3,
    char_30: 'rappa',
    app_rate_30: '0.55%',
    avg_round_30: 24880,
    app_flat_30: 7
  },
  {
    char: 'jiaoqiu',
    app: 19.41,
    char_1: 'acheron',
    app_rate_1: '96.1%',
    avg_round_1: 30400,
    app_flat_1: 838,
    char_2: 'aventurine',
    app_rate_2: '45.94%',
    avg_round_2: 30781,
    app_flat_2: 403,
    char_3: 'pela',
    app_rate_3: '25.38%',
    avg_round_3: 29303,
    app_flat_3: 443,
    char_4: 'sparkle',
    app_rate_4: '21.0%',
    avg_round_4: 28453,
    app_flat_4: 35,
    char_5: 'tribbie',
    app_rate_5: '20.96%',
    avg_round_5: 39022,
    app_flat_5: 150,
    char_6: 'fu-xuan',
    app_rate_6: '19.29%',
    avg_round_6: 27687,
    app_flat_6: 144,
    char_7: 'gallagher',
    app_rate_7: '15.35%',
    avg_round_7: 30006,
    app_flat_7: 174,
    char_8: 'sunday',
    app_rate_8: '12.37%',
    avg_round_8: 26123,
    app_flat_8: 52,
    char_9: 'robin',
    app_rate_9: '6.17%',
    avg_round_9: 31042,
    app_flat_9: 25,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '5.73%',
    avg_round_10: 30176,
    app_flat_10: 47,
    char_11: 'lingsha',
    app_rate_11: '4.38%',
    avg_round_11: 32495,
    app_flat_11: 34,
    char_12: 'kafka',
    app_rate_12: '3.7%',
    avg_round_12: 28446,
    app_flat_12: 44,
    char_13: 'black-swan',
    app_rate_13: '2.94%',
    avg_round_13: 26506,
    app_flat_13: 37,
    char_14: 'luocha',
    app_rate_14: '2.74%',
    avg_round_14: 26489,
    app_flat_14: 15,
    char_15: 'tingyun-fugue',
    app_rate_15: '2.7%',
    avg_round_15: 30925,
    app_flat_15: 32,
    char_16: 'ruan-mei',
    app_rate_16: '2.59%',
    avg_round_16: 30490,
    app_flat_16: 12,
    char_17: 'bronya',
    app_rate_17: '2.59%',
    avg_round_17: 27640,
    app_flat_17: 2,
    char_18: 'huohuo',
    app_rate_18: '1.75%',
    avg_round_18: 28897,
    app_flat_18: 11,
    char_19: 'silver-wolf',
    app_rate_19: '1.23%',
    avg_round_19: 26929,
    app_flat_19: 14,
    char_20: 'mydei',
    app_rate_20: '1.15%',
    avg_round_20: 30853,
    app_flat_20: 3,
    char_21: 'herta',
    app_rate_21: '1.07%',
    avg_round_21: 28540,
    app_flat_21: 6,
    char_22: 'gepard',
    app_rate_22: '0.84%',
    avg_round_22: 26488,
    app_flat_22: 7,
    char_23: 'jade',
    app_rate_23: '0.8%',
    avg_round_23: 32504,
    app_flat_23: 5,
    char_24: 'jing-yuan',
    app_rate_24: '0.6%',
    avg_round_24: 27480,
    app_flat_24: 1,
    char_25: 'the-herta',
    app_rate_25: '0.6%',
    avg_round_25: 31907,
    app_flat_25: 3,
    char_26: 'argenti',
    app_rate_26: '0.52%',
    avg_round_26: 28360,
    app_flat_26: 1,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.16%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'bailu',
    app_rate_28: '0.16%',
    avg_round_28: 21480,
    app_flat_28: 1,
    char_29: 'tingyun',
    app_rate_29: '0.16%',
    avg_round_29: 40000,
    app_flat_29: 1,
    char_30: 'topaz',
    app_rate_30: '0.12%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'rappa',
    app: 16.01,
    char_1: 'ruan-mei',
    app_rate_1: '90.55%',
    avg_round_1: 31410,
    app_flat_1: 1097,
    char_2: 'tingyun-fugue',
    app_rate_2: '75.89%',
    avg_round_2: 32805,
    app_flat_2: 885,
    char_3: 'lingsha',
    app_rate_3: '43.44%',
    avg_round_3: 31774,
    app_flat_3: 501,
    char_4: 'trailblazer-harmony',
    app_rate_4: '39.63%',
    avg_round_4: 30444,
    app_flat_4: 520,
    char_5: 'gallagher',
    app_rate_5: '22.28%',
    avg_round_5: 27419,
    app_flat_5: 318,
    char_6: 'herta',
    app_rate_6: '3.81%',
    avg_round_6: 29920,
    app_flat_6: 48,
    char_7: 'aventurine',
    app_rate_7: '3.71%',
    avg_round_7: 25749,
    app_flat_7: 49,
    char_8: 'firefly',
    app_rate_8: '2.84%',
    avg_round_8: 30687,
    app_flat_8: 11,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '2.84%',
    avg_round_9: 38497,
    app_flat_9: 26,
    char_10: 'himeko',
    app_rate_10: '2.41%',
    avg_round_10: 34497,
    app_flat_10: 38,
    char_11: 'huohuo',
    app_rate_11: '2.36%',
    avg_round_11: 26126,
    app_flat_11: 27,
    char_12: 'luocha',
    app_rate_12: '1.83%',
    avg_round_12: 26939,
    app_flat_12: 23,
    char_13: 'tribbie',
    app_rate_13: '1.64%',
    avg_round_13: 37191,
    app_flat_13: 15,
    char_14: 'the-herta',
    app_rate_14: '1.59%',
    avg_round_14: 34450,
    app_flat_14: 12,
    char_15: 'sunday',
    app_rate_15: '1.54%',
    avg_round_15: 29680,
    app_flat_15: 18,
    char_16: 'fu-xuan',
    app_rate_16: '0.72%',
    avg_round_16: 24880,
    app_flat_16: 7,
    char_17: 'pela',
    app_rate_17: '0.68%',
    avg_round_17: 31280,
    app_flat_17: 10,
    char_18: 'bronya',
    app_rate_18: '0.58%',
    avg_round_18: 31150,
    app_flat_18: 8,
    char_19: 'robin',
    app_rate_19: '0.58%',
    avg_round_19: 30853,
    app_flat_19: 6,
    char_20: 'sparkle',
    app_rate_20: '0.24%',
    avg_round_20: 29640,
    app_flat_20: 1,
    char_21: 'tingyun',
    app_rate_21: '0.14%',
    avg_round_21: 29820,
    app_flat_21: 2,
    char_22: 'jade',
    app_rate_22: '0.1%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'mydei',
    app_rate_23: '0.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jingliu',
    app_rate_24: '0.05%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'bailu',
    app_rate_25: '0.05%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'march-7th',
    app_rate_26: '0.05%',
    avg_round_26: 26720,
    app_flat_26: 1,
    char_27: 'aglaea',
    app_rate_27: '0.05%',
    avg_round_27: 20480,
    app_flat_27: 1,
    char_28: 'jiaoqiu',
    app_rate_28: '0.05%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.05%',
    avg_round_29: 24960,
    app_flat_29: 1,
    char_30: 'seele',
    app_rate_30: '0.05%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'firefly',
    app: 15.28,
    char_1: 'ruan-mei',
    app_rate_1: '93.03%',
    avg_round_1: 25610,
    app_flat_1: 231,
    char_2: 'tingyun-fugue',
    app_rate_2: '84.59%',
    avg_round_2: 25808,
    app_flat_2: 226,
    char_3: 'lingsha',
    app_rate_3: '58.06%',
    avg_round_3: 25736,
    app_flat_3: 149,
    char_4: 'trailblazer-harmony',
    app_rate_4: '28.09%',
    avg_round_4: 23252,
    app_flat_4: 30,
    char_5: 'gallagher',
    app_rate_5: '14.7%',
    avg_round_5: 24202,
    app_flat_5: 43,
    char_6: 'himeko',
    app_rate_6: '10.71%',
    avg_round_6: 27716,
    app_flat_6: 36,
    char_7: 'rappa',
    app_rate_7: '2.98%',
    avg_round_7: 30687,
    app_flat_7: 11,
    char_8: 'herta',
    app_rate_8: '2.22%',
    avg_round_8: 26290,
    app_flat_8: 4,
    char_9: 'tribbie',
    app_rate_9: '1.26%',
    avg_round_9: 28050,
    app_flat_9: 4,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '0.96%',
    avg_round_10: 24480,
    app_flat_10: 2,
    char_11: 'bronya',
    app_rate_11: '0.71%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'huohuo',
    app_rate_12: '0.45%',
    avg_round_12: 27000,
    app_flat_12: 1,
    char_13: 'aventurine',
    app_rate_13: '0.45%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'acheron',
    app_rate_14: '0.35%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'fu-xuan',
    app_rate_15: '0.25%',
    avg_round_15: 27040,
    app_flat_15: 1,
    char_16: 'sunday',
    app_rate_16: '0.2%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'robin',
    app_rate_17: '0.15%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'pela',
    app_rate_18: '0.1%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '0.1%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'the-herta',
    app_rate_20: '0.1%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'luocha',
    app_rate_21: '0.1%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'kafka',
    app_rate_22: '0.05%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'natasha',
    app_rate_23: '0.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jingliu',
    app_rate_24: '0.05%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jiaoqiu',
    app_rate_25: '0.05%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: '0.05%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'guinaifen',
    app_rate_27: '0.05%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'gepard',
    app_rate_28: '0.05%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'arlan',
    app_rate_29: '0.05%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'aglaea',
    app: 14.03,
    char_1: 'sunday',
    app_rate_1: '82.94%',
    avg_round_1: 32296,
    app_flat_1: 644,
    char_2: 'robin',
    app_rate_2: '57.4%',
    avg_round_2: 31230,
    app_flat_2: 436,
    char_3: 'huohuo',
    app_rate_3: '48.54%',
    avg_round_3: 31234,
    app_flat_3: 397,
    char_4: 'tribbie',
    app_rate_4: '28.95%',
    avg_round_4: 35568,
    app_flat_4: 235,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '26.91%',
    avg_round_5: 30603,
    app_flat_5: 220,
    char_6: 'fu-xuan',
    app_rate_6: '11.78%',
    avg_round_6: 31600,
    app_flat_6: 82,
    char_7: 'gallagher',
    app_rate_7: '7.54%',
    avg_round_7: 32803,
    app_flat_7: 57,
    char_8: 'bronya',
    app_rate_8: '6.88%',
    avg_round_8: 31677,
    app_flat_8: 38,
    char_9: 'aventurine',
    app_rate_9: '6.77%',
    avg_round_9: 31319,
    app_flat_9: 54,
    char_10: 'lingsha',
    app_rate_10: '5.89%',
    avg_round_10: 33565,
    app_flat_10: 60,
    char_11: 'luocha',
    app_rate_11: '5.06%',
    avg_round_11: 31207,
    app_flat_11: 30,
    char_12: 'jade',
    app_rate_12: '3.19%',
    avg_round_12: 31865,
    app_flat_12: 19,
    char_13: 'ruan-mei',
    app_rate_13: '2.31%',
    avg_round_13: 28971,
    app_flat_13: 14,
    char_14: 'sparkle',
    app_rate_14: '1.43%',
    avg_round_14: 29140,
    app_flat_14: 8,
    char_15: 'bailu',
    app_rate_15: '0.88%',
    avg_round_15: 31270,
    app_flat_15: 4,
    char_16: 'tingyun',
    app_rate_16: '0.66%',
    avg_round_16: 22810,
    app_flat_16: 4,
    char_17: 'herta',
    app_rate_17: '0.55%',
    avg_round_17: 30256,
    app_flat_17: 5,
    char_18: 'acheron',
    app_rate_18: '0.28%',
    avg_round_18: 27620,
    app_flat_18: 2,
    char_19: 'lynx',
    app_rate_19: '0.28%',
    avg_round_19: 26160,
    app_flat_19: 2,
    char_20: 'pela',
    app_rate_20: '0.17%',
    avg_round_20: 23820,
    app_flat_20: 2,
    char_21: 'asta',
    app_rate_21: '0.17%',
    avg_round_21: 28580,
    app_flat_21: 2,
    char_22: 'yunli',
    app_rate_22: '0.17%',
    avg_round_22: 26680,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.17%',
    avg_round_23: 26240,
    app_flat_23: 2,
    char_24: 'kafka',
    app_rate_24: '0.17%',
    avg_round_24: 25180,
    app_flat_24: 2,
    char_25: 'the-herta',
    app_rate_25: '0.11%',
    avg_round_25: 29560,
    app_flat_25: 1,
    char_26: 'gepard',
    app_rate_26: '0.11%',
    avg_round_26: 26300,
    app_flat_26: 2,
    char_27: 'jiaoqiu',
    app_rate_27: '0.11%',
    avg_round_27: 29080,
    app_flat_27: 1,
    char_28: 'clara',
    app_rate_28: '0.06%',
    avg_round_28: 33280,
    app_flat_28: 1,
    char_29: 'rappa',
    app_rate_29: '0.06%',
    avg_round_29: 20480,
    app_flat_29: 1,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.06%',
    avg_round_30: 20480,
    app_flat_30: 1
  },
  {
    char: 'pela',
    app: 13.1,
    char_1: 'acheron',
    app_rate_1: '94.05%',
    avg_round_1: 28733,
    app_flat_1: 739,
    char_2: 'aventurine',
    app_rate_2: '41.6%',
    avg_round_2: 29185,
    app_flat_2: 338,
    char_3: 'jiaoqiu',
    app_rate_3: '37.6%',
    avg_round_3: 29303,
    app_flat_3: 443,
    char_4: 'fu-xuan',
    app_rate_4: '22.69%',
    avg_round_4: 26701,
    app_flat_4: 149,
    char_5: 'tribbie',
    app_rate_5: '21.92%',
    avg_round_5: 34670,
    app_flat_5: 122,
    char_6: 'gallagher',
    app_rate_6: '14.08%',
    avg_round_6: 28452,
    app_flat_6: 148,
    char_7: 'sparkle',
    app_rate_7: '11.02%',
    avg_round_7: 24640,
    app_flat_7: 16,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '6.95%',
    avg_round_8: 30756,
    app_flat_8: 57,
    char_9: 'sunday',
    app_rate_9: '5.54%',
    avg_round_9: 24260,
    app_flat_9: 18,
    char_10: 'lingsha',
    app_rate_10: '4.66%',
    avg_round_10: 28614,
    app_flat_10: 35,
    char_11: 'kafka',
    app_rate_11: '4.66%',
    avg_round_11: 25518,
    app_flat_11: 52,
    char_12: 'robin',
    app_rate_12: '4.12%',
    avg_round_12: 25808,
    app_flat_12: 20,
    char_13: 'silver-wolf',
    app_rate_13: '3.65%',
    avg_round_13: 23784,
    app_flat_13: 38,
    char_14: 'ruan-mei',
    app_rate_14: '3.3%',
    avg_round_14: 30901,
    app_flat_14: 17,
    char_15: 'herta',
    app_rate_15: '3.24%',
    avg_round_15: 32795,
    app_flat_15: 33,
    char_16: 'tingyun-fugue',
    app_rate_16: '2.89%',
    avg_round_16: 26803,
    app_flat_16: 26,
    char_17: 'black-swan',
    app_rate_17: '2.65%',
    avg_round_17: 23185,
    app_flat_17: 21,
    char_18: 'bronya',
    app_rate_18: '2.47%',
    avg_round_18: 24080,
    app_flat_18: 1,
    char_19: 'gepard',
    app_rate_19: '2.18%',
    avg_round_19: 24180,
    app_flat_19: 12,
    char_20: 'the-herta',
    app_rate_20: '2.0%',
    avg_round_20: 35709,
    app_flat_20: 19,
    char_21: 'luocha',
    app_rate_21: '1.18%',
    avg_round_21: 24480,
    app_flat_21: 6,
    char_22: 'huohuo',
    app_rate_22: '1.06%',
    avg_round_22: 26450,
    app_flat_22: 8,
    char_23: 'jade',
    app_rate_23: '0.88%',
    avg_round_23: 25910,
    app_flat_23: 4,
    char_24: 'trailblazer-harmony',
    app_rate_24: '0.82%',
    avg_round_24: 30662,
    app_flat_24: 11,
    char_25: 'rappa',
    app_rate_25: '0.82%',
    avg_round_25: 31280,
    app_flat_25: 10,
    char_26: 'guinaifen',
    app_rate_26: '0.71%',
    avg_round_26: 23945,
    app_flat_26: 11,
    char_27: 'serval',
    app_rate_27: '0.53%',
    avg_round_27: 32630,
    app_flat_27: 4,
    char_28: 'march-7th',
    app_rate_28: '0.41%',
    avg_round_28: 30880,
    app_flat_28: 1,
    char_29: 'himeko',
    app_rate_29: '0.35%',
    avg_round_29: 25710,
    app_flat_29: 4,
    char_30: 'mydei',
    app_rate_30: '0.35%',
    avg_round_30: 24040,
    app_flat_30: 4
  },
  {
    char: 'jing-yuan',
    app: 13.09,
    char_1: 'sunday',
    app_rate_1: '96.23%',
    avg_round_1: 29536,
    app_flat_1: 669,
    char_2: 'robin',
    app_rate_2: '57.67%',
    avg_round_2: 28680,
    app_flat_2: 401,
    char_3: 'aventurine',
    app_rate_3: '38.03%',
    avg_round_3: 30433,
    app_flat_3: 258,
    char_4: 'tribbie',
    app_rate_4: '25.88%',
    avg_round_4: 34181,
    app_flat_4: 172,
    char_5: 'huohuo',
    app_rate_5: '21.52%',
    avg_round_5: 28236,
    app_flat_5: 157,
    char_6: 'fu-xuan',
    app_rate_6: '12.21%',
    avg_round_6: 27658,
    app_flat_6: 73,
    char_7: 'gallagher',
    app_rate_7: '11.2%',
    avg_round_7: 30886,
    app_flat_7: 81,
    char_8: 'luocha',
    app_rate_8: '6.37%',
    avg_round_8: 29941,
    app_flat_8: 54,
    char_9: 'jade',
    app_rate_9: '5.6%',
    avg_round_9: 29499,
    app_flat_9: 47,
    char_10: 'sparkle',
    app_rate_10: '5.37%',
    avg_round_10: 24817,
    app_flat_10: 41,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '5.25%',
    avg_round_11: 26619,
    app_flat_11: 41,
    char_12: 'lingsha',
    app_rate_12: '4.78%',
    avg_round_12: 30069,
    app_flat_12: 35,
    char_13: 'tingyun',
    app_rate_13: '2.65%',
    avg_round_13: 26323,
    app_flat_13: 15,
    char_14: 'ruan-mei',
    app_rate_14: '2.54%',
    avg_round_14: 26470,
    app_flat_14: 14,
    char_15: 'bronya',
    app_rate_15: '1.06%',
    avg_round_15: 23056,
    app_flat_15: 7,
    char_16: 'jiaoqiu',
    app_rate_16: '0.88%',
    avg_round_16: 27480,
    app_flat_16: 1,
    char_17: 'the-herta',
    app_rate_17: '0.53%',
    avg_round_17: 35800,
    app_flat_17: 3,
    char_18: 'bailu',
    app_rate_18: '0.53%',
    avg_round_18: 26640,
    app_flat_18: 6,
    char_19: 'herta',
    app_rate_19: '0.47%',
    avg_round_19: 25150,
    app_flat_19: 4,
    char_20: 'pela',
    app_rate_20: '0.12%',
    avg_round_20: 24360,
    app_flat_20: 1,
    char_21: 'yunli',
    app_rate_21: '0.12%',
    avg_round_21: 22600,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '0.12%',
    avg_round_22: 27640,
    app_flat_22: 1,
    char_23: 'gepard',
    app_rate_23: '0.12%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'hanya',
    app_rate_24: '0.12%',
    avg_round_24: 26180,
    app_flat_24: 2,
    char_25: 'clara',
    app_rate_25: '0.12%',
    avg_round_25: 25280,
    app_flat_25: 2,
    char_26: 'lynx',
    app_rate_26: '0.12%',
    avg_round_26: 24640,
    app_flat_26: 1,
    char_27: 'mydei',
    app_rate_27: '0.06%',
    avg_round_27: 22040,
    app_flat_27: 1,
    char_28: 'natasha',
    app_rate_28: '0.06%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'firefly',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.06%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'sparkle',
    app: 12.72,
    char_1: 'acheron',
    app_rate_1: '48.3%',
    avg_round_1: 26974,
    app_flat_1: 58,
    char_2: 'jiaoqiu',
    app_rate_2: '32.04%',
    avg_round_2: 28453,
    app_flat_2: 35,
    char_3: 'aventurine',
    app_rate_3: '27.49%',
    avg_round_3: 27942,
    app_flat_3: 59,
    char_4: 'fu-xuan',
    app_rate_4: '24.51%',
    avg_round_4: 25880,
    app_flat_4: 60,
    char_5: 'tribbie',
    app_rate_5: '18.39%',
    avg_round_5: 32362,
    app_flat_5: 60,
    char_6: 'herta',
    app_rate_6: '15.23%',
    avg_round_6: 31016,
    app_flat_6: 80,
    char_7: 'sunday',
    app_rate_7: '12.5%',
    avg_round_7: 25434,
    app_flat_7: 76,
    char_8: 'pela',
    app_rate_8: '11.35%',
    avg_round_8: 24640,
    app_flat_8: 16,
    char_9: 'the-herta',
    app_rate_9: '10.62%',
    avg_round_9: 34728,
    app_flat_9: 58,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '10.01%',
    avg_round_10: 31271,
    app_flat_10: 66,
    char_11: 'robin',
    app_rate_11: '9.77%',
    avg_round_11: 25623,
    app_flat_11: 35,
    char_12: 'huohuo',
    app_rate_12: '8.5%',
    avg_round_12: 26266,
    app_flat_12: 33,
    char_13: 'gallagher',
    app_rate_13: '6.43%',
    avg_round_13: 27640,
    app_flat_13: 17,
    char_14: 'jade',
    app_rate_14: '6.07%',
    avg_round_14: 33491,
    app_flat_14: 32,
    char_15: 'jing-yuan',
    app_rate_15: '5.52%',
    avg_round_15: 24817,
    app_flat_15: 41,
    char_16: 'lingsha',
    app_rate_16: '5.52%',
    avg_round_16: 31735,
    app_flat_16: 21,
    char_17: 'yunli',
    app_rate_17: '5.28%',
    avg_round_17: 29304,
    app_flat_17: 22,
    char_18: 'argenti',
    app_rate_18: '5.1%',
    avg_round_18: 30768,
    app_flat_18: 40,
    char_19: 'imbibitor-lunae',
    app_rate_19: '4.85%',
    avg_round_19: 22360,
    app_flat_19: 3,
    char_20: 'ruan-mei',
    app_rate_20: '4.0%',
    avg_round_20: 27373,
    app_flat_20: 15,
    char_21: 'luocha',
    app_rate_21: '3.7%',
    avg_round_21: 23982,
    app_flat_21: 11,
    char_22: 'mydei',
    app_rate_22: '3.34%',
    avg_round_22: 24367,
    app_flat_22: 12,
    char_23: 'tingyun',
    app_rate_23: '3.03%',
    avg_round_23: 27920,
    app_flat_23: 14,
    char_24: 'qingque',
    app_rate_24: '2.91%',
    avg_round_24: 23817,
    app_flat_24: 13,
    char_25: 'kafka',
    app_rate_25: '1.88%',
    avg_round_25: 24813,
    app_flat_25: 3,
    char_26: 'aglaea',
    app_rate_26: '1.58%',
    avg_round_26: 29140,
    app_flat_26: 8,
    char_27: 'bronya',
    app_rate_27: '1.58%',
    avg_round_27: 24520,
    app_flat_27: 5,
    char_28: 'seele',
    app_rate_28: '1.4%',
    avg_round_28: 25360,
    app_flat_28: 1,
    char_29: 'clara',
    app_rate_29: '1.4%',
    avg_round_29: 25080,
    app_flat_29: 10,
    char_30: 'silver-wolf',
    app_rate_30: '1.27%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-harmony',
    app: 11.55,
    char_1: 'ruan-mei',
    app_rate_1: '85.29%',
    avg_round_1: 30450,
    app_flat_1: 480,
    char_2: 'rappa',
    app_rate_2: '54.95%',
    avg_round_2: 30444,
    app_flat_2: 520,
    char_3: 'tingyun-fugue',
    app_rate_3: '51.94%',
    avg_round_3: 37374,
    app_flat_3: 252,
    char_4: 'firefly',
    app_rate_4: '37.17%',
    avg_round_4: 23252,
    app_flat_4: 30,
    char_5: 'lingsha',
    app_rate_5: '33.22%',
    avg_round_5: 26041,
    app_flat_5: 140,
    char_6: 'gallagher',
    app_rate_6: '18.92%',
    avg_round_6: 24797,
    app_flat_6: 146,
    char_7: 'himeko',
    app_rate_7: '7.15%',
    avg_round_7: 32395,
    app_flat_7: 35,
    char_8: 'herta',
    app_rate_8: '5.21%',
    avg_round_8: 29477,
    app_flat_8: 35,
    char_9: 'aventurine',
    app_rate_9: '1.87%',
    avg_round_9: 23618,
    app_flat_9: 18,
    char_10: 'tribbie',
    app_rate_10: '1.74%',
    avg_round_10: 37776,
    app_flat_10: 9,
    char_11: 'sunday',
    app_rate_11: '1.6%',
    avg_round_11: 29247,
    app_flat_11: 12,
    char_12: 'luocha',
    app_rate_12: '1.0%',
    avg_round_12: 25178,
    app_flat_12: 9,
    char_13: 'pela',
    app_rate_13: '0.94%',
    avg_round_13: 30662,
    app_flat_13: 11,
    char_14: 'huohuo',
    app_rate_14: '0.94%',
    avg_round_14: 25418,
    app_flat_14: 9,
    char_15: 'bronya',
    app_rate_15: '0.6%',
    avg_round_15: 29573,
    app_flat_15: 3,
    char_16: 'fu-xuan',
    app_rate_16: '0.2%',
    avg_round_16: 27560,
    app_flat_16: 1,
    char_17: 'robin',
    app_rate_17: '0.2%',
    avg_round_17: 27320,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.13%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'acheron',
    app_rate_19: '0.13%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'the-herta',
    app_rate_20: '0.13%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'boothill',
    app_rate_21: '0.13%',
    avg_round_21: 28800,
    app_flat_21: 2,
    char_22: 'mydei',
    app_rate_22: '0.07%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jade',
    app_rate_23: '0.07%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'serval',
    app_rate_24: '0.07%',
    avg_round_24: 23480,
    app_flat_24: 1,
    char_25: 'xueyi',
    app_rate_25: '0.07%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'argenti',
    app_rate_26: '0.07%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'aglaea',
    app_rate_27: '0.07%',
    avg_round_27: 20480,
    app_flat_27: 1,
    char_28: 'jing-yuan',
    app_rate_28: '0.07%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'lynx',
    app_rate_29: '0.07%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'welt',
    app_rate_30: '0.07%',
    avg_round_30: 24000,
    app_flat_30: 1
  },
  {
    char: 'luocha',
    app: 8.75,
    char_1: 'the-herta',
    app_rate_1: '43.42%',
    avg_round_1: 35842,
    app_flat_1: 306,
    char_2: 'herta',
    app_rate_2: '34.25%',
    avg_round_2: 34122,
    app_flat_2: 249,
    char_3: 'sunday',
    app_rate_3: '31.24%',
    avg_round_3: 29182,
    app_flat_3: 139,
    char_4: 'tribbie',
    app_rate_4: '31.07%',
    avg_round_4: 38813,
    app_flat_4: 180,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '27.45%',
    avg_round_5: 32487,
    app_flat_5: 181,
    char_6: 'robin',
    app_rate_6: '21.36%',
    avg_round_6: 29947,
    app_flat_6: 110,
    char_7: 'jade',
    app_rate_7: '17.21%',
    avg_round_7: 37128,
    app_flat_7: 106,
    char_8: 'mydei',
    app_rate_8: '10.33%',
    avg_round_8: 24843,
    app_flat_8: 30,
    char_9: 'ruan-mei',
    app_rate_9: '9.97%',
    avg_round_9: 27220,
    app_flat_9: 48,
    char_10: 'jing-yuan',
    app_rate_10: '9.53%',
    avg_round_10: 29941,
    app_flat_10: 54,
    char_11: 'aglaea',
    app_rate_11: '8.12%',
    avg_round_11: 31207,
    app_flat_11: 30,
    char_12: 'acheron',
    app_rate_12: '6.88%',
    avg_round_12: 26071,
    app_flat_12: 23,
    char_13: 'jiaoqiu',
    app_rate_13: '6.09%',
    avg_round_13: 26489,
    app_flat_13: 15,
    char_14: 'sparkle',
    app_rate_14: '5.38%',
    avg_round_14: 23982,
    app_flat_14: 11,
    char_15: 'argenti',
    app_rate_15: '4.68%',
    avg_round_15: 31339,
    app_flat_15: 27,
    char_16: 'serval',
    app_rate_16: '3.88%',
    avg_round_16: 34293,
    app_flat_16: 24,
    char_17: 'rappa',
    app_rate_17: '3.35%',
    avg_round_17: 26939,
    app_flat_17: 23,
    char_18: 'yunli',
    app_rate_18: '3.09%',
    avg_round_18: 29347,
    app_flat_18: 18,
    char_19: 'kafka',
    app_rate_19: '2.74%',
    avg_round_19: 25337,
    app_flat_19: 11,
    char_20: 'tingyun-fugue',
    app_rate_20: '2.65%',
    avg_round_20: 27289,
    app_flat_20: 17,
    char_21: 'himeko',
    app_rate_21: '2.29%',
    avg_round_21: 25538,
    app_flat_21: 11,
    char_22: 'bronya',
    app_rate_22: '2.29%',
    avg_round_22: 24675,
    app_flat_22: 8,
    char_23: 'imbibitor-lunae',
    app_rate_23: '2.03%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'black-swan',
    app_rate_24: '1.77%',
    avg_round_24: 24125,
    app_flat_24: 8,
    char_25: 'tingyun',
    app_rate_25: '1.77%',
    avg_round_25: 30065,
    app_flat_25: 11,
    char_26: 'pela',
    app_rate_26: '1.77%',
    avg_round_26: 24480,
    app_flat_26: 6,
    char_27: 'blade',
    app_rate_27: '1.77%',
    avg_round_27: 26130,
    app_flat_27: 8,
    char_28: 'trailblazer-harmony',
    app_rate_28: '1.32%',
    avg_round_28: 25178,
    app_flat_28: 9,
    char_29: 'clara',
    app_rate_29: '0.79%',
    avg_round_29: 25170,
    app_flat_29: 4,
    char_30: 'jingliu',
    app_rate_30: '0.35%',
    avg_round_30: 22640,
    app_flat_30: 1
  },
  {
    char: 'himeko',
    app: 8.43,
    char_1: 'ruan-mei',
    app_rate_1: '57.78%',
    avg_round_1: 29116,
    app_flat_1: 272,
    char_2: 'tingyun-fugue',
    app_rate_2: '51.83%',
    avg_round_2: 29852,
    app_flat_2: 214,
    char_3: 'herta',
    app_rate_3: '45.97%',
    avg_round_3: 27717,
    app_flat_3: 269,
    char_4: 'firefly',
    app_rate_4: '19.41%',
    avg_round_4: 27716,
    app_flat_4: 36,
    char_5: 'the-herta',
    app_rate_5: '16.39%',
    avg_round_5: 34098,
    app_flat_5: 108,
    char_6: 'tribbie',
    app_rate_6: '15.93%',
    avg_round_6: 34444,
    app_flat_6: 85,
    char_7: 'aventurine',
    app_rate_7: '14.1%',
    avg_round_7: 25235,
    app_flat_7: 77,
    char_8: 'robin',
    app_rate_8: '13.83%',
    avg_round_8: 23962,
    app_flat_8: 70,
    char_9: 'lingsha',
    app_rate_9: '11.08%',
    avg_round_9: 29577,
    app_flat_9: 52,
    char_10: 'trailblazer-harmony',
    app_rate_10: '9.8%',
    avg_round_10: 32395,
    app_flat_10: 35,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '9.52%',
    avg_round_11: 31739,
    app_flat_11: 74,
    char_12: 'gallagher',
    app_rate_12: '8.24%',
    avg_round_12: 27751,
    app_flat_12: 52,
    char_13: 'rappa',
    app_rate_13: '4.58%',
    avg_round_13: 34497,
    app_flat_13: 38,
    char_14: 'fu-xuan',
    app_rate_14: '4.21%',
    avg_round_14: 29339,
    app_flat_14: 19,
    char_15: 'huohuo',
    app_rate_15: '3.85%',
    avg_round_15: 29322,
    app_flat_15: 22,
    char_16: 'acheron',
    app_rate_16: '2.56%',
    avg_round_16: 29333,
    app_flat_16: 9,
    char_17: 'jade',
    app_rate_17: '2.56%',
    avg_round_17: 32991,
    app_flat_17: 18,
    char_18: 'luocha',
    app_rate_18: '2.38%',
    avg_round_18: 25538,
    app_flat_18: 11,
    char_19: 'sparkle',
    app_rate_19: '1.28%',
    avg_round_19: 26760,
    app_flat_19: 4,
    char_20: 'sunday',
    app_rate_20: '1.28%',
    avg_round_20: 31124,
    app_flat_20: 9,
    char_21: 'serval',
    app_rate_21: '0.82%',
    avg_round_21: 26530,
    app_flat_21: 4,
    char_22: 'pela',
    app_rate_22: '0.55%',
    avg_round_22: 25710,
    app_flat_22: 4,
    char_23: 'kafka',
    app_rate_23: '0.27%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'xueyi',
    app_rate_24: '0.27%',
    avg_round_24: 24720,
    app_flat_24: 1,
    char_25: 'feixiao',
    app_rate_25: '0.18%',
    avg_round_25: 23240,
    app_flat_25: 1,
    char_26: 'argenti',
    app_rate_26: '0.18%',
    avg_round_26: 24200,
    app_flat_26: 1,
    char_27: 'yunli',
    app_rate_27: '0.18%',
    avg_round_27: 24680,
    app_flat_27: 1,
    char_28: 'gepard',
    app_rate_28: '0.09%',
    avg_round_28: 28080,
    app_flat_28: 1,
    char_29: 'trailblazer-preservation',
    app_rate_29: '0.09%',
    avg_round_29: 25320,
    app_flat_29: 1,
    char_30: 'tingyun',
    app_rate_30: '0.09%',
    avg_round_30: 22200,
    app_flat_30: 1
  },
  {
    char: 'serval',
    app: 7.97,
    char_1: 'the-herta',
    app_rate_1: '86.63%',
    avg_round_1: 38689,
    app_flat_1: 487,
    char_2: 'tribbie',
    app_rate_2: '64.92%',
    avg_round_2: 39628,
    app_flat_2: 371,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '27.91%',
    avg_round_3: 34652,
    app_flat_3: 173,
    char_4: 'huohuo',
    app_rate_4: '18.6%',
    avg_round_4: 37664,
    app_flat_4: 96,
    char_5: 'aventurine',
    app_rate_5: '18.02%',
    avg_round_5: 38101,
    app_flat_5: 115,
    char_6: 'lingsha',
    app_rate_6: '16.18%',
    avg_round_6: 38688,
    app_flat_6: 98,
    char_7: 'gallagher',
    app_rate_7: '15.02%',
    avg_round_7: 36794,
    app_flat_7: 99,
    char_8: 'fu-xuan',
    app_rate_8: '11.82%',
    avg_round_8: 34439,
    app_flat_8: 59,
    char_9: 'robin',
    app_rate_9: '8.53%',
    avg_round_9: 29488,
    app_flat_9: 37,
    char_10: 'sunday',
    app_rate_10: '6.98%',
    avg_round_10: 30276,
    app_flat_10: 36,
    char_11: 'luocha',
    app_rate_11: '4.26%',
    avg_round_11: 34293,
    app_flat_11: 24,
    char_12: 'ruan-mei',
    app_rate_12: '4.07%',
    avg_round_12: 31604,
    app_flat_12: 21,
    char_13: 'jade',
    app_rate_13: '3.78%',
    avg_round_13: 31037,
    app_flat_13: 23,
    char_14: 'herta',
    app_rate_14: '3.2%',
    avg_round_14: 32002,
    app_flat_14: 17,
    char_15: 'sparkle',
    app_rate_15: '1.36%',
    avg_round_15: 28310,
    app_flat_15: 4,
    char_16: 'acheron',
    app_rate_16: '1.36%',
    avg_round_16: 26910,
    app_flat_16: 4,
    char_17: 'tingyun',
    app_rate_17: '1.26%',
    avg_round_17: 26109,
    app_flat_17: 11,
    char_18: 'himeko',
    app_rate_18: '0.87%',
    avg_round_18: 26530,
    app_flat_18: 4,
    char_19: 'pela',
    app_rate_19: '0.87%',
    avg_round_19: 32630,
    app_flat_19: 4,
    char_20: 'kafka',
    app_rate_20: '0.78%',
    avg_round_20: 24770,
    app_flat_20: 4,
    char_21: 'bronya',
    app_rate_21: '0.58%',
    avg_round_21: 26000,
    app_flat_21: 3,
    char_22: 'tingyun-fugue',
    app_rate_22: '0.58%',
    avg_round_22: 25630,
    app_flat_22: 4,
    char_23: 'yunli',
    app_rate_23: '0.29%',
    avg_round_23: 29620,
    app_flat_23: 2,
    char_24: 'hanya',
    app_rate_24: '0.29%',
    avg_round_24: 25920,
    app_flat_24: 2,
    char_25: 'march-7th',
    app_rate_25: '0.29%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'gepard',
    app_rate_26: '0.19%',
    avg_round_26: 24160,
    app_flat_26: 1,
    char_27: 'bailu',
    app_rate_27: '0.19%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'xueyi',
    app_rate_28: '0.19%',
    avg_round_28: 35280,
    app_flat_28: 1,
    char_29: 'asta',
    app_rate_29: '0.19%',
    avg_round_29: 25920,
    app_flat_29: 2,
    char_30: 'lynx',
    app_rate_30: '0.19%',
    avg_round_30: 32920,
    app_flat_30: 2
  },
  {
    char: 'kafka',
    app: 6.62,
    char_1: 'acheron',
    app_rate_1: '78.21%',
    avg_round_1: 25225,
    app_flat_1: 278,
    char_2: 'black-swan',
    app_rate_2: '55.48%',
    avg_round_2: 24434,
    app_flat_2: 151,
    char_3: 'aventurine',
    app_rate_3: '25.87%',
    avg_round_3: 25975,
    app_flat_3: 101,
    char_4: 'fu-xuan',
    app_rate_4: '24.24%',
    avg_round_4: 24633,
    app_flat_4: 86,
    char_5: 'ruan-mei',
    app_rate_5: '15.85%',
    avg_round_5: 23425,
    app_flat_5: 21,
    char_6: 'tribbie',
    app_rate_6: '15.03%',
    avg_round_6: 31396,
    app_flat_6: 29,
    char_7: 'huohuo',
    app_rate_7: '15.03%',
    avg_round_7: 24157,
    app_flat_7: 23,
    char_8: 'gallagher',
    app_rate_8: '12.47%',
    avg_round_8: 25496,
    app_flat_8: 44,
    char_9: 'jiaoqiu',
    app_rate_9: '10.84%',
    avg_round_9: 28446,
    app_flat_9: 44,
    char_10: 'pela',
    app_rate_10: '9.21%',
    avg_round_10: 25518,
    app_flat_10: 52,
    char_11: 'robin',
    app_rate_11: '7.81%',
    avg_round_11: 25217,
    app_flat_11: 13,
    char_12: 'lingsha',
    app_rate_12: '5.71%',
    avg_round_12: 26050,
    app_flat_12: 16,
    char_13: 'luocha',
    app_rate_13: '3.61%',
    avg_round_13: 25337,
    app_flat_13: 11,
    char_14: 'sparkle',
    app_rate_14: '3.61%',
    avg_round_14: 24813,
    app_flat_14: 3,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '2.45%',
    avg_round_15: 25150,
    app_flat_15: 6,
    char_16: 'gepard',
    app_rate_16: '2.45%',
    avg_round_16: 25025,
    app_flat_16: 13,
    char_17: 'tingyun-fugue',
    app_rate_17: '2.33%',
    avg_round_17: 25473,
    app_flat_17: 11,
    char_18: 'jade',
    app_rate_18: '2.21%',
    avg_round_18: 30309,
    app_flat_18: 7,
    char_19: 'bailu',
    app_rate_19: '1.17%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'serval',
    app_rate_20: '0.93%',
    avg_round_20: 24770,
    app_flat_20: 4,
    char_21: 'silver-wolf',
    app_rate_21: '0.82%',
    avg_round_21: 22780,
    app_flat_21: 4,
    char_22: 'herta',
    app_rate_22: '0.7%',
    avg_round_22: 26220,
    app_flat_22: 2,
    char_23: 'sunday',
    app_rate_23: '0.7%',
    avg_round_23: 24440,
    app_flat_23: 2,
    char_24: 'bronya',
    app_rate_24: '0.47%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'tingyun',
    app_rate_25: '0.47%',
    avg_round_25: 24000,
    app_flat_25: 1,
    char_26: 'march-7th',
    app_rate_26: '0.47%',
    avg_round_26: 25480,
    app_flat_26: 2,
    char_27: 'himeko',
    app_rate_27: '0.35%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'guinaifen',
    app_rate_28: '0.35%',
    avg_round_28: 24040,
    app_flat_28: 1,
    char_29: 'aglaea',
    app_rate_29: '0.35%',
    avg_round_29: 25180,
    app_flat_29: 2,
    char_30: 'yunli',
    app_rate_30: '0.23%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 5.21,
    char_1: 'tribbie',
    app_rate_1: '47.41%',
    avg_round_1: 39671,
    app_flat_1: 163,
    char_2: 'the-herta',
    app_rate_2: '36.0%',
    avg_round_2: 39764,
    app_flat_2: 130,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '32.44%',
    avg_round_3: 34379,
    app_flat_3: 131,
    char_4: 'sunday',
    app_rate_4: '31.26%',
    avg_round_4: 32200,
    app_flat_4: 98,
    char_5: 'huohuo',
    app_rate_5: '23.85%',
    avg_round_5: 34955,
    app_flat_5: 80,
    char_6: 'robin',
    app_rate_6: '22.07%',
    avg_round_6: 32740,
    app_flat_6: 55,
    char_7: 'aventurine',
    app_rate_7: '17.33%',
    avg_round_7: 33987,
    app_flat_7: 55,
    char_8: 'sparkle',
    app_rate_8: '12.44%',
    avg_round_8: 30768,
    app_flat_8: 40,
    char_9: 'tingyun',
    app_rate_9: '10.67%',
    avg_round_9: 30233,
    app_flat_9: 42,
    char_10: 'ruan-mei',
    app_rate_10: '9.48%',
    avg_round_10: 31082,
    app_flat_10: 38,
    char_11: 'jade',
    app_rate_11: '9.04%',
    avg_round_11: 38165,
    app_flat_11: 29,
    char_12: 'gallagher',
    app_rate_12: '8.74%',
    avg_round_12: 39362,
    app_flat_12: 42,
    char_13: 'herta',
    app_rate_13: '8.3%',
    avg_round_13: 31031,
    app_flat_13: 35,
    char_14: 'luocha',
    app_rate_14: '7.85%',
    avg_round_14: 31339,
    app_flat_14: 27,
    char_15: 'lingsha',
    app_rate_15: '7.7%',
    avg_round_15: 39229,
    app_flat_15: 27,
    char_16: 'fu-xuan',
    app_rate_16: '7.11%',
    avg_round_16: 35021,
    app_flat_16: 28,
    char_17: 'jiaoqiu',
    app_rate_17: '1.93%',
    avg_round_17: 28360,
    app_flat_17: 1,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.78%',
    avg_round_18: 29620,
    app_flat_18: 6,
    char_19: 'bronya',
    app_rate_19: '1.63%',
    avg_round_19: 28720,
    app_flat_19: 8,
    char_20: 'bailu',
    app_rate_20: '0.44%',
    avg_round_20: 32280,
    app_flat_20: 2,
    char_21: 'yunli',
    app_rate_21: '0.44%',
    avg_round_21: 24000,
    app_flat_21: 1,
    char_22: 'mydei',
    app_rate_22: '0.3%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'himeko',
    app_rate_23: '0.3%',
    avg_round_23: 24200,
    app_flat_23: 1,
    char_24: 'blade',
    app_rate_24: '0.15%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.15%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'feixiao',
    app_rate_26: '0.15%',
    avg_round_26: 39680,
    app_flat_26: 1,
    char_27: 'acheron',
    app_rate_27: '0.15%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'lynx',
    app_rate_28: '0.15%',
    avg_round_28: 30560,
    app_flat_28: 1,
    char_29: 'xueyi',
    app_rate_29: '0.15%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'qingque',
    app_rate_30: '0.15%',
    avg_round_30: 34200,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    app: 5.04,
    char_1: 'acheron',
    app_rate_1: '78.1%',
    avg_round_1: 24469,
    app_flat_1: 206,
    char_2: 'kafka',
    app_rate_2: '72.89%',
    avg_round_2: 24434,
    app_flat_2: 151,
    char_3: 'aventurine',
    app_rate_3: '29.25%',
    avg_round_3: 25979,
    app_flat_3: 83,
    char_4: 'fu-xuan',
    app_rate_4: '21.75%',
    avg_round_4: 24190,
    app_flat_4: 54,
    char_5: 'ruan-mei',
    app_rate_5: '16.54%',
    avg_round_5: 22513,
    app_flat_5: 11,
    char_6: 'huohuo',
    app_rate_6: '16.08%',
    avg_round_6: 23040,
    app_flat_6: 15,
    char_7: 'gallagher',
    app_rate_7: '12.86%',
    avg_round_7: 24349,
    app_flat_7: 34,
    char_8: 'jiaoqiu',
    app_rate_8: '11.33%',
    avg_round_8: 26506,
    app_flat_8: 37,
    char_9: 'tribbie',
    app_rate_9: '8.42%',
    avg_round_9: 29437,
    app_flat_9: 12,
    char_10: 'robin',
    app_rate_10: '7.5%',
    avg_round_10: 24065,
    app_flat_10: 8,
    char_11: 'pela',
    app_rate_11: '6.89%',
    avg_round_11: 23185,
    app_flat_11: 21,
    char_12: 'lingsha',
    app_rate_12: '4.75%',
    avg_round_12: 24648,
    app_flat_12: 10,
    char_13: 'luocha',
    app_rate_13: '3.06%',
    avg_round_13: 24125,
    app_flat_13: 8,
    char_14: 'gepard',
    app_rate_14: '2.76%',
    avg_round_14: 24097,
    app_flat_14: 11,
    char_15: 'sparkle',
    app_rate_15: '2.3%',
    avg_round_15: 20860,
    app_flat_15: 2,
    char_16: 'bailu',
    app_rate_16: '1.23%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'trailblazer-remembrance',
    app_rate_17: '0.77%',
    avg_round_17: 21760,
    app_flat_17: 1,
    char_18: 'herta',
    app_rate_18: '0.61%',
    avg_round_18: 24120,
    app_flat_18: 1,
    char_19: 'tingyun-fugue',
    app_rate_19: '0.61%',
    avg_round_19: 23560,
    app_flat_19: 3,
    char_20: 'sunday',
    app_rate_20: '0.46%',
    avg_round_20: 20440,
    app_flat_20: 1,
    char_21: 'guinaifen',
    app_rate_21: '0.31%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jade',
    app_rate_22: '0.31%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'hanya',
    app_rate_23: '0.15%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'himeko',
    app_rate_24: '0.15%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'sampo',
    app_rate_25: '0.15%',
    avg_round_25: 22920,
    app_flat_25: 1,
    char_26: 'tingyun',
    app_rate_26: '0.15%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'the-herta',
    app_rate_27: '0.15%',
    avg_round_27: 21760,
    app_flat_27: 1,
    char_28: 'silver-wolf',
    app_rate_28: '0.15%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'lynx',
    app_rate_29: '0.15%',
    avg_round_29: 24040,
    app_flat_29: 1,
    char_30: 'march-7th',
    app_rate_30: '0.15%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'yunli',
    app: 4.65,
    char_1: 'robin',
    app_rate_1: '62.62%',
    avg_round_1: 30426,
    app_flat_1: 148,
    char_2: 'tribbie',
    app_rate_2: '50.17%',
    avg_round_2: 37366,
    app_flat_2: 115,
    char_3: 'huohuo',
    app_rate_3: '37.38%',
    avg_round_3: 31199,
    app_flat_3: 85,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '21.43%',
    avg_round_4: 30156,
    app_flat_4: 57,
    char_5: 'sunday',
    app_rate_5: '19.6%',
    avg_round_5: 28238,
    app_flat_5: 40,
    char_6: 'aventurine',
    app_rate_6: '16.94%',
    avg_round_6: 31122,
    app_flat_6: 39,
    char_7: 'sparkle',
    app_rate_7: '14.45%',
    avg_round_7: 29304,
    app_flat_7: 22,
    char_8: 'fu-xuan',
    app_rate_8: '11.13%',
    avg_round_8: 32068,
    app_flat_8: 29,
    char_9: 'herta',
    app_rate_9: '10.3%',
    avg_round_9: 30342,
    app_flat_9: 26,
    char_10: 'tingyun',
    app_rate_10: '9.97%',
    avg_round_10: 26260,
    app_flat_10: 22,
    char_11: 'jade',
    app_rate_11: '8.97%',
    avg_round_11: 38597,
    app_flat_11: 27,
    char_12: 'luocha',
    app_rate_12: '5.81%',
    avg_round_12: 29347,
    app_flat_12: 18,
    char_13: 'lingsha',
    app_rate_13: '5.81%',
    avg_round_13: 32573,
    app_flat_13: 19,
    char_14: 'gallagher',
    app_rate_14: '3.49%',
    avg_round_14: 31606,
    app_flat_14: 7,
    char_15: 'ruan-mei',
    app_rate_15: '2.99%',
    avg_round_15: 24770,
    app_flat_15: 8,
    char_16: 'feixiao',
    app_rate_16: '2.82%',
    avg_round_16: 27795,
    app_flat_16: 8,
    char_17: 'clara',
    app_rate_17: '2.66%',
    avg_round_17: 25160,
    app_flat_17: 9,
    char_18: 'lynx',
    app_rate_18: '2.16%',
    avg_round_18: 26647,
    app_flat_18: 11,
    char_19: 'acheron',
    app_rate_19: '1.83%',
    avg_round_19: 26053,
    app_flat_19: 3,
    char_20: 'topaz',
    app_rate_20: '1.5%',
    avg_round_20: 30740,
    app_flat_20: 2,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '0.83%',
    avg_round_21: 25800,
    app_flat_21: 3,
    char_22: 'the-herta',
    app_rate_22: '0.83%',
    avg_round_22: 31560,
    app_flat_22: 2,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.66%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'blade',
    app_rate_24: '0.66%',
    avg_round_24: 27040,
    app_flat_24: 2,
    char_25: 'aglaea',
    app_rate_25: '0.5%',
    avg_round_25: 26680,
    app_flat_25: 1,
    char_26: 'serval',
    app_rate_26: '0.5%',
    avg_round_26: 29620,
    app_flat_26: 2,
    char_27: 'jiaoqiu',
    app_rate_27: '0.5%',
    avg_round_27: 27080,
    app_flat_27: 1,
    char_28: 'argenti',
    app_rate_28: '0.5%',
    avg_round_28: 24000,
    app_flat_28: 1,
    char_29: 'bronya',
    app_rate_29: '0.33%',
    avg_round_29: 24040,
    app_flat_29: 1,
    char_30: 'jing-yuan',
    app_rate_30: '0.33%',
    avg_round_30: 22600,
    app_flat_30: 1
  },
  {
    char: 'bronya',
    app: 3.3,
    char_1: 'sunday',
    app_rate_1: '34.58%',
    avg_round_1: 31193,
    app_flat_1: 49,
    char_2: 'aglaea',
    app_rate_2: '29.21%',
    avg_round_2: 31677,
    app_flat_2: 38,
    char_3: 'acheron',
    app_rate_3: '26.87%',
    avg_round_3: 26920,
    app_flat_3: 1,
    char_4: 'robin',
    app_rate_4: '20.09%',
    avg_round_4: 30114,
    app_flat_4: 25,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '19.86%',
    avg_round_5: 30919,
    app_flat_5: 31,
    char_6: 'jiaoqiu',
    app_rate_6: '15.19%',
    avg_round_6: 27640,
    app_flat_6: 2,
    char_7: 'tribbie',
    app_rate_7: '13.79%',
    avg_round_7: 30017,
    app_flat_7: 19,
    char_8: 'mydei',
    app_rate_8: '13.08%',
    avg_round_8: 27690,
    app_flat_8: 16,
    char_9: 'aventurine',
    app_rate_9: '12.62%',
    avg_round_9: 25953,
    app_flat_9: 6,
    char_10: 'ruan-mei',
    app_rate_10: '11.68%',
    avg_round_10: 29765,
    app_flat_10: 15,
    char_11: 'pela',
    app_rate_11: '9.81%',
    avg_round_11: 24080,
    app_flat_11: 1,
    char_12: 'fu-xuan',
    app_rate_12: '9.11%',
    avg_round_12: 25260,
    app_flat_12: 4,
    char_13: 'herta',
    app_rate_13: '8.64%',
    avg_round_13: 27984,
    app_flat_13: 17,
    char_14: 'gallagher',
    app_rate_14: '8.41%',
    avg_round_14: 25693,
    app_flat_14: 5,
    char_15: 'huohuo',
    app_rate_15: '7.01%',
    avg_round_15: 26953,
    app_flat_15: 10,
    char_16: 'lingsha',
    app_rate_16: '6.78%',
    avg_round_16: 32740,
    app_flat_16: 6,
    char_17: 'luocha',
    app_rate_17: '6.07%',
    avg_round_17: 24675,
    app_flat_17: 8,
    char_18: 'sparkle',
    app_rate_18: '6.07%',
    avg_round_18: 24520,
    app_flat_18: 5,
    char_19: 'tingyun-fugue',
    app_rate_19: '5.14%',
    avg_round_19: 33413,
    app_flat_19: 6,
    char_20: 'jing-yuan',
    app_rate_20: '4.21%',
    avg_round_20: 23056,
    app_flat_20: 7,
    char_21: 'the-herta',
    app_rate_21: '3.97%',
    avg_round_21: 34160,
    app_flat_21: 5,
    char_22: 'jade',
    app_rate_22: '3.97%',
    avg_round_22: 33806,
    app_flat_22: 7,
    char_23: 'firefly',
    app_rate_23: '3.27%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'blade',
    app_rate_24: '2.8%',
    avg_round_24: 25467,
    app_flat_24: 3,
    char_25: 'rappa',
    app_rate_25: '2.8%',
    avg_round_25: 31150,
    app_flat_25: 8,
    char_26: 'argenti',
    app_rate_26: '2.57%',
    avg_round_26: 28720,
    app_flat_26: 8,
    char_27: 'trailblazer-harmony',
    app_rate_27: '2.1%',
    avg_round_27: 29573,
    app_flat_27: 3,
    char_28: 'tingyun',
    app_rate_28: '1.64%',
    avg_round_28: 24060,
    app_flat_28: 2,
    char_29: 'gepard',
    app_rate_29: '1.64%',
    avg_round_29: 21960,
    app_flat_29: 1,
    char_30: 'serval',
    app_rate_30: '1.4%',
    avg_round_30: 26000,
    app_flat_30: 3
  },
  {
    char: 'feixiao',
    app: 3.08,
    char_1: 'robin',
    app_rate_1: '81.7%',
    avg_round_1: 27779,
    app_flat_1: 90,
    char_2: 'aventurine',
    app_rate_2: '52.63%',
    avg_round_2: 25568,
    app_flat_2: 48,
    char_3: 'herta',
    app_rate_3: '43.86%',
    avg_round_3: 29065,
    app_flat_3: 56,
    char_4: 'tribbie',
    app_rate_4: '29.57%',
    avg_round_4: 33249,
    app_flat_4: 38,
    char_5: 'jade',
    app_rate_5: '16.29%',
    avg_round_5: 35233,
    app_flat_5: 6,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '16.04%',
    avg_round_6: 30803,
    app_flat_6: 24,
    char_7: 'topaz',
    app_rate_7: '11.53%',
    avg_round_7: 25224,
    app_flat_7: 5,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '10.78%',
    avg_round_8: 24804,
    app_flat_8: 20,
    char_9: 'lingsha',
    app_rate_9: '7.02%',
    avg_round_9: 34453,
    app_flat_9: 6,
    char_10: 'fu-xuan',
    app_rate_10: '5.76%',
    avg_round_10: 29160,
    app_flat_10: 10,
    char_11: 'gallagher',
    app_rate_11: '5.51%',
    avg_round_11: 26040,
    app_flat_11: 10,
    char_12: 'yunli',
    app_rate_12: '4.26%',
    avg_round_12: 27795,
    app_flat_12: 8,
    char_13: 'ruan-mei',
    app_rate_13: '2.51%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'huohuo',
    app_rate_14: '2.51%',
    avg_round_14: 26080,
    app_flat_14: 2,
    char_15: 'moze',
    app_rate_15: '2.26%',
    avg_round_15: 22480,
    app_flat_15: 2,
    char_16: 'sunday',
    app_rate_16: '1.25%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'clara',
    app_rate_17: '1.25%',
    avg_round_17: 26853,
    app_flat_17: 3,
    char_18: 'acheron',
    app_rate_18: '1.0%',
    avg_round_18: 24960,
    app_flat_18: 1,
    char_19: 'sparkle',
    app_rate_19: '0.75%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'himeko',
    app_rate_20: '0.5%',
    avg_round_20: 23240,
    app_flat_20: 1,
    char_21: 'dr-ratio',
    app_rate_21: '0.5%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'luocha',
    app_rate_22: '0.5%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'bronya',
    app_rate_23: '0.5%',
    avg_round_23: 21520,
    app_flat_23: 1,
    char_24: 'the-herta',
    app_rate_24: '0.25%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'argenti',
    app_rate_25: '0.25%',
    avg_round_25: 39680,
    app_flat_25: 1,
    char_26: 'aglaea',
    app_rate_26: '0.25%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'serval',
    app_rate_27: '0.25%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'lynx',
    app_rate_28: '0.25%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'blade',
    app_rate_29: '0.25%',
    avg_round_29: 33400,
    app_flat_29: 1,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'mydei',
    app: 2.83,
    char_1: 'sunday',
    app_rate_1: '68.66%',
    avg_round_1: 26344,
    app_flat_1: 76,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '54.77%',
    avg_round_2: 29090,
    app_flat_2: 58,
    char_3: 'tribbie',
    app_rate_3: '39.51%',
    avg_round_3: 32340,
    app_flat_3: 41,
    char_4: 'luocha',
    app_rate_4: '31.88%',
    avg_round_4: 24843,
    app_flat_4: 30,
    char_5: 'ruan-mei',
    app_rate_5: '21.25%',
    avg_round_5: 26751,
    app_flat_5: 17,
    char_6: 'bronya',
    app_rate_6: '15.26%',
    avg_round_6: 27690,
    app_flat_6: 16,
    char_7: 'sparkle',
    app_rate_7: '14.99%',
    avg_round_7: 24367,
    app_flat_7: 12,
    char_8: 'gallagher',
    app_rate_8: '13.62%',
    avg_round_8: 26502,
    app_flat_8: 9,
    char_9: 'herta',
    app_rate_9: '10.08%',
    avg_round_9: 31553,
    app_flat_9: 10,
    char_10: 'jiaoqiu',
    app_rate_10: '7.9%',
    avg_round_10: 30853,
    app_flat_10: 3,
    char_11: 'huohuo',
    app_rate_11: '6.27%',
    avg_round_11: 24084,
    app_flat_11: 9,
    char_12: 'robin',
    app_rate_12: '4.9%',
    avg_round_12: 26680,
    app_flat_12: 5,
    char_13: 'lingsha',
    app_rate_13: '2.45%',
    avg_round_13: 26340,
    app_flat_13: 4,
    char_14: 'pela',
    app_rate_14: '1.63%',
    avg_round_14: 24040,
    app_flat_14: 4,
    char_15: 'jade',
    app_rate_15: '1.36%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'lynx',
    app_rate_16: '1.36%',
    avg_round_16: 23067,
    app_flat_16: 3,
    char_17: 'the-herta',
    app_rate_17: '0.82%',
    avg_round_17: 40000,
    app_flat_17: 1,
    char_18: 'argenti',
    app_rate_18: '0.54%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'tingyun',
    app_rate_19: '0.54%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'rappa',
    app_rate_20: '0.27%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-harmony',
    app_rate_21: '0.27%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '0.27%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'yunli',
    app_rate_23: '0.27%',
    avg_round_23: 26040,
    app_flat_23: 1,
    char_24: 'jing-yuan',
    app_rate_24: '0.27%',
    avg_round_24: 22040,
    app_flat_24: 1,
    char_25: 'acheron',
    app_rate_25: '0.27%',
    avg_round_25: 33720,
    app_flat_25: 1,
    char_26: 'himeko',
    app_rate_26: '0.27%',
    avg_round_26: 40000,
    app_flat_26: 1,
    char_27: 'jingliu',
    app_rate_27: '0.27%',
    avg_round_27: 22640,
    app_flat_27: 1,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'tingyun',
    app: 2.28,
    char_1: 'sunday',
    app_rate_1: '27.46%',
    avg_round_1: 26929,
    app_flat_1: 31,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '24.75%',
    avg_round_2: 30497,
    app_flat_2: 44,
    char_3: 'argenti',
    app_rate_3: '24.41%',
    avg_round_3: 30233,
    app_flat_3: 42,
    char_4: 'huohuo',
    app_rate_4: '22.71%',
    avg_round_4: 28910,
    app_flat_4: 25,
    char_5: 'yunli',
    app_rate_5: '20.34%',
    avg_round_5: 26260,
    app_flat_5: 22,
    char_6: 'robin',
    app_rate_6: '18.31%',
    avg_round_6: 25667,
    app_flat_6: 24,
    char_7: 'tribbie',
    app_rate_7: '17.63%',
    avg_round_7: 33640,
    app_flat_7: 24,
    char_8: 'sparkle',
    app_rate_8: '16.95%',
    avg_round_8: 27920,
    app_flat_8: 14,
    char_9: 'herta',
    app_rate_9: '15.25%',
    avg_round_9: 30143,
    app_flat_9: 30,
    char_10: 'jing-yuan',
    app_rate_10: '15.25%',
    avg_round_10: 26323,
    app_flat_10: 15,
    char_11: 'aventurine',
    app_rate_11: '13.22%',
    avg_round_11: 25992,
    app_flat_11: 15,
    char_12: 'fu-xuan',
    app_rate_12: '11.86%',
    avg_round_12: 24629,
    app_flat_12: 13,
    char_13: 'ruan-mei',
    app_rate_13: '11.86%',
    avg_round_13: 28518,
    app_flat_13: 25,
    char_14: 'gallagher',
    app_rate_14: '8.14%',
    avg_round_14: 25380,
    app_flat_14: 16,
    char_15: 'jade',
    app_rate_15: '7.12%',
    avg_round_15: 34144,
    app_flat_15: 10,
    char_16: 'luocha',
    app_rate_16: '6.78%',
    avg_round_16: 30065,
    app_flat_16: 11,
    char_17: 'lingsha',
    app_rate_17: '5.08%',
    avg_round_17: 32967,
    app_flat_17: 6,
    char_18: 'imbibitor-lunae',
    app_rate_18: '4.41%',
    avg_round_18: 24000,
    app_flat_18: 1,
    char_19: 'serval',
    app_rate_19: '4.41%',
    avg_round_19: 26109,
    app_flat_19: 11,
    char_20: 'aglaea',
    app_rate_20: '4.07%',
    avg_round_20: 22810,
    app_flat_20: 4,
    char_21: 'the-herta',
    app_rate_21: '3.05%',
    avg_round_21: 36407,
    app_flat_21: 6,
    char_22: 'bronya',
    app_rate_22: '2.37%',
    avg_round_22: 24060,
    app_flat_22: 2,
    char_23: 'pela',
    app_rate_23: '1.69%',
    avg_round_23: 31933,
    app_flat_23: 3,
    char_24: 'acheron',
    app_rate_24: '1.36%',
    avg_round_24: 31760,
    app_flat_24: 3,
    char_25: 'kafka',
    app_rate_25: '1.36%',
    avg_round_25: 24000,
    app_flat_25: 1,
    char_26: 'tingyun-fugue',
    app_rate_26: '1.36%',
    avg_round_26: 29820,
    app_flat_26: 2,
    char_27: 'seele',
    app_rate_27: '1.36%',
    avg_round_27: 23000,
    app_flat_27: 1,
    char_28: 'jiaoqiu',
    app_rate_28: '1.36%',
    avg_round_28: 40000,
    app_flat_28: 1,
    char_29: 'rappa',
    app_rate_29: '1.02%',
    avg_round_29: 29820,
    app_flat_29: 2,
    char_30: 'lynx',
    app_rate_30: '1.02%',
    avg_round_30: 24733,
    app_flat_30: 3
  },
  {
    char: 'silver-wolf',
    app: 1.18,
    char_1: 'acheron',
    app_rate_1: '96.08%',
    avg_round_1: 24054,
    app_flat_1: 59,
    char_2: 'pela',
    app_rate_2: '40.52%',
    avg_round_2: 23784,
    app_flat_2: 38,
    char_3: 'fu-xuan',
    app_rate_3: '40.52%',
    avg_round_3: 23684,
    app_flat_3: 20,
    char_4: 'aventurine',
    app_rate_4: '29.41%',
    avg_round_4: 24651,
    app_flat_4: 21,
    char_5: 'jiaoqiu',
    app_rate_5: '20.26%',
    avg_round_5: 26929,
    app_flat_5: 14,
    char_6: 'sparkle',
    app_rate_6: '13.73%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'tribbie',
    app_rate_7: '13.07%',
    avg_round_7: 31040,
    app_flat_7: 4,
    char_8: 'gallagher',
    app_rate_8: '10.46%',
    avg_round_8: 23422,
    app_flat_8: 9,
    char_9: 'kafka',
    app_rate_9: '4.58%',
    avg_round_9: 22780,
    app_flat_9: 4,
    char_10: 'lingsha',
    app_rate_10: '4.58%',
    avg_round_10: 23920,
    app_flat_10: 4,
    char_11: 'gepard',
    app_rate_11: '4.58%',
    avg_round_11: 24040,
    app_flat_11: 1,
    char_12: 'robin',
    app_rate_12: '1.96%',
    avg_round_12: 21080,
    app_flat_12: 1,
    char_13: 'jade',
    app_rate_13: '1.96%',
    avg_round_13: 26000,
    app_flat_13: 1,
    char_14: 'herta',
    app_rate_14: '1.96%',
    avg_round_14: 23600,
    app_flat_14: 1,
    char_15: 'bronya',
    app_rate_15: '1.96%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'sunday',
    app_rate_16: '1.96%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'the-herta',
    app_rate_17: '1.31%',
    avg_round_17: 40000,
    app_flat_17: 1,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '1.31%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'ruan-mei',
    app_rate_19: '1.31%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'huohuo',
    app_rate_20: '1.31%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.31%',
    avg_round_21: 28560,
    app_flat_21: 1,
    char_22: 'luocha',
    app_rate_22: '1.31%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'serval',
    app_rate_23: '0.65%',
    avg_round_23: 40000,
    app_flat_23: 1,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.65%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-preservation',
    app_rate_25: '0.65%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'qingque',
    app_rate_26: '0.65%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'black-swan',
    app_rate_27: '0.65%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'clara',
    app_rate_28: '0.65%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'aglaea',
    app_rate_29: '0.65%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 1.13,
    char_1: 'robin',
    app_rate_1: '69.39%',
    avg_round_1: 26501,
    app_flat_1: 46,
    char_2: 'tribbie',
    app_rate_2: '39.46%',
    avg_round_2: 31177,
    app_flat_2: 23,
    char_3: 'aventurine',
    app_rate_3: '37.41%',
    avg_round_3: 26894,
    app_flat_3: 28,
    char_4: 'fu-xuan',
    app_rate_4: '19.73%',
    avg_round_4: 27632,
    app_flat_4: 10,
    char_5: 'herta',
    app_rate_5: '17.01%',
    avg_round_5: 30250,
    app_flat_5: 8,
    char_6: 'sparkle',
    app_rate_6: '15.65%',
    avg_round_6: 25080,
    app_flat_6: 10,
    char_7: 'huohuo',
    app_rate_7: '15.65%',
    avg_round_7: 26150,
    app_flat_7: 8,
    char_8: 'jade',
    app_rate_8: '15.65%',
    avg_round_8: 31443,
    app_flat_8: 12,
    char_9: 'sunday',
    app_rate_9: '12.93%',
    avg_round_9: 28390,
    app_flat_9: 8,
    char_10: 'yunli',
    app_rate_10: '10.88%',
    avg_round_10: 25160,
    app_flat_10: 9,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '9.52%',
    avg_round_11: 28440,
    app_flat_11: 4,
    char_12: 'lingsha',
    app_rate_12: '6.8%',
    avg_round_12: 28890,
    app_flat_12: 4,
    char_13: 'luocha',
    app_rate_13: '6.12%',
    avg_round_13: 25170,
    app_flat_13: 4,
    char_14: 'feixiao',
    app_rate_14: '3.4%',
    avg_round_14: 26853,
    app_flat_14: 3,
    char_15: 'topaz',
    app_rate_15: '2.72%',
    avg_round_15: 21627,
    app_flat_15: 3,
    char_16: 'ruan-mei',
    app_rate_16: '2.72%',
    avg_round_16: 35600,
    app_flat_16: 1,
    char_17: 'gallagher',
    app_rate_17: '2.72%',
    avg_round_17: 30780,
    app_flat_17: 2,
    char_18: 'acheron',
    app_rate_18: '2.04%',
    avg_round_18: 24700,
    app_flat_18: 2,
    char_19: 'tingyun',
    app_rate_19: '1.36%',
    avg_round_19: 22340,
    app_flat_19: 2,
    char_20: 'jiaoqiu',
    app_rate_20: '1.36%',
    avg_round_20: 24080,
    app_flat_20: 1,
    char_21: 'jing-yuan',
    app_rate_21: '1.36%',
    avg_round_21: 25280,
    app_flat_21: 2,
    char_22: 'the-herta',
    app_rate_22: '1.36%',
    avg_round_22: 35520,
    app_flat_22: 1,
    char_23: 'aglaea',
    app_rate_23: '0.68%',
    avg_round_23: 33280,
    app_flat_23: 1,
    char_24: 'jingliu',
    app_rate_24: '0.68%',
    avg_round_24: 24040,
    app_flat_24: 1,
    char_25: 'serval',
    app_rate_25: '0.68%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'argenti',
    app_rate_26: '0.68%',
    avg_round_26: 25520,
    app_flat_26: 1,
    char_27: 'dr-ratio',
    app_rate_27: '0.68%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'silver-wolf',
    app_rate_28: '0.68%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'tingyun-fugue',
    app_rate_29: '0.68%',
    avg_round_29: 24160,
    app_flat_29: 1,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 1.05,
    char_1: 'sparkle',
    app_rate_1: '58.82%',
    avg_round_1: 22360,
    app_flat_1: 3,
    char_2: 'sunday',
    app_rate_2: '54.41%',
    avg_round_2: 23740,
    app_flat_2: 2,
    char_3: 'robin',
    app_rate_3: '30.15%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'huohuo',
    app_rate_5: '22.79%',
    avg_round_5: 22960,
    app_flat_5: 1,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '22.79%',
    avg_round_6: 22060,
    app_flat_6: 2,
    char_7: 'luocha',
    app_rate_7: '16.91%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'aventurine',
    app_rate_8: '16.18%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tribbie',
    app_rate_9: '13.97%',
    avg_round_9: 22960,
    app_flat_9: 1,
    char_10: 'tingyun',
    app_rate_10: '9.56%',
    avg_round_10: 24000,
    app_flat_10: 1,
    char_11: 'gallagher',
    app_rate_11: '9.56%',
    avg_round_11: 24000,
    app_flat_11: 1,
    char_12: 'herta',
    app_rate_12: '8.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'fu-xuan',
    app_rate_13: '3.68%',
    avg_round_13: 20640,
    app_flat_13: 1,
    char_14: 'jiaoqiu',
    app_rate_14: '2.94%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'lingsha',
    app_rate_15: '1.47%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.74%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'hanya',
    app_rate_17: '0.74%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'lynx',
    app_rate_18: '0.74%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jing-yuan',
    app_rate_19: '0.74%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'blade',
    app_rate_20: '0.74%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'blade',
    app: 0.84,
    char_1: 'jade',
    app_rate_1: '52.29%',
    avg_round_1: 33497,
    app_flat_1: 21,
    char_2: 'tribbie',
    app_rate_2: '51.38%',
    avg_round_2: 34438,
    app_flat_2: 20,
    char_3: 'sunday',
    app_rate_3: '29.36%',
    avg_round_3: 30711,
    app_flat_3: 9,
    char_4: 'fu-xuan',
    app_rate_4: '23.85%',
    avg_round_4: 36728,
    app_flat_4: 7,
    char_5: 'luocha',
    app_rate_5: '18.35%',
    avg_round_5: 26130,
    app_flat_5: 8,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '17.43%',
    avg_round_6: 25486,
    app_flat_6: 7,
    char_7: 'robin',
    app_rate_7: '17.43%',
    avg_round_7: 27680,
    app_flat_7: 4,
    char_8: 'ruan-mei',
    app_rate_8: '12.84%',
    avg_round_8: 26253,
    app_flat_8: 3,
    char_9: 'huohuo',
    app_rate_9: '11.93%',
    avg_round_9: 33920,
    app_flat_9: 1,
    char_10: 'bronya',
    app_rate_10: '11.01%',
    avg_round_10: 25467,
    app_flat_10: 3,
    char_11: 'lingsha',
    app_rate_11: '9.17%',
    avg_round_11: 33264,
    app_flat_11: 5,
    char_12: 'gallagher',
    app_rate_12: '8.26%',
    avg_round_12: 37520,
    app_flat_12: 4,
    char_13: 'herta',
    app_rate_13: '6.42%',
    avg_round_13: 26693,
    app_flat_13: 3,
    char_14: 'lynx',
    app_rate_14: '6.42%',
    avg_round_14: 27080,
    app_flat_14: 1,
    char_15: 'sparkle',
    app_rate_15: '6.42%',
    avg_round_15: 32280,
    app_flat_15: 1,
    char_16: 'jingliu',
    app_rate_16: '4.59%',
    avg_round_16: 24160,
    app_flat_16: 1,
    char_17: 'yunli',
    app_rate_17: '3.67%',
    avg_round_17: 27040,
    app_flat_17: 2,
    char_18: 'jiaoqiu',
    app_rate_18: '1.83%',
    avg_round_18: 26600,
    app_flat_18: 1,
    char_19: 'acheron',
    app_rate_19: '0.92%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'argenti',
    app_rate_20: '0.92%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jing-yuan',
    app_rate_21: '0.92%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'imbibitor-lunae',
    app_rate_22: '0.92%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'bailu',
    app_rate_23: '0.92%',
    avg_round_23: 26000,
    app_flat_23: 1,
    char_24: 'feixiao',
    app_rate_24: '0.92%',
    avg_round_24: 33400,
    app_flat_24: 1,
    char_25: 'the-herta',
    app_rate_25: '0.92%',
    avg_round_25: 24000,
    app_flat_25: 1,
    char_26: 'aventurine',
    app_rate_26: '0.92%',
    avg_round_26: 24000,
    app_flat_26: 1,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 0.73,
    char_1: 'acheron',
    app_rate_1: '87.37%',
    avg_round_1: 24494,
    app_flat_1: 26,
    char_2: 'pela',
    app_rate_2: '38.95%',
    avg_round_2: 24180,
    app_flat_2: 12,
    char_3: 'jiaoqiu',
    app_rate_3: '22.11%',
    avg_round_3: 26488,
    app_flat_3: 7,
    char_4: 'kafka',
    app_rate_4: '22.11%',
    avg_round_4: 25025,
    app_flat_4: 13,
    char_5: 'sparkle',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'black-swan',
    app_rate_6: '18.95%',
    avg_round_6: 24097,
    app_flat_6: 11,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '11.58%',
    avg_round_7: 29400,
    app_flat_7: 5,
    char_8: 'sunday',
    app_rate_8: '10.53%',
    avg_round_8: 27340,
    app_flat_8: 2,
    char_9: 'tribbie',
    app_rate_9: '8.42%',
    avg_round_9: 35520,
    app_flat_9: 1,
    char_10: 'the-herta',
    app_rate_10: '7.37%',
    avg_round_10: 31280,
    app_flat_10: 4,
    char_11: 'herta',
    app_rate_11: '7.37%',
    avg_round_11: 31856,
    app_flat_11: 5,
    char_12: 'tingyun-fugue',
    app_rate_12: '7.37%',
    avg_round_12: 24293,
    app_flat_12: 3,
    char_13: 'silver-wolf',
    app_rate_13: '7.37%',
    avg_round_13: 24040,
    app_flat_13: 1,
    char_14: 'bronya',
    app_rate_14: '7.37%',
    avg_round_14: 21960,
    app_flat_14: 1,
    char_15: 'jade',
    app_rate_15: '5.26%',
    avg_round_15: 29160,
    app_flat_15: 2,
    char_16: 'robin',
    app_rate_16: '5.26%',
    avg_round_16: 32440,
    app_flat_16: 2,
    char_17: 'serval',
    app_rate_17: '2.11%',
    avg_round_17: 24160,
    app_flat_17: 1,
    char_18: 'jing-yuan',
    app_rate_18: '2.11%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'ruan-mei',
    app_rate_19: '2.11%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'aglaea',
    app_rate_20: '2.11%',
    avg_round_20: 26300,
    app_flat_20: 2,
    char_21: 'himeko',
    app_rate_21: '1.05%',
    avg_round_21: 28080,
    app_flat_21: 1,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '1.05%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'guinaifen',
    app_rate_23: '1.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'firefly',
    app_rate_24: '1.05%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 0.56,
    char_1: 'robin',
    app_rate_1: '75.34%',
    avg_round_1: 24025,
    app_flat_1: 8,
    char_2: 'aventurine',
    app_rate_2: '67.12%',
    avg_round_2: 24031,
    app_flat_2: 9,
    char_3: 'feixiao',
    app_rate_3: '63.01%',
    avg_round_3: 25224,
    app_flat_3: 5,
    char_4: 'tribbie',
    app_rate_4: '23.29%',
    avg_round_4: 26093,
    app_flat_4: 5,
    char_5: 'yunli',
    app_rate_5: '12.33%',
    avg_round_5: 30740,
    app_flat_5: 2,
    char_6: 'lingsha',
    app_rate_6: '10.96%',
    avg_round_6: 20840,
    app_flat_6: 1,
    char_7: 'herta',
    app_rate_7: '9.59%',
    avg_round_7: 24320,
    app_flat_7: 1,
    char_8: 'huohuo',
    app_rate_8: '5.48%',
    avg_round_8: 31600,
    app_flat_8: 1,
    char_9: 'jade',
    app_rate_9: '5.48%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'clara',
    app_rate_10: '5.48%',
    avg_round_10: 21627,
    app_flat_10: 3,
    char_11: 'jiaoqiu',
    app_rate_11: '4.11%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'acheron',
    app_rate_12: '2.74%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'luocha',
    app_rate_13: '2.74%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '2.74%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '2.74%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'fu-xuan',
    app_rate_16: '1.37%',
    avg_round_16: 26000,
    app_flat_16: 1,
    char_17: 'the-herta',
    app_rate_17: '1.37%',
    avg_round_17: 20840,
    app_flat_17: 1,
    char_18: 'himeko',
    app_rate_18: '1.37%',
    avg_round_18: 20840,
    app_flat_18: 1,
    char_19: 'aglaea',
    app_rate_19: '1.37%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'gallagher',
    app_rate_20: '1.37%',
    avg_round_20: 27600,
    app_flat_20: 1,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 0.54,
    char_1: 'robin',
    app_rate_1: '74.29%',
    avg_round_1: 24185,
    app_flat_1: 24,
    char_2: 'feixiao',
    app_rate_2: '61.43%',
    avg_round_2: 24804,
    app_flat_2: 20,
    char_3: 'aventurine',
    app_rate_3: '40.0%',
    avg_round_3: 23806,
    app_flat_3: 13,
    char_4: 'tribbie',
    app_rate_4: '24.29%',
    avg_round_4: 28567,
    app_flat_4: 6,
    char_5: 'herta',
    app_rate_5: '21.43%',
    avg_round_5: 24260,
    app_flat_5: 4,
    char_6: 'gallagher',
    app_rate_6: '10.0%',
    avg_round_6: 25360,
    app_flat_6: 4,
    char_7: 'fu-xuan',
    app_rate_7: '10.0%',
    avg_round_7: 26520,
    app_flat_7: 2,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '10.0%',
    avg_round_8: 22540,
    app_flat_8: 2,
    char_9: 'ruan-mei',
    app_rate_9: '7.14%',
    avg_round_9: 24520,
    app_flat_9: 3,
    char_10: 'yunli',
    app_rate_10: '7.14%',
    avg_round_10: 25800,
    app_flat_10: 3,
    char_11: 'huohuo',
    app_rate_11: '7.14%',
    avg_round_11: 24400,
    app_flat_11: 3,
    char_12: 'lingsha',
    app_rate_12: '4.29%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'firefly',
    app_rate_13: '2.86%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'trailblazer-harmony',
    app_rate_14: '2.86%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '1.43%',
    avg_round_15: 26040,
    app_flat_15: 1,
    char_16: 'acheron',
    app_rate_16: '1.43%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'silver-wolf',
    app_rate_17: '1.43%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'gepard',
    app_rate_18: '1.43%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'imbibitor-lunae',
    app_rate_19: '1.43%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'mydei',
    app_rate_20: '1.43%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'luocha',
    app_rate_21: '1.43%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'rappa',
    app_rate_22: '1.43%',
    avg_round_22: 24960,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '1.43%',
    avg_round_23: 24960,
    app_flat_23: 1,
    char_24: 'the-herta',
    app_rate_24: '1.43%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jade',
    app_rate_25: '1.43%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'sunday',
    app_rate_26: '1.43%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.53,
    char_1: 'sunday',
    app_rate_1: '34.78%',
    avg_round_1: 27126,
    app_flat_1: 11,
    char_2: 'robin',
    app_rate_2: '33.33%',
    avg_round_2: 33434,
    app_flat_2: 7,
    char_3: 'the-herta',
    app_rate_3: '27.54%',
    avg_round_3: 37996,
    app_flat_3: 9,
    char_4: 'tribbie',
    app_rate_4: '26.09%',
    avg_round_4: 37650,
    app_flat_4: 8,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '24.64%',
    avg_round_5: 32990,
    app_flat_5: 8,
    char_6: 'aglaea',
    app_rate_6: '23.19%',
    avg_round_6: 31270,
    app_flat_6: 4,
    char_7: 'herta',
    app_rate_7: '18.84%',
    avg_round_7: 37467,
    app_flat_7: 9,
    char_8: 'jade',
    app_rate_8: '17.39%',
    avg_round_8: 37093,
    app_flat_8: 5,
    char_9: 'kafka',
    app_rate_9: '14.49%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jing-yuan',
    app_rate_10: '13.04%',
    avg_round_10: 26640,
    app_flat_10: 6,
    char_11: 'acheron',
    app_rate_11: '13.04%',
    avg_round_11: 21480,
    app_flat_11: 1,
    char_12: 'black-swan',
    app_rate_12: '11.59%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'bronya',
    app_rate_13: '5.8%',
    avg_round_13: 26000,
    app_flat_13: 1,
    char_14: 'ruan-mei',
    app_rate_14: '5.8%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'jiaoqiu',
    app_rate_15: '5.8%',
    avg_round_15: 21480,
    app_flat_15: 1,
    char_16: 'argenti',
    app_rate_16: '4.35%',
    avg_round_16: 32280,
    app_flat_16: 2,
    char_17: 'yunli',
    app_rate_17: '2.9%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'tingyun',
    app_rate_18: '2.9%',
    avg_round_18: 28080,
    app_flat_18: 1,
    char_19: 'serval',
    app_rate_19: '2.9%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'sparkle',
    app_rate_20: '2.9%',
    avg_round_20: 25920,
    app_flat_20: 1,
    char_21: 'qingque',
    app_rate_21: '1.45%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'pela',
    app_rate_22: '1.45%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'rappa',
    app_rate_23: '1.45%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.45%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'himeko',
    app_rate_25: '1.45%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'blade',
    app_rate_26: '1.45%',
    avg_round_26: 26000,
    app_flat_26: 1,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingque',
    app: 0.44,
    char_1: 'sparkle',
    app_rate_1: '84.21%',
    avg_round_1: 23817,
    app_flat_1: 13,
    char_2: 'fu-xuan',
    app_rate_2: '64.91%',
    avg_round_2: 24080,
    app_flat_2: 7,
    char_3: 'tribbie',
    app_rate_3: '57.89%',
    avg_round_3: 30160,
    app_flat_3: 7,
    char_4: 'sunday',
    app_rate_4: '19.3%',
    avg_round_4: 24460,
    app_flat_4: 6,
    char_5: 'robin',
    app_rate_5: '10.53%',
    avg_round_5: 26520,
    app_flat_5: 2,
    char_6: 'jade',
    app_rate_6: '8.77%',
    avg_round_6: 22960,
    app_flat_6: 4,
    char_7: 'huohuo',
    app_rate_7: '8.77%',
    avg_round_7: 27227,
    app_flat_7: 3,
    char_8: 'the-herta',
    app_rate_8: '7.02%',
    avg_round_8: 36000,
    app_flat_8: 2,
    char_9: 'lingsha',
    app_rate_9: '7.02%',
    avg_round_9: 32800,
    app_flat_9: 1,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '7.02%',
    avg_round_10: 22960,
    app_flat_10: 1,
    char_11: 'gallagher',
    app_rate_11: '3.51%',
    avg_round_11: 24120,
    app_flat_11: 2,
    char_12: 'herta',
    app_rate_12: '3.51%',
    avg_round_12: 35040,
    app_flat_12: 1,
    char_13: 'ruan-mei',
    app_rate_13: '3.51%',
    avg_round_13: 29360,
    app_flat_13: 1,
    char_14: 'pela',
    app_rate_14: '1.75%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'bailu',
    app_rate_15: '1.75%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'tingyun',
    app_rate_16: '1.75%',
    avg_round_16: 21880,
    app_flat_16: 1,
    char_17: 'silver-wolf',
    app_rate_17: '1.75%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'seele',
    app_rate_18: '1.75%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'argenti',
    app_rate_19: '1.75%',
    avg_round_19: 34200,
    app_flat_19: 1,
    char_20: 'aventurine',
    app_rate_20: '1.75%',
    avg_round_20: 36960,
    app_flat_20: 1,
    char_21: 'luocha',
    app_rate_21: '1.75%',
    avg_round_21: 24000,
    app_flat_21: 1,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.42,
    char_1: 'tribbie',
    app_rate_1: '34.55%',
    avg_round_1: 32288,
    app_flat_1: 10,
    char_2: 'jade',
    app_rate_2: '32.73%',
    avg_round_2: 34240,
    app_flat_2: 10,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '32.73%',
    avg_round_3: 26490,
    app_flat_3: 8,
    char_4: 'sunday',
    app_rate_4: '29.09%',
    avg_round_4: 24950,
    app_flat_4: 8,
    char_5: 'yunli',
    app_rate_5: '23.64%',
    avg_round_5: 26647,
    app_flat_5: 11,
    char_6: 'the-herta',
    app_rate_6: '20.0%',
    avg_round_6: 38504,
    app_flat_6: 7,
    char_7: 'robin',
    app_rate_7: '16.36%',
    avg_round_7: 24680,
    app_flat_7: 5,
    char_8: 'blade',
    app_rate_8: '12.73%',
    avg_round_8: 27080,
    app_flat_8: 1,
    char_9: 'ruan-mei',
    app_rate_9: '12.73%',
    avg_round_9: 28470,
    app_flat_9: 4,
    char_10: 'herta',
    app_rate_10: '12.73%',
    avg_round_10: 35180,
    app_flat_10: 2,
    char_11: 'sparkle',
    app_rate_11: '10.91%',
    avg_round_11: 24610,
    app_flat_11: 4,
    char_12: 'mydei',
    app_rate_12: '9.09%',
    avg_round_12: 23067,
    app_flat_12: 3,
    char_13: 'aglaea',
    app_rate_13: '9.09%',
    avg_round_13: 26160,
    app_flat_13: 2,
    char_14: 'acheron',
    app_rate_14: '5.45%',
    avg_round_14: 24040,
    app_flat_14: 1,
    char_15: 'bronya',
    app_rate_15: '5.45%',
    avg_round_15: 24400,
    app_flat_15: 1,
    char_16: 'tingyun',
    app_rate_16: '5.45%',
    avg_round_16: 24733,
    app_flat_16: 3,
    char_17: 'jiaoqiu',
    app_rate_17: '3.64%',
    avg_round_17: 24040,
    app_flat_17: 1,
    char_18: 'jing-yuan',
    app_rate_18: '3.64%',
    avg_round_18: 24640,
    app_flat_18: 1,
    char_19: 'serval',
    app_rate_19: '3.64%',
    avg_round_19: 32920,
    app_flat_19: 2,
    char_20: 'kafka',
    app_rate_20: '1.82%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jingliu',
    app_rate_21: '1.82%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'argenti',
    app_rate_22: '1.82%',
    avg_round_22: 30560,
    app_flat_22: 1,
    char_23: 'xueyi',
    app_rate_23: '1.82%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'trailblazer-harmony',
    app_rate_24: '1.82%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'asta',
    app_rate_25: '1.82%',
    avg_round_25: 27920,
    app_flat_25: 1,
    char_26: 'imbibitor-lunae',
    app_rate_26: '1.82%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'feixiao',
    app_rate_27: '1.82%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'black-swan',
    app_rate_28: '1.82%',
    avg_round_28: 24040,
    app_flat_28: 1,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seele',
    app: 0.35,
    char_1: 'sparkle',
    app_rate_1: '51.11%',
    avg_round_1: 25360,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: '48.89%',
    avg_round_2: 29880,
    app_flat_2: 3,
    char_3: 'tribbie',
    app_rate_3: '44.44%',
    avg_round_3: 31230,
    app_flat_3: 4,
    char_4: 'robin',
    app_rate_4: '44.44%',
    avg_round_4: 24280,
    app_flat_4: 1,
    char_5: 'sunday',
    app_rate_5: '20.0%',
    avg_round_5: 23000,
    app_flat_5: 1,
    char_6: 'herta',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '15.56%',
    avg_round_7: 35280,
    app_flat_7: 1,
    char_8: 'gallagher',
    app_rate_8: '11.11%',
    avg_round_8: 27453,
    app_flat_8: 3,
    char_9: 'jade',
    app_rate_9: '8.89%',
    avg_round_9: 40000,
    app_flat_9: 1,
    char_10: 'tingyun',
    app_rate_10: '8.89%',
    avg_round_10: 23000,
    app_flat_10: 1,
    char_11: 'bronya',
    app_rate_11: '4.44%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'aventurine',
    app_rate_12: '4.44%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'acheron',
    app_rate_13: '2.22%',
    avg_round_13: 24080,
    app_flat_13: 1,
    char_14: 'tingyun-fugue',
    app_rate_14: '2.22%',
    avg_round_14: 24080,
    app_flat_14: 1,
    char_15: 'yunli',
    app_rate_15: '2.22%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'serval',
    app_rate_16: '2.22%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'qingque',
    app_rate_17: '2.22%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'luocha',
    app_rate_18: '2.22%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'rappa',
    app_rate_19: '2.22%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'huohuo',
    app_rate_20: '2.22%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jingliu',
    app: 0.3,
    char_1: 'ruan-mei',
    app_rate_1: '35.9%',
    avg_round_1: 24100,
    app_flat_1: 4,
    char_2: 'tribbie',
    app_rate_2: '33.33%',
    avg_round_2: 24040,
    app_flat_2: 1,
    char_3: 'sunday',
    app_rate_3: '30.77%',
    avg_round_3: 24120,
    app_flat_3: 2,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '28.21%',
    avg_round_4: 24480,
    app_flat_4: 4,
    char_5: 'robin',
    app_rate_5: '28.21%',
    avg_round_5: 24140,
    app_flat_5: 2,
    char_6: 'herta',
    app_rate_6: '17.95%',
    avg_round_6: 25133,
    app_flat_6: 3,
    char_7: 'huohuo',
    app_rate_7: '17.95%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '17.95%',
    avg_round_8: 24040,
    app_flat_8: 1,
    char_9: 'bronya',
    app_rate_9: '15.38%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'blade',
    app_rate_10: '12.82%',
    avg_round_10: 24160,
    app_flat_10: 1,
    char_11: 'luocha',
    app_rate_11: '10.26%',
    avg_round_11: 22640,
    app_flat_11: 1,
    char_12: 'lingsha',
    app_rate_12: '10.26%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'the-herta',
    app_rate_13: '7.69%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'aventurine',
    app_rate_14: '5.13%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'gallagher',
    app_rate_15: '5.13%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'rappa',
    app_rate_16: '2.56%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'jade',
    app_rate_17: '2.56%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'lynx',
    app_rate_18: '2.56%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'sparkle',
    app_rate_19: '2.56%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'clara',
    app_rate_20: '2.56%',
    avg_round_20: 24040,
    app_flat_20: 1,
    char_21: 'firefly',
    app_rate_21: '2.56%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'tingyun-fugue',
    app_rate_22: '2.56%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'mydei',
    app_rate_23: '2.56%',
    avg_round_23: 22640,
    app_flat_23: 1,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.29,
    char_1: 'tribbie',
    app_rate_1: '60.53%',
    avg_round_1: 33382,
    app_flat_1: 9,
    char_2: 'tingyun-fugue',
    app_rate_2: '36.84%',
    avg_round_2: 25960,
    app_flat_2: 5,
    char_3: 'fu-xuan',
    app_rate_3: '31.58%',
    avg_round_3: 29720,
    app_flat_3: 2,
    char_4: 'ruan-mei',
    app_rate_4: '31.58%',
    avg_round_4: 24350,
    app_flat_4: 4,
    char_5: 'sparkle',
    app_rate_5: '21.05%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'lingsha',
    app_rate_6: '18.42%',
    avg_round_6: 28147,
    app_flat_6: 3,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '18.42%',
    avg_round_7: 32944,
    app_flat_7: 5,
    char_8: 'jade',
    app_rate_8: '13.16%',
    avg_round_8: 28920,
    app_flat_8: 1,
    char_9: 'robin',
    app_rate_9: '13.16%',
    avg_round_9: 39500,
    app_flat_9: 2,
    char_10: 'aventurine',
    app_rate_10: '13.16%',
    avg_round_10: 35240,
    app_flat_10: 2,
    char_11: 'himeko',
    app_rate_11: '7.89%',
    avg_round_11: 24720,
    app_flat_11: 1,
    char_12: 'serval',
    app_rate_12: '5.26%',
    avg_round_12: 35280,
    app_flat_12: 1,
    char_13: 'gallagher',
    app_rate_13: '5.26%',
    avg_round_13: 20640,
    app_flat_13: 1,
    char_14: 'herta',
    app_rate_14: '5.26%',
    avg_round_14: 40000,
    app_flat_14: 1,
    char_15: 'trailblazer-harmony',
    app_rate_15: '2.63%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'luocha',
    app_rate_16: '2.63%',
    avg_round_16: 24280,
    app_flat_16: 1,
    char_17: 'the-herta',
    app_rate_17: '2.63%',
    avg_round_17: 35280,
    app_flat_17: 1,
    char_18: 'lynx',
    app_rate_18: '2.63%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'argenti',
    app_rate_19: '2.63%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'bronya',
    app_rate_20: '2.63%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'huohuo',
    app_rate_21: '2.63%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.19,
    char_1: 'acheron',
    app_rate_1: '52.0%',
    avg_round_1: 27280,
    app_flat_1: 3,
    char_2: 'tribbie',
    app_rate_2: '40.0%',
    avg_round_2: 36960,
    app_flat_2: 3,
    char_3: 'the-herta',
    app_rate_3: '32.0%',
    avg_round_3: 35352,
    app_flat_3: 5,
    char_4: 'herta',
    app_rate_4: '32.0%',
    avg_round_4: 31273,
    app_flat_4: 6,
    char_5: 'pela',
    app_rate_5: '28.0%',
    avg_round_5: 30880,
    app_flat_5: 1,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '24.0%',
    avg_round_6: 35352,
    app_flat_6: 5,
    char_7: 'kafka',
    app_rate_7: '16.0%',
    avg_round_7: 25480,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '16.0%',
    avg_round_8: 25440,
    app_flat_8: 2,
    char_9: 'serval',
    app_rate_9: '12.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jiaoqiu',
    app_rate_10: '8.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'jade',
    app_rate_11: '8.0%',
    avg_round_11: 32080,
    app_flat_11: 2,
    char_12: 'robin',
    app_rate_12: '8.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'tingyun',
    app_rate_13: '4.0%',
    avg_round_13: 24000,
    app_flat_13: 1,
    char_14: 'rappa',
    app_rate_14: '4.0%',
    avg_round_14: 26720,
    app_flat_14: 1,
    char_15: 'tingyun-fugue',
    app_rate_15: '4.0%',
    avg_round_15: 26960,
    app_flat_15: 1,
    char_16: 'sunday',
    app_rate_16: '4.0%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'argenti',
    app_rate_17: '4.0%',
    avg_round_17: 40000,
    app_flat_17: 1,
    char_18: 'black-swan',
    app_rate_18: '4.0%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'guinaifen',
    app: 0.15,
    char_1: 'acheron',
    app_rate_1: '84.21%',
    avg_round_1: 23960,
    app_flat_1: 13,
    char_2: 'aventurine',
    app_rate_2: '63.16%',
    avg_round_2: 23907,
    app_flat_2: 9,
    char_3: 'pela',
    app_rate_3: '63.16%',
    avg_round_3: 23945,
    app_flat_3: 11,
    char_4: 'kafka',
    app_rate_4: '15.79%',
    avg_round_4: 24040,
    app_flat_4: 1,
    char_5: 'gallagher',
    app_rate_5: '15.79%',
    avg_round_5: 24093,
    app_flat_5: 3,
    char_6: 'black-swan',
    app_rate_6: '10.53%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'tingyun-fugue',
    app_rate_7: '10.53%',
    avg_round_7: 24040,
    app_flat_7: 1,
    char_8: 'huohuo',
    app_rate_8: '5.26%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'fu-xuan',
    app_rate_9: '5.26%',
    avg_round_9: 24040,
    app_flat_9: 1,
    char_10: 'robin',
    app_rate_10: '5.26%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'gepard',
    app_rate_11: '5.26%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'firefly',
    app_rate_12: '5.26%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'lingsha',
    app_rate_13: '5.26%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jiaoqiu',
    app_rate_14: '5.26%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 0.11,
    char_1: 'robin',
    app_rate_1: '71.43%',
    avg_round_1: 22480,
    app_flat_1: 2,
    char_2: 'feixiao',
    app_rate_2: '64.29%',
    avg_round_2: 22480,
    app_flat_2: 2,
    char_3: 'aventurine',
    app_rate_3: '64.29%',
    avg_round_3: 23560,
    app_flat_3: 1,
    char_4: 'tribbie',
    app_rate_4: '21.43%',
    avg_round_4: 26760,
    app_flat_4: 1,
    char_5: 'gallagher',
    app_rate_5: '14.29%',
    avg_round_5: 21400,
    app_flat_5: 1,
    char_6: 'herta',
    app_rate_6: '7.14%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: '7.14%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'aglaea',
    app_rate_8: '7.14%',
    avg_round_8: 26200,
    app_flat_8: 1,
    char_9: 'sunday',
    app_rate_9: '7.14%',
    avg_round_9: 26200,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: '7.14%',
    avg_round_10: 26200,
    app_flat_10: 1,
    char_11: 'yunli',
    app_rate_11: '7.14%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'jade',
    app_rate_12: '7.14%',
    avg_round_12: 26760,
    app_flat_12: 1,
    char_13: 'huohuo',
    app_rate_13: '7.14%',
    avg_round_13: 26760,
    app_flat_13: 1,
    char_14: 'dr-ratio',
    app_rate_14: '7.14%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.08,
    char_1: 'acheron',
    app_rate_1: '63.64%',
    avg_round_1: 24240,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: '45.45%',
    avg_round_2: 25093,
    app_flat_2: 3,
    char_3: 'tribbie',
    app_rate_3: '36.36%',
    avg_round_3: 24000,
    app_flat_3: 1,
    char_4: 'aventurine',
    app_rate_4: '27.27%',
    avg_round_4: 24240,
    app_flat_4: 1,
    char_5: 'tingyun-fugue',
    app_rate_5: '18.18%',
    avg_round_5: 27280,
    app_flat_5: 1,
    char_6: 'pela',
    app_rate_6: '18.18%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'sparkle',
    app_rate_7: '18.18%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'jiaoqiu',
    app_rate_8: '18.18%',
    avg_round_8: 24240,
    app_flat_8: 1,
    char_9: 'herta',
    app_rate_9: '9.09%',
    avg_round_9: 27280,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: '9.09%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'himeko',
    app_rate_11: '9.09%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'rappa',
    app_rate_12: '9.09%',
    avg_round_12: 24000,
    app_flat_12: 1,
    char_13: 'trailblazer-harmony',
    app_rate_13: '9.09%',
    avg_round_13: 24000,
    app_flat_13: 1,
    char_14: 'tingyun',
    app_rate_14: '9.09%',
    avg_round_14: 24000,
    app_flat_14: 1,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.08,
    char_1: 'sunday',
    app_rate_1: '40.0%',
    avg_round_1: 28740,
    app_flat_1: 4,
    char_2: 'aglaea',
    app_rate_2: '30.0%',
    avg_round_2: 28580,
    app_flat_2: 2,
    char_3: 'herta',
    app_rate_3: '30.0%',
    avg_round_3: 27880,
    app_flat_3: 2,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '20.0%',
    avg_round_4: 28900,
    app_flat_4: 2,
    char_5: 'the-herta',
    app_rate_5: '20.0%',
    avg_round_5: 25520,
    app_flat_5: 1,
    char_6: 'lingsha',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'robin',
    app_rate_7: '20.0%',
    avg_round_7: 27560,
    app_flat_7: 1,
    char_8: 'serval',
    app_rate_8: '20.0%',
    avg_round_8: 25920,
    app_flat_8: 2,
    char_9: 'hanya',
    app_rate_9: '20.0%',
    avg_round_9: 25920,
    app_flat_9: 2,
    char_10: 'gallagher',
    app_rate_10: '20.0%',
    avg_round_10: 25920,
    app_flat_10: 2,
    char_11: 'tribbie',
    app_rate_11: '10.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'huohuo',
    app_rate_12: '10.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'jade',
    app_rate_13: '10.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'lynx',
    app_rate_14: '10.0%',
    avg_round_14: 27920,
    app_flat_14: 1,
    char_15: 'aventurine',
    app_rate_15: '10.0%',
    avg_round_15: 25520,
    app_flat_15: 1,
    char_16: 'luocha',
    app_rate_16: '10.0%',
    avg_round_16: 29240,
    app_flat_16: 1,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'boothill',
    app: 0.07,
    char_1: 'tingyun-fugue',
    app_rate_1: '77.78%',
    avg_round_1: 26800,
    app_flat_1: 2,
    char_2: 'ruan-mei',
    app_rate_2: '77.78%',
    avg_round_2: 22480,
    app_flat_2: 2,
    char_3: 'lingsha',
    app_rate_3: '44.44%',
    avg_round_3: 20480,
    app_flat_3: 1,
    char_4: 'pela',
    app_rate_4: '22.22%',
    avg_round_4: 24480,
    app_flat_4: 1,
    char_5: 'trailblazer-harmony',
    app_rate_5: '22.22%',
    avg_round_5: 28800,
    app_flat_5: 2,
    char_6: 'sunday',
    app_rate_6: '11.11%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'the-herta',
    app_rate_7: '11.11%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'herta',
    app_rate_8: '11.11%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'rappa',
    app_rate_9: '11.11%',
    avg_round_9: 33120,
    app_flat_9: 1,
    char_10: 'gallagher',
    app_rate_10: '11.11%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.07,
    char_1: 'sunday',
    app_rate_1: '44.44%',
    avg_round_1: 26180,
    app_flat_1: 2,
    char_2: 'serval',
    app_rate_2: '33.33%',
    avg_round_2: 25920,
    app_flat_2: 2,
    char_3: 'pela',
    app_rate_3: '22.22%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'jing-yuan',
    app_rate_4: '22.22%',
    avg_round_4: 26180,
    app_flat_4: 2,
    char_5: 'fu-xuan',
    app_rate_5: '22.22%',
    avg_round_5: 26440,
    app_flat_5: 1,
    char_6: 'the-herta',
    app_rate_6: '22.22%',
    avg_round_6: 32760,
    app_flat_6: 1,
    char_7: 'herta',
    app_rate_7: '22.22%',
    avg_round_7: 32760,
    app_flat_7: 1,
    char_8: 'asta',
    app_rate_8: '22.22%',
    avg_round_8: 25920,
    app_flat_8: 2,
    char_9: 'gallagher',
    app_rate_9: '22.22%',
    avg_round_9: 25920,
    app_flat_9: 2,
    char_10: 'acheron',
    app_rate_10: '11.11%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'black-swan',
    app_rate_11: '11.11%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '11.11%',
    avg_round_12: 32760,
    app_flat_12: 1,
    char_13: 'imbibitor-lunae',
    app_rate_13: '11.11%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'argenti',
    app_rate_14: '11.11%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '11.11%',
    avg_round_15: 25920,
    app_flat_15: 1,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.05,
    char_1: 'robin',
    app_rate_1: '83.33%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'aventurine',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'feixiao',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'tribbie',
    app_rate_4: '16.67%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'pela',
    app_rate_5: '16.67%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'gallagher',
    app_rate_6: '16.67%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'yunli',
    app_rate_7: '16.67%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '16.67%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'clara',
    app_rate_9: '16.67%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'moze',
    app_rate_10: '16.67%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sampo',
    app: 0.05,
    char_1: 'acheron',
    app_rate_1: '66.67%',
    avg_round_1: 27900,
    app_flat_1: 2,
    char_2: 'aventurine',
    app_rate_2: '50.0%',
    avg_round_2: 25240,
    app_flat_2: 2,
    char_3: 'pela',
    app_rate_3: '50.0%',
    avg_round_3: 32880,
    app_flat_3: 1,
    char_4: 'tribbie',
    app_rate_4: '33.33%',
    avg_round_4: 32880,
    app_flat_4: 1,
    char_5: 'black-swan',
    app_rate_5: '16.67%',
    avg_round_5: 22920,
    app_flat_5: 1,
    char_6: 'luka',
    app_rate_6: '16.67%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'the-herta',
    app_rate_7: '16.67%',
    avg_round_7: 27560,
    app_flat_7: 1,
    char_8: 'herta',
    app_rate_8: '16.67%',
    avg_round_8: 27560,
    app_flat_8: 1,
    char_9: 'kafka',
    app_rate_9: '16.67%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'huohuo',
    app_rate_10: '16.67%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.04,
    char_1: 'acheron',
    app_rate_1: '60.0%',
    avg_round_1: 25320,
    app_flat_1: 1,
    char_2: 'pela',
    app_rate_2: '40.0%',
    avg_round_2: 25320,
    app_flat_2: 1,
    char_3: 'sparkle',
    app_rate_3: '40.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'himeko',
    app_rate_4: '20.0%',
    avg_round_4: 25320,
    app_flat_4: 1,
    char_5: 'kafka',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'silver-wolf',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'herta',
    app_rate_7: '20.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'tribbie',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'jiaoqiu',
    app_rate_9: '20.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'yunli',
    app_rate_10: '20.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'tingyun',
    app_rate_11: '20.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.03,
    char_1: 'sunday',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'the-herta',
    app_rate_2: '25.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'jade',
    app_rate_3: '25.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'herta',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'firefly',
    app_rate_5: '25.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'himeko',
    app_rate_6: '25.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'tingyun-fugue',
    app_rate_7: '25.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'aglaea',
    app_rate_8: '25.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tribbie',
    app_rate_9: '25.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jing-yuan',
    app_rate_10: '25.0%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'tingyun',
    app_rate_11: '25.0%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.02,
    char_1: 'herta',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'ruan-mei',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'robin',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'sparkle',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'sunday',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'arlan',
    app: 0.02,
    char_1: 'the-herta',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'jade',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'sunday',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'firefly',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'tingyun-fugue',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'ruan-mei',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.01,
    char_1: 'the-herta',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'herta',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'tribbie',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luka',
    app: 0.01,
    char_1: 'sampo',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'pela',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'tribbie',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hook',
    app: 0.01,
    char_1: 'sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'tribbie',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'fu-xuan',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
