export const PFCharacterData = [
  {
    name: 'Acheron',
    char: 'acheron',
    current_app_rate: 32.67,
    app_eidolons: 21.85,
    app_free: 1.42,
    current_avg_score: 29180,
    round_eidolons: 33287,
    round_free: 27779,
    prev_app_rate: 54.61,
    prev_avg_score: 33030,
    rarity: 'Limited 5*'
  },
  {
    name: 'Aglaea',
    char: 'aglaea',
    current_app_rate: 14.03,
    app_eidolons: 8.06,
    app_free: 2.03,
    current_avg_score: 31931,
    round_eidolons: 38348,
    round_free: 30299,
    prev_app_rate: 4.08,
    prev_avg_score: 30909,
    rarity: 'Limited 5*'
  },
  {
    name: 'Argenti',
    char: 'argenti',
    current_app_rate: 5.21,
    app_eidolons: 2.55,
    app_free: 1.29,
    current_avg_score: 34659,
    round_eidolons: 38681,
    round_free: 34165,
    prev_app_rate: 10.92,
    prev_avg_score: 32831,
    rarity: 'Limited 5*'
  },
  {
    name: 'Arlan',
    char: 'arlan',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Asta',
    char: 'asta',
    current_app_rate: 0.08,
    app_eidolons: 0.02,
    app_free: 0.03,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.16,
    prev_avg_score: 26932,
    rarity: '4*'
  },
  {
    name: 'Aventurine',
    char: 'aventurine',
    current_app_rate: 46.36,
    app_eidolons: 24.36,
    app_free: 8.13,
    current_avg_score: 33929,
    round_eidolons: 37120,
    round_free: 34209,
    prev_app_rate: 52.89,
    prev_avg_score: 33907,
    rarity: 'Limited 5*'
  },
  {
    name: 'Bailu',
    char: 'bailu',
    current_app_rate: 0.53,
    app_eidolons: 0.34,
    app_free: 0.04,
    current_avg_score: 32682,
    round_eidolons: 32402,
    round_free: 0,
    prev_app_rate: 0.6,
    prev_avg_score: 29253,
    rarity: 'Standard 5*'
  },
  {
    name: 'Black Swan',
    char: 'black-swan',
    current_app_rate: 5.04,
    app_eidolons: 3.31,
    app_free: 0.22,
    current_avg_score: 24453,
    round_eidolons: 26696,
    round_free: 24336,
    prev_app_rate: 13.93,
    prev_avg_score: 28965,
    rarity: 'Limited 5*'
  },
  {
    name: 'Blade',
    char: 'blade',
    current_app_rate: 0.84,
    app_eidolons: 0.57,
    app_free: 0.1,
    current_avg_score: 31110,
    round_eidolons: 32686,
    round_free: 31535,
    prev_app_rate: 0.44,
    prev_avg_score: 26658,
    rarity: 'Limited 5*'
  },
  {
    name: 'Boothill',
    char: 'boothill',
    current_app_rate: 0.07,
    app_eidolons: 0.05,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.13,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    name: 'Bronya',
    char: 'bronya',
    current_app_rate: 3.3,
    app_eidolons: 2.49,
    app_free: 0.23,
    current_avg_score: 29585,
    round_eidolons: 33128,
    round_free: 27080,
    prev_app_rate: 3.81,
    prev_avg_score: 28850,
    rarity: 'Standard 5*'
  },
  {
    name: 'Clara',
    char: 'clara',
    current_app_rate: 1.13,
    app_eidolons: 0.63,
    app_free: 0.26,
    current_avg_score: 27714,
    round_eidolons: 31777,
    round_free: 27688,
    prev_app_rate: 3.23,
    prev_avg_score: 24621,
    rarity: 'Standard 5*'
  },
  {
    name: 'Dan Heng',
    char: 'dan-heng',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Dr. Ratio',
    char: 'dr-ratio',
    current_app_rate: 0.05,
    app_eidolons: 0.05,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.03,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    name: 'Feixiao',
    char: 'feixiao',
    current_app_rate: 3.08,
    app_eidolons: 2.23,
    app_free: 0.37,
    current_avg_score: 28620,
    round_eidolons: 33718,
    round_free: 28600,
    prev_app_rate: 2.74,
    prev_avg_score: 25919,
    rarity: 'Limited 5*'
  },
  {
    name: 'Firefly',
    char: 'firefly',
    current_app_rate: 15.28,
    app_eidolons: 13.38,
    app_free: 0.79,
    current_avg_score: 25603,
    round_eidolons: 33075,
    round_free: 25618,
    prev_app_rate: 13.14,
    prev_avg_score: 25585,
    rarity: 'Limited 5*'
  },
  {
    name: 'Fu Xuan',
    char: 'fu-xuan',
    current_app_rate: 20.89,
    app_eidolons: 13.05,
    app_free: 2.63,
    current_avg_score: 32602,
    round_eidolons: 34279,
    round_free: 33726,
    prev_app_rate: 28.75,
    prev_avg_score: 32402,
    rarity: 'Limited 5*'
  },
  {
    name: 'Gallagher',
    char: 'gallagher',
    current_app_rate: 21.92,
    app_eidolons: 10.21,
    app_free: 5.43,
    current_avg_score: 32325,
    round_eidolons: 33316,
    round_free: 32549,
    prev_app_rate: 25.08,
    prev_avg_score: 33063,
    rarity: '4*'
  },
  {
    name: 'Gepard',
    char: 'gepard',
    current_app_rate: 0.73,
    app_eidolons: 0.48,
    app_free: 0.03,
    current_avg_score: 25139,
    round_eidolons: 28219,
    round_free: 0,
    prev_app_rate: 1.85,
    prev_avg_score: 28990,
    rarity: 'Standard 5*'
  },
  {
    name: 'Guinaifen',
    char: 'guinaifen',
    current_app_rate: 0.15,
    app_eidolons: 0.05,
    app_free: 0.02,
    current_avg_score: 23960,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.58,
    prev_avg_score: 26709,
    rarity: '4*'
  },
  {
    name: 'Hanya',
    char: 'hanya',
    current_app_rate: 0.07,
    app_eidolons: 0.03,
    app_free: 0.03,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.12,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Herta',
    char: 'herta',
    current_app_rate: 60.95,
    app_eidolons: 25.17,
    app_free: 18.08,
    current_avg_score: 37957,
    round_eidolons: 39346,
    round_free: 36850,
    prev_app_rate: 64.31,
    prev_avg_score: 34405,
    rarity: '4*'
  },
  {
    name: 'Himeko',
    char: 'himeko',
    current_app_rate: 8.43,
    app_eidolons: 4.61,
    app_free: 2.42,
    current_avg_score: 29565,
    round_eidolons: 33166,
    round_free: 28573,
    prev_app_rate: 10.7,
    prev_avg_score: 26256,
    rarity: 'Standard 5*'
  },
  {
    name: 'Hook',
    char: 'hook',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Huohuo',
    char: 'huohuo',
    current_app_rate: 26.73,
    app_eidolons: 14.03,
    app_free: 5.28,
    current_avg_score: 34037,
    round_eidolons: 38040,
    round_free: 33591,
    prev_app_rate: 27.75,
    prev_avg_score: 33537,
    rarity: 'Limited 5*'
  },
  {
    name: 'Imbibitor Lunae',
    char: 'imbibitor-lunae',
    current_app_rate: 1.05,
    app_eidolons: 1.03,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 30469,
    round_free: 0,
    prev_app_rate: 0.39,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    name: 'Jade',
    char: 'jade',
    current_app_rate: 29.37,
    app_eidolons: 14.44,
    app_free: 7.09,
    current_avg_score: 39415,
    round_eidolons: 40000,
    round_free: 38926,
    prev_app_rate: 26,
    prev_avg_score: 37279,
    rarity: 'Limited 5*'
  },
  {
    name: 'Jiaoqiu',
    char: 'jiaoqiu',
    current_app_rate: 19.41,
    app_eidolons: 12.91,
    app_free: 0.92,
    current_avg_score: 30340,
    round_eidolons: 35120,
    round_free: 29344,
    prev_app_rate: 26.92,
    prev_avg_score: 38448,
    rarity: 'Limited 5*'
  },
  {
    name: 'Jing Yuan',
    char: 'jing-yuan',
    current_app_rate: 13.09,
    app_eidolons: 7.76,
    app_free: 1.52,
    current_avg_score: 29531,
    round_eidolons: 33478,
    round_free: 28003,
    prev_app_rate: 13.6,
    prev_avg_score: 30614,
    rarity: 'Limited 5*'
  },
  {
    name: 'Jingliu',
    char: 'jingliu',
    current_app_rate: 0.3,
    app_eidolons: 0.25,
    app_free: 0.02,
    current_avg_score: 0,
    round_eidolons: 25685,
    round_free: 0,
    prev_app_rate: 0.61,
    prev_avg_score: 23795,
    rarity: 'Limited 5*'
  },
  {
    name: 'Kafka',
    char: 'kafka',
    current_app_rate: 6.62,
    app_eidolons: 4.24,
    app_free: 0.39,
    current_avg_score: 25247,
    round_eidolons: 29182,
    round_free: 25544,
    prev_app_rate: 17.13,
    prev_avg_score: 30197,
    rarity: 'Limited 5*'
  },
  {
    name: 'Lingsha',
    char: 'lingsha',
    current_app_rate: 39.67,
    app_eidolons: 21.85,
    app_free: 8.2,
    current_avg_score: 34913,
    round_eidolons: 37980,
    round_free: 34118,
    prev_app_rate: 39.33,
    prev_avg_score: 34535,
    rarity: 'Limited 5*'
  },
  {
    name: 'Luka',
    char: 'luka',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Luocha',
    char: 'luocha',
    current_app_rate: 8.75,
    app_eidolons: 4.47,
    app_free: 1.86,
    current_avg_score: 32530,
    round_eidolons: 34071,
    round_free: 32220,
    prev_app_rate: 8.25,
    prev_avg_score: 32310,
    rarity: 'Limited 5*'
  },
  {
    name: 'Lynx',
    char: 'lynx',
    current_app_rate: 0.42,
    app_eidolons: 0.2,
    app_free: 0.08,
    current_avg_score: 29299,
    round_eidolons: 33112,
    round_free: 28952,
    prev_app_rate: 0.52,
    prev_avg_score: 25214,
    rarity: '4*'
  },
  {
    name: 'March 7th',
    char: 'march-7th',
    current_app_rate: 0.19,
    app_eidolons: 0.11,
    app_free: 0.02,
    current_avg_score: 31771,
    round_eidolons: 35234,
    round_free: 0,
    prev_app_rate: 0.55,
    prev_avg_score: 33180,
    rarity: '4*'
  },
  {
    name: 'March 7th - The Hunt',
    char: 'march-7th-swordmaster',
    current_app_rate: 0.54,
    app_eidolons: 0.32,
    app_free: 0.11,
    current_avg_score: 24818,
    round_eidolons: 29670,
    round_free: 24625,
    prev_app_rate: 0.56,
    prev_avg_score: 24378,
    rarity: '4*'
  },
  {
    name: 'Misha',
    char: 'misha',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Moze',
    char: 'moze',
    current_app_rate: 0.11,
    app_eidolons: 0.08,
    app_free: 0.02,
    current_avg_score: 0,
    round_eidolons: 30540,
    round_free: 0,
    prev_app_rate: 0.04,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Mydei',
    char: 'mydei',
    current_app_rate: 2.83,
    app_eidolons: 2.08,
    app_free: 0.15,
    current_avg_score: 26934,
    round_eidolons: 34728,
    round_free: 28200,
    prev_app_rate: 2.83,
    prev_avg_score: 26934,
    rarity: 'Limited 5*'
  },
  {
    name: 'Natasha',
    char: 'natasha',
    current_app_rate: 0.03,
    app_eidolons: 0.03,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.08,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Pela',
    char: 'pela',
    current_app_rate: 13.1,
    app_eidolons: 7,
    app_free: 0.91,
    current_avg_score: 28927,
    round_eidolons: 31466,
    round_free: 28932,
    prev_app_rate: 22.25,
    prev_avg_score: 33457,
    rarity: '4*'
  },
  {
    name: 'Qingque',
    char: 'qingque',
    current_app_rate: 0.44,
    app_eidolons: 0.3,
    app_free: 0.06,
    current_avg_score: 26762,
    round_eidolons: 30261,
    round_free: 0,
    prev_app_rate: 0.1,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Rappa',
    char: 'rappa',
    current_app_rate: 16.01,
    app_eidolons: 6.68,
    app_free: 4.56,
    current_avg_score: 31206,
    round_eidolons: 37355,
    round_free: 29756,
    prev_app_rate: 5.45,
    prev_avg_score: 29364,
    rarity: 'Limited 5*'
  },
  {
    name: 'Robin',
    char: 'robin',
    current_app_rate: 45.74,
    app_eidolons: 26.91,
    app_free: 7.26,
    current_avg_score: 32318,
    round_eidolons: 37249,
    round_free: 31824,
    prev_app_rate: 52.85,
    prev_avg_score: 32973,
    rarity: 'Limited 5*'
  },
  {
    name: 'Ruan Mei',
    char: 'ruan-mei',
    current_app_rate: 44.24,
    app_eidolons: 27.23,
    app_free: 8.58,
    current_avg_score: 31009,
    round_eidolons: 33888,
    round_free: 30197,
    prev_app_rate: 36.49,
    prev_avg_score: 29825,
    rarity: 'Limited 5*'
  },
  {
    name: 'Sampo',
    char: 'sampo',
    current_app_rate: 0.05,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.07,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Seele',
    char: 'seele',
    current_app_rate: 0.35,
    app_eidolons: 0.3,
    app_free: 0.02,
    current_avg_score: 0,
    round_eidolons: 29409,
    round_free: 0,
    prev_app_rate: 0.17,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    name: 'Serval',
    char: 'serval',
    current_app_rate: 7.97,
    app_eidolons: 3.67,
    app_free: 2.01,
    current_avg_score: 37661,
    round_eidolons: 39995,
    round_free: 36664,
    prev_app_rate: 4.97,
    prev_avg_score: 32781,
    rarity: '4*'
  },
  {
    name: 'Silver Wolf',
    char: 'silver-wolf',
    current_app_rate: 1.18,
    app_eidolons: 0.72,
    app_free: 0.02,
    current_avg_score: 24093,
    round_eidolons: 25603,
    round_free: 0,
    prev_app_rate: 3.71,
    prev_avg_score: 26768,
    rarity: 'Limited 5*'
  },
  {
    name: 'Sparkle',
    char: 'sparkle',
    current_app_rate: 12.72,
    app_eidolons: 10.35,
    app_free: 0.74,
    current_avg_score: 28927,
    round_eidolons: 32994,
    round_free: 29143,
    prev_app_rate: 20.32,
    prev_avg_score: 30402,
    rarity: 'Limited 5*'
  },
  {
    name: 'Sunday',
    char: 'sunday',
    current_app_rate: 40.67,
    app_eidolons: 24.13,
    app_free: 4.45,
    current_avg_score: 31548,
    round_eidolons: 35008,
    round_free: 30306,
    prev_app_rate: 40.31,
    prev_avg_score: 32866,
    rarity: 'Limited 5*'
  },
  {
    name: 'Sushang',
    char: 'sushang',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'The Herta',
    char: 'the-herta',
    current_app_rate: 72,
    app_eidolons: 28.88,
    app_free: 20.23,
    current_avg_score: 39344,
    round_eidolons: 40000,
    round_free: 38692,
    prev_app_rate: 71.04,
    prev_avg_score: 37703,
    rarity: 'Limited 5*'
  },
  {
    name: 'Tingyun',
    char: 'tingyun',
    current_app_rate: 2.28,
    app_eidolons: 1.2,
    app_free: 0.51,
    current_avg_score: 28873,
    round_eidolons: 31555,
    round_free: 28422,
    prev_app_rate: 5.24,
    prev_avg_score: 29152,
    rarity: '4*'
  },
  {
    name: 'Fugue',
    char: 'tingyun-fugue',
    current_app_rate: 30.73,
    app_eidolons: 19.58,
    app_free: 5.65,
    current_avg_score: 30970,
    round_eidolons: 34815,
    round_free: 29805,
    prev_app_rate: 23.58,
    prev_avg_score: 29670,
    rarity: 'Limited 5*'
  },
  {
    name: 'Topaz & Numby',
    char: 'topaz',
    current_app_rate: 0.56,
    app_eidolons: 0.46,
    app_free: 0.04,
    current_avg_score: 24794,
    round_eidolons: 31505,
    round_free: 0,
    prev_app_rate: 0.76,
    prev_avg_score: 24289,
    rarity: 'Limited 5*'
  },
  {
    name: 'Trailblazer - Destruction',
    char: 'trailblazer-destruction',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Trailblazer - Harmony',
    char: 'trailblazer-harmony',
    current_app_rate: 11.55,
    app_eidolons: 6.97,
    app_free: 2.28,
    current_avg_score: 30194,
    round_eidolons: 33142,
    round_free: 29462,
    prev_app_rate: 5.45,
    prev_avg_score: 25113,
    rarity: '4*'
  },
  {
    name: 'Trailblazer - Preservation',
    char: 'trailblazer-preservation',
    current_app_rate: 0.04,
    app_eidolons: 0.03,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.06,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Trailblazer - Remembrance',
    char: 'trailblazer-remembrance',
    current_app_rate: 40.47,
    app_eidolons: 15.99,
    app_free: 12.08,
    current_avg_score: 36794,
    round_eidolons: 38566,
    round_free: 34692,
    prev_app_rate: 41.54,
    prev_avg_score: 37346,
    rarity: '4*'
  },
  {
    name: 'Tribbie',
    char: 'tribbie',
    current_app_rate: 65.7,
    app_eidolons: 34.55,
    app_free: 14.46,
    current_avg_score: 39681,
    round_eidolons: 40000,
    round_free: 39510,
    prev_app_rate: 6.75,
    prev_avg_score: 39565,
    rarity: 'Limited 5*'
  },
  {
    name: 'Welt',
    char: 'welt',
    current_app_rate: 0.08,
    app_eidolons: 0.05,
    app_free: 0.02,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.3,
    prev_avg_score: 24283,
    rarity: 'Standard 5*'
  },
  {
    name: 'Xueyi',
    char: 'xueyi',
    current_app_rate: 0.29,
    app_eidolons: 0.19,
    app_free: 0.07,
    current_avg_score: 30637,
    round_eidolons: 31506,
    round_free: 0,
    prev_app_rate: 0.1,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Yanqing',
    char: 'yanqing',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0,
    prev_avg_score: 0,
    rarity: 'Standard 5*'
  },
  {
    name: 'Yukong',
    char: 'yukong',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    name: 'Yunli',
    char: 'yunli',
    current_app_rate: 4.65,
    app_eidolons: 2.83,
    app_free: 0.59,
    current_avg_score: 31234,
    round_eidolons: 37598,
    round_free: 30778,
    prev_app_rate: 5.26,
    prev_avg_score: 25944,
    rarity: 'Limited 5*'
  }
];
