import React from 'react';

export const TribbieSpeedTuning: React.FC = () => {
  return (
    <>
      <p>
        There are two ways to approach building Tribbie - Slow and Fast - each
        with its own benefits and issues.
      </p>
      <h5>Slow Tribbie</h5>
      <p>
        Slow Tribbie takes greater advantage of her Bonus Ability which grants
        her energy each time allies attack, making hitting her coveted 2 action
        (and in some cases 1 action) Ultimates much more comfortable/realistic.
        Slow Tribbie can also build substantially more damage at very little
        cost, as the majority of her damage stems from additional damage
        triggers, her Ultimate and Follow-Up attacks none of which are directly
        tied to her own actions.
      </p>
      <p>
        This damage comes in the form of easy access to offensive main stats on
        Relics, no need to use any specific Relic Set or waste sub-stats on
        Speed/energy. And also being able to use the Poet of Mourning Collapse
        set which specifically requires the wearer to be at or below 95 Speed at
        the start of combat.
      </p>
      <p>
        The downside of Slow Tribbie is that without help she'll need to wait
        until her entire team acts before using her Ultimate for the first time
        - this can be solved via her Signature or with a team that can grant her
        energy via her additional ability or other means.
      </p>
      <ul>
        <li>
          <strong className="green">Pros:</strong>
        </li>
        <ul>
          <li>More Damage.</li>
          <li>More flexible Relic options.</li>
          <li>Longer buff duration and as a result more comfortable Energy.</li>
        </ul>
        <li>
          <strong className="red">Cons:</strong>
        </li>
        <ul>
          <li>
            Less actions in a fight, resulting in less attacks and therefore
            less SP generation and Energy from those attacks.
          </li>
          <li>
            Won't have an Ultimate at the very start of the fight without help
            from her Signature or her team.
          </li>
          <li>
            Less Ultimates overall in a fight despite similar or better buff
            uptime.
          </li>
        </ul>
      </ul>
      <h6>Fast Tribbie</h6>
      <p>
        Fast Tribbie has less damage, more restrictive Relic options and higher
        energy pressure for her rotation but in exchange is able to use her
        Ultimate without issue at the very beginning of the fight, granting your
        team another round of attacks with all of Tribbie's buffs active. There
        are two ways to achieve this:
      </p>
      <ul>
        <li>
          The first is to build as much Speed on Tribbie as possible in order to
          outspeed the rest of your team. This will come at the cost of a lot of
          stats.
        </li>
        <li>
          The other is to build roughly 120 Speed (more or less than this may be
          ideal depending on the rest of your teams Speed) and equip Sprightly
          Vonwaq. The combination of this Speed breakpoint plus the Planar's
          advance will allow Tribbie to leapfrog to the start of the action
          order.
        </li>
      </ul>
      <p>
        In both scenarios the primary goal is for Tribbie to move first.
        Building Fast Tribbie comes at the cost of quite a bit of damage but has
        some strong supportive benefits and options - especially if you don't
        have her signature. Her faster Speed and more total Ultimates in a fight
        synergise very well with the Dance! Dance! Dance! Light Cone and Eagle
        of Twilight Line relic set.
      </p>
      <ul>
        <li>
          <strong className="green">Pros:</strong>
        </li>
        <ul>
          <li>
            More actions in a fight resulting in more attacks, SP generation and
            Energy.
          </li>
          <li>
            Will have access to Ultimate at the very start of the fight without
            help from her Signature or her team.
          </li>
          <li>
            More Ultimates overall in a fight opening greater synergies with
            Dance! Dance! Dance! and 4P Eagle.
          </li>
        </ul>
        <li>
          <strong className="red">Cons:</strong>
        </li>
        <ul>
          <li>Less damage.</li>
          <li>Less Flexible Relic Options.</li>
          <li>
            Shorter buff durations mean less time to generate energy from her
            team.
          </li>
        </ul>
      </ul>
      <h6>Summary</h6>
      <p>
        To sum it up, when deciding between the two playstyles{' '}
        <strong>
          for those with Tribbie's Signature, Slow Tribbie is the best option
        </strong>{' '}
        (unless you plan on abusing Dance! Dance! Dance! and or the 4P Eagle
        set). Outside of that, if you're fine with Tribbie and your team taking
        one round of actions to warm up in a fight to gain access to her
        Ultimate or if you have other ways to grant her the energy she needs for
        quick access, Slow Tribbie will deal more damage, be more comfortable to
        build and is more consistent when it comes to buff uptime.
      </p>
      <p>
        But if you're looking to gain immediate Ultimate access, want to build
        into using her Ultimate more frequently for Light Cone or Relic
        benefits, don't have the Poet of Mourning Collapse set that makes Slow
        Tribbie so strong and or don't mind losing some of her personal damage,
        Fast Tribbie might be what you're looking for.
      </p>
    </>
  );
};
