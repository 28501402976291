export const HSRMydeiDPS = {
  V1: {
    // 1 target
    e0: 433639,
    e1: 453447,
    e2: 536071,
    e3: 580593,
    e4: 677993,
    e5: 683855,
    e6: 867128,
    sig_e0: 598874,
    sig_e1: 627376,
    sig_e2: 741579,
    sig_e3: 803645,
    sig_e4: 938162,
    sig_e5: 946271,
    sig_e6: 1205674,
    // 3 targets
    alt_e0: 1055356,
    alt_e1: 1319416,
    alt_e2: 1562688,
    alt_e3: 1696063,
    alt_e4: 1982800,
    alt_e5: 1996225,
    alt_e6: 2638794,
    alt_sig_e0: 1457749,
    alt_sig_e1: 1823245,
    alt_sig_e2: 2159287,
    alt_sig_e3: 2343552,
    alt_sig_e4: 2740678,
    alt_sig_e5: 2759242,
    alt_sig_e6: 3668391,
    // 5 targets
    e0_5targets: 1055356,
    e1_5targets: 1840841,
    e2_5targets: 2212972,
    e3_5targets: 2405100,
    e4_5targets: 2838625,
    e5_5targets: 2852050,
    e6_5targets: 3912884,
    sig_e0_5targets: 1457749,
    sig_e1_5targets: 2544923,
    sig_e2_5targets: 3059064,
    sig_e3_5targets: 3326760,
    sig_e4_5targets: 3925080,
    sig_e5_5targets: 3943644,
    sig_e6_5targets: 5444359
  }
};
