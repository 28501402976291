export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    current_app_rate: 24.98,
    app_eidolons: 17.65,
    app_free: 0.91,
    current_avg_score: 3334,
    round_eidolons: 3450,
    round_free: 3302,
    prev_app_rate: 30.28,
    prev_avg_score: 3325,
    rarity: 'Limited 5*'
  },
  {
    char: 'aglaea',
    name: 'Aglaea',
    current_app_rate: 16.23,
    app_eidolons: 8.85,
    app_free: 2.53,
    current_avg_score: 3476,
    round_eidolons: 3625,
    round_free: 3446,
    prev_app_rate: 2.66,
    prev_avg_score: 3460,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    current_app_rate: 3.22,
    app_eidolons: 1.63,
    app_free: 0.58,
    current_avg_score: 3569,
    round_eidolons: 3605,
    round_free: 3547,
    prev_app_rate: 5.8,
    prev_avg_score: 3401,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    current_app_rate: 0.01,
    app_eidolons: 0,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    current_app_rate: 0.16,
    app_eidolons: 0.06,
    app_free: 0.04,
    current_avg_score: 3402,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.31,
    prev_avg_score: 3312,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    current_app_rate: 49.34,
    app_eidolons: 24.61,
    app_free: 11,
    current_avg_score: 3419,
    round_eidolons: 3495,
    round_free: 3409,
    prev_app_rate: 53.78,
    prev_avg_score: 3454,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    current_app_rate: 0.95,
    app_eidolons: 0.46,
    app_free: 0.14,
    current_avg_score: 3337,
    round_eidolons: 3430,
    round_free: 3302,
    prev_app_rate: 0.63,
    prev_avg_score: 3378,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    current_app_rate: 5.12,
    app_eidolons: 3.92,
    app_free: 0.17,
    current_avg_score: 3225,
    round_eidolons: 3360,
    round_free: 3210,
    prev_app_rate: 12.26,
    prev_avg_score: 3293,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    current_app_rate: 1.75,
    app_eidolons: 1.21,
    app_free: 0.19,
    current_avg_score: 3354,
    round_eidolons: 3424,
    round_free: 3335,
    prev_app_rate: 0.49,
    prev_avg_score: 3276,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    current_app_rate: 6.18,
    app_eidolons: 2.78,
    app_free: 1.04,
    current_avg_score: 3483,
    round_eidolons: 3582,
    round_free: 3435,
    prev_app_rate: 1.33,
    prev_avg_score: 3220,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    current_app_rate: 10.12,
    app_eidolons: 6.31,
    app_free: 1.52,
    current_avg_score: 3536,
    round_eidolons: 3627,
    round_free: 3499,
    prev_app_rate: 4.37,
    prev_avg_score: 3394,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    current_app_rate: 0.49,
    app_eidolons: 0.34,
    app_free: 0.05,
    current_avg_score: 3193,
    round_eidolons: 3325,
    round_free: 0,
    prev_app_rate: 1.46,
    prev_avg_score: 3143,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    current_app_rate: 0.08,
    app_eidolons: 0.04,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    current_app_rate: 0.27,
    app_eidolons: 0.16,
    app_free: 0.03,
    current_avg_score: 3111,
    round_eidolons: 3309,
    round_free: 0,
    prev_app_rate: 0.51,
    prev_avg_score: 3230,
    rarity: 'Limited 5*'
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    current_app_rate: 36.83,
    app_eidolons: 16.28,
    app_free: 12,
    current_avg_score: 3441,
    round_eidolons: 3568,
    round_free: 3416,
    prev_app_rate: 16.7,
    prev_avg_score: 3488,
    rarity: 'Limited 5*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    current_app_rate: 15.61,
    app_eidolons: 13.16,
    app_free: 1.11,
    current_avg_score: 3249,
    round_eidolons: 3425,
    round_free: 3251,
    prev_app_rate: 42.18,
    prev_avg_score: 3353,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    current_app_rate: 16.88,
    app_eidolons: 10.44,
    app_free: 2.43,
    current_avg_score: 3387,
    round_eidolons: 3441,
    round_free: 3391,
    prev_app_rate: 23.32,
    prev_avg_score: 3406,
    rarity: 'Limited 5*'
  },
  {
    char: 'tingyun-fugue',
    name: 'Fugue',
    current_app_rate: 26.54,
    app_eidolons: 16.93,
    app_free: 4.35,
    current_avg_score: 3408,
    round_eidolons: 3479,
    round_free: 3393,
    prev_app_rate: 40.64,
    prev_avg_score: 3409,
    rarity: 'Limited 5*'
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    current_app_rate: 24.27,
    app_eidolons: 10.68,
    app_free: 6.26,
    current_avg_score: 3396,
    round_eidolons: 3428,
    round_free: 3395,
    prev_app_rate: 35.94,
    prev_avg_score: 3373,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    current_app_rate: 0.89,
    app_eidolons: 0.56,
    app_free: 0.11,
    current_avg_score: 3350,
    round_eidolons: 3340,
    round_free: 3339,
    prev_app_rate: 1.04,
    prev_avg_score: 3298,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    current_app_rate: 0.34,
    app_eidolons: 0.14,
    app_free: 0.05,
    current_avg_score: 3284,
    round_eidolons: 3362,
    round_free: 0,
    prev_app_rate: 0.37,
    prev_avg_score: 3202,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    current_app_rate: 0.12,
    app_eidolons: 0.04,
    app_free: 0.05,
    current_avg_score: 3388,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.09,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    current_app_rate: 28.01,
    app_eidolons: 10.61,
    app_free: 8.14,
    current_avg_score: 3485,
    round_eidolons: 3579,
    round_free: 3469,
    prev_app_rate: 36.7,
    prev_avg_score: 3463,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    current_app_rate: 1.2,
    app_eidolons: 0.6,
    app_free: 0.3,
    current_avg_score: 3425,
    round_eidolons: 3564,
    round_free: 3416,
    prev_app_rate: 1.26,
    prev_avg_score: 3329,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.02,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    current_app_rate: 26.6,
    app_eidolons: 13.32,
    app_free: 5.58,
    current_avg_score: 3434,
    round_eidolons: 3506,
    round_free: 3417,
    prev_app_rate: 22.27,
    prev_avg_score: 3438,
    rarity: 'Limited 5*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    current_app_rate: 0.82,
    app_eidolons: 0.8,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 3298,
    round_free: 0,
    prev_app_rate: 3.97,
    prev_avg_score: 3255,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    current_app_rate: 20.92,
    app_eidolons: 10.46,
    app_free: 4.48,
    current_avg_score: 3597,
    round_eidolons: 3671,
    round_free: 3586,
    prev_app_rate: 23.99,
    prev_avg_score: 3534,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    current_app_rate: 14.58,
    app_eidolons: 9.8,
    app_free: 0.63,
    current_avg_score: 3380,
    round_eidolons: 3498,
    round_free: 3341,
    prev_app_rate: 18.61,
    prev_avg_score: 3347,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    current_app_rate: 10.57,
    app_eidolons: 6.36,
    app_free: 0.87,
    current_avg_score: 3376,
    round_eidolons: 3470,
    round_free: 3329,
    prev_app_rate: 5.23,
    prev_avg_score: 3326,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    current_app_rate: 2.25,
    app_eidolons: 1.5,
    app_free: 0.36,
    current_avg_score: 3328,
    round_eidolons: 3462,
    round_free: 3274,
    prev_app_rate: 0.95,
    prev_avg_score: 3189,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    current_app_rate: 4.84,
    app_eidolons: 3.63,
    app_free: 0.15,
    current_avg_score: 3231,
    round_eidolons: 3362,
    round_free: 3194,
    prev_app_rate: 10.74,
    prev_avg_score: 3295,
    rarity: 'Limited 5*'
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    current_app_rate: 40.18,
    app_eidolons: 21.55,
    app_free: 8.6,
    current_avg_score: 3489,
    round_eidolons: 3528,
    round_free: 3474,
    prev_app_rate: 41.64,
    prev_avg_score: 3451,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    current_app_rate: 0.09,
    app_eidolons: 0.03,
    app_free: 0.04,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    current_app_rate: 10.13,
    app_eidolons: 5.15,
    app_free: 2.18,
    current_avg_score: 3414,
    round_eidolons: 3455,
    round_free: 3414,
    prev_app_rate: 9.97,
    prev_avg_score: 3392,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    current_app_rate: 0.28,
    app_eidolons: 0.12,
    app_free: 0.05,
    current_avg_score: 3346,
    round_eidolons: 3455,
    round_free: 0,
    prev_app_rate: 0.46,
    prev_avg_score: 3304,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    current_app_rate: 0.11,
    app_eidolons: 0.09,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 3507,
    round_free: 0,
    prev_app_rate: 0.17,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    current_app_rate: 19.59,
    app_eidolons: 7.34,
    app_free: 7.33,
    current_avg_score: 3475,
    round_eidolons: 3592,
    round_free: 3450,
    prev_app_rate: 9.39,
    prev_avg_score: 3493,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    current_app_rate: 0.08,
    app_eidolons: 0.06,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'moze',
    name: 'Moze',
    current_app_rate: 2.75,
    app_eidolons: 1.11,
    app_free: 1.04,
    current_avg_score: 3341,
    round_eidolons: 3444,
    round_free: 3331,
    prev_app_rate: 0.78,
    prev_avg_score: 3432,
    rarity: '4*'
  },
  {
    char: 'mydei',
    name: 'Mydei',
    current_app_rate: 1.49,
    app_eidolons: 1.06,
    app_free: 0.08,
    current_avg_score: 3400,
    round_eidolons: 3559,
    round_free: 3364,
    prev_app_rate: 1.49,
    prev_avg_score: 3400,
    rarity: 'Limited 5*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    current_app_rate: 0.07,
    app_eidolons: 0.04,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.05,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    current_app_rate: 13.42,
    app_eidolons: 7.29,
    app_free: 0.97,
    current_avg_score: 3365,
    round_eidolons: 3433,
    round_free: 3368,
    prev_app_rate: 11.82,
    prev_avg_score: 3341,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    current_app_rate: 0.14,
    app_eidolons: 0.12,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 3358,
    round_free: 0,
    prev_app_rate: 0.12,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'rappa',
    name: 'Rappa',
    current_app_rate: 11.36,
    app_eidolons: 4.63,
    app_free: 3.26,
    current_avg_score: 3396,
    round_eidolons: 3479,
    round_free: 3394,
    prev_app_rate: 15.35,
    prev_avg_score: 3432,
    rarity: 'Limited 5*'
  },
  {
    char: 'robin',
    name: 'Robin',
    current_app_rate: 62.38,
    app_eidolons: 32.27,
    app_free: 14.39,
    current_avg_score: 3448,
    round_eidolons: 3561,
    round_free: 3424,
    prev_app_rate: 50.92,
    prev_avg_score: 3447,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    current_app_rate: 39.52,
    app_eidolons: 25.17,
    app_free: 6.32,
    current_avg_score: 3399,
    round_eidolons: 3465,
    round_free: 3378,
    prev_app_rate: 69.53,
    prev_avg_score: 3381,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    current_app_rate: 0.08,
    app_eidolons: 0.03,
    app_free: 0.01,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.25,
    prev_avg_score: 3222,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    current_app_rate: 0.9,
    app_eidolons: 0.76,
    app_free: 0.05,
    current_avg_score: 3560,
    round_eidolons: 3611,
    round_free: 0,
    prev_app_rate: 0.16,
    prev_avg_score: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    current_app_rate: 14.55,
    app_eidolons: 5.81,
    app_free: 4.57,
    current_avg_score: 3536,
    round_eidolons: 3667,
    round_free: 3518,
    prev_app_rate: 4.54,
    prev_avg_score: 3447,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    current_app_rate: 2.59,
    app_eidolons: 1.92,
    app_free: 0.08,
    current_avg_score: 3282,
    round_eidolons: 3426,
    round_free: 3291,
    prev_app_rate: 3.38,
    prev_avg_score: 3311,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    current_app_rate: 12.11,
    app_eidolons: 10.18,
    app_free: 0.49,
    current_avg_score: 3366,
    round_eidolons: 3472,
    round_free: 3352,
    prev_app_rate: 16.36,
    prev_avg_score: 3299,
    rarity: 'Limited 5*'
  },
  {
    char: 'sunday',
    name: 'Sunday',
    current_app_rate: 41.9,
    app_eidolons: 23.63,
    app_free: 4.88,
    current_avg_score: 3455,
    round_eidolons: 3555,
    round_free: 3431,
    prev_app_rate: 34.59,
    prev_avg_score: 3425,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    current_app_rate: 0.23,
    app_eidolons: 0.12,
    app_free: 0.06,
    current_avg_score: 3436,
    round_eidolons: 3524,
    round_free: 0,
    prev_app_rate: 0.02,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'the-herta',
    name: 'The Herta',
    current_app_rate: 64.12,
    app_eidolons: 26.25,
    app_free: 17.54,
    current_avg_score: 3527,
    round_eidolons: 3627,
    round_free: 3510,
    prev_app_rate: 63.62,
    prev_avg_score: 3489,
    rarity: 'Limited 5*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    current_app_rate: 2.25,
    app_eidolons: 1.31,
    app_free: 0.3,
    current_avg_score: 3347,
    round_eidolons: 3441,
    round_free: 3329,
    prev_app_rate: 3.04,
    prev_avg_score: 3251,
    rarity: '4*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    current_app_rate: 11.92,
    app_eidolons: 6.24,
    app_free: 3.16,
    current_avg_score: 3386,
    round_eidolons: 3528,
    round_free: 3361,
    prev_app_rate: 7.3,
    prev_avg_score: 3480,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer - Destruction',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer - Harmony',
    current_app_rate: 10.4,
    app_eidolons: 6.22,
    app_free: 1.9,
    current_avg_score: 3343,
    round_eidolons: 3418,
    round_free: 3326,
    prev_app_rate: 27.69,
    prev_avg_score: 3344,
    rarity: '4*'
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer - Preservation',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.03,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'trailblazer-remembrance',
    name: 'Trailblazer - Remembrance',
    current_app_rate: 39.73,
    app_eidolons: 15.85,
    app_free: 11.52,
    current_avg_score: 3508,
    round_eidolons: 3598,
    round_free: 3493,
    prev_app_rate: 27.19,
    prev_avg_score: 3528,
    rarity: '4*'
  },
  {
    char: 'tribbie',
    name: 'Tribbie',
    current_app_rate: 44.04,
    app_eidolons: 24.7,
    app_free: 8.59,
    current_avg_score: 3548,
    round_eidolons: 3628,
    round_free: 3537,
    prev_app_rate: 44.04,
    prev_avg_score: 3548,
    rarity: 'Limited 5*'
  },
  {
    char: 'welt',
    name: 'Welt',
    current_app_rate: 0.19,
    app_eidolons: 0.1,
    app_free: 0,
    current_avg_score: 3241,
    round_eidolons: 3340,
    round_free: 0,
    prev_app_rate: 0.25,
    prev_avg_score: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.04,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    current_app_rate: 0.14,
    app_eidolons: 0.09,
    app_free: 0.04,
    current_avg_score: 0,
    round_eidolons: 3449,
    round_free: 0,
    prev_app_rate: 0.01,
    prev_avg_score: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_score: 0,
    round_eidolons: 0,
    round_free: 0,
    prev_app_rate: 0.03,
    prev_avg_score: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    current_app_rate: 3.83,
    app_eidolons: 2.35,
    app_free: 0.47,
    current_avg_score: 3288,
    round_eidolons: 3401,
    round_free: 3279,
    prev_app_rate: 4.35,
    prev_avg_score: 3303,
    rarity: 'Limited 5*'
  }
];
