export const MoCCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 27.91,
    app_eidolons: 20.89,
    app_free: 0.6,
    current_avg_round: 8.7,
    round_eidolons: 6.56,
    round_free: 9.18,
    prev_app_rate: 47.85,
    prev_avg_round: 8.91
  },
  {
    char: 'aglaea',
    name: 'Aglaea',
    rarity: 'Limited 5*',
    current_app_rate: 19.03,
    app_eidolons: 9.79,
    app_free: 3.32,
    current_avg_round: 7.79,
    round_eidolons: 5.09,
    round_free: 8.25,
    prev_app_rate: 5.47,
    prev_avg_round: 7.12
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 2.22,
    app_eidolons: 0.98,
    app_free: 0.46,
    current_avg_round: 6.82,
    round_eidolons: 5.63,
    round_free: 7.8,
    prev_app_rate: 1.07,
    prev_avg_round: 7.63
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0.03,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.04,
    prev_avg_round: 99.99
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.1,
    app_eidolons: 0.08,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 5.75,
    round_free: 99.99,
    prev_app_rate: 0.23,
    prev_avg_round: 99.99
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 49.02,
    app_eidolons: 27.1,
    app_free: 8,
    current_avg_round: 8.48,
    round_eidolons: 6.65,
    round_free: 8.78,
    prev_app_rate: 50.91,
    prev_avg_round: 8.85
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.93,
    app_eidolons: 0.55,
    app_free: 0.1,
    current_avg_round: 8.71,
    round_eidolons: 7.45,
    round_free: 8.64,
    prev_app_rate: 0.84,
    prev_avg_round: 8.69
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 2.4,
    app_eidolons: 2.12,
    app_free: 0.03,
    current_avg_round: 9.45,
    round_eidolons: 7.67,
    round_free: 99.99,
    prev_app_rate: 21.83,
    prev_avg_round: 9.27
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 0.49,
    app_eidolons: 0.4,
    app_free: 0.03,
    current_avg_round: 8.54,
    round_eidolons: 7.06,
    round_free: 99.99,
    prev_app_rate: 0.96,
    prev_avg_round: 9.62
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 1.62,
    app_eidolons: 0.99,
    app_free: 0.08,
    current_avg_round: 8.37,
    round_eidolons: 5.78,
    round_free: 9.33,
    prev_app_rate: 1.76,
    prev_avg_round: 9.2
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 5.47,
    app_eidolons: 4.36,
    app_free: 0.3,
    current_avg_round: 7.87,
    round_eidolons: 5.23,
    round_free: 9.09,
    prev_app_rate: 5.94,
    prev_avg_round: 9.12
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 0.53,
    app_eidolons: 0.4,
    app_free: 0.06,
    current_avg_round: 9.27,
    round_eidolons: 7.64,
    round_free: 99.99,
    prev_app_rate: 0.57,
    prev_avg_round: 8.16
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.01,
    prev_avg_round: 99.99
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 1.72,
    app_eidolons: 1.24,
    app_free: 0.1,
    current_avg_round: 8.97,
    round_eidolons: 7.52,
    round_free: 8.8,
    prev_app_rate: 0.67,
    prev_avg_round: 8.71
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 12.63,
    app_eidolons: 8.15,
    app_free: 2.03,
    current_avg_round: 8.79,
    round_eidolons: 5.76,
    round_free: 9.07,
    prev_app_rate: 17.61,
    prev_avg_round: 8.91
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 31.05,
    app_eidolons: 24.12,
    app_free: 3.08,
    current_avg_round: 9.08,
    round_eidolons: 6.38,
    round_free: 9.25,
    prev_app_rate: 43.41,
    prev_avg_round: 9.32
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 20.64,
    app_eidolons: 13.3,
    app_free: 2.46,
    current_avg_round: 8.75,
    round_eidolons: 7,
    round_free: 8.98,
    prev_app_rate: 24.56,
    prev_avg_round: 8.79
  },
  {
    char: 'tingyun-fugue',
    name: 'Fugue',
    rarity: 'Limited 5*',
    current_app_rate: 36.19,
    app_eidolons: 23.42,
    app_free: 5.89,
    current_avg_round: 8.32,
    round_eidolons: 5.89,
    round_free: 8.69,
    prev_app_rate: 41.37,
    prev_avg_round: 8.76
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 29.17,
    app_eidolons: 14.73,
    app_free: 6.43,
    current_avg_round: 8.59,
    round_eidolons: 7.19,
    round_free: 8.9,
    prev_app_rate: 34.54,
    prev_avg_round: 9
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 1.2,
    app_eidolons: 0.89,
    app_free: 0.05,
    current_avg_round: 9.62,
    round_eidolons: 8.26,
    round_free: 99.99,
    prev_app_rate: 1.75,
    prev_avg_round: 9.18
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 0.06,
    app_eidolons: 0.03,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.58,
    prev_avg_round: 9.21
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.08,
    app_eidolons: 0.05,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.12,
    prev_avg_round: 99.99
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 27.15,
    app_eidolons: 9.92,
    app_free: 7.87,
    current_avg_round: 8.52,
    round_eidolons: 6.08,
    round_free: 8.82,
    prev_app_rate: 17.49,
    prev_avg_round: 8.46
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 0.35,
    app_eidolons: 0.19,
    app_free: 0.04,
    current_avg_round: 8.26,
    round_eidolons: 7.79,
    round_free: 99.99,
    prev_app_rate: 0.75,
    prev_avg_round: 9.32
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.01,
    prev_avg_round: 99.99
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 28.59,
    app_eidolons: 14.36,
    app_free: 5.44,
    current_avg_round: 7.92,
    round_eidolons: 6.13,
    round_free: 8.54,
    prev_app_rate: 25.09,
    prev_avg_round: 8.45
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 4.65,
    app_eidolons: 4.23,
    app_free: 0.09,
    current_avg_round: 9.52,
    round_eidolons: 6.83,
    round_free: 9.86,
    prev_app_rate: 4.89,
    prev_avg_round: 9.54
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 23.32,
    app_eidolons: 10.93,
    app_free: 5.14,
    current_avg_round: 7.98,
    round_eidolons: 5.42,
    round_free: 8.25,
    prev_app_rate: 13.83,
    prev_avg_round: 7.67
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 18.9,
    app_eidolons: 12.85,
    app_free: 0.57,
    current_avg_round: 8.51,
    round_eidolons: 5.98,
    round_free: 8.88,
    prev_app_rate: 25.3,
    prev_avg_round: 8.39
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 9.23,
    app_eidolons: 5.72,
    app_free: 0.76,
    current_avg_round: 8.91,
    round_eidolons: 7.75,
    round_free: 8.95,
    prev_app_rate: 9.52,
    prev_avg_round: 9.14
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 1.9,
    app_eidolons: 1.42,
    app_free: 0.11,
    current_avg_round: 9,
    round_eidolons: 7.34,
    round_free: 9.5,
    prev_app_rate: 3.06,
    prev_avg_round: 8.71
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 2.3,
    app_eidolons: 1.99,
    app_free: 0.03,
    current_avg_round: 9,
    round_eidolons: 7.96,
    round_free: 99.99,
    prev_app_rate: 17.64,
    prev_avg_round: 9.35
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 40.2,
    app_eidolons: 23.11,
    app_free: 7.49,
    current_avg_round: 8.24,
    round_eidolons: 6.13,
    round_free: 8.6,
    prev_app_rate: 34.33,
    prev_avg_round: 8.45
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0,
    prev_avg_round: 99.99
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 11.48,
    app_eidolons: 6.38,
    app_free: 1.93,
    current_avg_round: 8.56,
    round_eidolons: 7.56,
    round_free: 8.7,
    prev_app_rate: 10.49,
    prev_avg_round: 9.03
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.5,
    app_eidolons: 0.27,
    app_free: 0.07,
    current_avg_round: 8.65,
    round_eidolons: 8.14,
    round_free: 9.1,
    prev_app_rate: 0.4,
    prev_avg_round: 9.08
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.14,
    app_eidolons: 0.1,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 5.86,
    round_free: 99.99,
    prev_app_rate: 0.14,
    prev_avg_round: 99.99
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    rarity: '4*',
    current_app_rate: 5.21,
    app_eidolons: 2.87,
    app_free: 1.17,
    current_avg_round: 8.88,
    round_eidolons: 6.4,
    round_free: 9.21,
    prev_app_rate: 7.85,
    prev_avg_round: 8.9
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.04,
    prev_avg_round: 99.99
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 1.83,
    app_eidolons: 1.09,
    app_free: 0.32,
    current_avg_round: 9.4,
    round_eidolons: 5.49,
    round_free: 9.64,
    prev_app_rate: 0.73,
    prev_avg_round: 8.69
  },
  {
    char: 'mydei',
    name: 'Mydei',
    rarity: 'Limited 5*',
    current_app_rate: 4.29,
    app_eidolons: 2.46,
    app_free: 0.36,
    current_avg_round: 7.3,
    round_eidolons: 5.42,
    round_free: 7.69,
    prev_app_rate: 4.29,
    prev_avg_round: 7.3
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.04,
    app_eidolons: 0.03,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.05,
    prev_avg_round: 99.99
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 14.01,
    app_eidolons: 8.36,
    app_free: 0.6,
    current_avg_round: 8.63,
    round_eidolons: 7.81,
    round_free: 9.23,
    prev_app_rate: 19.1,
    prev_avg_round: 8.83
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.89,
    app_eidolons: 0.57,
    app_free: 0.14,
    current_avg_round: 8.92,
    round_eidolons: 6.54,
    round_free: 8.91,
    prev_app_rate: 0.51,
    prev_avg_round: 8.67
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 15.39,
    app_eidolons: 5.73,
    app_free: 4.76,
    current_avg_round: 8.17,
    round_eidolons: 5.88,
    round_free: 8.61,
    prev_app_rate: 15.95,
    prev_avg_round: 8.65
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 52.36,
    app_eidolons: 31.5,
    app_free: 7.4,
    current_avg_round: 8.37,
    round_eidolons: 5.79,
    round_free: 8.79,
    prev_app_rate: 48.24,
    prev_avg_round: 8.61
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 53.58,
    app_eidolons: 35.48,
    app_free: 8.05,
    current_avg_round: 8.55,
    round_eidolons: 6.25,
    round_free: 8.88,
    prev_app_rate: 70.4,
    prev_avg_round: 9.04
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.47,
    prev_avg_round: 9.67
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.31,
    app_eidolons: 0.29,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 7.87,
    round_free: 99.99,
    prev_app_rate: 0.55,
    prev_avg_round: 99.99
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 12.59,
    app_eidolons: 4.94,
    app_free: 3.85,
    current_avg_round: 8.07,
    round_eidolons: 5.51,
    round_free: 8.31,
    prev_app_rate: 4.97,
    prev_avg_round: 7.47
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 3.02,
    app_eidolons: 2.43,
    app_free: 0.05,
    current_avg_round: 9.25,
    round_eidolons: 7.82,
    round_free: 99.99,
    prev_app_rate: 7.45,
    prev_avg_round: 9.05
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 16.71,
    app_eidolons: 14.79,
    app_free: 0.53,
    current_avg_round: 8.96,
    round_eidolons: 6.27,
    round_free: 9.15,
    prev_app_rate: 21.38,
    prev_avg_round: 9.01
  },
  {
    char: 'sunday',
    name: 'Sunday',
    rarity: 'Limited 5*',
    current_app_rate: 45.15,
    app_eidolons: 26.41,
    app_free: 4.77,
    current_avg_round: 8.18,
    round_eidolons: 5.88,
    round_free: 8.45,
    prev_app_rate: 36.88,
    prev_avg_round: 8.43
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.01,
    prev_avg_round: 99.99
  },
  {
    char: 'the-herta',
    name: 'The Herta',
    rarity: 'Limited 5*',
    current_app_rate: 62.77,
    app_eidolons: 25.04,
    app_free: 16.88,
    current_avg_round: 8.21,
    round_eidolons: 5.63,
    round_free: 8.52,
    prev_app_rate: 33.73,
    prev_avg_round: 8.18
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 2.03,
    app_eidolons: 1.37,
    app_free: 0.27,
    current_avg_round: 9.04,
    round_eidolons: 7.82,
    round_free: 8.69,
    prev_app_rate: 3.24,
    prev_avg_round: 9.46
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 5.76,
    app_eidolons: 4.39,
    app_free: 0.51,
    current_avg_round: 8.37,
    round_eidolons: 5.94,
    round_free: 8.63,
    prev_app_rate: 8.51,
    prev_avg_round: 8.92
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer - Destruction',
    rarity: '4*',
    current_app_rate: 0,
    app_eidolons: 0,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.01,
    prev_avg_round: 99.99
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer - Harmony',
    rarity: '4*',
    current_app_rate: 22.04,
    app_eidolons: 13.94,
    app_free: 3.98,
    current_avg_round: 8.43,
    round_eidolons: 6.13,
    round_free: 8.76,
    prev_app_rate: 36.13,
    prev_avg_round: 9.33
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer - Preservation',
    rarity: '4*',
    current_app_rate: 0.02,
    app_eidolons: 0.02,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.01,
    prev_avg_round: 99.99
  },
  {
    char: 'trailblazer-remembrance',
    name: 'Trailblazer - Remembrance',
    rarity: '4*',
    current_app_rate: 42.4,
    app_eidolons: 17.28,
    app_free: 12.06,
    current_avg_round: 8.26,
    round_eidolons: 5.8,
    round_free: 8.65,
    prev_app_rate: 16.36,
    prev_avg_round: 7.76
  },
  {
    char: 'tribbie',
    name: 'Tribbie',
    rarity: 'Limited 5*',
    current_app_rate: 25.91,
    app_eidolons: 15.17,
    app_free: 4.62,
    current_avg_round: 6.77,
    round_eidolons: 4.37,
    round_free: 7.06,
    prev_app_rate: 17.24,
    prev_avg_round: 6.59
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.22,
    app_eidolons: 0.14,
    app_free: 0.02,
    current_avg_round: 9.29,
    round_eidolons: 9.09,
    round_free: 99.99,
    prev_app_rate: 0.31,
    prev_avg_round: 9.36
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.15,
    app_eidolons: 0.1,
    app_free: 0.01,
    current_avg_round: 99.99,
    round_eidolons: 7.67,
    round_free: 99.99,
    prev_app_rate: 0.1,
    prev_avg_round: 99.99
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.05,
    app_eidolons: 0.05,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0.03,
    prev_avg_round: 99.99
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.01,
    app_eidolons: 0.01,
    app_free: 0,
    current_avg_round: 99.99,
    round_eidolons: 99.99,
    round_free: 99.99,
    prev_app_rate: 0,
    prev_avg_round: 99.99
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 2.48,
    app_eidolons: 1.75,
    app_free: 0.17,
    current_avg_round: 8.73,
    round_eidolons: 6.09,
    round_free: 8.6,
    prev_app_rate: 2.4,
    prev_avg_round: 8.74
  }
];
