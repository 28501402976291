export const ApoDuos = [
  {
    char: 'the-herta',
    app: 64.12,
    char_1: 'tribbie',
    app_rate_1: '49.07%',
    avg_round_1: 3580,
    app_flat_1: 2530,
    char_2: 'herta',
    app_rate_2: '41.49%',
    avg_round_2: 3488,
    app_flat_2: 2573,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '37.64%',
    avg_round_3: 3546,
    app_flat_3: 2546,
    char_4: 'jade',
    app_rate_4: '29.76%',
    avg_round_4: 3604,
    app_flat_4: 1522,
    char_5: 'lingsha',
    app_rate_5: '23.56%',
    avg_round_5: 3581,
    app_flat_5: 1365,
    char_6: 'serval',
    app_rate_6: '22.23%',
    avg_round_6: 3544,
    app_flat_6: 1318,
    char_7: 'aventurine',
    app_rate_7: '18.04%',
    avg_round_7: 3497,
    app_flat_7: 1064,
    char_8: 'huohuo',
    app_rate_8: '15.51%',
    avg_round_8: 3473,
    app_flat_8: 894,
    char_9: 'gallagher',
    app_rate_9: '12.45%',
    avg_round_9: 3498,
    app_flat_9: 894,
    char_10: 'sunday',
    app_rate_10: '10.12%',
    avg_round_10: 3482,
    app_flat_10: 559,
    char_11: 'fu-xuan',
    app_rate_11: '9.11%',
    avg_round_11: 3455,
    app_flat_11: 432,
    char_12: 'robin',
    app_rate_12: '9.0%',
    avg_round_12: 3452,
    app_flat_12: 400,
    char_13: 'luocha',
    app_rate_13: '6.91%',
    avg_round_13: 3474,
    app_flat_13: 442,
    char_14: 'ruan-mei',
    app_rate_14: '4.16%',
    avg_round_14: 3463,
    app_flat_14: 188,
    char_15: 'argenti',
    app_rate_15: '3.72%',
    avg_round_15: 3620,
    app_flat_15: 194,
    char_16: 'sparkle',
    app_rate_16: '1.93%',
    avg_round_16: 3418,
    app_flat_16: 61,
    char_17: 'himeko',
    app_rate_17: '1.19%',
    avg_round_17: 3503,
    app_flat_17: 66,
    char_18: 'pela',
    app_rate_18: '0.77%',
    avg_round_18: 3518,
    app_flat_18: 37,
    char_19: 'rappa',
    app_rate_19: '0.52%',
    avg_round_19: 3451,
    app_flat_19: 28,
    char_20: 'jing-yuan',
    app_rate_20: '0.5%',
    avg_round_20: 3432,
    app_flat_20: 22,
    char_21: 'bailu',
    app_rate_21: '0.41%',
    avg_round_21: 3383,
    app_flat_21: 25,
    char_22: 'bronya',
    app_rate_22: '0.28%',
    avg_round_22: 3420,
    app_flat_22: 15,
    char_23: 'jiaoqiu',
    app_rate_23: '0.19%',
    avg_round_23: 3526,
    app_flat_23: 7,
    char_24: 'feixiao',
    app_rate_24: '0.18%',
    avg_round_24: 3396,
    app_flat_24: 10,
    char_25: 'yunli',
    app_rate_25: '0.17%',
    avg_round_25: 3465,
    app_flat_25: 7,
    char_26: 'tingyun-fugue',
    app_rate_26: '0.16%',
    avg_round_26: 3468,
    app_flat_26: 8,
    char_27: 'gepard',
    app_rate_27: '0.15%',
    avg_round_27: 3442,
    app_flat_27: 12,
    char_28: 'tingyun',
    app_rate_28: '0.14%',
    avg_round_28: 3619,
    app_flat_28: 8,
    char_29: 'lynx',
    app_rate_29: '0.1%',
    avg_round_29: 3523,
    app_flat_29: 6,
    char_30: 'asta',
    app_rate_30: '0.07%',
    avg_round_30: 3459,
    app_flat_30: 5
  },
  {
    char: 'robin',
    app: 62.38,
    char_1: 'feixiao',
    app_rate_1: '53.19%',
    avg_round_1: 3451,
    app_flat_1: 2681,
    char_2: 'aventurine',
    app_rate_2: '40.3%',
    avg_round_2: 3420,
    app_flat_2: 1922,
    char_3: 'sunday',
    app_rate_3: '28.66%',
    avg_round_3: 3496,
    app_flat_3: 1108,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '28.04%',
    avg_round_4: 3488,
    app_flat_4: 1582,
    char_5: 'topaz',
    app_rate_5: '17.63%',
    avg_round_5: 3393,
    app_flat_5: 753,
    char_6: 'aglaea',
    app_rate_6: '16.83%',
    avg_round_6: 3509,
    app_flat_6: 693,
    char_7: 'huohuo',
    app_rate_7: '16.64%',
    avg_round_7: 3423,
    app_flat_7: 738,
    char_8: 'jing-yuan',
    app_rate_8: '9.53%',
    avg_round_8: 3415,
    app_flat_8: 344,
    char_9: 'the-herta',
    app_rate_9: '9.25%',
    avg_round_9: 3452,
    app_flat_9: 400,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '8.75%',
    avg_round_10: 3506,
    app_flat_10: 342,
    char_11: 'fu-xuan',
    app_rate_11: '6.84%',
    avg_round_11: 3370,
    app_flat_11: 284,
    char_12: 'bronya',
    app_rate_12: '6.29%',
    avg_round_12: 3703,
    app_flat_12: 208,
    char_13: 'lingsha',
    app_rate_13: '6.0%',
    avg_round_13: 3435,
    app_flat_13: 299,
    char_14: 'gallagher',
    app_rate_14: '5.62%',
    avg_round_14: 3412,
    app_flat_14: 284,
    char_15: 'herta',
    app_rate_15: '5.55%',
    avg_round_15: 3443,
    app_flat_15: 225,
    char_16: 'jade',
    app_rate_16: '5.04%',
    avg_round_16: 3481,
    app_flat_16: 167,
    char_17: 'yunli',
    app_rate_17: '4.35%',
    avg_round_17: 3296,
    app_flat_17: 170,
    char_18: 'moze',
    app_rate_18: '3.76%',
    avg_round_18: 3352,
    app_flat_18: 205,
    char_19: 'luocha',
    app_rate_19: '3.64%',
    avg_round_19: 3368,
    app_flat_19: 155,
    char_20: 'tribbie',
    app_rate_20: '3.57%',
    avg_round_20: 3504,
    app_flat_20: 92,
    char_21: 'ruan-mei',
    app_rate_21: '2.94%',
    avg_round_21: 3585,
    app_flat_21: 103,
    char_22: 'sparkle',
    app_rate_22: '1.97%',
    avg_round_22: 3447,
    app_flat_22: 38,
    char_23: 'acheron',
    app_rate_23: '1.66%',
    avg_round_23: 3328,
    app_flat_23: 14,
    char_24: 'jingliu',
    app_rate_24: '1.48%',
    avg_round_24: 3410,
    app_flat_24: 43,
    char_25: 'black-swan',
    app_rate_25: '1.22%',
    avg_round_25: 3276,
    app_flat_25: 18,
    char_26: 'kafka',
    app_rate_26: '1.2%',
    avg_round_26: 3264,
    app_flat_26: 19,
    char_27: 'tingyun',
    app_rate_27: '1.11%',
    avg_round_27: 3340,
    app_flat_27: 42,
    char_28: 'serval',
    app_rate_28: '1.02%',
    avg_round_28: 3425,
    app_flat_28: 35,
    char_29: 'jiaoqiu',
    app_rate_29: '0.99%',
    avg_round_29: 3314,
    app_flat_29: 10,
    char_30: 'seele',
    app_rate_30: '0.88%',
    avg_round_30: 3564,
    app_flat_30: 13
  },
  {
    char: 'aventurine',
    app: 49.34,
    char_1: 'robin',
    app_rate_1: '50.95%',
    avg_round_1: 3420,
    app_flat_1: 1922,
    char_2: 'feixiao',
    app_rate_2: '41.95%',
    avg_round_2: 3420,
    app_flat_2: 1713,
    char_3: 'the-herta',
    app_rate_3: '23.44%',
    avg_round_3: 3497,
    app_flat_3: 1064,
    char_4: 'acheron',
    app_rate_4: '20.6%',
    avg_round_4: 3328,
    app_flat_4: 475,
    char_5: 'topaz',
    app_rate_5: '18.64%',
    avg_round_5: 3392,
    app_flat_5: 636,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '16.9%',
    avg_round_6: 3454,
    app_flat_6: 828,
    char_7: 'sunday',
    app_rate_7: '15.55%',
    avg_round_7: 3388,
    app_flat_7: 451,
    char_8: 'tribbie',
    app_rate_8: '12.96%',
    avg_round_8: 3488,
    app_flat_8: 479,
    char_9: 'jiaoqiu',
    app_rate_9: '12.85%',
    avg_round_9: 3368,
    app_flat_9: 319,
    char_10: 'herta',
    app_rate_10: '12.38%',
    avg_round_10: 3469,
    app_flat_10: 591,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '10.52%',
    avg_round_11: 3469,
    app_flat_11: 522,
    char_12: 'pela',
    app_rate_12: '10.32%',
    avg_round_12: 3344,
    app_flat_12: 357,
    char_13: 'jade',
    app_rate_13: '7.92%',
    avg_round_13: 3553,
    app_flat_13: 284,
    char_14: 'sparkle',
    app_rate_14: '7.41%',
    avg_round_14: 3316,
    app_flat_14: 73,
    char_15: 'jing-yuan',
    app_rate_15: '7.39%',
    avg_round_15: 3365,
    app_flat_15: 210,
    char_16: 'ruan-mei',
    app_rate_16: '3.94%',
    avg_round_16: 3345,
    app_flat_16: 119,
    char_17: 'moze',
    app_rate_17: '3.9%',
    avg_round_17: 3349,
    app_flat_17: 175,
    char_18: 'serval',
    app_rate_18: '3.54%',
    avg_round_18: 3515,
    app_flat_18: 177,
    char_19: 'black-swan',
    app_rate_19: '2.37%',
    avg_round_19: 3209,
    app_flat_19: 55,
    char_20: 'aglaea',
    app_rate_20: '2.17%',
    avg_round_20: 3392,
    app_flat_20: 72,
    char_21: 'kafka',
    app_rate_21: '1.85%',
    avg_round_21: 3213,
    app_flat_21: 56,
    char_22: 'bronya',
    app_rate_22: '1.81%',
    avg_round_22: 3356,
    app_flat_22: 41,
    char_23: 'silver-wolf',
    app_rate_23: '1.48%',
    avg_round_23: 3241,
    app_flat_23: 43,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.38%',
    avg_round_24: 3321,
    app_flat_24: 45,
    char_25: 'yunli',
    app_rate_25: '1.36%',
    avg_round_25: 3279,
    app_flat_25: 42,
    char_26: 'argenti',
    app_rate_26: '0.92%',
    avg_round_26: 3512,
    app_flat_26: 28,
    char_27: 'rappa',
    app_rate_27: '0.75%',
    avg_round_27: 3310,
    app_flat_27: 33,
    char_28: 'boothill',
    app_rate_28: '0.72%',
    avg_round_28: 3357,
    app_flat_28: 29,
    char_29: 'dr-ratio',
    app_rate_29: '0.53%',
    avg_round_29: 3122,
    app_flat_29: 15,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.5%',
    avg_round_30: 3279,
    app_flat_30: 1
  },
  {
    char: 'tribbie',
    app: 44.04,
    char_1: 'the-herta',
    app_rate_1: '71.44%',
    avg_round_1: 3580,
    app_flat_1: 2530,
    char_2: 'herta',
    app_rate_2: '26.78%',
    avg_round_2: 3530,
    app_flat_2: 1021,
    char_3: 'jade',
    app_rate_3: '24.03%',
    avg_round_3: 3642,
    app_flat_3: 714,
    char_4: 'lingsha',
    app_rate_4: '21.44%',
    avg_round_4: 3630,
    app_flat_4: 678,
    char_5: 'serval',
    app_rate_5: '17.97%',
    avg_round_5: 3594,
    app_flat_5: 661,
    char_6: 'huohuo',
    app_rate_6: '15.6%',
    avg_round_6: 3460,
    app_flat_6: 511,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '15.44%',
    avg_round_7: 3699,
    app_flat_7: 501,
    char_8: 'aventurine',
    app_rate_8: '14.52%',
    avg_round_8: 3488,
    app_flat_8: 479,
    char_9: 'sunday',
    app_rate_9: '12.51%',
    avg_round_9: 3430,
    app_flat_9: 271,
    char_10: 'gallagher',
    app_rate_10: '9.28%',
    avg_round_10: 3508,
    app_flat_10: 366,
    char_11: 'fu-xuan',
    app_rate_11: '8.5%',
    avg_round_11: 3456,
    app_flat_11: 215,
    char_12: 'acheron',
    app_rate_12: '7.67%',
    avg_round_12: 3457,
    app_flat_12: 103,
    char_13: 'luocha',
    app_rate_13: '5.54%',
    avg_round_13: 3485,
    app_flat_13: 199,
    char_14: 'robin',
    app_rate_14: '5.05%',
    avg_round_14: 3504,
    app_flat_14: 92,
    char_15: 'jiaoqiu',
    app_rate_15: '4.62%',
    avg_round_15: 3543,
    app_flat_15: 60,
    char_16: 'aglaea',
    app_rate_16: '4.27%',
    avg_round_16: 3424,
    app_flat_16: 101,
    char_17: 'pela',
    app_rate_17: '3.98%',
    avg_round_17: 3502,
    app_flat_17: 83,
    char_18: 'argenti',
    app_rate_18: '3.96%',
    avg_round_18: 3677,
    app_flat_18: 130,
    char_19: 'feixiao',
    app_rate_19: '3.93%',
    avg_round_19: 3414,
    app_flat_19: 124,
    char_20: 'jing-yuan',
    app_rate_20: '3.85%',
    avg_round_20: 3374,
    app_flat_20: 102,
    char_21: 'sparkle',
    app_rate_21: '2.23%',
    avg_round_21: 3533,
    app_flat_21: 17,
    char_22: 'ruan-mei',
    app_rate_22: '1.97%',
    avg_round_22: 3561,
    app_flat_22: 31,
    char_23: 'yunli',
    app_rate_23: '1.67%',
    avg_round_23: 3366,
    app_flat_23: 27,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.5%',
    avg_round_24: 3523,
    app_flat_24: 24,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '1.39%',
    avg_round_25: 3459,
    app_flat_25: 53,
    char_26: 'mydei',
    app_rate_26: '1.36%',
    avg_round_26: 3451,
    app_flat_26: 29,
    char_27: 'himeko',
    app_rate_27: '0.95%',
    avg_round_27: 3593,
    app_flat_27: 29,
    char_28: 'rappa',
    app_rate_28: '0.94%',
    avg_round_28: 3522,
    app_flat_28: 27,
    char_29: 'blade',
    app_rate_29: '0.94%',
    avg_round_29: 3386,
    app_flat_29: 19,
    char_30: 'bronya',
    app_rate_30: '0.87%',
    avg_round_30: 3456,
    app_flat_30: 16
  },
  {
    char: 'sunday',
    app: 41.9,
    char_1: 'robin',
    app_rate_1: '42.67%',
    avg_round_1: 3496,
    app_flat_1: 1108,
    char_2: 'aglaea',
    app_rate_2: '33.03%',
    avg_round_2: 3491,
    app_flat_2: 948,
    char_3: 'huohuo',
    app_rate_3: '26.23%',
    avg_round_3: 3440,
    app_flat_3: 799,
    char_4: 'jing-yuan',
    app_rate_4: '24.39%',
    avg_round_4: 3377,
    app_flat_4: 600,
    char_5: 'aventurine',
    app_rate_5: '18.31%',
    avg_round_5: 3388,
    app_flat_5: 451,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '16.34%',
    avg_round_6: 3441,
    app_flat_6: 466,
    char_7: 'the-herta',
    app_rate_7: '15.49%',
    avg_round_7: 3482,
    app_flat_7: 559,
    char_8: 'tribbie',
    app_rate_8: '13.15%',
    avg_round_8: 3430,
    app_flat_8: 271,
    char_9: 'gallagher',
    app_rate_9: '9.44%',
    avg_round_9: 3398,
    app_flat_9: 311,
    char_10: 'ruan-mei',
    app_rate_10: '9.28%',
    avg_round_10: 3525,
    app_flat_10: 213,
    char_11: 'luocha',
    app_rate_11: '8.05%',
    avg_round_11: 3370,
    app_flat_11: 199,
    char_12: 'lingsha',
    app_rate_12: '7.25%',
    avg_round_12: 3457,
    app_flat_12: 216,
    char_13: 'fu-xuan',
    app_rate_13: '6.97%',
    avg_round_13: 3386,
    app_flat_13: 164,
    char_14: 'herta',
    app_rate_14: '6.07%',
    avg_round_14: 3427,
    app_flat_14: 226,
    char_15: 'acheron',
    app_rate_15: '6.07%',
    avg_round_15: 3327,
    app_flat_15: 54,
    char_16: 'bronya',
    app_rate_16: '5.34%',
    avg_round_16: 3624,
    app_flat_16: 124,
    char_17: 'jade',
    app_rate_17: '5.23%',
    avg_round_17: 3554,
    app_flat_17: 163,
    char_18: 'boothill',
    app_rate_18: '4.7%',
    avg_round_18: 3529,
    app_flat_18: 148,
    char_19: 'feixiao',
    app_rate_19: '4.66%',
    avg_round_19: 3622,
    app_flat_19: 147,
    char_20: 'jiaoqiu',
    app_rate_20: '4.18%',
    avg_round_20: 3326,
    app_flat_20: 43,
    char_21: 'serval',
    app_rate_21: '3.8%',
    avg_round_21: 3460,
    app_flat_21: 147,
    char_22: 'sparkle',
    app_rate_22: '3.48%',
    avg_round_22: 3385,
    app_flat_22: 78,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '3.07%',
    avg_round_23: 3672,
    app_flat_23: 108,
    char_24: 'tingyun-fugue',
    app_rate_24: '3.02%',
    avg_round_24: 3627,
    app_flat_24: 77,
    char_25: 'pela',
    app_rate_25: '2.64%',
    avg_round_25: 3388,
    app_flat_25: 31,
    char_26: 'jingliu',
    app_rate_26: '2.38%',
    avg_round_26: 3380,
    app_flat_26: 57,
    char_27: 'mydei',
    app_rate_27: '2.31%',
    avg_round_27: 3406,
    app_flat_27: 39,
    char_28: 'tingyun',
    app_rate_28: '2.15%',
    avg_round_28: 3398,
    app_flat_28: 59,
    char_29: 'yunli',
    app_rate_29: '2.0%',
    avg_round_29: 3260,
    app_flat_29: 45,
    char_30: 'blade',
    app_rate_30: '1.57%',
    avg_round_30: 3355,
    app_flat_30: 36
  },
  {
    char: 'lingsha',
    app: 40.18,
    char_1: 'ruan-mei',
    app_rate_1: '46.31%',
    avg_round_1: 3419,
    app_flat_1: 868,
    char_2: 'tingyun-fugue',
    app_rate_2: '41.52%',
    avg_round_2: 3437,
    app_flat_2: 765,
    char_3: 'the-herta',
    app_rate_3: '37.59%',
    avg_round_3: 3581,
    app_flat_3: 1365,
    char_4: 'firefly',
    app_rate_4: '24.58%',
    avg_round_4: 3252,
    app_flat_4: 236,
    char_5: 'tribbie',
    app_rate_5: '23.5%',
    avg_round_5: 3630,
    app_flat_5: 678,
    char_6: 'rappa',
    app_rate_6: '18.22%',
    avg_round_6: 3467,
    app_flat_6: 619,
    char_7: 'jade',
    app_rate_7: '16.94%',
    avg_round_7: 3644,
    app_flat_7: 531,
    char_8: 'trailblazer-harmony',
    app_rate_8: '13.33%',
    avg_round_8: 3404,
    app_flat_8: 230,
    char_9: 'herta',
    app_rate_9: '12.89%',
    avg_round_9: 3535,
    app_flat_9: 528,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '11.73%',
    avg_round_10: 3568,
    app_flat_10: 517,
    char_11: 'robin',
    app_rate_11: '9.32%',
    avg_round_11: 3435,
    app_flat_11: 299,
    char_12: 'sunday',
    app_rate_12: '7.56%',
    avg_round_12: 3457,
    app_flat_12: 216,
    char_13: 'serval',
    app_rate_13: '6.77%',
    avg_round_13: 3597,
    app_flat_13: 250,
    char_14: 'feixiao',
    app_rate_14: '5.11%',
    avg_round_14: 3408,
    app_flat_14: 201,
    char_15: 'lingsha',
    app_rate_15: '4.31%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'acheron',
    app_rate_16: '4.27%',
    avg_round_16: 3364,
    app_flat_16: 81,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '2.82%',
    avg_round_17: 3424,
    app_flat_17: 125,
    char_18: 'jiaoqiu',
    app_rate_18: '2.29%',
    avg_round_18: 3395,
    app_flat_18: 54,
    char_19: 'pela',
    app_rate_19: '2.15%',
    avg_round_19: 3389,
    app_flat_19: 59,
    char_20: 'aglaea',
    app_rate_20: '1.71%',
    avg_round_20: 3407,
    app_flat_20: 49,
    char_21: 'sparkle',
    app_rate_21: '1.47%',
    avg_round_21: 3367,
    app_flat_21: 17,
    char_22: 'topaz',
    app_rate_22: '1.45%',
    avg_round_22: 3381,
    app_flat_22: 52,
    char_23: 'boothill',
    app_rate_23: '1.44%',
    avg_round_23: 3446,
    app_flat_23: 49,
    char_24: 'argenti',
    app_rate_24: '1.08%',
    avg_round_24: 3643,
    app_flat_24: 31,
    char_25: 'jing-yuan',
    app_rate_25: '1.03%',
    avg_round_25: 3347,
    app_flat_25: 28,
    char_26: 'himeko',
    app_rate_26: '0.94%',
    avg_round_26: 3371,
    app_flat_26: 35,
    char_27: 'bronya',
    app_rate_27: '0.67%',
    avg_round_27: 3422,
    app_flat_27: 15,
    char_28: 'black-swan',
    app_rate_28: '0.63%',
    avg_round_28: 3271,
    app_flat_28: 8,
    char_29: 'kafka',
    app_rate_29: '0.58%',
    avg_round_29: 3204,
    app_flat_29: 6,
    char_30: 'yunli',
    app_rate_30: '0.39%',
    avg_round_30: 3276,
    app_flat_30: 12
  },
  {
    char: 'trailblazer-remembrance',
    app: 39.73,
    char_1: 'the-herta',
    app_rate_1: '60.75%',
    avg_round_1: 3546,
    app_flat_1: 2546,
    char_2: 'herta',
    app_rate_2: '27.32%',
    avg_round_2: 3509,
    app_flat_2: 1230,
    char_3: 'sunday',
    app_rate_3: '17.24%',
    avg_round_3: 3441,
    app_flat_3: 466,
    char_4: 'tribbie',
    app_rate_4: '17.12%',
    avg_round_4: 3699,
    app_flat_4: 501,
    char_5: 'huohuo',
    app_rate_5: '16.72%',
    avg_round_5: 3435,
    app_flat_5: 629,
    char_6: 'serval',
    app_rate_6: '16.2%',
    avg_round_6: 3558,
    app_flat_6: 649,
    char_7: 'aglaea',
    app_rate_7: '14.56%',
    avg_round_7: 3433,
    app_flat_7: 390,
    char_8: 'jade',
    app_rate_8: '14.26%',
    avg_round_8: 3621,
    app_flat_8: 538,
    char_9: 'robin',
    app_rate_9: '13.74%',
    avg_round_9: 3506,
    app_flat_9: 342,
    char_10: 'aventurine',
    app_rate_10: '13.07%',
    avg_round_10: 3469,
    app_flat_10: 522,
    char_11: 'gallagher',
    app_rate_11: '12.09%',
    avg_round_11: 3460,
    app_flat_11: 515,
    char_12: 'lingsha',
    app_rate_12: '11.86%',
    avg_round_12: 3568,
    app_flat_12: 517,
    char_13: 'luocha',
    app_rate_13: '7.68%',
    avg_round_13: 3435,
    app_flat_13: 262,
    char_14: 'fu-xuan',
    app_rate_14: '7.31%',
    avg_round_14: 3434,
    app_flat_14: 215,
    char_15: 'feixiao',
    app_rate_15: '5.13%',
    avg_round_15: 3536,
    app_flat_15: 165,
    char_16: 'ruan-mei',
    app_rate_16: '4.74%',
    avg_round_16: 3489,
    app_flat_16: 109,
    char_17: 'acheron',
    app_rate_17: '4.15%',
    avg_round_17: 3342,
    app_flat_17: 76,
    char_18: 'jing-yuan',
    app_rate_18: '3.09%',
    avg_round_18: 3343,
    app_flat_18: 82,
    char_19: 'argenti',
    app_rate_19: '2.97%',
    avg_round_19: 3595,
    app_flat_19: 102,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '2.84%',
    avg_round_20: 3548,
    app_flat_20: 93,
    char_21: 'bronya',
    app_rate_21: '2.75%',
    avg_round_21: 3431,
    app_flat_21: 56,
    char_22: 'pela',
    app_rate_22: '2.66%',
    avg_round_22: 3411,
    app_flat_22: 70,
    char_23: 'sparkle',
    app_rate_23: '2.66%',
    avg_round_23: 3454,
    app_flat_23: 38,
    char_24: 'yunli',
    app_rate_24: '2.3%',
    avg_round_24: 3327,
    app_flat_24: 63,
    char_25: 'mydei',
    app_rate_25: '2.02%',
    avg_round_25: 3385,
    app_flat_25: 36,
    char_26: 'jiaoqiu',
    app_rate_26: '1.88%',
    avg_round_26: 3420,
    app_flat_26: 33,
    char_27: 'jingliu',
    app_rate_27: '1.68%',
    avg_round_27: 3296,
    app_flat_27: 31,
    char_28: 'blade',
    app_rate_28: '1.26%',
    avg_round_28: 3371,
    app_flat_28: 30,
    char_29: 'tingyun',
    app_rate_29: '1.21%',
    avg_round_29: 3412,
    app_flat_29: 33,
    char_30: 'tingyun-fugue',
    app_rate_30: '1.12%',
    avg_round_30: 3478,
    app_flat_30: 28
  },
  {
    char: 'ruan-mei',
    app: 39.52,
    char_1: 'tingyun-fugue',
    app_rate_1: '56.56%',
    avg_round_1: 3411,
    app_flat_1: 1088,
    char_2: 'lingsha',
    app_rate_2: '47.09%',
    avg_round_2: 3419,
    app_flat_2: 868,
    char_3: 'firefly',
    app_rate_3: '36.59%',
    avg_round_3: 3248,
    app_flat_3: 333,
    char_4: 'rappa',
    app_rate_4: '23.52%',
    avg_round_4: 3389,
    app_flat_4: 799,
    char_5: 'trailblazer-harmony',
    app_rate_5: '19.97%',
    avg_round_5: 3322,
    app_flat_5: 384,
    char_6: 'gallagher',
    app_rate_6: '18.2%',
    avg_round_6: 3275,
    app_flat_6: 457,
    char_7: 'boothill',
    app_rate_7: '13.47%',
    avg_round_7: 3499,
    app_flat_7: 426,
    char_8: 'sunday',
    app_rate_8: '9.84%',
    avg_round_8: 3525,
    app_flat_8: 213,
    char_9: 'the-herta',
    app_rate_9: '6.74%',
    avg_round_9: 3463,
    app_flat_9: 188,
    char_10: 'bronya',
    app_rate_10: '6.54%',
    avg_round_10: 3523,
    app_flat_10: 164,
    char_11: 'aventurine',
    app_rate_11: '4.92%',
    avg_round_11: 3345,
    app_flat_11: 119,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '4.76%',
    avg_round_12: 3489,
    app_flat_12: 109,
    char_13: 'robin',
    app_rate_13: '4.64%',
    avg_round_13: 3585,
    app_flat_13: 103,
    char_14: 'huohuo',
    app_rate_14: '4.21%',
    avg_round_14: 3290,
    app_flat_14: 62,
    char_15: 'kafka',
    app_rate_15: '3.89%',
    avg_round_15: 3311,
    app_flat_15: 33,
    char_16: 'black-swan',
    app_rate_16: '3.77%',
    avg_round_16: 3307,
    app_flat_16: 33,
    char_17: 'feixiao',
    app_rate_17: '3.42%',
    avg_round_17: 3464,
    app_flat_17: 97,
    char_18: 'herta',
    app_rate_18: '2.85%',
    avg_round_18: 3436,
    app_flat_18: 67,
    char_19: 'jade',
    app_rate_19: '2.64%',
    avg_round_19: 3515,
    app_flat_19: 75,
    char_20: 'acheron',
    app_rate_20: '2.5%',
    avg_round_20: 3433,
    app_flat_20: 18,
    char_21: 'luocha',
    app_rate_21: '2.35%',
    avg_round_21: 3295,
    app_flat_21: 49,
    char_22: 'jingliu',
    app_rate_22: '2.35%',
    avg_round_22: 3366,
    app_flat_22: 44,
    char_23: 'jing-yuan',
    app_rate_23: '2.33%',
    avg_round_23: 3471,
    app_flat_23: 39,
    char_24: 'tribbie',
    app_rate_24: '2.19%',
    avg_round_24: 3561,
    app_flat_24: 31,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '2.16%',
    avg_round_25: 3488,
    app_flat_25: 67,
    char_26: 'fu-xuan',
    app_rate_26: '1.93%',
    avg_round_26: 3335,
    app_flat_26: 29,
    char_27: 'pela',
    app_rate_27: '1.56%',
    avg_round_27: 3536,
    app_flat_27: 31,
    char_28: 'aglaea',
    app_rate_28: '1.41%',
    avg_round_28: 3494,
    app_flat_28: 24,
    char_29: 'blade',
    app_rate_29: '1.41%',
    avg_round_29: 3357,
    app_flat_29: 25,
    char_30: 'serval',
    app_rate_30: '1.01%',
    avg_round_30: 3445,
    app_flat_30: 23
  },
  {
    char: 'feixiao',
    app: 36.83,
    char_1: 'robin',
    app_rate_1: '90.1%',
    avg_round_1: 3451,
    app_flat_1: 2681,
    char_2: 'aventurine',
    app_rate_2: '56.2%',
    avg_round_2: 3420,
    app_flat_2: 1713,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '51.05%',
    avg_round_3: 3478,
    app_flat_3: 1739,
    char_4: 'topaz',
    app_rate_4: '31.34%',
    avg_round_4: 3390,
    app_flat_4: 813,
    char_5: 'fu-xuan',
    app_rate_5: '7.57%',
    avg_round_5: 3364,
    app_flat_5: 226,
    char_6: 'moze',
    app_rate_6: '7.2%',
    avg_round_6: 3345,
    app_flat_6: 235,
    char_7: 'bronya',
    app_rate_7: '7.07%',
    avg_round_7: 3662,
    app_flat_7: 174,
    char_8: 'lingsha',
    app_rate_8: '5.58%',
    avg_round_8: 3408,
    app_flat_8: 201,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '5.54%',
    avg_round_9: 3536,
    app_flat_9: 165,
    char_10: 'sunday',
    app_rate_10: '5.3%',
    avg_round_10: 3622,
    app_flat_10: 147,
    char_11: 'huohuo',
    app_rate_11: '4.94%',
    avg_round_11: 3371,
    app_flat_11: 165,
    char_12: 'gallagher',
    app_rate_12: '4.89%',
    avg_round_12: 3391,
    app_flat_12: 184,
    char_13: 'tribbie',
    app_rate_13: '4.7%',
    avg_round_13: 3414,
    app_flat_13: 124,
    char_14: 'ruan-mei',
    app_rate_14: '3.67%',
    avg_round_14: 3464,
    app_flat_14: 97,
    char_15: 'herta',
    app_rate_15: '2.78%',
    avg_round_15: 3503,
    app_flat_15: 54,
    char_16: 'luocha',
    app_rate_16: '2.61%',
    avg_round_16: 3362,
    app_flat_16: 89,
    char_17: 'yunli',
    app_rate_17: '2.29%',
    avg_round_17: 3355,
    app_flat_17: 70,
    char_18: 'sparkle',
    app_rate_18: '1.55%',
    avg_round_18: 3395,
    app_flat_18: 40,
    char_19: 'jade',
    app_rate_19: '1.47%',
    avg_round_19: 3360,
    app_flat_19: 4,
    char_20: 'aglaea',
    app_rate_20: '0.54%',
    avg_round_20: 3504,
    app_flat_20: 7,
    char_21: 'bailu',
    app_rate_21: '0.47%',
    avg_round_21: 3383,
    app_flat_21: 18,
    char_22: 'clara',
    app_rate_22: '0.45%',
    avg_round_22: 3214,
    app_flat_22: 9,
    char_23: 'gepard',
    app_rate_23: '0.35%',
    avg_round_23: 3337,
    app_flat_23: 14,
    char_24: 'the-herta',
    app_rate_24: '0.32%',
    avg_round_24: 3396,
    app_flat_24: 10,
    char_25: 'seele',
    app_rate_25: '0.21%',
    avg_round_25: 3755,
    app_flat_25: 4,
    char_26: 'pela',
    app_rate_26: '0.17%',
    avg_round_26: 3490,
    app_flat_26: 6,
    char_27: 'acheron',
    app_rate_27: '0.17%',
    avg_round_27: 3477,
    app_flat_27: 2,
    char_28: 'jiaoqiu',
    app_rate_28: '0.17%',
    avg_round_28: 3194,
    app_flat_28: 2,
    char_29: 'argenti',
    app_rate_29: '0.13%',
    avg_round_29: 3338,
    app_flat_29: 2,
    char_30: 'jing-yuan',
    app_rate_30: '0.13%',
    avg_round_30: 3516,
    app_flat_30: 2
  },
  {
    char: 'herta',
    app: 28.01,
    char_1: 'the-herta',
    app_rate_1: '94.97%',
    avg_round_1: 3488,
    app_flat_1: 2573,
    char_2: 'tribbie',
    app_rate_2: '42.1%',
    avg_round_2: 3530,
    app_flat_2: 1021,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '38.74%',
    avg_round_3: 3509,
    app_flat_3: 1230,
    char_4: 'aventurine',
    app_rate_4: '21.8%',
    avg_round_4: 3469,
    app_flat_4: 591,
    char_5: 'lingsha',
    app_rate_5: '18.49%',
    avg_round_5: 3535,
    app_flat_5: 528,
    char_6: 'huohuo',
    app_rate_6: '15.13%',
    avg_round_6: 3440,
    app_flat_6: 405,
    char_7: 'gallagher',
    app_rate_7: '12.87%',
    avg_round_7: 3461,
    app_flat_7: 408,
    char_8: 'robin',
    app_rate_8: '12.36%',
    avg_round_8: 3443,
    app_flat_8: 225,
    char_9: 'fu-xuan',
    app_rate_9: '10.96%',
    avg_round_9: 3423,
    app_flat_9: 234,
    char_10: 'sunday',
    app_rate_10: '9.07%',
    avg_round_10: 3427,
    app_flat_10: 226,
    char_11: 'luocha',
    app_rate_11: '7.11%',
    avg_round_11: 3442,
    app_flat_11: 209,
    char_12: 'ruan-mei',
    app_rate_12: '4.02%',
    avg_round_12: 3436,
    app_flat_12: 67,
    char_13: 'feixiao',
    app_rate_13: '3.65%',
    avg_round_13: 3503,
    app_flat_13: 54,
    char_14: 'sparkle',
    app_rate_14: '2.38%',
    avg_round_14: 3387,
    app_flat_14: 33,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.01%',
    avg_round_15: 3627,
    app_flat_15: 17,
    char_16: 'jade',
    app_rate_16: '0.88%',
    avg_round_16: 3433,
    app_flat_16: 20,
    char_17: 'bronya',
    app_rate_17: '0.66%',
    avg_round_17: 3512,
    app_flat_17: 8,
    char_18: 'pela',
    app_rate_18: '0.61%',
    avg_round_18: 3454,
    app_flat_18: 12,
    char_19: 'serval',
    app_rate_19: '0.49%',
    avg_round_19: 3551,
    app_flat_19: 13,
    char_20: 'bailu',
    app_rate_20: '0.49%',
    avg_round_20: 3363,
    app_flat_20: 13,
    char_21: 'yunli',
    app_rate_21: '0.27%',
    avg_round_21: 3381,
    app_flat_21: 4,
    char_22: 'topaz',
    app_rate_22: '0.2%',
    avg_round_22: 3591,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.2%',
    avg_round_23: 3169,
    app_flat_23: 2,
    char_24: 'himeko',
    app_rate_24: '0.17%',
    avg_round_24: 3303,
    app_flat_24: 4,
    char_25: 'gepard',
    app_rate_25: '0.17%',
    avg_round_25: 3400,
    app_flat_25: 6,
    char_26: 'jiaoqiu',
    app_rate_26: '0.15%',
    avg_round_26: 3617,
    app_flat_26: 2,
    char_27: 'moze',
    app_rate_27: '0.1%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'mydei',
    app_rate_28: '0.1%',
    avg_round_28: 3402,
    app_flat_28: 2,
    char_29: 'lynx',
    app_rate_29: '0.07%',
    avg_round_29: 3330,
    app_flat_29: 2,
    char_30: 'jingliu',
    app_rate_30: '0.07%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'huohuo',
    app: 26.6,
    char_1: 'sunday',
    app_rate_1: '41.32%',
    avg_round_1: 3440,
    app_flat_1: 799,
    char_2: 'robin',
    app_rate_2: '39.02%',
    avg_round_2: 3423,
    app_flat_2: 738,
    char_3: 'the-herta',
    app_rate_3: '37.4%',
    avg_round_3: 3473,
    app_flat_3: 894,
    char_4: 'aglaea',
    app_rate_4: '29.52%',
    avg_round_4: 3465,
    app_flat_4: 614,
    char_5: 'tribbie',
    app_rate_5: '25.83%',
    avg_round_5: 3460,
    app_flat_5: 511,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '24.97%',
    avg_round_6: 3435,
    app_flat_6: 629,
    char_7: 'herta',
    app_rate_7: '15.93%',
    avg_round_7: 3440,
    app_flat_7: 405,
    char_8: 'jade',
    app_rate_8: '11.36%',
    avg_round_8: 3517,
    app_flat_8: 243,
    char_9: 'serval',
    app_rate_9: '8.47%',
    avg_round_9: 3471,
    app_flat_9: 203,
    char_10: 'jing-yuan',
    app_rate_10: '8.24%',
    avg_round_10: 3360,
    app_flat_10: 137,
    char_11: 'feixiao',
    app_rate_11: '6.84%',
    avg_round_11: 3371,
    app_flat_11: 165,
    char_12: 'yunli',
    app_rate_12: '6.28%',
    avg_round_12: 3218,
    app_flat_12: 88,
    char_13: 'ruan-mei',
    app_rate_13: '6.25%',
    avg_round_13: 3290,
    app_flat_13: 62,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '4.6%',
    avg_round_14: 3396,
    app_flat_14: 112,
    char_15: 'sparkle',
    app_rate_15: '4.26%',
    avg_round_15: 3332,
    app_flat_15: 39,
    char_16: 'kafka',
    app_rate_16: '4.26%',
    avg_round_16: 3185,
    app_flat_16: 22,
    char_17: 'black-swan',
    app_rate_17: '4.24%',
    avg_round_17: 3191,
    app_flat_17: 22,
    char_18: 'tingyun',
    app_rate_18: '2.58%',
    avg_round_18: 3234,
    app_flat_18: 47,
    char_19: 'acheron',
    app_rate_19: '2.45%',
    avg_round_19: 3252,
    app_flat_19: 12,
    char_20: 'jingliu',
    app_rate_20: '2.07%',
    avg_round_20: 3290,
    app_flat_20: 22,
    char_21: 'bronya',
    app_rate_21: '1.99%',
    avg_round_21: 3392,
    app_flat_21: 38,
    char_22: 'argenti',
    app_rate_22: '1.81%',
    avg_round_22: 3467,
    app_flat_22: 42,
    char_23: 'topaz',
    app_rate_23: '1.29%',
    avg_round_23: 3308,
    app_flat_23: 30,
    char_24: 'boothill',
    app_rate_24: '1.14%',
    avg_round_24: 3362,
    app_flat_24: 24,
    char_25: 'tingyun-fugue',
    app_rate_25: '1.14%',
    avg_round_25: 3282,
    app_flat_25: 15,
    char_26: 'blade',
    app_rate_26: '0.9%',
    avg_round_26: 3261,
    app_flat_26: 8,
    char_27: 'jiaoqiu',
    app_rate_27: '0.88%',
    avg_round_27: 3372,
    app_flat_27: 2,
    char_28: 'pela',
    app_rate_28: '0.7%',
    avg_round_28: 3418,
    app_flat_28: 7,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.65%',
    avg_round_29: 3256,
    app_flat_29: 11,
    char_30: 'imbibitor-lunae',
    app_rate_30: '0.62%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'tingyun-fugue',
    app: 26.54,
    char_1: 'ruan-mei',
    app_rate_1: '84.24%',
    avg_round_1: 3411,
    app_flat_1: 1088,
    char_2: 'lingsha',
    app_rate_2: '62.88%',
    avg_round_2: 3437,
    app_flat_2: 765,
    char_3: 'firefly',
    app_rate_3: '46.98%',
    avg_round_3: 3276,
    app_flat_3: 298,
    char_4: 'rappa',
    app_rate_4: '31.97%',
    avg_round_4: 3423,
    app_flat_4: 709,
    char_5: 'gallagher',
    app_rate_5: '19.05%',
    avg_round_5: 3294,
    app_flat_5: 312,
    char_6: 'trailblazer-harmony',
    app_rate_6: '14.42%',
    avg_round_6: 3426,
    app_flat_6: 157,
    char_7: 'boothill',
    app_rate_7: '13.2%',
    avg_round_7: 3542,
    app_flat_7: 259,
    char_8: 'bronya',
    app_rate_8: '4.81%',
    avg_round_8: 3640,
    app_flat_8: 70,
    char_9: 'sunday',
    app_rate_9: '4.76%',
    avg_round_9: 3627,
    app_flat_9: 77,
    char_10: 'acheron',
    app_rate_10: '4.01%',
    avg_round_10: 3347,
    app_flat_10: 62,
    char_11: 'aventurine',
    app_rate_11: '2.56%',
    avg_round_11: 3321,
    app_flat_11: 45,
    char_12: 'tribbie',
    app_rate_12: '2.49%',
    avg_round_12: 3523,
    app_flat_12: 24,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '1.68%',
    avg_round_13: 3478,
    app_flat_13: 28,
    char_14: 'pela',
    app_rate_14: '1.68%',
    avg_round_14: 3407,
    app_flat_14: 39,
    char_15: 'jiaoqiu',
    app_rate_15: '1.19%',
    avg_round_15: 3378,
    app_flat_15: 23,
    char_16: 'huohuo',
    app_rate_16: '1.14%',
    avg_round_16: 3282,
    app_flat_16: 15,
    char_17: 'himeko',
    app_rate_17: '1.04%',
    avg_round_17: 3297,
    app_flat_17: 16,
    char_18: 'luocha',
    app_rate_18: '0.8%',
    avg_round_18: 3325,
    app_flat_18: 11,
    char_19: 'fu-xuan',
    app_rate_19: '0.54%',
    avg_round_19: 3295,
    app_flat_19: 7,
    char_20: 'the-herta',
    app_rate_20: '0.39%',
    avg_round_20: 3468,
    app_flat_20: 8,
    char_21: 'jade',
    app_rate_21: '0.34%',
    avg_round_21: 3497,
    app_flat_21: 7,
    char_22: 'sparkle',
    app_rate_22: '0.26%',
    avg_round_22: 3373,
    app_flat_22: 1,
    char_23: 'kafka',
    app_rate_23: '0.26%',
    avg_round_23: 3226,
    app_flat_23: 2,
    char_24: 'sushang',
    app_rate_24: '0.26%',
    avg_round_24: 3411,
    app_flat_24: 6,
    char_25: 'black-swan',
    app_rate_25: '0.21%',
    avg_round_25: 3290,
    app_flat_25: 1,
    char_26: 'herta',
    app_rate_26: '0.21%',
    avg_round_26: 3169,
    app_flat_26: 2,
    char_27: 'robin',
    app_rate_27: '0.18%',
    avg_round_27: 3490,
    app_flat_27: 2,
    char_28: 'feixiao',
    app_rate_28: '0.13%',
    avg_round_28: 3380,
    app_flat_28: 2,
    char_29: 'luka',
    app_rate_29: '0.13%',
    avg_round_29: 3470,
    app_flat_29: 3,
    char_30: 'gepard',
    app_rate_30: '0.13%',
    avg_round_30: 3463,
    app_flat_30: 1
  },
  {
    char: 'acheron',
    app: 24.98,
    char_1: 'jiaoqiu',
    app_rate_1: '55.84%',
    avg_round_1: 3383,
    app_flat_1: 687,
    char_2: 'pela',
    app_rate_2: '47.26%',
    avg_round_2: 3355,
    app_flat_2: 790,
    char_3: 'aventurine',
    app_rate_3: '40.69%',
    avg_round_3: 3328,
    app_flat_3: 475,
    char_4: 'sparkle',
    app_rate_4: '25.52%',
    avg_round_4: 3319,
    app_flat_4: 44,
    char_5: 'fu-xuan',
    app_rate_5: '19.33%',
    avg_round_5: 3275,
    app_flat_5: 159,
    char_6: 'gallagher',
    app_rate_6: '13.8%',
    avg_round_6: 3306,
    app_flat_6: 212,
    char_7: 'tribbie',
    app_rate_7: '13.53%',
    avg_round_7: 3457,
    app_flat_7: 103,
    char_8: 'black-swan',
    app_rate_8: '13.06%',
    avg_round_8: 3223,
    app_flat_8: 138,
    char_9: 'kafka',
    app_rate_9: '11.8%',
    avg_round_9: 3231,
    app_flat_9: 135,
    char_10: 'sunday',
    app_rate_10: '10.17%',
    avg_round_10: 3327,
    app_flat_10: 54,
    char_11: 'lingsha',
    app_rate_11: '6.87%',
    avg_round_11: 3364,
    app_flat_11: 81,
    char_12: 'silver-wolf',
    app_rate_12: '6.76%',
    avg_round_12: 3241,
    app_flat_12: 81,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '6.6%',
    avg_round_13: 3342,
    app_flat_13: 76,
    char_14: 'bronya',
    app_rate_14: '4.43%',
    avg_round_14: 3564,
    app_flat_14: 2,
    char_15: 'tingyun-fugue',
    app_rate_15: '4.26%',
    avg_round_15: 3347,
    app_flat_15: 62,
    char_16: 'robin',
    app_rate_16: '4.15%',
    avg_round_16: 3328,
    app_flat_16: 14,
    char_17: 'ruan-mei',
    app_rate_17: '3.96%',
    avg_round_17: 3433,
    app_flat_17: 18,
    char_18: 'luocha',
    app_rate_18: '2.8%',
    avg_round_18: 3291,
    app_flat_18: 22,
    char_19: 'huohuo',
    app_rate_19: '2.61%',
    avg_round_19: 3252,
    app_flat_19: 12,
    char_20: 'gepard',
    app_rate_20: '2.03%',
    avg_round_20: 3209,
    app_flat_20: 12,
    char_21: 'guinaifen',
    app_rate_21: '1.29%',
    avg_round_21: 3284,
    app_flat_21: 28,
    char_22: 'welt',
    app_rate_22: '0.52%',
    avg_round_22: 3240,
    app_flat_22: 12,
    char_23: 'himeko',
    app_rate_23: '0.38%',
    avg_round_23: 3272,
    app_flat_23: 3,
    char_24: 'bailu',
    app_rate_24: '0.27%',
    avg_round_24: 3194,
    app_flat_24: 3,
    char_25: 'feixiao',
    app_rate_25: '0.25%',
    avg_round_25: 3477,
    app_flat_25: 2,
    char_26: 'sampo',
    app_rate_26: '0.16%',
    avg_round_26: 3176,
    app_flat_26: 5,
    char_27: 'jade',
    app_rate_27: '0.16%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'boothill',
    app_rate_28: '0.14%',
    avg_round_28: 3751,
    app_flat_28: 1,
    char_29: 'the-herta',
    app_rate_29: '0.14%',
    avg_round_29: 3666,
    app_flat_29: 1,
    char_30: 'firefly',
    app_rate_30: '0.14%',
    avg_round_30: 3429,
    app_flat_30: 1
  },
  {
    char: 'gallagher',
    app: 24.27,
    char_1: 'the-herta',
    app_rate_1: '32.89%',
    avg_round_1: 3498,
    app_flat_1: 894,
    char_2: 'ruan-mei',
    app_rate_2: '29.63%',
    avg_round_2: 3275,
    app_flat_2: 457,
    char_3: 'tingyun-fugue',
    app_rate_3: '20.83%',
    avg_round_3: 3294,
    app_flat_3: 312,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '19.78%',
    avg_round_4: 3460,
    app_flat_4: 515,
    char_5: 'tribbie',
    app_rate_5: '16.84%',
    avg_round_5: 3508,
    app_flat_5: 366,
    char_6: 'sunday',
    app_rate_6: '16.3%',
    avg_round_6: 3398,
    app_flat_6: 311,
    char_7: 'herta',
    app_rate_7: '14.86%',
    avg_round_7: 3461,
    app_flat_7: 408,
    char_8: 'robin',
    app_rate_8: '14.44%',
    avg_round_8: 3412,
    app_flat_8: 284,
    char_9: 'acheron',
    app_rate_9: '14.21%',
    avg_round_9: 3306,
    app_flat_9: 212,
    char_10: 'firefly',
    app_rate_10: '13.08%',
    avg_round_10: 3158,
    app_flat_10: 77,
    char_11: 'rappa',
    app_rate_11: '12.4%',
    avg_round_11: 3271,
    app_flat_11: 292,
    char_12: 'trailblazer-harmony',
    app_rate_12: '12.06%',
    avg_round_12: 3241,
    app_flat_12: 210,
    char_13: 'serval',
    app_rate_13: '11.15%',
    avg_round_13: 3516,
    app_flat_13: 299,
    char_14: 'pela',
    app_rate_14: '8.83%',
    avg_round_14: 3317,
    app_flat_14: 186,
    char_15: 'jiaoqiu',
    app_rate_15: '8.72%',
    avg_round_15: 3348,
    app_flat_15: 151,
    char_16: 'feixiao',
    app_rate_16: '7.42%',
    avg_round_16: 3391,
    app_flat_16: 184,
    char_17: 'boothill',
    app_rate_17: '7.05%',
    avg_round_17: 3376,
    app_flat_17: 155,
    char_18: 'jade',
    app_rate_18: '5.92%',
    avg_round_18: 3570,
    app_flat_18: 153,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '5.04%',
    avg_round_19: 3405,
    app_flat_19: 131,
    char_20: 'aglaea',
    app_rate_20: '4.73%',
    avg_round_20: 3430,
    app_flat_20: 78,
    char_21: 'bronya',
    app_rate_21: '3.96%',
    avg_round_21: 3396,
    app_flat_21: 68,
    char_22: 'jing-yuan',
    app_rate_22: '3.71%',
    avg_round_22: 3352,
    app_flat_22: 60,
    char_23: 'sparkle',
    app_rate_23: '3.06%',
    avg_round_23: 3270,
    app_flat_23: 22,
    char_24: 'kafka',
    app_rate_24: '2.12%',
    avg_round_24: 3194,
    app_flat_24: 24,
    char_25: 'black-swan',
    app_rate_25: '2.09%',
    avg_round_25: 3162,
    app_flat_25: 21,
    char_26: 'mydei',
    app_rate_26: '1.53%',
    avg_round_26: 3396,
    app_flat_26: 16,
    char_27: 'argenti',
    app_rate_27: '1.44%',
    avg_round_27: 3573,
    app_flat_27: 37,
    char_28: 'topaz',
    app_rate_28: '1.13%',
    avg_round_28: 3342,
    app_flat_28: 28,
    char_29: 'jingliu',
    app_rate_29: '0.74%',
    avg_round_29: 3278,
    app_flat_29: 12,
    char_30: 'moze',
    app_rate_30: '0.59%',
    avg_round_30: 3306,
    app_flat_30: 13
  },
  {
    char: 'jade',
    app: 20.92,
    char_1: 'the-herta',
    app_rate_1: '91.23%',
    avg_round_1: 3604,
    app_flat_1: 1522,
    char_2: 'tribbie',
    app_rate_2: '50.61%',
    avg_round_2: 3642,
    app_flat_2: 714,
    char_3: 'lingsha',
    app_rate_3: '32.55%',
    avg_round_3: 3644,
    app_flat_3: 531,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '27.09%',
    avg_round_4: 3621,
    app_flat_4: 538,
    char_5: 'aventurine',
    app_rate_5: '18.69%',
    avg_round_5: 3553,
    app_flat_5: 284,
    char_6: 'robin',
    app_rate_6: '15.04%',
    avg_round_6: 3481,
    app_flat_6: 167,
    char_7: 'huohuo',
    app_rate_7: '14.45%',
    avg_round_7: 3517,
    app_flat_7: 243,
    char_8: 'sunday',
    app_rate_8: '10.48%',
    avg_round_8: 3554,
    app_flat_8: 163,
    char_9: 'fu-xuan',
    app_rate_9: '7.91%',
    avg_round_9: 3522,
    app_flat_9: 103,
    char_10: 'gallagher',
    app_rate_10: '6.86%',
    avg_round_10: 3570,
    app_flat_10: 153,
    char_11: 'luocha',
    app_rate_11: '5.45%',
    avg_round_11: 3557,
    app_flat_11: 100,
    char_12: 'ruan-mei',
    app_rate_12: '4.99%',
    avg_round_12: 3515,
    app_flat_12: 75,
    char_13: 'feixiao',
    app_rate_13: '2.59%',
    avg_round_13: 3360,
    app_flat_13: 4,
    char_14: 'sparkle',
    app_rate_14: '1.87%',
    avg_round_14: 3510,
    app_flat_14: 17,
    char_15: 'herta',
    app_rate_15: '1.18%',
    avg_round_15: 3433,
    app_flat_15: 20,
    char_16: 'jing-yuan',
    app_rate_16: '1.15%',
    avg_round_16: 3266,
    app_flat_16: 16,
    char_17: 'blade',
    app_rate_17: '1.12%',
    avg_round_17: 3497,
    app_flat_17: 6,
    char_18: 'bronya',
    app_rate_18: '0.92%',
    avg_round_18: 3500,
    app_flat_18: 7,
    char_19: 'pela',
    app_rate_19: '0.82%',
    avg_round_19: 3587,
    app_flat_19: 7,
    char_20: 'argenti',
    app_rate_20: '0.53%',
    avg_round_20: 3482,
    app_flat_20: 5,
    char_21: 'jiaoqiu',
    app_rate_21: '0.46%',
    avg_round_21: 3483,
    app_flat_21: 4,
    char_22: 'tingyun-fugue',
    app_rate_22: '0.43%',
    avg_round_22: 3497,
    app_flat_22: 7,
    char_23: 'serval',
    app_rate_23: '0.39%',
    avg_round_23: 3688,
    app_flat_23: 8,
    char_24: 'bailu',
    app_rate_24: '0.39%',
    avg_round_24: 3535,
    app_flat_24: 5,
    char_25: 'yunli',
    app_rate_25: '0.33%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'lynx',
    app_rate_26: '0.3%',
    avg_round_26: 3560,
    app_flat_26: 5,
    char_27: 'topaz',
    app_rate_27: '0.26%',
    avg_round_27: 3510,
    app_flat_27: 1,
    char_28: 'tingyun',
    app_rate_28: '0.26%',
    avg_round_28: 3542,
    app_flat_28: 3,
    char_29: 'himeko',
    app_rate_29: '0.23%',
    avg_round_29: 3429,
    app_flat_29: 5,
    char_30: 'acheron',
    app_rate_30: '0.2%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 19.59,
    char_1: 'feixiao',
    app_rate_1: '95.97%',
    avg_round_1: 3478,
    app_flat_1: 1739,
    char_2: 'robin',
    app_rate_2: '89.27%',
    avg_round_2: 3488,
    app_flat_2: 1582,
    char_3: 'aventurine',
    app_rate_3: '42.57%',
    avg_round_3: 3454,
    app_flat_3: 828,
    char_4: 'bronya',
    app_rate_4: '10.34%',
    avg_round_4: 3685,
    app_flat_4: 148,
    char_5: 'fu-xuan',
    app_rate_5: '9.29%',
    avg_round_5: 3391,
    app_flat_5: 158,
    char_6: 'sunday',
    app_rate_6: '6.56%',
    avg_round_6: 3672,
    app_flat_6: 108,
    char_7: 'gallagher',
    app_rate_7: '6.24%',
    avg_round_7: 3405,
    app_flat_7: 131,
    char_8: 'huohuo',
    app_rate_8: '6.24%',
    avg_round_8: 3396,
    app_flat_8: 112,
    char_9: 'lingsha',
    app_rate_9: '5.79%',
    avg_round_9: 3424,
    app_flat_9: 125,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '5.75%',
    avg_round_10: 3548,
    app_flat_10: 93,
    char_11: 'ruan-mei',
    app_rate_11: '4.35%',
    avg_round_11: 3488,
    app_flat_11: 67,
    char_12: 'tribbie',
    app_rate_12: '3.12%',
    avg_round_12: 3459,
    app_flat_12: 53,
    char_13: 'luocha',
    app_rate_13: '2.52%',
    avg_round_13: 3420,
    app_flat_13: 47,
    char_14: 'sparkle',
    app_rate_14: '1.89%',
    avg_round_14: 3443,
    app_flat_14: 26,
    char_15: 'topaz',
    app_rate_15: '1.47%',
    avg_round_15: 3440,
    app_flat_15: 19,
    char_16: 'herta',
    app_rate_16: '1.44%',
    avg_round_16: 3627,
    app_flat_16: 17,
    char_17: 'yunli',
    app_rate_17: '1.33%',
    avg_round_17: 3370,
    app_flat_17: 17,
    char_18: 'moze',
    app_rate_18: '0.81%',
    avg_round_18: 3314,
    app_flat_18: 16,
    char_19: 'bailu',
    app_rate_19: '0.56%',
    avg_round_19: 3361,
    app_flat_19: 14,
    char_20: 'gepard',
    app_rate_20: '0.53%',
    avg_round_20: 3354,
    app_flat_20: 12,
    char_21: 'trailblazer-harmony',
    app_rate_21: '0.39%',
    avg_round_21: 3447,
    app_flat_21: 3,
    char_22: 'clara',
    app_rate_22: '0.35%',
    avg_round_22: 3121,
    app_flat_22: 3,
    char_23: 'sushang',
    app_rate_23: '0.35%',
    avg_round_23: 3489,
    app_flat_23: 5,
    char_24: 'blade',
    app_rate_24: '0.32%',
    avg_round_24: 3492,
    app_flat_24: 6,
    char_25: 'firefly',
    app_rate_25: '0.25%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'lynx',
    app_rate_26: '0.21%',
    avg_round_26: 3347,
    app_flat_26: 6,
    char_27: 'yanqing',
    app_rate_27: '0.21%',
    avg_round_27: 3282,
    app_flat_27: 2,
    char_28: 'aglaea',
    app_rate_28: '0.18%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jingliu',
    app_rate_29: '0.18%',
    avg_round_29: 3300,
    app_flat_29: 2,
    char_30: 'pela',
    app_rate_30: '0.18%',
    avg_round_30: 3543,
    app_flat_30: 4
  },
  {
    char: 'fu-xuan',
    app: 16.88,
    char_1: 'the-herta',
    app_rate_1: '34.6%',
    avg_round_1: 3455,
    app_flat_1: 432,
    char_2: 'acheron',
    app_rate_2: '28.61%',
    avg_round_2: 3275,
    app_flat_2: 159,
    char_3: 'robin',
    app_rate_3: '25.27%',
    avg_round_3: 3370,
    app_flat_3: 284,
    char_4: 'tribbie',
    app_rate_4: '22.18%',
    avg_round_4: 3456,
    app_flat_4: 215,
    char_5: 'herta',
    app_rate_5: '18.19%',
    avg_round_5: 3423,
    app_flat_5: 234,
    char_6: 'sunday',
    app_rate_6: '17.3%',
    avg_round_6: 3386,
    app_flat_6: 164,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '17.22%',
    avg_round_7: 3434,
    app_flat_7: 215,
    char_8: 'feixiao',
    app_rate_8: '16.52%',
    avg_round_8: 3364,
    app_flat_8: 226,
    char_9: 'sparkle',
    app_rate_9: '15.91%',
    avg_round_9: 3260,
    app_flat_9: 33,
    char_10: 'jiaoqiu',
    app_rate_10: '14.2%',
    avg_round_10: 3361,
    app_flat_10: 85,
    char_11: 'pela',
    app_rate_11: '13.31%',
    avg_round_11: 3288,
    app_flat_11: 125,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '10.79%',
    avg_round_12: 3391,
    app_flat_12: 158,
    char_13: 'jade',
    app_rate_13: '9.81%',
    avg_round_13: 3522,
    app_flat_13: 103,
    char_14: 'aglaea',
    app_rate_14: '8.1%',
    avg_round_14: 3418,
    app_flat_14: 75,
    char_15: 'serval',
    app_rate_15: '6.15%',
    avg_round_15: 3460,
    app_flat_15: 82,
    char_16: 'silver-wolf',
    app_rate_16: '4.72%',
    avg_round_16: 3184,
    app_flat_16: 23,
    char_17: 'ruan-mei',
    app_rate_17: '4.52%',
    avg_round_17: 3335,
    app_flat_17: 29,
    char_18: 'jing-yuan',
    app_rate_18: '4.52%',
    avg_round_18: 3357,
    app_flat_18: 36,
    char_19: 'black-swan',
    app_rate_19: '4.31%',
    avg_round_19: 3187,
    app_flat_19: 27,
    char_20: 'kafka',
    app_rate_20: '4.15%',
    avg_round_20: 3208,
    app_flat_20: 26,
    char_21: 'topaz',
    app_rate_21: '3.22%',
    avg_round_21: 3297,
    app_flat_21: 37,
    char_22: 'bronya',
    app_rate_22: '2.69%',
    avg_round_22: 3310,
    app_flat_22: 22,
    char_23: 'yunli',
    app_rate_23: '2.24%',
    avg_round_23: 3216,
    app_flat_23: 17,
    char_24: 'jingliu',
    app_rate_24: '1.22%',
    avg_round_24: 3222,
    app_flat_24: 9,
    char_25: 'moze',
    app_rate_25: '1.14%',
    avg_round_25: 3234,
    app_flat_25: 14,
    char_26: 'blade',
    app_rate_26: '1.02%',
    avg_round_26: 3260,
    app_flat_26: 4,
    char_27: 'argenti',
    app_rate_27: '0.98%',
    avg_round_27: 3488,
    app_flat_27: 7,
    char_28: 'tingyun',
    app_rate_28: '0.94%',
    avg_round_28: 3128,
    app_flat_28: 5,
    char_29: 'seele',
    app_rate_29: '0.85%',
    avg_round_29: 3367,
    app_flat_29: 1,
    char_30: 'tingyun-fugue',
    app_rate_30: '0.85%',
    avg_round_30: 3295,
    app_flat_30: 7
  },
  {
    char: 'aglaea',
    app: 16.23,
    char_1: 'sunday',
    app_rate_1: '85.27%',
    avg_round_1: 3491,
    app_flat_1: 948,
    char_2: 'robin',
    app_rate_2: '64.66%',
    avg_round_2: 3509,
    app_flat_2: 693,
    char_3: 'huohuo',
    app_rate_3: '48.37%',
    avg_round_3: 3465,
    app_flat_3: 614,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '35.63%',
    avg_round_4: 3433,
    app_flat_4: 390,
    char_5: 'bronya',
    app_rate_5: '12.15%',
    avg_round_5: 3588,
    app_flat_5: 107,
    char_6: 'tribbie',
    app_rate_6: '11.6%',
    avg_round_6: 3424,
    app_flat_6: 101,
    char_7: 'fu-xuan',
    app_rate_7: '8.42%',
    avg_round_7: 3418,
    app_flat_7: 75,
    char_8: 'gallagher',
    app_rate_8: '7.07%',
    avg_round_8: 3430,
    app_flat_8: 78,
    char_9: 'aventurine',
    app_rate_9: '6.6%',
    avg_round_9: 3392,
    app_flat_9: 72,
    char_10: 'luocha',
    app_rate_10: '5.25%',
    avg_round_10: 3441,
    app_flat_10: 40,
    char_11: 'lingsha',
    app_rate_11: '4.23%',
    avg_round_11: 3407,
    app_flat_11: 49,
    char_12: 'ruan-mei',
    app_rate_12: '3.43%',
    avg_round_12: 3494,
    app_flat_12: 24,
    char_13: 'tingyun',
    app_rate_13: '1.78%',
    avg_round_13: 3409,
    app_flat_13: 21,
    char_14: 'sparkle',
    app_rate_14: '1.52%',
    avg_round_14: 3465,
    app_flat_14: 12,
    char_15: 'feixiao',
    app_rate_15: '1.23%',
    avg_round_15: 3504,
    app_flat_15: 7,
    char_16: 'bailu',
    app_rate_16: '0.97%',
    avg_round_16: 3436,
    app_flat_16: 11,
    char_17: 'gepard',
    app_rate_17: '0.3%',
    avg_round_17: 3534,
    app_flat_17: 3,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.21%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'pela',
    app_rate_19: '0.21%',
    avg_round_19: 3482,
    app_flat_19: 3,
    char_20: 'the-herta',
    app_rate_20: '0.17%',
    avg_round_20: 3408,
    app_flat_20: 2,
    char_21: 'jade',
    app_rate_21: '0.17%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'natasha',
    app_rate_22: '0.17%',
    avg_round_22: 3380,
    app_flat_22: 1,
    char_23: 'lynx',
    app_rate_23: '0.13%',
    avg_round_23: 3406,
    app_flat_23: 2,
    char_24: 'asta',
    app_rate_24: '0.13%',
    avg_round_24: 3450,
    app_flat_24: 2,
    char_25: 'hanya',
    app_rate_25: '0.08%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'boothill',
    app_rate_26: '0.04%',
    avg_round_26: 3560,
    app_flat_26: 1,
    char_27: 'kafka',
    app_rate_27: '0.04%',
    avg_round_27: 3028,
    app_flat_27: 1,
    char_28: 'acheron',
    app_rate_28: '0.04%',
    avg_round_28: 3170,
    app_flat_28: 1,
    char_29: 'yunli',
    app_rate_29: '0.04%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'herta',
    app_rate_30: '0.04%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'firefly',
    app: 15.61,
    char_1: 'ruan-mei',
    app_rate_1: '92.65%',
    avg_round_1: 3248,
    app_flat_1: 333,
    char_2: 'tingyun-fugue',
    app_rate_2: '79.89%',
    avg_round_2: 3276,
    app_flat_2: 298,
    char_3: 'lingsha',
    app_rate_3: '63.29%',
    avg_round_3: 3252,
    app_flat_3: 236,
    char_4: 'trailblazer-harmony',
    app_rate_4: '32.48%',
    avg_round_4: 3216,
    app_flat_4: 98,
    char_5: 'gallagher',
    app_rate_5: '20.33%',
    avg_round_5: 3158,
    app_flat_5: 77,
    char_6: 'bronya',
    app_rate_6: '2.2%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'tribbie',
    app_rate_7: '1.36%',
    avg_round_7: 3269,
    app_flat_7: 4,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '0.92%',
    avg_round_8: 3443,
    app_flat_8: 6,
    char_9: 'huohuo',
    app_rate_9: '0.92%',
    avg_round_9: 3170,
    app_flat_9: 3,
    char_10: 'rappa',
    app_rate_10: '0.79%',
    avg_round_10: 3311,
    app_flat_10: 4,
    char_11: 'aventurine',
    app_rate_11: '0.79%',
    avg_round_11: 3171,
    app_flat_11: 4,
    char_12: 'luocha',
    app_rate_12: '0.48%',
    avg_round_12: 3017,
    app_flat_12: 1,
    char_13: 'pela',
    app_rate_13: '0.44%',
    avg_round_13: 3471,
    app_flat_13: 1,
    char_14: 'sunday',
    app_rate_14: '0.44%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'boothill',
    app_rate_15: '0.35%',
    avg_round_15: 3567,
    app_flat_15: 1,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.31%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'robin',
    app_rate_17: '0.31%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'himeko',
    app_rate_18: '0.26%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'fu-xuan',
    app_rate_19: '0.26%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'acheron',
    app_rate_20: '0.22%',
    avg_round_20: 3429,
    app_flat_20: 1,
    char_21: 'bailu',
    app_rate_21: '0.18%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'feixiao',
    app_rate_22: '0.18%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: '0.13%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.09%',
    avg_round_24: 3429,
    app_flat_24: 1,
    char_25: 'gepard',
    app_rate_25: '0.09%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'herta',
    app_rate_26: '0.09%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'yunli',
    app_rate_27: '0.09%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'clara',
    app_rate_28: '0.04%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'sushang',
    app_rate_29: '0.04%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'tingyun',
    app_rate_30: '0.04%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jiaoqiu',
    app: 14.58,
    char_1: 'acheron',
    app_rate_1: '95.67%',
    avg_round_1: 3383,
    app_flat_1: 687,
    char_2: 'aventurine',
    app_rate_2: '43.48%',
    avg_round_2: 3368,
    app_flat_2: 319,
    char_3: 'pela',
    app_rate_3: '35.04%',
    avg_round_3: 3403,
    app_flat_3: 523,
    char_4: 'sparkle',
    app_rate_4: '26.0%',
    avg_round_4: 3345,
    app_flat_4: 28,
    char_5: 'fu-xuan',
    app_rate_5: '16.44%',
    avg_round_5: 3361,
    app_flat_5: 85,
    char_6: 'gallagher',
    app_rate_6: '14.51%',
    avg_round_6: 3348,
    app_flat_6: 151,
    char_7: 'tribbie',
    app_rate_7: '13.94%',
    avg_round_7: 3543,
    app_flat_7: 60,
    char_8: 'sunday',
    app_rate_8: '12.01%',
    avg_round_8: 3326,
    app_flat_8: 43,
    char_9: 'lingsha',
    app_rate_9: '6.31%',
    avg_round_9: 3395,
    app_flat_9: 54,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '5.13%',
    avg_round_10: 3420,
    app_flat_10: 33,
    char_11: 'bronya',
    app_rate_11: '4.29%',
    avg_round_11: 3481,
    app_flat_11: 3,
    char_12: 'robin',
    app_rate_12: '4.24%',
    avg_round_12: 3314,
    app_flat_12: 10,
    char_13: 'luocha',
    app_rate_13: '3.58%',
    avg_round_13: 3332,
    app_flat_13: 18,
    char_14: 'ruan-mei',
    app_rate_14: '2.5%',
    avg_round_14: 3418,
    app_flat_14: 6,
    char_15: 'black-swan',
    app_rate_15: '2.36%',
    avg_round_15: 3198,
    app_flat_15: 13,
    char_16: 'tingyun-fugue',
    app_rate_16: '2.17%',
    avg_round_16: 3378,
    app_flat_16: 23,
    char_17: 'silver-wolf',
    app_rate_17: '1.84%',
    avg_round_17: 3302,
    app_flat_17: 14,
    char_18: 'kafka',
    app_rate_18: '1.6%',
    avg_round_18: 3324,
    app_flat_18: 10,
    char_19: 'huohuo',
    app_rate_19: '1.6%',
    avg_round_19: 3372,
    app_flat_19: 2,
    char_20: 'mydei',
    app_rate_20: '0.94%',
    avg_round_20: 3443,
    app_flat_20: 3,
    char_21: 'jing-yuan',
    app_rate_21: '0.94%',
    avg_round_21: 3104,
    app_flat_21: 2,
    char_22: 'the-herta',
    app_rate_22: '0.85%',
    avg_round_22: 3526,
    app_flat_22: 7,
    char_23: 'gepard',
    app_rate_23: '0.71%',
    avg_round_23: 3385,
    app_flat_23: 2,
    char_24: 'jade',
    app_rate_24: '0.66%',
    avg_round_24: 3483,
    app_flat_24: 4,
    char_25: 'himeko',
    app_rate_25: '0.52%',
    avg_round_25: 3254,
    app_flat_25: 1,
    char_26: 'feixiao',
    app_rate_26: '0.42%',
    avg_round_26: 3194,
    app_flat_26: 2,
    char_27: 'argenti',
    app_rate_27: '0.38%',
    avg_round_27: 3159,
    app_flat_27: 1,
    char_28: 'herta',
    app_rate_28: '0.28%',
    avg_round_28: 3617,
    app_flat_28: 2,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.24%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'guinaifen',
    app_rate_30: '0.19%',
    avg_round_30: 3365,
    app_flat_30: 1
  },
  {
    char: 'serval',
    app: 14.55,
    char_1: 'the-herta',
    app_rate_1: '97.97%',
    avg_round_1: 3544,
    app_flat_1: 1318,
    char_2: 'tribbie',
    app_rate_2: '54.39%',
    avg_round_2: 3594,
    app_flat_2: 661,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '44.24%',
    avg_round_3: 3558,
    app_flat_3: 649,
    char_4: 'lingsha',
    app_rate_4: '18.7%',
    avg_round_4: 3597,
    app_flat_4: 250,
    char_5: 'gallagher',
    app_rate_5: '18.6%',
    avg_round_5: 3516,
    app_flat_5: 299,
    char_6: 'huohuo',
    app_rate_6: '15.49%',
    avg_round_6: 3471,
    app_flat_6: 203,
    char_7: 'aventurine',
    app_rate_7: '11.99%',
    avg_round_7: 3515,
    app_flat_7: 177,
    char_8: 'sunday',
    app_rate_8: '10.95%',
    avg_round_8: 3460,
    app_flat_8: 147,
    char_9: 'fu-xuan',
    app_rate_9: '7.13%',
    avg_round_9: 3460,
    app_flat_9: 82,
    char_10: 'luocha',
    app_rate_10: '6.33%',
    avg_round_10: 3475,
    app_flat_10: 100,
    char_11: 'robin',
    app_rate_11: '4.39%',
    avg_round_11: 3425,
    app_flat_11: 35,
    char_12: 'ruan-mei',
    app_rate_12: '2.74%',
    avg_round_12: 3445,
    app_flat_12: 23,
    char_13: 'sparkle',
    app_rate_13: '1.46%',
    avg_round_13: 3303,
    app_flat_13: 7,
    char_14: 'pela',
    app_rate_14: '1.37%',
    avg_round_14: 3534,
    app_flat_14: 15,
    char_15: 'herta',
    app_rate_15: '0.94%',
    avg_round_15: 3551,
    app_flat_15: 13,
    char_16: 'tingyun',
    app_rate_16: '0.71%',
    avg_round_16: 3426,
    app_flat_16: 12,
    char_17: 'jade',
    app_rate_17: '0.57%',
    avg_round_17: 3688,
    app_flat_17: 8,
    char_18: 'bronya',
    app_rate_18: '0.33%',
    avg_round_18: 3294,
    app_flat_18: 5,
    char_19: 'asta',
    app_rate_19: '0.28%',
    avg_round_19: 3409,
    app_flat_19: 5,
    char_20: 'bailu',
    app_rate_20: '0.24%',
    avg_round_20: 3356,
    app_flat_20: 4,
    char_21: 'acheron',
    app_rate_21: '0.19%',
    avg_round_21: 3666,
    app_flat_21: 1,
    char_22: 'himeko',
    app_rate_22: '0.14%',
    avg_round_22: 3787,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.14%',
    avg_round_23: 3081,
    app_flat_23: 1,
    char_24: 'gepard',
    app_rate_24: '0.14%',
    avg_round_24: 3452,
    app_flat_24: 3,
    char_25: 'yunli',
    app_rate_25: '0.09%',
    avg_round_25: 3762,
    app_flat_25: 2,
    char_26: 'argenti',
    app_rate_26: '0.09%',
    avg_round_26: 3646,
    app_flat_26: 1,
    char_27: 'trailblazer-harmony',
    app_rate_27: '0.09%',
    avg_round_27: 3288,
    app_flat_27: 2,
    char_28: 'feixiao',
    app_rate_28: '0.05%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'black-swan',
    app_rate_29: '0.05%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'firefly',
    app_rate_30: '0.05%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'pela',
    app: 13.42,
    char_1: 'acheron',
    app_rate_1: '88.02%',
    avg_round_1: 3355,
    app_flat_1: 790,
    char_2: 'jiaoqiu',
    app_rate_2: '38.1%',
    avg_round_2: 3403,
    app_flat_2: 523,
    char_3: 'aventurine',
    app_rate_3: '37.94%',
    avg_round_3: 3344,
    app_flat_3: 357,
    char_4: 'fu-xuan',
    app_rate_4: '16.74%',
    avg_round_4: 3288,
    app_flat_4: 125,
    char_5: 'gallagher',
    app_rate_5: '15.98%',
    avg_round_5: 3317,
    app_flat_5: 186,
    char_6: 'sparkle',
    app_rate_6: '13.82%',
    avg_round_6: 3295,
    app_flat_6: 16,
    char_7: 'tribbie',
    app_rate_7: '13.06%',
    avg_round_7: 3502,
    app_flat_7: 83,
    char_8: 'sunday',
    app_rate_8: '8.24%',
    avg_round_8: 3388,
    app_flat_8: 31,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '7.89%',
    avg_round_9: 3411,
    app_flat_9: 70,
    char_10: 'lingsha',
    app_rate_10: '6.45%',
    avg_round_10: 3389,
    app_flat_10: 59,
    char_11: 'silver-wolf',
    app_rate_11: '5.43%',
    avg_round_11: 3233,
    app_flat_11: 63,
    char_12: 'ruan-mei',
    app_rate_12: '4.61%',
    avg_round_12: 3536,
    app_flat_12: 31,
    char_13: 'bronya',
    app_rate_13: '4.61%',
    avg_round_13: 3522,
    app_flat_13: 15,
    char_14: 'the-herta',
    app_rate_14: '3.69%',
    avg_round_14: 3518,
    app_flat_14: 37,
    char_15: 'robin',
    app_rate_15: '3.43%',
    avg_round_15: 3468,
    app_flat_15: 13,
    char_16: 'tingyun-fugue',
    app_rate_16: '3.33%',
    avg_round_16: 3407,
    app_flat_16: 39,
    char_17: 'black-swan',
    app_rate_17: '2.82%',
    avg_round_17: 3222,
    app_flat_17: 25,
    char_18: 'gepard',
    app_rate_18: '2.46%',
    avg_round_18: 3207,
    app_flat_18: 10,
    char_19: 'kafka',
    app_rate_19: '2.46%',
    avg_round_19: 3213,
    app_flat_19: 28,
    char_20: 'luocha',
    app_rate_20: '2.2%',
    avg_round_20: 3308,
    app_flat_20: 13,
    char_21: 'boothill',
    app_rate_21: '2.1%',
    avg_round_21: 3579,
    app_flat_21: 23,
    char_22: 'trailblazer-harmony',
    app_rate_22: '1.84%',
    avg_round_22: 3405,
    app_flat_22: 24,
    char_23: 'guinaifen',
    app_rate_23: '1.74%',
    avg_round_23: 3287,
    app_flat_23: 26,
    char_24: 'serval',
    app_rate_24: '1.48%',
    avg_round_24: 3534,
    app_flat_24: 15,
    char_25: 'huohuo',
    app_rate_25: '1.38%',
    avg_round_25: 3418,
    app_flat_25: 7,
    char_26: 'jade',
    app_rate_26: '1.28%',
    avg_round_26: 3587,
    app_flat_26: 7,
    char_27: 'herta',
    app_rate_27: '1.28%',
    avg_round_27: 3454,
    app_flat_27: 12,
    char_28: 'rappa',
    app_rate_28: '1.18%',
    avg_round_28: 3313,
    app_flat_28: 20,
    char_29: 'welt',
    app_rate_29: '0.77%',
    avg_round_29: 3197,
    app_flat_29: 9,
    char_30: 'jingliu',
    app_rate_30: '0.77%',
    avg_round_30: 3346,
    app_flat_30: 5
  },
  {
    char: 'sparkle',
    app: 12.11,
    char_1: 'acheron',
    app_rate_1: '52.64%',
    avg_round_1: 3319,
    app_flat_1: 44,
    char_2: 'jiaoqiu',
    app_rate_2: '31.31%',
    avg_round_2: 3345,
    app_flat_2: 28,
    char_3: 'aventurine',
    app_rate_3: '30.18%',
    avg_round_3: 3316,
    app_flat_3: 73,
    char_4: 'fu-xuan',
    app_rate_4: '22.18%',
    avg_round_4: 3260,
    app_flat_4: 33,
    char_5: 'pela',
    app_rate_5: '15.31%',
    avg_round_5: 3295,
    app_flat_5: 16,
    char_6: 'sunday',
    app_rate_6: '12.02%',
    avg_round_6: 3385,
    app_flat_6: 78,
    char_7: 'the-herta',
    app_rate_7: '10.21%',
    avg_round_7: 3418,
    app_flat_7: 61,
    char_8: 'robin',
    app_rate_8: '10.15%',
    avg_round_8: 3447,
    app_flat_8: 38,
    char_9: 'huohuo',
    app_rate_9: '9.36%',
    avg_round_9: 3332,
    app_flat_9: 39,
    char_10: 'silver-wolf',
    app_rate_10: '9.02%',
    avg_round_10: 3538,
    app_flat_10: 9,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '8.74%',
    avg_round_11: 3454,
    app_flat_11: 38,
    char_12: 'tribbie',
    app_rate_12: '8.11%',
    avg_round_12: 3533,
    app_flat_12: 17,
    char_13: 'jing-yuan',
    app_rate_13: '6.92%',
    avg_round_13: 3345,
    app_flat_13: 53,
    char_14: 'gallagher',
    app_rate_14: '6.13%',
    avg_round_14: 3270,
    app_flat_14: 22,
    char_15: 'herta',
    app_rate_15: '5.5%',
    avg_round_15: 3387,
    app_flat_15: 33,
    char_16: 'lingsha',
    app_rate_16: '4.88%',
    avg_round_16: 3367,
    app_flat_16: 17,
    char_17: 'feixiao',
    app_rate_17: '4.71%',
    avg_round_17: 3395,
    app_flat_17: 40,
    char_18: 'seele',
    app_rate_18: '4.59%',
    avg_round_18: 3550,
    app_flat_18: 8,
    char_19: 'luocha',
    app_rate_19: '4.31%',
    avg_round_19: 3267,
    app_flat_19: 14,
    char_20: 'yunli',
    app_rate_20: '4.25%',
    avg_round_20: 3247,
    app_flat_20: 17,
    char_21: 'imbibitor-lunae',
    app_rate_21: '4.08%',
    avg_round_21: 3078,
    app_flat_21: 3,
    char_22: 'jade',
    app_rate_22: '3.23%',
    avg_round_22: 3510,
    app_flat_22: 17,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '3.06%',
    avg_round_23: 3443,
    app_flat_23: 26,
    char_24: 'ruan-mei',
    app_rate_24: '2.72%',
    avg_round_24: 3360,
    app_flat_24: 13,
    char_25: 'blade',
    app_rate_25: '2.5%',
    avg_round_25: 3363,
    app_flat_25: 10,
    char_26: 'tingyun',
    app_rate_26: '2.44%',
    avg_round_26: 3270,
    app_flat_26: 13,
    char_27: 'bronya',
    app_rate_27: '2.21%',
    avg_round_27: 3499,
    app_flat_27: 9,
    char_28: 'jingliu',
    app_rate_28: '2.1%',
    avg_round_28: 3259,
    app_flat_28: 9,
    char_29: 'aglaea',
    app_rate_29: '2.04%',
    avg_round_29: 3465,
    app_flat_29: 12,
    char_30: 'black-swan',
    app_rate_30: '1.87%',
    avg_round_30: 3551,
    app_flat_30: 1
  },
  {
    char: 'topaz',
    app: 11.92,
    char_1: 'feixiao',
    app_rate_1: '96.77%',
    avg_round_1: 3390,
    app_flat_1: 813,
    char_2: 'robin',
    app_rate_2: '92.22%',
    avg_round_2: 3393,
    app_flat_2: 753,
    char_3: 'aventurine',
    app_rate_3: '77.13%',
    avg_round_3: 3392,
    app_flat_3: 636,
    char_4: 'lingsha',
    app_rate_4: '4.9%',
    avg_round_4: 3381,
    app_flat_4: 52,
    char_5: 'fu-xuan',
    app_rate_5: '4.55%',
    avg_round_5: 3297,
    app_flat_5: 37,
    char_6: 'huohuo',
    app_rate_6: '2.88%',
    avg_round_6: 3308,
    app_flat_6: 30,
    char_7: 'tribbie',
    app_rate_7: '2.82%',
    avg_round_7: 3316,
    app_flat_7: 16,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '2.42%',
    avg_round_8: 3440,
    app_flat_8: 19,
    char_9: 'ruan-mei',
    app_rate_9: '2.3%',
    avg_round_9: 3339,
    app_flat_9: 22,
    char_10: 'gallagher',
    app_rate_10: '2.3%',
    avg_round_10: 3342,
    app_flat_10: 28,
    char_11: 'luocha',
    app_rate_11: '1.84%',
    avg_round_11: 3321,
    app_flat_11: 19,
    char_12: 'sunday',
    app_rate_12: '1.5%',
    avg_round_12: 3391,
    app_flat_12: 9,
    char_13: 'dr-ratio',
    app_rate_13: '1.44%',
    avg_round_13: 3094,
    app_flat_13: 10,
    char_14: 'bronya',
    app_rate_14: '1.38%',
    avg_round_14: 3364,
    app_flat_14: 11,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '1.38%',
    avg_round_15: 3378,
    app_flat_15: 11,
    char_16: 'yunli',
    app_rate_16: '1.15%',
    avg_round_16: 3217,
    app_flat_16: 4,
    char_17: 'sparkle',
    app_rate_17: '0.92%',
    avg_round_17: 3374,
    app_flat_17: 9,
    char_18: 'herta',
    app_rate_18: '0.46%',
    avg_round_18: 3591,
    app_flat_18: 1,
    char_19: 'jade',
    app_rate_19: '0.46%',
    avg_round_19: 3510,
    app_flat_19: 1,
    char_20: 'moze',
    app_rate_20: '0.35%',
    avg_round_20: 3348,
    app_flat_20: 3,
    char_21: 'bailu',
    app_rate_21: '0.17%',
    avg_round_21: 3326,
    app_flat_21: 2,
    char_22: 'march-7th',
    app_rate_22: '0.12%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'acheron',
    app_rate_23: '0.06%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.06%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'clara',
    app_rate_25: '0.06%',
    avg_round_25: 3139,
    app_flat_25: 1,
    char_26: 'hanya',
    app_rate_26: '0.06%',
    avg_round_26: 3273,
    app_flat_26: 1,
    char_27: 'lynx',
    app_rate_27: '0.06%',
    avg_round_27: 3032,
    app_flat_27: 1,
    char_28: 'gepard',
    app_rate_28: '0.06%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'the-herta',
    app_rate_29: '0.06%',
    avg_round_29: 3510,
    app_flat_29: 1,
    char_30: 'jing-yuan',
    app_rate_30: '0.06%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'rappa',
    app: 11.36,
    char_1: 'ruan-mei',
    app_rate_1: '81.8%',
    avg_round_1: 3389,
    app_flat_1: 799,
    char_2: 'tingyun-fugue',
    app_rate_2: '74.67%',
    avg_round_2: 3423,
    app_flat_2: 709,
    char_3: 'lingsha',
    app_rate_3: '64.45%',
    avg_round_3: 3467,
    app_flat_3: 619,
    char_4: 'trailblazer-harmony',
    app_rate_4: '31.56%',
    avg_round_4: 3355,
    app_flat_4: 351,
    char_5: 'gallagher',
    app_rate_5: '26.48%',
    avg_round_5: 3271,
    app_flat_5: 292,
    char_6: 'tribbie',
    app_rate_6: '3.63%',
    avg_round_6: 3522,
    app_flat_6: 27,
    char_7: 'aventurine',
    app_rate_7: '3.26%',
    avg_round_7: 3310,
    app_flat_7: 33,
    char_8: 'the-herta',
    app_rate_8: '2.96%',
    avg_round_8: 3451,
    app_flat_8: 28,
    char_9: 'pela',
    app_rate_9: '1.39%',
    avg_round_9: 3313,
    app_flat_9: 20,
    char_10: 'luocha',
    app_rate_10: '1.39%',
    avg_round_10: 3237,
    app_flat_10: 11,
    char_11: 'huohuo',
    app_rate_11: '1.33%',
    avg_round_11: 3229,
    app_flat_11: 9,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '1.27%',
    avg_round_12: 3504,
    app_flat_12: 14,
    char_13: 'firefly',
    app_rate_13: '1.09%',
    avg_round_13: 3311,
    app_flat_13: 4,
    char_14: 'sunday',
    app_rate_14: '1.03%',
    avg_round_14: 3413,
    app_flat_14: 8,
    char_15: 'himeko',
    app_rate_15: '0.73%',
    avg_round_15: 3398,
    app_flat_15: 5,
    char_16: 'fu-xuan',
    app_rate_16: '0.48%',
    avg_round_16: 3325,
    app_flat_16: 4,
    char_17: 'robin',
    app_rate_17: '0.3%',
    avg_round_17: 3430,
    app_flat_17: 1,
    char_18: 'jade',
    app_rate_18: '0.3%',
    avg_round_18: 3422,
    app_flat_18: 1,
    char_19: 'sparkle',
    app_rate_19: '0.24%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'argenti',
    app_rate_20: '0.24%',
    avg_round_20: 3617,
    app_flat_20: 1,
    char_21: 'asta',
    app_rate_21: '0.18%',
    avg_round_21: 3310,
    app_flat_21: 3,
    char_22: 'bailu',
    app_rate_22: '0.18%',
    avg_round_22: 3222,
    app_flat_22: 1,
    char_23: 'bronya',
    app_rate_23: '0.18%',
    avg_round_23: 3144,
    app_flat_23: 1,
    char_24: 'march-7th',
    app_rate_24: '0.18%',
    avg_round_24: 3348,
    app_flat_24: 2,
    char_25: 'acheron',
    app_rate_25: '0.18%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'jiaoqiu',
    app_rate_26: '0.12%',
    avg_round_26: 3327,
    app_flat_26: 1,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.06%',
    avg_round_27: 3340,
    app_flat_27: 1,
    char_28: 'boothill',
    app_rate_28: '0.06%',
    avg_round_28: 3370,
    app_flat_28: 1,
    char_29: 'herta',
    app_rate_29: '0.06%',
    avg_round_29: 3341,
    app_flat_29: 1,
    char_30: 'tingyun',
    app_rate_30: '0.06%',
    avg_round_30: 3390,
    app_flat_30: 1
  },
  {
    char: 'jing-yuan',
    app: 10.57,
    char_1: 'sunday',
    app_rate_1: '96.69%',
    avg_round_1: 3377,
    app_flat_1: 600,
    char_2: 'robin',
    app_rate_2: '56.21%',
    avg_round_2: 3415,
    app_flat_2: 344,
    char_3: 'aventurine',
    app_rate_3: '34.5%',
    avg_round_3: 3365,
    app_flat_3: 210,
    char_4: 'huohuo',
    app_rate_4: '20.73%',
    avg_round_4: 3360,
    app_flat_4: 137,
    char_5: 'tribbie',
    app_rate_5: '16.05%',
    avg_round_5: 3374,
    app_flat_5: 102,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '11.63%',
    avg_round_6: 3343,
    app_flat_6: 82,
    char_7: 'luocha',
    app_rate_7: '10.27%',
    avg_round_7: 3302,
    app_flat_7: 63,
    char_8: 'ruan-mei',
    app_rate_8: '8.71%',
    avg_round_8: 3471,
    app_flat_8: 39,
    char_9: 'gallagher',
    app_rate_9: '8.51%',
    avg_round_9: 3352,
    app_flat_9: 60,
    char_10: 'sparkle',
    app_rate_10: '7.93%',
    avg_round_10: 3345,
    app_flat_10: 53,
    char_11: 'fu-xuan',
    app_rate_11: '7.21%',
    avg_round_11: 3357,
    app_flat_11: 36,
    char_12: 'tingyun',
    app_rate_12: '4.94%',
    avg_round_12: 3389,
    app_flat_12: 26,
    char_13: 'lingsha',
    app_rate_13: '3.9%',
    avg_round_13: 3347,
    app_flat_13: 28,
    char_14: 'the-herta',
    app_rate_14: '3.05%',
    avg_round_14: 3432,
    app_flat_14: 22,
    char_15: 'bronya',
    app_rate_15: '2.53%',
    avg_round_15: 3460,
    app_flat_15: 14,
    char_16: 'jade',
    app_rate_16: '2.27%',
    avg_round_16: 3266,
    app_flat_16: 16,
    char_17: 'jiaoqiu',
    app_rate_17: '1.3%',
    avg_round_17: 3104,
    app_flat_17: 2,
    char_18: 'bailu',
    app_rate_18: '0.71%',
    avg_round_18: 3250,
    app_flat_18: 7,
    char_19: 'pela',
    app_rate_19: '0.71%',
    avg_round_19: 3332,
    app_flat_19: 4,
    char_20: 'feixiao',
    app_rate_20: '0.45%',
    avg_round_20: 3516,
    app_flat_20: 2,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '0.26%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'gepard',
    app_rate_22: '0.26%',
    avg_round_22: 3382,
    app_flat_22: 2,
    char_23: 'moze',
    app_rate_23: '0.19%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.13%',
    avg_round_24: 3549,
    app_flat_24: 1,
    char_25: 'herta',
    app_rate_25: '0.13%',
    avg_round_25: 3337,
    app_flat_25: 1,
    char_26: 'clara',
    app_rate_26: '0.13%',
    avg_round_26: 3324,
    app_flat_26: 2,
    char_27: 'blade',
    app_rate_27: '0.13%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'hanya',
    app_rate_28: '0.06%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'argenti',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'natasha',
    app_rate_30: '0.06%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-harmony',
    app: 10.4,
    char_1: 'ruan-mei',
    app_rate_1: '75.89%',
    avg_round_1: 3322,
    app_flat_1: 384,
    char_2: 'lingsha',
    app_rate_2: '51.52%',
    avg_round_2: 3404,
    app_flat_2: 230,
    char_3: 'firefly',
    app_rate_3: '48.75%',
    avg_round_3: 3216,
    app_flat_3: 98,
    char_4: 'tingyun-fugue',
    app_rate_4: '36.79%',
    avg_round_4: 3426,
    app_flat_4: 157,
    char_5: 'rappa',
    app_rate_5: '34.48%',
    avg_round_5: 3355,
    app_flat_5: 351,
    char_6: 'gallagher',
    app_rate_6: '28.14%',
    avg_round_6: 3241,
    app_flat_6: 210,
    char_7: 'boothill',
    app_rate_7: '9.31%',
    avg_round_7: 3429,
    app_flat_7: 103,
    char_8: 'bronya',
    app_rate_8: '3.96%',
    avg_round_8: 3453,
    app_flat_8: 30,
    char_9: 'sunday',
    app_rate_9: '2.84%',
    avg_round_9: 3520,
    app_flat_9: 21,
    char_10: 'tribbie',
    app_rate_10: '2.44%',
    avg_round_10: 3407,
    app_flat_10: 14,
    char_11: 'pela',
    app_rate_11: '2.38%',
    avg_round_11: 3405,
    app_flat_11: 24,
    char_12: 'aventurine',
    app_rate_12: '1.98%',
    avg_round_12: 3281,
    app_flat_12: 13,
    char_13: 'huohuo',
    app_rate_13: '1.65%',
    avg_round_13: 3256,
    app_flat_13: 11,
    char_14: 'luocha',
    app_rate_14: '0.86%',
    avg_round_14: 3243,
    app_flat_14: 10,
    char_15: 'himeko',
    app_rate_15: '0.79%',
    avg_round_15: 3254,
    app_flat_15: 6,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.73%',
    avg_round_16: 3447,
    app_flat_16: 3,
    char_17: 'fu-xuan',
    app_rate_17: '0.59%',
    avg_round_17: 3313,
    app_flat_17: 4,
    char_18: 'sushang',
    app_rate_18: '0.4%',
    avg_round_18: 3538,
    app_flat_18: 2,
    char_19: 'bailu',
    app_rate_19: '0.4%',
    avg_round_19: 3379,
    app_flat_19: 1,
    char_20: 'the-herta',
    app_rate_20: '0.33%',
    avg_round_20: 3468,
    app_flat_20: 5,
    char_21: 'robin',
    app_rate_21: '0.26%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jade',
    app_rate_22: '0.26%',
    avg_round_22: 3347,
    app_flat_22: 2,
    char_23: 'feixiao',
    app_rate_23: '0.2%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: '0.13%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jiaoqiu',
    app_rate_25: '0.13%',
    avg_round_25: 3327,
    app_flat_25: 1,
    char_26: 'argenti',
    app_rate_26: '0.13%',
    avg_round_26: 3617,
    app_flat_26: 1,
    char_27: 'serval',
    app_rate_27: '0.13%',
    avg_round_27: 3288,
    app_flat_27: 2,
    char_28: 'asta',
    app_rate_28: '0.07%',
    avg_round_28: 3321,
    app_flat_28: 1,
    char_29: 'sparkle',
    app_rate_29: '0.07%',
    avg_round_29: 3456,
    app_flat_29: 1,
    char_30: 'xueyi',
    app_rate_30: '0.07%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'luocha',
    app: 10.13,
    char_1: 'the-herta',
    app_rate_1: '43.76%',
    avg_round_1: 3474,
    app_flat_1: 442,
    char_2: 'sunday',
    app_rate_2: '33.31%',
    avg_round_2: 3370,
    app_flat_2: 199,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '30.12%',
    avg_round_3: 3435,
    app_flat_3: 262,
    char_4: 'tribbie',
    app_rate_4: '24.08%',
    avg_round_4: 3485,
    app_flat_4: 199,
    char_5: 'robin',
    app_rate_5: '22.46%',
    avg_round_5: 3368,
    app_flat_5: 155,
    char_6: 'herta',
    app_rate_6: '19.67%',
    avg_round_6: 3442,
    app_flat_6: 209,
    char_7: 'jade',
    app_rate_7: '11.26%',
    avg_round_7: 3557,
    app_flat_7: 100,
    char_8: 'jing-yuan',
    app_rate_8: '10.72%',
    avg_round_8: 3302,
    app_flat_8: 63,
    char_9: 'feixiao',
    app_rate_9: '9.5%',
    avg_round_9: 3362,
    app_flat_9: 89,
    char_10: 'ruan-mei',
    app_rate_10: '9.16%',
    avg_round_10: 3295,
    app_flat_10: 49,
    char_11: 'serval',
    app_rate_11: '9.09%',
    avg_round_11: 3475,
    app_flat_11: 100,
    char_12: 'aglaea',
    app_rate_12: '8.41%',
    avg_round_12: 3441,
    app_flat_12: 40,
    char_13: 'mydei',
    app_rate_13: '6.92%',
    avg_round_13: 3405,
    app_flat_13: 29,
    char_14: 'acheron',
    app_rate_14: '6.92%',
    avg_round_14: 3291,
    app_flat_14: 22,
    char_15: 'bronya',
    app_rate_15: '5.16%',
    avg_round_15: 3321,
    app_flat_15: 23,
    char_16: 'sparkle',
    app_rate_16: '5.16%',
    avg_round_16: 3267,
    app_flat_16: 14,
    char_17: 'jiaoqiu',
    app_rate_17: '5.16%',
    avg_round_17: 3332,
    app_flat_17: 18,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '4.88%',
    avg_round_18: 3420,
    app_flat_18: 47,
    char_19: 'kafka',
    app_rate_19: '3.19%',
    avg_round_19: 3188,
    app_flat_19: 9,
    char_20: 'blade',
    app_rate_20: '3.12%',
    avg_round_20: 3240,
    app_flat_20: 14,
    char_21: 'black-swan',
    app_rate_21: '2.92%',
    avg_round_21: 3188,
    app_flat_21: 9,
    char_22: 'pela',
    app_rate_22: '2.92%',
    avg_round_22: 3308,
    app_flat_22: 13,
    char_23: 'argenti',
    app_rate_23: '2.85%',
    avg_round_23: 3507,
    app_flat_23: 22,
    char_24: 'jingliu',
    app_rate_24: '2.58%',
    avg_round_24: 3299,
    app_flat_24: 16,
    char_25: 'topaz',
    app_rate_25: '2.17%',
    avg_round_25: 3321,
    app_flat_25: 19,
    char_26: 'tingyun-fugue',
    app_rate_26: '2.1%',
    avg_round_26: 3325,
    app_flat_26: 11,
    char_27: 'imbibitor-lunae',
    app_rate_27: '2.04%',
    avg_round_27: 2921,
    app_flat_27: 1,
    char_28: 'boothill',
    app_rate_28: '1.56%',
    avg_round_28: 3343,
    app_flat_28: 15,
    char_29: 'rappa',
    app_rate_29: '1.56%',
    avg_round_29: 3237,
    app_flat_29: 11,
    char_30: 'moze',
    app_rate_30: '1.42%',
    avg_round_30: 3255,
    app_flat_30: 14
  },
  {
    char: 'bronya',
    app: 10.12,
    char_1: 'robin',
    app_rate_1: '38.76%',
    avg_round_1: 3703,
    app_flat_1: 208,
    char_2: 'feixiao',
    app_rate_2: '25.73%',
    avg_round_2: 3662,
    app_flat_2: 174,
    char_3: 'ruan-mei',
    app_rate_3: '25.53%',
    avg_round_3: 3523,
    app_flat_3: 164,
    char_4: 'sunday',
    app_rate_4: '22.13%',
    avg_round_4: 3624,
    app_flat_4: 124,
    char_5: 'boothill',
    app_rate_5: '21.32%',
    avg_round_5: 3517,
    app_flat_5: 171,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '20.03%',
    avg_round_6: 3685,
    app_flat_6: 148,
    char_7: 'aglaea',
    app_rate_7: '19.48%',
    avg_round_7: 3588,
    app_flat_7: 107,
    char_8: 'tingyun-fugue',
    app_rate_8: '12.63%',
    avg_round_8: 3640,
    app_flat_8: 70,
    char_9: 'acheron',
    app_rate_9: '10.93%',
    avg_round_9: 3564,
    app_flat_9: 2,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '10.79%',
    avg_round_10: 3431,
    app_flat_10: 56,
    char_11: 'gallagher',
    app_rate_11: '9.5%',
    avg_round_11: 3396,
    app_flat_11: 68,
    char_12: 'aventurine',
    app_rate_12: '8.83%',
    avg_round_12: 3356,
    app_flat_12: 41,
    char_13: 'jiaoqiu',
    app_rate_13: '6.18%',
    avg_round_13: 3481,
    app_flat_13: 3,
    char_14: 'pela',
    app_rate_14: '6.11%',
    avg_round_14: 3522,
    app_flat_14: 15,
    char_15: 'jingliu',
    app_rate_15: '5.63%',
    avg_round_15: 3312,
    app_flat_15: 30,
    char_16: 'blade',
    app_rate_16: '5.23%',
    avg_round_16: 3365,
    app_flat_16: 30,
    char_17: 'huohuo',
    app_rate_17: '5.23%',
    avg_round_17: 3392,
    app_flat_17: 38,
    char_18: 'luocha',
    app_rate_18: '5.16%',
    avg_round_18: 3321,
    app_flat_18: 23,
    char_19: 'fu-xuan',
    app_rate_19: '4.48%',
    avg_round_19: 3310,
    app_flat_19: 22,
    char_20: 'trailblazer-harmony',
    app_rate_20: '4.07%',
    avg_round_20: 3453,
    app_flat_20: 30,
    char_21: 'tribbie',
    app_rate_21: '3.8%',
    avg_round_21: 3456,
    app_flat_21: 16,
    char_22: 'firefly',
    app_rate_22: '3.39%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'lingsha',
    app_rate_23: '2.65%',
    avg_round_23: 3422,
    app_flat_23: 15,
    char_24: 'jing-yuan',
    app_rate_24: '2.65%',
    avg_round_24: 3460,
    app_flat_24: 14,
    char_25: 'sparkle',
    app_rate_25: '2.65%',
    avg_round_25: 3499,
    app_flat_25: 9,
    char_26: 'mydei',
    app_rate_26: '2.04%',
    avg_round_26: 3369,
    app_flat_26: 7,
    char_27: 'jade',
    app_rate_27: '1.9%',
    avg_round_27: 3500,
    app_flat_27: 7,
    char_28: 'herta',
    app_rate_28: '1.83%',
    avg_round_28: 3512,
    app_flat_28: 8,
    char_29: 'the-herta',
    app_rate_29: '1.77%',
    avg_round_29: 3420,
    app_flat_29: 15,
    char_30: 'topaz',
    app_rate_30: '1.63%',
    avg_round_30: 3364,
    app_flat_30: 11
  },
  {
    char: 'boothill',
    app: 6.18,
    char_1: 'ruan-mei',
    app_rate_1: '86.21%',
    avg_round_1: 3499,
    app_flat_1: 426,
    char_2: 'tingyun-fugue',
    app_rate_2: '56.73%',
    avg_round_2: 3542,
    app_flat_2: 259,
    char_3: 'bronya',
    app_rate_3: '34.93%',
    avg_round_3: 3517,
    app_flat_3: 171,
    char_4: 'sunday',
    app_rate_4: '31.92%',
    avg_round_4: 3529,
    app_flat_4: 148,
    char_5: 'gallagher',
    app_rate_5: '27.7%',
    avg_round_5: 3376,
    app_flat_5: 155,
    char_6: 'trailblazer-harmony',
    app_rate_6: '15.68%',
    avg_round_6: 3429,
    app_flat_6: 103,
    char_7: 'lingsha',
    app_rate_7: '9.34%',
    avg_round_7: 3446,
    app_flat_7: 49,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '6.67%',
    avg_round_8: 3470,
    app_flat_8: 29,
    char_9: 'aventurine',
    app_rate_9: '5.78%',
    avg_round_9: 3357,
    app_flat_9: 29,
    char_10: 'huohuo',
    app_rate_10: '4.89%',
    avg_round_10: 3362,
    app_flat_10: 24,
    char_11: 'pela',
    app_rate_11: '4.56%',
    avg_round_11: 3579,
    app_flat_11: 23,
    char_12: 'luocha',
    app_rate_12: '2.56%',
    avg_round_12: 3343,
    app_flat_12: 15,
    char_13: 'fu-xuan',
    app_rate_13: '1.78%',
    avg_round_13: 3326,
    app_flat_13: 8,
    char_14: 'sparkle',
    app_rate_14: '1.45%',
    avg_round_14: 3373,
    app_flat_14: 6,
    char_15: 'robin',
    app_rate_15: '1.33%',
    avg_round_15: 3345,
    app_flat_15: 5,
    char_16: 'luka',
    app_rate_16: '0.89%',
    avg_round_16: 3426,
    app_flat_16: 7,
    char_17: 'firefly',
    app_rate_17: '0.89%',
    avg_round_17: 3567,
    app_flat_17: 1,
    char_18: 'argenti',
    app_rate_18: '0.78%',
    avg_round_18: 3340,
    app_flat_18: 3,
    char_19: 'tribbie',
    app_rate_19: '0.67%',
    avg_round_19: 3477,
    app_flat_19: 4,
    char_20: 'gepard',
    app_rate_20: '0.67%',
    avg_round_20: 3424,
    app_flat_20: 3,
    char_21: 'acheron',
    app_rate_21: '0.56%',
    avg_round_21: 3751,
    app_flat_21: 1,
    char_22: 'jiaoqiu',
    app_rate_22: '0.33%',
    avg_round_22: 3315,
    app_flat_22: 1,
    char_23: 'hanya',
    app_rate_23: '0.33%',
    avg_round_23: 3530,
    app_flat_23: 2,
    char_24: 'bailu',
    app_rate_24: '0.33%',
    avg_round_24: 3379,
    app_flat_24: 1,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.33%',
    avg_round_25: 3564,
    app_flat_25: 2,
    char_26: 'feixiao',
    app_rate_26: '0.33%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'blade',
    app_rate_27: '0.22%',
    avg_round_27: 3505,
    app_flat_27: 1,
    char_28: 'herta',
    app_rate_28: '0.22%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'silver-wolf',
    app_rate_29: '0.22%',
    avg_round_29: 3298,
    app_flat_29: 1,
    char_30: 'tingyun',
    app_rate_30: '0.22%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    app: 5.12,
    char_1: 'kafka',
    app_rate_1: '77.88%',
    avg_round_1: 3232,
    app_flat_1: 131,
    char_2: 'acheron',
    app_rate_2: '63.67%',
    avg_round_2: 3223,
    app_flat_2: 138,
    char_3: 'ruan-mei',
    app_rate_3: '29.09%',
    avg_round_3: 3307,
    app_flat_3: 33,
    char_4: 'aventurine',
    app_rate_4: '22.79%',
    avg_round_4: 3209,
    app_flat_4: 55,
    char_5: 'huohuo',
    app_rate_5: '21.98%',
    avg_round_5: 3191,
    app_flat_5: 22,
    char_6: 'robin',
    app_rate_6: '14.88%',
    avg_round_6: 3276,
    app_flat_6: 18,
    char_7: 'fu-xuan',
    app_rate_7: '14.21%',
    avg_round_7: 3187,
    app_flat_7: 27,
    char_8: 'gallagher',
    app_rate_8: '9.92%',
    avg_round_8: 3162,
    app_flat_8: 21,
    char_9: 'pela',
    app_rate_9: '7.37%',
    avg_round_9: 3222,
    app_flat_9: 25,
    char_10: 'jiaoqiu',
    app_rate_10: '6.7%',
    avg_round_10: 3198,
    app_flat_10: 13,
    char_11: 'luocha',
    app_rate_11: '5.76%',
    avg_round_11: 3188,
    app_flat_11: 9,
    char_12: 'lingsha',
    app_rate_12: '4.96%',
    avg_round_12: 3271,
    app_flat_12: 8,
    char_13: 'sparkle',
    app_rate_13: '4.42%',
    avg_round_13: 3551,
    app_flat_13: 1,
    char_14: 'tribbie',
    app_rate_14: '3.35%',
    avg_round_14: 3370,
    app_flat_14: 4,
    char_15: 'gepard',
    app_rate_15: '2.82%',
    avg_round_15: 3129,
    app_flat_15: 5,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '1.88%',
    avg_round_16: 3381,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '1.74%',
    avg_round_17: 3098,
    app_flat_17: 4,
    char_18: 'sunday',
    app_rate_18: '1.21%',
    avg_round_18: 3132,
    app_flat_18: 1,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.07%',
    avg_round_19: 3290,
    app_flat_19: 1,
    char_20: 'sampo',
    app_rate_20: '0.67%',
    avg_round_20: 3167,
    app_flat_20: 2,
    char_21: 'bronya',
    app_rate_21: '0.54%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'guinaifen',
    app_rate_22: '0.54%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'tingyun',
    app_rate_23: '0.4%',
    avg_round_23: 3356,
    app_flat_23: 1,
    char_24: 'feixiao',
    app_rate_24: '0.4%',
    avg_round_24: 3166,
    app_flat_24: 1,
    char_25: 'luka',
    app_rate_25: '0.27%',
    avg_round_25: 3457,
    app_flat_25: 1,
    char_26: 'silver-wolf',
    app_rate_26: '0.27%',
    avg_round_26: 3205,
    app_flat_26: 2,
    char_27: 'natasha',
    app_rate_27: '0.13%',
    avg_round_27: 3194,
    app_flat_27: 1,
    char_28: 'boothill',
    app_rate_28: '0.13%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.13%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'serval',
    app_rate_30: '0.13%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'kafka',
    app: 4.84,
    char_1: 'black-swan',
    app_rate_1: '82.41%',
    avg_round_1: 3232,
    app_flat_1: 131,
    char_2: 'acheron',
    app_rate_2: '60.85%',
    avg_round_2: 3231,
    app_flat_2: 135,
    char_3: 'ruan-mei',
    app_rate_3: '31.77%',
    avg_round_3: 3311,
    app_flat_3: 33,
    char_4: 'huohuo',
    app_rate_4: '23.4%',
    avg_round_4: 3185,
    app_flat_4: 22,
    char_5: 'aventurine',
    app_rate_5: '18.87%',
    avg_round_5: 3213,
    app_flat_5: 56,
    char_6: 'robin',
    app_rate_6: '15.46%',
    avg_round_6: 3264,
    app_flat_6: 19,
    char_7: 'fu-xuan',
    app_rate_7: '14.47%',
    avg_round_7: 3208,
    app_flat_7: 26,
    char_8: 'gallagher',
    app_rate_8: '10.64%',
    avg_round_8: 3194,
    app_flat_8: 24,
    char_9: 'pela',
    app_rate_9: '6.81%',
    avg_round_9: 3213,
    app_flat_9: 28,
    char_10: 'luocha',
    app_rate_10: '6.67%',
    avg_round_10: 3188,
    app_flat_10: 9,
    char_11: 'jiaoqiu',
    app_rate_11: '4.82%',
    avg_round_11: 3324,
    app_flat_11: 10,
    char_12: 'lingsha',
    app_rate_12: '4.82%',
    avg_round_12: 3204,
    app_flat_12: 6,
    char_13: 'tribbie',
    app_rate_13: '4.11%',
    avg_round_13: 3354,
    app_flat_13: 2,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '2.7%',
    avg_round_14: 3314,
    app_flat_14: 2,
    char_15: 'sparkle',
    app_rate_15: '2.55%',
    avg_round_15: 3551,
    app_flat_15: 1,
    char_16: 'bailu',
    app_rate_16: '1.84%',
    avg_round_16: 3098,
    app_flat_16: 4,
    char_17: 'gepard',
    app_rate_17: '1.84%',
    avg_round_17: 3219,
    app_flat_17: 3,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.42%',
    avg_round_18: 3226,
    app_flat_18: 2,
    char_19: 'sampo',
    app_rate_19: '0.71%',
    avg_round_19: 3441,
    app_flat_19: 1,
    char_20: 'sunday',
    app_rate_20: '0.57%',
    avg_round_20: 3230,
    app_flat_20: 2,
    char_21: 'tingyun',
    app_rate_21: '0.57%',
    avg_round_21: 3350,
    app_flat_21: 2,
    char_22: 'guinaifen',
    app_rate_22: '0.57%',
    avg_round_22: 3226,
    app_flat_22: 2,
    char_23: 'luka',
    app_rate_23: '0.43%',
    avg_round_23: 3457,
    app_flat_23: 1,
    char_24: 'feixiao',
    app_rate_24: '0.28%',
    avg_round_24: 3184,
    app_flat_24: 2,
    char_25: 'silver-wolf',
    app_rate_25: '0.14%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'natasha',
    app_rate_26: '0.14%',
    avg_round_26: 3194,
    app_flat_26: 1,
    char_27: 'boothill',
    app_rate_27: '0.14%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'aglaea',
    app_rate_28: '0.14%',
    avg_round_28: 3028,
    app_flat_28: 1,
    char_29: 'jade',
    app_rate_29: '0.14%',
    avg_round_29: 3083,
    app_flat_29: 1,
    char_30: 'herta',
    app_rate_30: '0.14%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'yunli',
    app: 3.83,
    char_1: 'robin',
    app_rate_1: '70.79%',
    avg_round_1: 3296,
    app_flat_1: 170,
    char_2: 'huohuo',
    app_rate_2: '43.55%',
    avg_round_2: 3218,
    app_flat_2: 88,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '23.84%',
    avg_round_3: 3327,
    app_flat_3: 63,
    char_4: 'feixiao',
    app_rate_4: '22.04%',
    avg_round_4: 3355,
    app_flat_4: 70,
    char_5: 'sunday',
    app_rate_5: '21.86%',
    avg_round_5: 3260,
    app_flat_5: 45,
    char_6: 'tribbie',
    app_rate_6: '19.18%',
    avg_round_6: 3366,
    app_flat_6: 27,
    char_7: 'aventurine',
    app_rate_7: '17.56%',
    avg_round_7: 3279,
    app_flat_7: 42,
    char_8: 'tingyun',
    app_rate_8: '17.03%',
    avg_round_8: 3224,
    app_flat_8: 40,
    char_9: 'sparkle',
    app_rate_9: '13.44%',
    avg_round_9: 3247,
    app_flat_9: 17,
    char_10: 'fu-xuan',
    app_rate_10: '9.86%',
    avg_round_10: 3216,
    app_flat_10: 17,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '6.81%',
    avg_round_11: 3370,
    app_flat_11: 17,
    char_12: 'ruan-mei',
    app_rate_12: '5.73%',
    avg_round_12: 3407,
    app_flat_12: 9,
    char_13: 'lingsha',
    app_rate_13: '4.12%',
    avg_round_13: 3276,
    app_flat_13: 12,
    char_14: 'topaz',
    app_rate_14: '3.58%',
    avg_round_14: 3217,
    app_flat_14: 4,
    char_15: 'luocha',
    app_rate_15: '3.58%',
    avg_round_15: 3188,
    app_flat_15: 9,
    char_16: 'the-herta',
    app_rate_16: '2.87%',
    avg_round_16: 3465,
    app_flat_16: 7,
    char_17: 'gallagher',
    app_rate_17: '1.97%',
    avg_round_17: 3286,
    app_flat_17: 6,
    char_18: 'herta',
    app_rate_18: '1.97%',
    avg_round_18: 3381,
    app_flat_18: 4,
    char_19: 'jade',
    app_rate_19: '1.79%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'bronya',
    app_rate_20: '0.9%',
    avg_round_20: 3080,
    app_flat_20: 1,
    char_21: 'clara',
    app_rate_21: '0.9%',
    avg_round_21: 2961,
    app_flat_21: 1,
    char_22: 'blade',
    app_rate_22: '0.72%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'pela',
    app_rate_23: '0.72%',
    avg_round_23: 3122,
    app_flat_23: 2,
    char_24: 'acheron',
    app_rate_24: '0.72%',
    avg_round_24: 3385,
    app_flat_24: 1,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.54%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'lynx',
    app_rate_26: '0.36%',
    avg_round_26: 3010,
    app_flat_26: 1,
    char_27: 'hanya',
    app_rate_27: '0.36%',
    avg_round_27: 3404,
    app_flat_27: 1,
    char_28: 'moze',
    app_rate_28: '0.36%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'serval',
    app_rate_29: '0.36%',
    avg_round_29: 3762,
    app_flat_29: 2,
    char_30: 'firefly',
    app_rate_30: '0.36%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 3.22,
    char_1: 'the-herta',
    app_rate_1: '73.99%',
    avg_round_1: 3620,
    app_flat_1: 194,
    char_2: 'tribbie',
    app_rate_2: '54.16%',
    avg_round_2: 3677,
    app_flat_2: 130,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '36.67%',
    avg_round_3: 3595,
    app_flat_3: 102,
    char_4: 'sunday',
    app_rate_4: '19.62%',
    avg_round_4: 3482,
    app_flat_4: 40,
    char_5: 'huohuo',
    app_rate_5: '14.93%',
    avg_round_5: 3467,
    app_flat_5: 42,
    char_6: 'aventurine',
    app_rate_6: '14.07%',
    avg_round_6: 3512,
    app_flat_6: 28,
    char_7: 'robin',
    app_rate_7: '14.07%',
    avg_round_7: 3466,
    app_flat_7: 30,
    char_8: 'lingsha',
    app_rate_8: '13.43%',
    avg_round_8: 3643,
    app_flat_8: 31,
    char_9: 'gallagher',
    app_rate_9: '10.87%',
    avg_round_9: 3573,
    app_flat_9: 37,
    char_10: 'luocha',
    app_rate_10: '8.96%',
    avg_round_10: 3507,
    app_flat_10: 22,
    char_11: 'ruan-mei',
    app_rate_11: '6.4%',
    avg_round_11: 3470,
    app_flat_11: 14,
    char_12: 'sparkle',
    app_rate_12: '5.76%',
    avg_round_12: 3476,
    app_flat_12: 13,
    char_13: 'tingyun',
    app_rate_13: '5.12%',
    avg_round_13: 3305,
    app_flat_13: 12,
    char_14: 'fu-xuan',
    app_rate_14: '5.12%',
    avg_round_14: 3488,
    app_flat_14: 7,
    char_15: 'jade',
    app_rate_15: '3.41%',
    avg_round_15: 3482,
    app_flat_15: 5,
    char_16: 'pela',
    app_rate_16: '1.92%',
    avg_round_16: 3562,
    app_flat_16: 6,
    char_17: 'jiaoqiu',
    app_rate_17: '1.71%',
    avg_round_17: 3159,
    app_flat_17: 1,
    char_18: 'feixiao',
    app_rate_18: '1.49%',
    avg_round_18: 3338,
    app_flat_18: 2,
    char_19: 'boothill',
    app_rate_19: '1.49%',
    avg_round_19: 3340,
    app_flat_19: 3,
    char_20: 'bronya',
    app_rate_20: '1.28%',
    avg_round_20: 3283,
    app_flat_20: 2,
    char_21: 'rappa',
    app_rate_21: '0.85%',
    avg_round_21: 3617,
    app_flat_21: 1,
    char_22: 'tingyun-fugue',
    app_rate_22: '0.64%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'acheron',
    app_rate_23: '0.64%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'serval',
    app_rate_24: '0.43%',
    avg_round_24: 3646,
    app_flat_24: 1,
    char_25: 'blade',
    app_rate_25: '0.43%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'bailu',
    app_rate_26: '0.43%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'trailblazer-harmony',
    app_rate_27: '0.43%',
    avg_round_27: 3617,
    app_flat_27: 1,
    char_28: 'welt',
    app_rate_28: '0.21%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th',
    app_rate_29: '0.21%',
    avg_round_29: 3508,
    app_flat_29: 1,
    char_30: 'moze',
    app_rate_30: '0.21%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 2.75,
    char_1: 'feixiao',
    app_rate_1: '96.26%',
    avg_round_1: 3345,
    app_flat_1: 235,
    char_2: 'robin',
    app_rate_2: '85.04%',
    avg_round_2: 3352,
    app_flat_2: 205,
    char_3: 'aventurine',
    app_rate_3: '69.83%',
    avg_round_3: 3349,
    app_flat_3: 175,
    char_4: 'fu-xuan',
    app_rate_4: '6.98%',
    avg_round_4: 3234,
    app_flat_4: 14,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '5.74%',
    avg_round_5: 3314,
    app_flat_5: 16,
    char_6: 'gallagher',
    app_rate_6: '5.24%',
    avg_round_6: 3306,
    app_flat_6: 13,
    char_7: 'luocha',
    app_rate_7: '5.24%',
    avg_round_7: 3255,
    app_flat_7: 14,
    char_8: 'lingsha',
    app_rate_8: '4.24%',
    avg_round_8: 3353,
    app_flat_8: 11,
    char_9: 'tribbie',
    app_rate_9: '3.49%',
    avg_round_9: 3323,
    app_flat_9: 8,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '2.74%',
    avg_round_10: 3380,
    app_flat_10: 5,
    char_11: 'sunday',
    app_rate_11: '2.49%',
    avg_round_11: 3282,
    app_flat_11: 6,
    char_12: 'ruan-mei',
    app_rate_12: '2.24%',
    avg_round_12: 3459,
    app_flat_12: 5,
    char_13: 'topaz',
    app_rate_13: '1.5%',
    avg_round_13: 3348,
    app_flat_13: 3,
    char_14: 'bronya',
    app_rate_14: '1.5%',
    avg_round_14: 3240,
    app_flat_14: 2,
    char_15: 'huohuo',
    app_rate_15: '1.25%',
    avg_round_15: 3213,
    app_flat_15: 1,
    char_16: 'herta',
    app_rate_16: '1.0%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'dr-ratio',
    app_rate_17: '1.0%',
    avg_round_17: 3115,
    app_flat_17: 2,
    char_18: 'jing-yuan',
    app_rate_18: '0.75%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'gepard',
    app_rate_19: '0.5%',
    avg_round_19: 3293,
    app_flat_19: 1,
    char_20: 'yunli',
    app_rate_20: '0.5%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'blade',
    app_rate_21: '0.5%',
    avg_round_21: 3362,
    app_flat_21: 1,
    char_22: 'the-herta',
    app_rate_22: '0.25%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'bailu',
    app_rate_23: '0.25%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.25%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'argenti',
    app_rate_25: '0.25%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'jade',
    app_rate_26: '0.25%',
    avg_round_26: 3236,
    app_flat_26: 1,
    char_27: 'sparkle',
    app_rate_27: '0.25%',
    avg_round_27: 3140,
    app_flat_27: 1,
    char_28: 'lynx',
    app_rate_28: '0.25%',
    avg_round_28: 3288,
    app_flat_28: 1,
    char_29: 'clara',
    app_rate_29: '0.25%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 2.59,
    char_1: 'acheron',
    app_rate_1: '65.25%',
    avg_round_1: 3241,
    app_flat_1: 81,
    char_2: 'sparkle',
    app_rate_2: '42.18%',
    avg_round_2: 3538,
    app_flat_2: 9,
    char_3: 'fu-xuan',
    app_rate_3: '30.77%',
    avg_round_3: 3184,
    app_flat_3: 23,
    char_4: 'pela',
    app_rate_4: '28.12%',
    avg_round_4: 3233,
    app_flat_4: 63,
    char_5: 'aventurine',
    app_rate_5: '28.12%',
    avg_round_5: 3241,
    app_flat_5: 43,
    char_6: 'seele',
    app_rate_6: '25.73%',
    avg_round_6: 3562,
    app_flat_6: 14,
    char_7: 'robin',
    app_rate_7: '16.18%',
    avg_round_7: 3524,
    app_flat_7: 9,
    char_8: 'jiaoqiu',
    app_rate_8: '10.34%',
    avg_round_8: 3302,
    app_flat_8: 14,
    char_9: 'sunday',
    app_rate_9: '7.16%',
    avg_round_9: 3516,
    app_flat_9: 4,
    char_10: 'tribbie',
    app_rate_10: '5.57%',
    avg_round_10: 3527,
    app_flat_10: 5,
    char_11: 'gallagher',
    app_rate_11: '5.57%',
    avg_round_11: 3162,
    app_flat_11: 5,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '5.04%',
    avg_round_12: 3410,
    app_flat_12: 5,
    char_13: 'qingque',
    app_rate_13: '3.98%',
    avg_round_13: 3185,
    app_flat_13: 1,
    char_14: 'bronya',
    app_rate_14: '3.71%',
    avg_round_14: 3298,
    app_flat_14: 1,
    char_15: 'ruan-mei',
    app_rate_15: '3.71%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'tingyun',
    app_rate_16: '2.92%',
    avg_round_16: 3610,
    app_flat_16: 4,
    char_17: 'lingsha',
    app_rate_17: '2.92%',
    avg_round_17: 3218,
    app_flat_17: 4,
    char_18: 'imbibitor-lunae',
    app_rate_18: '1.59%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'huohuo',
    app_rate_19: '1.33%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'luocha',
    app_rate_20: '1.06%',
    avg_round_20: 3125,
    app_flat_20: 1,
    char_21: 'gepard',
    app_rate_21: '1.06%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'firefly',
    app_rate_22: '0.8%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.8%',
    avg_round_23: 3290,
    app_flat_23: 2,
    char_24: 'dr-ratio',
    app_rate_24: '0.53%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jade',
    app_rate_25: '0.53%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'trailblazer-harmony',
    app_rate_26: '0.53%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'boothill',
    app_rate_27: '0.53%',
    avg_round_27: 3298,
    app_flat_27: 1,
    char_28: 'march-7th',
    app_rate_28: '0.53%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'black-swan',
    app_rate_29: '0.53%',
    avg_round_29: 3205,
    app_flat_29: 2,
    char_30: 'kafka',
    app_rate_30: '0.27%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jingliu',
    app: 2.25,
    char_1: 'sunday',
    app_rate_1: '44.34%',
    avg_round_1: 3380,
    app_flat_1: 57,
    char_2: 'ruan-mei',
    app_rate_2: '41.28%',
    avg_round_2: 3366,
    app_flat_2: 44,
    char_3: 'robin',
    app_rate_3: '40.98%',
    avg_round_3: 3410,
    app_flat_3: 43,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '29.66%',
    avg_round_4: 3296,
    app_flat_4: 31,
    char_5: 'bronya',
    app_rate_5: '25.38%',
    avg_round_5: 3312,
    app_flat_5: 30,
    char_6: 'huohuo',
    app_rate_6: '24.46%',
    avg_round_6: 3290,
    app_flat_6: 22,
    char_7: 'tribbie',
    app_rate_7: '12.54%',
    avg_round_7: 3315,
    app_flat_7: 9,
    char_8: 'luocha',
    app_rate_8: '11.62%',
    avg_round_8: 3299,
    app_flat_8: 16,
    char_9: 'sparkle',
    app_rate_9: '11.31%',
    avg_round_9: 3259,
    app_flat_9: 9,
    char_10: 'blade',
    app_rate_10: '11.01%',
    avg_round_10: 3313,
    app_flat_10: 13,
    char_11: 'fu-xuan',
    app_rate_11: '9.17%',
    avg_round_11: 3222,
    app_flat_11: 9,
    char_12: 'gallagher',
    app_rate_12: '7.95%',
    avg_round_12: 3278,
    app_flat_12: 12,
    char_13: 'aventurine',
    app_rate_13: '7.34%',
    avg_round_13: 3248,
    app_flat_13: 12,
    char_14: 'tingyun',
    app_rate_14: '6.12%',
    avg_round_14: 3343,
    app_flat_14: 9,
    char_15: 'pela',
    app_rate_15: '4.59%',
    avg_round_15: 3346,
    app_flat_15: 5,
    char_16: 'lingsha',
    app_rate_16: '3.67%',
    avg_round_16: 3256,
    app_flat_16: 4,
    char_17: 'bailu',
    app_rate_17: '2.14%',
    avg_round_17: 3023,
    app_flat_17: 4,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '1.53%',
    avg_round_18: 3300,
    app_flat_18: 2,
    char_19: 'feixiao',
    app_rate_19: '0.92%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'herta',
    app_rate_20: '0.92%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jade',
    app_rate_21: '0.61%',
    avg_round_21: 3063,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '0.31%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'the-herta',
    app_rate_23: '0.31%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'gepard',
    app_rate_24: '0.31%',
    avg_round_24: 3429,
    app_flat_24: 1,
    char_25: 'firefly',
    app_rate_25: '0.31%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'boothill',
    app_rate_26: '0.31%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'jing-yuan',
    app_rate_27: '0.31%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'silver-wolf',
    app_rate_28: '0.31%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'tingyun',
    app: 2.25,
    char_1: 'sunday',
    app_rate_1: '39.94%',
    avg_round_1: 3398,
    app_flat_1: 59,
    char_2: 'robin',
    app_rate_2: '30.79%',
    avg_round_2: 3340,
    app_flat_2: 42,
    char_3: 'huohuo',
    app_rate_3: '30.49%',
    avg_round_3: 3234,
    app_flat_3: 47,
    char_4: 'yunli',
    app_rate_4: '28.96%',
    avg_round_4: 3224,
    app_flat_4: 40,
    char_5: 'jing-yuan',
    app_rate_5: '23.17%',
    avg_round_5: 3389,
    app_flat_5: 26,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '21.34%',
    avg_round_6: 3412,
    app_flat_6: 33,
    char_7: 'sparkle',
    app_rate_7: '13.11%',
    avg_round_7: 3270,
    app_flat_7: 13,
    char_8: 'aglaea',
    app_rate_8: '12.8%',
    avg_round_8: 3409,
    app_flat_8: 21,
    char_9: 'aventurine',
    app_rate_9: '10.98%',
    avg_round_9: 3311,
    app_flat_9: 16,
    char_10: 'argenti',
    app_rate_10: '7.32%',
    avg_round_10: 3305,
    app_flat_10: 12,
    char_11: 'tribbie',
    app_rate_11: '7.32%',
    avg_round_11: 3569,
    app_flat_11: 6,
    char_12: 'fu-xuan',
    app_rate_12: '7.01%',
    avg_round_12: 3128,
    app_flat_12: 5,
    char_13: 'jingliu',
    app_rate_13: '6.1%',
    avg_round_13: 3343,
    app_flat_13: 9,
    char_14: 'luocha',
    app_rate_14: '5.49%',
    avg_round_14: 3260,
    app_flat_14: 4,
    char_15: 'ruan-mei',
    app_rate_15: '5.49%',
    avg_round_15: 3427,
    app_flat_15: 5,
    char_16: 'serval',
    app_rate_16: '4.57%',
    avg_round_16: 3426,
    app_flat_16: 12,
    char_17: 'bronya',
    app_rate_17: '4.57%',
    avg_round_17: 3385,
    app_flat_17: 7,
    char_18: 'seele',
    app_rate_18: '3.96%',
    avg_round_18: 3587,
    app_flat_18: 5,
    char_19: 'the-herta',
    app_rate_19: '3.96%',
    avg_round_19: 3619,
    app_flat_19: 8,
    char_20: 'gallagher',
    app_rate_20: '3.96%',
    avg_round_20: 3296,
    app_flat_20: 10,
    char_21: 'imbibitor-lunae',
    app_rate_21: '3.66%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: '3.35%',
    avg_round_22: 3610,
    app_flat_22: 4,
    char_23: 'lingsha',
    app_rate_23: '3.05%',
    avg_round_23: 3325,
    app_flat_23: 5,
    char_24: 'jade',
    app_rate_24: '2.44%',
    avg_round_24: 3542,
    app_flat_24: 3,
    char_25: 'bailu',
    app_rate_25: '1.83%',
    avg_round_25: 3082,
    app_flat_25: 2,
    char_26: 'kafka',
    app_rate_26: '1.22%',
    avg_round_26: 3350,
    app_flat_26: 2,
    char_27: 'pela',
    app_rate_27: '1.22%',
    avg_round_27: 3429,
    app_flat_27: 3,
    char_28: 'black-swan',
    app_rate_28: '0.91%',
    avg_round_28: 3356,
    app_flat_28: 1,
    char_29: 'feixiao',
    app_rate_29: '0.91%',
    avg_round_29: 3577,
    app_flat_29: 1,
    char_30: 'yanqing',
    app_rate_30: '0.91%',
    avg_round_30: 3360,
    app_flat_30: 2
  },
  {
    char: 'blade',
    app: 1.75,
    char_1: 'sunday',
    app_rate_1: '37.65%',
    avg_round_1: 3355,
    app_flat_1: 36,
    char_2: 'ruan-mei',
    app_rate_2: '31.76%',
    avg_round_2: 3357,
    app_flat_2: 25,
    char_3: 'bronya',
    app_rate_3: '30.2%',
    avg_round_3: 3365,
    app_flat_3: 30,
    char_4: 'robin',
    app_rate_4: '29.02%',
    avg_round_4: 3452,
    app_flat_4: 26,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '28.63%',
    avg_round_5: 3371,
    app_flat_5: 30,
    char_6: 'tribbie',
    app_rate_6: '23.53%',
    avg_round_6: 3386,
    app_flat_6: 19,
    char_7: 'luocha',
    app_rate_7: '18.04%',
    avg_round_7: 3240,
    app_flat_7: 14,
    char_8: 'sparkle',
    app_rate_8: '17.25%',
    avg_round_8: 3363,
    app_flat_8: 10,
    char_9: 'jingliu',
    app_rate_9: '14.12%',
    avg_round_9: 3313,
    app_flat_9: 13,
    char_10: 'huohuo',
    app_rate_10: '13.73%',
    avg_round_10: 3261,
    app_flat_10: 8,
    char_11: 'jade',
    app_rate_11: '13.33%',
    avg_round_11: 3497,
    app_flat_11: 6,
    char_12: 'fu-xuan',
    app_rate_12: '9.8%',
    avg_round_12: 3260,
    app_flat_12: 4,
    char_13: 'lynx',
    app_rate_13: '5.49%',
    avg_round_13: 3228,
    app_flat_13: 6,
    char_14: 'gallagher',
    app_rate_14: '3.92%',
    avg_round_14: 3217,
    app_flat_14: 5,
    char_15: 'lingsha',
    app_rate_15: '3.53%',
    avg_round_15: 3222,
    app_flat_15: 5,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '3.53%',
    avg_round_16: 3492,
    app_flat_16: 6,
    char_17: 'feixiao',
    app_rate_17: '2.75%',
    avg_round_17: 3385,
    app_flat_17: 2,
    char_18: 'the-herta',
    app_rate_18: '2.35%',
    avg_round_18: 3565,
    app_flat_18: 3,
    char_19: 'pela',
    app_rate_19: '1.57%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'yunli',
    app_rate_20: '1.57%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'herta',
    app_rate_21: '0.78%',
    avg_round_21: 3525,
    app_flat_21: 1,
    char_22: 'bailu',
    app_rate_22: '0.78%',
    avg_round_22: 3136,
    app_flat_22: 1,
    char_23: 'boothill',
    app_rate_23: '0.78%',
    avg_round_23: 3505,
    app_flat_23: 1,
    char_24: 'argenti',
    app_rate_24: '0.78%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jing-yuan',
    app_rate_25: '0.78%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'aventurine',
    app_rate_26: '0.78%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'moze',
    app_rate_27: '0.78%',
    avg_round_27: 3362,
    app_flat_27: 1,
    char_28: 'jiaoqiu',
    app_rate_28: '0.39%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'tingyun',
    app_rate_29: '0.39%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'qingque',
    app_rate_30: '0.39%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'mydei',
    app: 1.49,
    char_1: 'sunday',
    app_rate_1: '64.98%',
    avg_round_1: 3406,
    app_flat_1: 39,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '53.92%',
    avg_round_2: 3385,
    app_flat_2: 36,
    char_3: 'luocha',
    app_rate_3: '47.0%',
    avg_round_3: 3405,
    app_flat_3: 29,
    char_4: 'tribbie',
    app_rate_4: '40.09%',
    avg_round_4: 3451,
    app_flat_4: 29,
    char_5: 'gallagher',
    app_rate_5: '24.88%',
    avg_round_5: 3396,
    app_flat_5: 16,
    char_6: 'bronya',
    app_rate_6: '13.82%',
    avg_round_6: 3369,
    app_flat_6: 7,
    char_7: 'sparkle',
    app_rate_7: '11.98%',
    avg_round_7: 3408,
    app_flat_7: 9,
    char_8: 'huohuo',
    app_rate_8: '9.68%',
    avg_round_8: 3316,
    app_flat_8: 7,
    char_9: 'jiaoqiu',
    app_rate_9: '9.22%',
    avg_round_9: 3443,
    app_flat_9: 3,
    char_10: 'ruan-mei',
    app_rate_10: '6.91%',
    avg_round_10: 3232,
    app_flat_10: 1,
    char_11: 'lingsha',
    app_rate_11: '5.53%',
    avg_round_11: 3392,
    app_flat_11: 8,
    char_12: 'herta',
    app_rate_12: '1.84%',
    avg_round_12: 3402,
    app_flat_12: 2,
    char_13: 'pela',
    app_rate_13: '1.84%',
    avg_round_13: 3178,
    app_flat_13: 1,
    char_14: 'jade',
    app_rate_14: '1.84%',
    avg_round_14: 3654,
    app_flat_14: 1,
    char_15: 'aventurine',
    app_rate_15: '1.38%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'the-herta',
    app_rate_16: '1.38%',
    avg_round_16: 3574,
    app_flat_16: 2,
    char_17: 'lynx',
    app_rate_17: '0.92%',
    avg_round_17: 3450,
    app_flat_17: 1,
    char_18: 'robin',
    app_rate_18: '0.92%',
    avg_round_18: 3308,
    app_flat_18: 2,
    char_19: 'himeko',
    app_rate_19: '0.46%',
    avg_round_19: 3184,
    app_flat_19: 1,
    char_20: 'bailu',
    app_rate_20: '0.46%',
    avg_round_20: 3066,
    app_flat_20: 1,
    char_21: 'argenti',
    app_rate_21: '0.46%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'tingyun',
    app_rate_22: '0.46%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'himeko',
    app: 1.2,
    char_1: 'the-herta',
    app_rate_1: '63.79%',
    avg_round_1: 3503,
    app_flat_1: 66,
    char_2: 'tribbie',
    app_rate_2: '35.06%',
    avg_round_2: 3593,
    app_flat_2: 29,
    char_3: 'lingsha',
    app_rate_3: '31.61%',
    avg_round_3: 3371,
    app_flat_3: 35,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '25.86%',
    avg_round_4: 3541,
    app_flat_4: 32,
    char_5: 'tingyun-fugue',
    app_rate_5: '22.99%',
    avg_round_5: 3297,
    app_flat_5: 16,
    char_6: 'ruan-mei',
    app_rate_6: '13.79%',
    avg_round_6: 3271,
    app_flat_6: 10,
    char_7: 'aventurine',
    app_rate_7: '13.22%',
    avg_round_7: 3435,
    app_flat_7: 15,
    char_8: 'gallagher',
    app_rate_8: '11.49%',
    avg_round_8: 3382,
    app_flat_8: 7,
    char_9: 'huohuo',
    app_rate_9: '8.05%',
    avg_round_9: 3384,
    app_flat_9: 7,
    char_10: 'acheron',
    app_rate_10: '8.05%',
    avg_round_10: 3272,
    app_flat_10: 3,
    char_11: 'sunday',
    app_rate_11: '6.9%',
    avg_round_11: 3407,
    app_flat_11: 7,
    char_12: 'trailblazer-harmony',
    app_rate_12: '6.9%',
    avg_round_12: 3254,
    app_flat_12: 6,
    char_13: 'rappa',
    app_rate_13: '6.9%',
    avg_round_13: 3398,
    app_flat_13: 5,
    char_14: 'robin',
    app_rate_14: '6.32%',
    avg_round_14: 3440,
    app_flat_14: 5,
    char_15: 'jiaoqiu',
    app_rate_15: '6.32%',
    avg_round_15: 3254,
    app_flat_15: 1,
    char_16: 'fu-xuan',
    app_rate_16: '6.32%',
    avg_round_16: 3334,
    app_flat_16: 6,
    char_17: 'luocha',
    app_rate_17: '5.17%',
    avg_round_17: 3394,
    app_flat_17: 4,
    char_18: 'jade',
    app_rate_18: '4.02%',
    avg_round_18: 3429,
    app_flat_18: 5,
    char_19: 'herta',
    app_rate_19: '4.02%',
    avg_round_19: 3303,
    app_flat_19: 4,
    char_20: 'firefly',
    app_rate_20: '3.45%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'sparkle',
    app_rate_21: '2.87%',
    avg_round_21: 3295,
    app_flat_21: 3,
    char_22: 'pela',
    app_rate_22: '1.72%',
    avg_round_22: 3192,
    app_flat_22: 2,
    char_23: 'serval',
    app_rate_23: '1.72%',
    avg_round_23: 3787,
    app_flat_23: 1,
    char_24: 'bailu',
    app_rate_24: '1.15%',
    avg_round_24: 3410,
    app_flat_24: 2,
    char_25: 'tingyun',
    app_rate_25: '0.57%',
    avg_round_25: 3615,
    app_flat_25: 1,
    char_26: 'mydei',
    app_rate_26: '0.57%',
    avg_round_26: 3184,
    app_flat_26: 1,
    char_27: 'argenti',
    app_rate_27: '0.57%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'feixiao',
    app_rate_28: '0.57%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.95,
    char_1: 'robin',
    app_rate_1: '31.65%',
    avg_round_1: 3389,
    app_flat_1: 24,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '30.22%',
    avg_round_2: 3325,
    app_flat_2: 25,
    char_3: 'the-herta',
    app_rate_3: '27.34%',
    avg_round_3: 3383,
    app_flat_3: 25,
    char_4: 'sunday',
    app_rate_4: '23.74%',
    avg_round_4: 3363,
    app_flat_4: 20,
    char_5: 'feixiao',
    app_rate_5: '17.99%',
    avg_round_5: 3383,
    app_flat_5: 18,
    char_6: 'aglaea',
    app_rate_6: '16.55%',
    avg_round_6: 3436,
    app_flat_6: 11,
    char_7: 'tribbie',
    app_rate_7: '15.11%',
    avg_round_7: 3326,
    app_flat_7: 9,
    char_8: 'herta',
    app_rate_8: '14.39%',
    avg_round_8: 3363,
    app_flat_8: 13,
    char_9: 'ruan-mei',
    app_rate_9: '13.67%',
    avg_round_9: 3225,
    app_flat_9: 4,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '11.51%',
    avg_round_10: 3361,
    app_flat_10: 14,
    char_11: 'kafka',
    app_rate_11: '9.35%',
    avg_round_11: 3098,
    app_flat_11: 4,
    char_12: 'black-swan',
    app_rate_12: '9.35%',
    avg_round_12: 3098,
    app_flat_12: 4,
    char_13: 'jade',
    app_rate_13: '8.63%',
    avg_round_13: 3535,
    app_flat_13: 5,
    char_14: 'jing-yuan',
    app_rate_14: '7.91%',
    avg_round_14: 3250,
    app_flat_14: 7,
    char_15: 'bronya',
    app_rate_15: '7.19%',
    avg_round_15: 3203,
    app_flat_15: 5,
    char_16: 'acheron',
    app_rate_16: '7.19%',
    avg_round_16: 3194,
    app_flat_16: 3,
    char_17: 'sparkle',
    app_rate_17: '6.47%',
    avg_round_17: 3284,
    app_flat_17: 4,
    char_18: 'jingliu',
    app_rate_18: '5.04%',
    avg_round_18: 3023,
    app_flat_18: 4,
    char_19: 'tingyun',
    app_rate_19: '4.32%',
    avg_round_19: 3082,
    app_flat_19: 2,
    char_20: 'trailblazer-harmony',
    app_rate_20: '4.32%',
    avg_round_20: 3379,
    app_flat_20: 1,
    char_21: 'serval',
    app_rate_21: '3.6%',
    avg_round_21: 3356,
    app_flat_21: 4,
    char_22: 'pela',
    app_rate_22: '3.6%',
    avg_round_22: 3419,
    app_flat_22: 1,
    char_23: 'firefly',
    app_rate_23: '2.88%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'rappa',
    app_rate_24: '2.16%',
    avg_round_24: 3222,
    app_flat_24: 1,
    char_25: 'boothill',
    app_rate_25: '2.16%',
    avg_round_25: 3379,
    app_flat_25: 1,
    char_26: 'topaz',
    app_rate_26: '2.16%',
    avg_round_26: 3326,
    app_flat_26: 2,
    char_27: 'imbibitor-lunae',
    app_rate_27: '1.44%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'blade',
    app_rate_28: '1.44%',
    avg_round_28: 3136,
    app_flat_28: 1,
    char_29: 'jiaoqiu',
    app_rate_29: '1.44%',
    avg_round_29: 3419,
    app_flat_29: 1,
    char_30: 'himeko',
    app_rate_30: '1.44%',
    avg_round_30: 3410,
    app_flat_30: 2
  },
  {
    char: 'seele',
    app: 0.9,
    char_1: 'silver-wolf',
    app_rate_1: '74.05%',
    avg_round_1: 3562,
    app_flat_1: 14,
    char_2: 'sparkle',
    app_rate_2: '61.83%',
    avg_round_2: 3550,
    app_flat_2: 8,
    char_3: 'robin',
    app_rate_3: '61.07%',
    avg_round_3: 3564,
    app_flat_3: 13,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '25.19%',
    avg_round_4: 3542,
    app_flat_4: 8,
    char_5: 'fu-xuan',
    app_rate_5: '16.03%',
    avg_round_5: 3367,
    app_flat_5: 1,
    char_6: 'sunday',
    app_rate_6: '16.03%',
    avg_round_6: 3506,
    app_flat_6: 3,
    char_7: 'tingyun',
    app_rate_7: '9.92%',
    avg_round_7: 3587,
    app_flat_7: 5,
    char_8: 'feixiao',
    app_rate_8: '8.4%',
    avg_round_8: 3755,
    app_flat_8: 4,
    char_9: 'ruan-mei',
    app_rate_9: '7.63%',
    avg_round_9: 3628,
    app_flat_9: 2,
    char_10: 'bronya',
    app_rate_10: '6.11%',
    avg_round_10: 3696,
    app_flat_10: 1,
    char_11: 'tribbie',
    app_rate_11: '5.34%',
    avg_round_11: 3590,
    app_flat_11: 2,
    char_12: 'pela',
    app_rate_12: '3.05%',
    avg_round_12: 3496,
    app_flat_12: 1,
    char_13: 'luocha',
    app_rate_13: '1.53%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'huohuo',
    app_rate_14: '1.53%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '0.76%',
    avg_round_15: 3353,
    app_flat_15: 1,
    char_16: 'lynx',
    app_rate_16: '0.76%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'herta',
    app_rate_17: '0.76%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 0.89,
    char_1: 'acheron',
    app_rate_1: '56.92%',
    avg_round_1: 3209,
    app_flat_1: 12,
    char_2: 'pela',
    app_rate_2: '36.92%',
    avg_round_2: 3207,
    app_flat_2: 10,
    char_3: 'robin',
    app_rate_3: '19.23%',
    avg_round_3: 3384,
    app_flat_3: 15,
    char_4: 'black-swan',
    app_rate_4: '16.15%',
    avg_round_4: 3129,
    app_flat_4: 5,
    char_5: 'sunday',
    app_rate_5: '15.38%',
    avg_round_5: 3443,
    app_flat_5: 8,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '15.38%',
    avg_round_6: 3395,
    app_flat_6: 11,
    char_7: 'feixiao',
    app_rate_7: '14.62%',
    avg_round_7: 3337,
    app_flat_7: 14,
    char_8: 'sparkle',
    app_rate_8: '13.08%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '11.54%',
    avg_round_9: 3354,
    app_flat_9: 12,
    char_10: 'jiaoqiu',
    app_rate_10: '11.54%',
    avg_round_10: 3385,
    app_flat_10: 2,
    char_11: 'tribbie',
    app_rate_11: '11.54%',
    avg_round_11: 3349,
    app_flat_11: 7,
    char_12: 'the-herta',
    app_rate_12: '10.77%',
    avg_round_12: 3442,
    app_flat_12: 12,
    char_13: 'kafka',
    app_rate_13: '10.0%',
    avg_round_13: 3219,
    app_flat_13: 3,
    char_14: 'bronya',
    app_rate_14: '8.46%',
    avg_round_14: 3335,
    app_flat_14: 3,
    char_15: 'ruan-mei',
    app_rate_15: '7.69%',
    avg_round_15: 3363,
    app_flat_15: 5,
    char_16: 'aglaea',
    app_rate_16: '5.38%',
    avg_round_16: 3534,
    app_flat_16: 3,
    char_17: 'herta',
    app_rate_17: '5.38%',
    avg_round_17: 3400,
    app_flat_17: 6,
    char_18: 'boothill',
    app_rate_18: '4.62%',
    avg_round_18: 3424,
    app_flat_18: 3,
    char_19: 'tingyun-fugue',
    app_rate_19: '3.85%',
    avg_round_19: 3463,
    app_flat_19: 1,
    char_20: 'silver-wolf',
    app_rate_20: '3.08%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jade',
    app_rate_21: '3.08%',
    avg_round_21: 3515,
    app_flat_21: 3,
    char_22: 'jing-yuan',
    app_rate_22: '3.08%',
    avg_round_22: 3382,
    app_flat_22: 2,
    char_23: 'guinaifen',
    app_rate_23: '2.31%',
    avg_round_23: 3165,
    app_flat_23: 1,
    char_24: 'serval',
    app_rate_24: '2.31%',
    avg_round_24: 3452,
    app_flat_24: 3,
    char_25: 'moze',
    app_rate_25: '1.54%',
    avg_round_25: 3293,
    app_flat_25: 1,
    char_26: 'firefly',
    app_rate_26: '1.54%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'tingyun',
    app_rate_27: '1.54%',
    avg_round_27: 3336,
    app_flat_27: 1,
    char_28: 'luocha',
    app_rate_28: '0.77%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.77%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'jingliu',
    app_rate_30: '0.77%',
    avg_round_30: 3429,
    app_flat_30: 1
  },
  {
    char: 'imbibitor-lunae',
    app: 0.82,
    char_1: 'sparkle',
    app_rate_1: '60.0%',
    avg_round_1: 3078,
    app_flat_1: 3,
    char_2: 'sunday',
    app_rate_2: '45.83%',
    avg_round_2: 3279,
    app_flat_2: 1,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '35.0%',
    avg_round_3: 2977,
    app_flat_3: 2,
    char_4: 'aventurine',
    app_rate_4: '30.0%',
    avg_round_4: 3279,
    app_flat_4: 1,
    char_5: 'luocha',
    app_rate_5: '25.0%',
    avg_round_5: 2921,
    app_flat_5: 1,
    char_6: 'tribbie',
    app_rate_6: '22.5%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'huohuo',
    app_rate_7: '20.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '11.67%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tingyun',
    app_rate_9: '10.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'robin',
    app_rate_10: '9.17%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'gallagher',
    app_rate_11: '9.17%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'fu-xuan',
    app_rate_12: '5.0%',
    avg_round_12: 3033,
    app_flat_12: 1,
    char_13: 'silver-wolf',
    app_rate_13: '5.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jiaoqiu',
    app_rate_14: '4.17%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'bailu',
    app_rate_15: '1.67%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'lingsha',
    app_rate_16: '1.67%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'boothill',
    app_rate_17: '0.83%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'pela',
    app_rate_18: '0.83%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jade',
    app_rate_19: '0.83%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.83%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'lynx',
    app_rate_21: '0.83%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 0.49,
    char_1: 'robin',
    app_rate_1: '66.2%',
    avg_round_1: 3211,
    app_flat_1: 14,
    char_2: 'aventurine',
    app_rate_2: '42.25%',
    avg_round_2: 3162,
    app_flat_2: 11,
    char_3: 'feixiao',
    app_rate_3: '33.8%',
    avg_round_3: 3214,
    app_flat_3: 9,
    char_4: 'tribbie',
    app_rate_4: '22.54%',
    avg_round_4: 3292,
    app_flat_4: 4,
    char_5: 'huohuo',
    app_rate_5: '16.9%',
    avg_round_5: 3217,
    app_flat_5: 3,
    char_6: 'sparkle',
    app_rate_6: '15.49%',
    avg_round_6: 3033,
    app_flat_6: 1,
    char_7: 'march-7th-swordmaster',
    app_rate_7: '14.08%',
    avg_round_7: 3121,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: '12.68%',
    avg_round_8: 3243,
    app_flat_8: 1,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '11.27%',
    avg_round_9: 3240,
    app_flat_9: 2,
    char_10: 'sunday',
    app_rate_10: '9.86%',
    avg_round_10: 3324,
    app_flat_10: 2,
    char_11: 'lingsha',
    app_rate_11: '8.45%',
    avg_round_11: 2961,
    app_flat_11: 1,
    char_12: 'yunli',
    app_rate_12: '7.04%',
    avg_round_12: 2961,
    app_flat_12: 1,
    char_13: 'gallagher',
    app_rate_13: '5.63%',
    avg_round_13: 3205,
    app_flat_13: 3,
    char_14: 'luocha',
    app_rate_14: '4.23%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'the-herta',
    app_rate_15: '2.82%',
    avg_round_15: 3743,
    app_flat_15: 1,
    char_16: 'tingyun',
    app_rate_16: '2.82%',
    avg_round_16: 3033,
    app_flat_16: 1,
    char_17: 'jing-yuan',
    app_rate_17: '2.82%',
    avg_round_17: 3324,
    app_flat_17: 2,
    char_18: 'ruan-mei',
    app_rate_18: '2.82%',
    avg_round_18: 3215,
    app_flat_18: 1,
    char_19: 'boothill',
    app_rate_19: '2.82%',
    avg_round_19: 3215,
    app_flat_19: 1,
    char_20: 'firefly',
    app_rate_20: '1.41%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.41%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'topaz',
    app_rate_22: '1.41%',
    avg_round_22: 3139,
    app_flat_22: 1,
    char_23: 'jade',
    app_rate_23: '1.41%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '1.41%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'acheron',
    app_rate_25: '1.41%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'black-swan',
    app_rate_26: '1.41%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'herta',
    app_rate_27: '1.41%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'moze',
    app_rate_28: '1.41%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'bronya',
    app_rate_29: '1.41%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'serval',
    app_rate_30: '1.41%',
    avg_round_30: 3743,
    app_flat_30: 1
  },
  {
    char: 'guinaifen',
    app: 0.34,
    char_1: 'acheron',
    app_rate_1: '95.92%',
    avg_round_1: 3284,
    app_flat_1: 28,
    char_2: 'pela',
    app_rate_2: '69.39%',
    avg_round_2: 3287,
    app_flat_2: 26,
    char_3: 'aventurine',
    app_rate_3: '40.82%',
    avg_round_3: 3275,
    app_flat_3: 14,
    char_4: 'fu-xuan',
    app_rate_4: '22.45%',
    avg_round_4: 3298,
    app_flat_4: 4,
    char_5: 'gallagher',
    app_rate_5: '12.24%',
    avg_round_5: 3241,
    app_flat_5: 5,
    char_6: 'jiaoqiu',
    app_rate_6: '8.16%',
    avg_round_6: 3365,
    app_flat_6: 1,
    char_7: 'kafka',
    app_rate_7: '8.16%',
    avg_round_7: 3226,
    app_flat_7: 2,
    char_8: 'black-swan',
    app_rate_8: '8.16%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'gepard',
    app_rate_9: '6.12%',
    avg_round_9: 3165,
    app_flat_9: 1,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '6.12%',
    avg_round_10: 3551,
    app_flat_10: 1,
    char_11: 'lingsha',
    app_rate_11: '6.12%',
    avg_round_11: 3136,
    app_flat_11: 1,
    char_12: 'bronya',
    app_rate_12: '4.08%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'tribbie',
    app_rate_13: '4.08%',
    avg_round_13: 3564,
    app_flat_13: 1,
    char_14: 'sparkle',
    app_rate_14: '2.04%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'huohuo',
    app_rate_15: '2.04%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'firefly',
    app_rate_16: '2.04%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'tingyun-fugue',
    app_rate_17: '2.04%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.28,
    char_1: 'blade',
    app_rate_1: '34.15%',
    avg_round_1: 3228,
    app_flat_1: 6,
    char_2: 'robin',
    app_rate_2: '26.83%',
    avg_round_2: 3278,
    app_flat_2: 9,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '26.83%',
    avg_round_3: 3491,
    app_flat_3: 6,
    char_4: 'the-herta',
    app_rate_4: '21.95%',
    avg_round_4: 3523,
    app_flat_4: 6,
    char_5: 'jade',
    app_rate_5: '21.95%',
    avg_round_5: 3560,
    app_flat_5: 5,
    char_6: 'sunday',
    app_rate_6: '21.95%',
    avg_round_6: 3364,
    app_flat_6: 6,
    char_7: 'bronya',
    app_rate_7: '17.07%',
    avg_round_7: 3316,
    app_flat_7: 4,
    char_8: 'tribbie',
    app_rate_8: '17.07%',
    avg_round_8: 3380,
    app_flat_8: 2,
    char_9: 'sparkle',
    app_rate_9: '17.07%',
    avg_round_9: 3144,
    app_flat_9: 2,
    char_10: 'feixiao',
    app_rate_10: '14.63%',
    avg_round_10: 3393,
    app_flat_10: 6,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '14.63%',
    avg_round_11: 3347,
    app_flat_11: 6,
    char_12: 'ruan-mei',
    app_rate_12: '12.2%',
    avg_round_12: 3152,
    app_flat_12: 1,
    char_13: 'aglaea',
    app_rate_13: '7.32%',
    avg_round_13: 3406,
    app_flat_13: 2,
    char_14: 'herta',
    app_rate_14: '7.32%',
    avg_round_14: 3330,
    app_flat_14: 2,
    char_15: 'yunli',
    app_rate_15: '4.88%',
    avg_round_15: 3010,
    app_flat_15: 1,
    char_16: 'mydei',
    app_rate_16: '4.88%',
    avg_round_16: 3450,
    app_flat_16: 1,
    char_17: 'acheron',
    app_rate_17: '4.88%',
    avg_round_17: 3126,
    app_flat_17: 1,
    char_18: 'seele',
    app_rate_18: '2.44%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: '2.44%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'dr-ratio',
    app_rate_20: '2.44%',
    avg_round_20: 3032,
    app_flat_20: 1,
    char_21: 'topaz',
    app_rate_21: '2.44%',
    avg_round_21: 3032,
    app_flat_21: 1,
    char_22: 'asta',
    app_rate_22: '2.44%',
    avg_round_22: 3566,
    app_flat_22: 1,
    char_23: 'moze',
    app_rate_23: '2.44%',
    avg_round_23: 3288,
    app_flat_23: 1,
    char_24: 'imbibitor-lunae',
    app_rate_24: '2.44%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'pela',
    app_rate_25: '2.44%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'kafka',
    app_rate_26: '2.44%',
    avg_round_26: 3126,
    app_flat_26: 1,
    char_27: 'black-swan',
    app_rate_27: '2.44%',
    avg_round_27: 3126,
    app_flat_27: 1,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.27,
    char_1: 'aventurine',
    app_rate_1: '95.0%',
    avg_round_1: 3122,
    app_flat_1: 15,
    char_2: 'robin',
    app_rate_2: '87.5%',
    avg_round_2: 3113,
    app_flat_2: 15,
    char_3: 'topaz',
    app_rate_3: '62.5%',
    avg_round_3: 3094,
    app_flat_3: 10,
    char_4: 'moze',
    app_rate_4: '10.0%',
    avg_round_4: 3115,
    app_flat_4: 2,
    char_5: 'sunday',
    app_rate_5: '7.5%',
    avg_round_5: 3244,
    app_flat_5: 2,
    char_6: 'pela',
    app_rate_6: '7.5%',
    avg_round_6: 3210,
    app_flat_6: 1,
    char_7: 'silver-wolf',
    app_rate_7: '5.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'tribbie',
    app_rate_8: '5.0%',
    avg_round_8: 2983,
    app_flat_8: 1,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '5.0%',
    avg_round_9: 2983,
    app_flat_9: 1,
    char_10: 'feixiao',
    app_rate_10: '2.5%',
    avg_round_10: 3305,
    app_flat_10: 1,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '2.5%',
    avg_round_11: 2949,
    app_flat_11: 1,
    char_12: 'jiaoqiu',
    app_rate_12: '2.5%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '2.5%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'gallagher',
    app_rate_14: '2.5%',
    avg_round_14: 3027,
    app_flat_14: 1,
    char_15: 'lynx',
    app_rate_15: '2.5%',
    avg_round_15: 3032,
    app_flat_15: 1,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sushang',
    app: 0.23,
    char_1: 'ruan-mei',
    app_rate_1: '61.76%',
    avg_round_1: 3428,
    app_flat_1: 12,
    char_2: 'robin',
    app_rate_2: '47.06%',
    avg_round_2: 3399,
    app_flat_2: 7,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '29.41%',
    avg_round_3: 3489,
    app_flat_3: 5,
    char_4: 'tingyun-fugue',
    app_rate_4: '29.41%',
    avg_round_4: 3411,
    app_flat_4: 6,
    char_5: 'trailblazer-harmony',
    app_rate_5: '17.65%',
    avg_round_5: 3538,
    app_flat_5: 2,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '17.65%',
    avg_round_6: 3438,
    app_flat_6: 1,
    char_7: 'gallagher',
    app_rate_7: '14.71%',
    avg_round_7: 3362,
    app_flat_7: 3,
    char_8: 'sparkle',
    app_rate_8: '11.76%',
    avg_round_8: 3577,
    app_flat_8: 1,
    char_9: 'fu-xuan',
    app_rate_9: '8.82%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'sunday',
    app_rate_10: '8.82%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'lingsha',
    app_rate_11: '8.82%',
    avg_round_11: 3332,
    app_flat_11: 2,
    char_12: 'bronya',
    app_rate_12: '8.82%',
    avg_round_12: 3363,
    app_flat_12: 1,
    char_13: 'pela',
    app_rate_13: '8.82%',
    avg_round_13: 3512,
    app_flat_13: 2,
    char_14: 'hanya',
    app_rate_14: '5.88%',
    avg_round_14: 3421,
    app_flat_14: 2,
    char_15: 'huohuo',
    app_rate_15: '5.88%',
    avg_round_15: 3335,
    app_flat_15: 1,
    char_16: 'firefly',
    app_rate_16: '2.94%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'yunli',
    app_rate_17: '2.94%',
    avg_round_17: 3404,
    app_flat_17: 1,
    char_18: 'boothill',
    app_rate_18: '2.94%',
    avg_round_18: 3525,
    app_flat_18: 1,
    char_19: 'feixiao',
    app_rate_19: '2.94%',
    avg_round_19: 3577,
    app_flat_19: 1,
    char_20: 'herta',
    app_rate_20: '2.94%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.19,
    char_1: 'acheron',
    app_rate_1: '70.37%',
    avg_round_1: 3240,
    app_flat_1: 12,
    char_2: 'pela',
    app_rate_2: '55.56%',
    avg_round_2: 3197,
    app_flat_2: 9,
    char_3: 'aventurine',
    app_rate_3: '29.63%',
    avg_round_3: 3241,
    app_flat_3: 7,
    char_4: 'gallagher',
    app_rate_4: '25.93%',
    avg_round_4: 3162,
    app_flat_4: 4,
    char_5: 'sunday',
    app_rate_5: '22.22%',
    avg_round_5: 3250,
    app_flat_5: 1,
    char_6: 'tribbie',
    app_rate_6: '14.81%',
    avg_round_6: 3250,
    app_flat_6: 1,
    char_7: 'fu-xuan',
    app_rate_7: '11.11%',
    avg_round_7: 3250,
    app_flat_7: 1,
    char_8: 'jiaoqiu',
    app_rate_8: '11.11%',
    avg_round_8: 3394,
    app_flat_8: 2,
    char_9: 'lingsha',
    app_rate_9: '11.11%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'sparkle',
    app_rate_10: '7.41%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '7.41%',
    avg_round_11: 3538,
    app_flat_11: 1,
    char_12: 'tingyun-fugue',
    app_rate_12: '7.41%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '7.41%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'argenti',
    app_rate_14: '3.7%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'bailu',
    app_rate_15: '3.7%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'jing-yuan',
    app_rate_16: '3.7%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'robin',
    app_rate_17: '3.7%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'black-swan',
    app_rate_18: '3.7%',
    avg_round_18: 3232,
    app_flat_18: 1,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.16,
    char_1: 'gallagher',
    app_rate_1: '33.33%',
    avg_round_1: 3273,
    app_flat_1: 7,
    char_2: 'the-herta',
    app_rate_2: '29.17%',
    avg_round_2: 3459,
    app_flat_2: 5,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '29.17%',
    avg_round_3: 3523,
    app_flat_3: 5,
    char_4: 'serval',
    app_rate_4: '25.0%',
    avg_round_4: 3409,
    app_flat_4: 5,
    char_5: 'robin',
    app_rate_5: '16.67%',
    avg_round_5: 3608,
    app_flat_5: 1,
    char_6: 'rappa',
    app_rate_6: '12.5%',
    avg_round_6: 3310,
    app_flat_6: 3,
    char_7: 'lingsha',
    app_rate_7: '12.5%',
    avg_round_7: 3468,
    app_flat_7: 2,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '12.5%',
    avg_round_8: 3267,
    app_flat_8: 1,
    char_9: 'jade',
    app_rate_9: '12.5%',
    avg_round_9: 3532,
    app_flat_9: 2,
    char_10: 'aglaea',
    app_rate_10: '12.5%',
    avg_round_10: 3450,
    app_flat_10: 2,
    char_11: 'tribbie',
    app_rate_11: '12.5%',
    avg_round_11: 3450,
    app_flat_11: 1,
    char_12: 'sunday',
    app_rate_12: '12.5%',
    avg_round_12: 3587,
    app_flat_12: 2,
    char_13: 'feixiao',
    app_rate_13: '8.33%',
    avg_round_13: 3267,
    app_flat_13: 1,
    char_14: 'herta',
    app_rate_14: '8.33%',
    avg_round_14: 3214,
    app_flat_14: 1,
    char_15: 'tingyun-fugue',
    app_rate_15: '8.33%',
    avg_round_15: 3305,
    app_flat_15: 2,
    char_16: 'tingyun',
    app_rate_16: '8.33%',
    avg_round_16: 3290,
    app_flat_16: 2,
    char_17: 'acheron',
    app_rate_17: '8.33%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jiaoqiu',
    app_rate_18: '8.33%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'trailblazer-harmony',
    app_rate_19: '4.17%',
    avg_round_19: 3321,
    app_flat_19: 1,
    char_20: 'bronya',
    app_rate_20: '4.17%',
    avg_round_20: 3333,
    app_flat_20: 1,
    char_21: 'lynx',
    app_rate_21: '4.17%',
    avg_round_21: 3566,
    app_flat_21: 1,
    char_22: 'ruan-mei',
    app_rate_22: '4.17%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'march-7th',
    app_rate_23: '4.17%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'kafka',
    app_rate_24: '4.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: '4.17%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingque',
    app: 0.14,
    char_1: 'sparkle',
    app_rate_1: '90.48%',
    avg_round_1: 3218,
    app_flat_1: 2,
    char_2: 'fu-xuan',
    app_rate_2: '80.95%',
    avg_round_2: 3218,
    app_flat_2: 2,
    char_3: 'silver-wolf',
    app_rate_3: '71.43%',
    avg_round_3: 3185,
    app_flat_3: 1,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '19.05%',
    avg_round_4: 3344,
    app_flat_4: 2,
    char_5: 'robin',
    app_rate_5: '9.52%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'tribbie',
    app_rate_6: '9.52%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'the-herta',
    app_rate_7: '4.76%',
    avg_round_7: 3438,
    app_flat_7: 1,
    char_8: 'luocha',
    app_rate_8: '4.76%',
    avg_round_8: 3438,
    app_flat_8: 1,
    char_9: 'blade',
    app_rate_9: '4.76%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jade',
    app_rate_10: '4.76%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanqing',
    app: 0.14,
    char_1: 'robin',
    app_rate_1: '76.19%',
    avg_round_1: 3287,
    app_flat_1: 5,
    char_2: 'aventurine',
    app_rate_2: '52.38%',
    avg_round_2: 3156,
    app_flat_2: 5,
    char_3: 'sunday',
    app_rate_3: '42.86%',
    avg_round_3: 3448,
    app_flat_3: 4,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '28.57%',
    avg_round_4: 3282,
    app_flat_4: 2,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '23.81%',
    avg_round_5: 3116,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: '19.05%',
    avg_round_6: 3282,
    app_flat_6: 2,
    char_7: 'bronya',
    app_rate_7: '14.29%',
    avg_round_7: 3116,
    app_flat_7: 1,
    char_8: 'tingyun',
    app_rate_8: '14.29%',
    avg_round_8: 3360,
    app_flat_8: 2,
    char_9: 'feixiao',
    app_rate_9: '9.52%',
    avg_round_9: 3347,
    app_flat_9: 1,
    char_10: 'sparkle',
    app_rate_10: '4.76%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'blade',
    app_rate_11: '4.76%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'yunli',
    app_rate_12: '4.76%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'pela',
    app_rate_13: '4.76%',
    avg_round_13: 3501,
    app_flat_13: 1,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.12,
    char_1: 'robin',
    app_rate_1: '29.41%',
    avg_round_1: 3358,
    app_flat_1: 2,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '29.41%',
    avg_round_2: 3420,
    app_flat_2: 4,
    char_3: 'ruan-mei',
    app_rate_3: '23.53%',
    avg_round_3: 3260,
    app_flat_3: 2,
    char_4: 'sunday',
    app_rate_4: '17.65%',
    avg_round_4: 3544,
    app_flat_4: 1,
    char_5: 'boothill',
    app_rate_5: '17.65%',
    avg_round_5: 3530,
    app_flat_5: 2,
    char_6: 'tingyun-fugue',
    app_rate_6: '17.65%',
    avg_round_6: 3530,
    app_flat_6: 2,
    char_7: 'aglaea',
    app_rate_7: '11.76%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'bronya',
    app_rate_8: '11.76%',
    avg_round_8: 3516,
    app_flat_8: 1,
    char_9: 'the-herta',
    app_rate_9: '11.76%',
    avg_round_9: 3338,
    app_flat_9: 2,
    char_10: 'huohuo',
    app_rate_10: '11.76%',
    avg_round_10: 3332,
    app_flat_10: 1,
    char_11: 'sushang',
    app_rate_11: '11.76%',
    avg_round_11: 3421,
    app_flat_11: 2,
    char_12: 'yunli',
    app_rate_12: '11.76%',
    avg_round_12: 3404,
    app_flat_12: 1,
    char_13: 'feixiao',
    app_rate_13: '11.76%',
    avg_round_13: 3384,
    app_flat_13: 2,
    char_14: 'pela',
    app_rate_14: '11.76%',
    avg_round_14: 3436,
    app_flat_14: 2,
    char_15: 'jing-yuan',
    app_rate_15: '5.88%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'fu-xuan',
    app_rate_16: '5.88%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'herta',
    app_rate_17: '5.88%',
    avg_round_17: 3332,
    app_flat_17: 1,
    char_18: 'topaz',
    app_rate_18: '5.88%',
    avg_round_18: 3273,
    app_flat_18: 1,
    char_19: 'aventurine',
    app_rate_19: '5.88%',
    avg_round_19: 3273,
    app_flat_19: 1,
    char_20: 'firefly',
    app_rate_20: '5.88%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'sparkle',
    app_rate_21: '5.88%',
    avg_round_21: 3494,
    app_flat_21: 1,
    char_22: 'misha',
    app_rate_22: '5.88%',
    avg_round_22: 3435,
    app_flat_22: 1,
    char_23: 'sampo',
    app_rate_23: '5.88%',
    avg_round_23: 3343,
    app_flat_23: 1,
    char_24: 'argenti',
    app_rate_24: '5.88%',
    avg_round_24: 3177,
    app_flat_24: 1,
    char_25: 'tingyun',
    app_rate_25: '5.88%',
    avg_round_25: 3177,
    app_flat_25: 1,
    char_26: 'dan-heng',
    app_rate_26: '5.88%',
    avg_round_26: 3313,
    app_flat_26: 1,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.11,
    char_1: 'acheron',
    app_rate_1: '31.25%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'the-herta',
    app_rate_2: '25.0%',
    avg_round_2: 3508,
    app_flat_2: 1,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '25.0%',
    avg_round_3: 3508,
    app_flat_3: 1,
    char_4: 'robin',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'feixiao',
    app_rate_5: '18.75%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'ruan-mei',
    app_rate_6: '18.75%',
    avg_round_6: 3330,
    app_flat_6: 1,
    char_7: 'pela',
    app_rate_7: '18.75%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'rappa',
    app_rate_8: '18.75%',
    avg_round_8: 3348,
    app_flat_8: 2,
    char_9: 'topaz',
    app_rate_9: '12.5%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'herta',
    app_rate_10: '12.5%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'silver-wolf',
    app_rate_11: '12.5%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'sparkle',
    app_rate_12: '12.5%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'tribbie',
    app_rate_13: '12.5%',
    avg_round_13: 3365,
    app_flat_13: 1,
    char_14: 'tingyun-fugue',
    app_rate_14: '12.5%',
    avg_round_14: 3330,
    app_flat_14: 1,
    char_15: 'bronya',
    app_rate_15: '12.5%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'argenti',
    app_rate_16: '6.25%',
    avg_round_16: 3508,
    app_flat_16: 1,
    char_17: 'jade',
    app_rate_17: '6.25%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'yunli',
    app_rate_18: '6.25%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'trailblazer-harmony',
    app_rate_19: '6.25%',
    avg_round_19: 3365,
    app_flat_19: 1,
    char_20: 'asta',
    app_rate_20: '6.25%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luka',
    app: 0.09,
    char_1: 'ruan-mei',
    app_rate_1: '76.92%',
    avg_round_1: 3453,
    app_flat_1: 7,
    char_2: 'boothill',
    app_rate_2: '61.54%',
    avg_round_2: 3426,
    app_flat_2: 7,
    char_3: 'tingyun-fugue',
    app_rate_3: '38.46%',
    avg_round_3: 3470,
    app_flat_3: 3,
    char_4: 'gallagher',
    app_rate_4: '23.08%',
    avg_round_4: 3298,
    app_flat_4: 3,
    char_5: 'kafka',
    app_rate_5: '23.08%',
    avg_round_5: 3457,
    app_flat_5: 1,
    char_6: 'black-swan',
    app_rate_6: '15.38%',
    avg_round_6: 3457,
    app_flat_6: 1,
    char_7: 'lingsha',
    app_rate_7: '15.38%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'sunday',
    app_rate_8: '7.69%',
    avg_round_8: 3231,
    app_flat_8: 1,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '7.69%',
    avg_round_9: 3310,
    app_flat_9: 1,
    char_10: 'luocha',
    app_rate_10: '7.69%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'trailblazer-harmony',
    app_rate_11: '7.69%',
    avg_round_11: 3521,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '7.69%',
    avg_round_12: 3271,
    app_flat_12: 1,
    char_13: 'sampo',
    app_rate_13: '7.69%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'bronya',
    app_rate_14: '7.69%',
    avg_round_14: 3431,
    app_flat_14: 1,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.08,
    char_1: 'robin',
    app_rate_1: '75.0%',
    avg_round_1: 3472,
    app_flat_1: 3,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '41.67%',
    avg_round_2: 3500,
    app_flat_2: 2,
    char_3: 'ruan-mei',
    app_rate_3: '33.33%',
    avg_round_3: 3301,
    app_flat_3: 3,
    char_4: 'sunday',
    app_rate_4: '33.33%',
    avg_round_4: 3379,
    app_flat_4: 3,
    char_5: 'bronya',
    app_rate_5: '25.0%',
    avg_round_5: 3620,
    app_flat_5: 1,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '25.0%',
    avg_round_6: 3279,
    app_flat_6: 3,
    char_7: 'tingyun',
    app_rate_7: '16.67%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'the-herta',
    app_rate_8: '8.33%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'herta',
    app_rate_9: '8.33%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'aventurine',
    app_rate_10: '8.33%',
    avg_round_10: 3247,
    app_flat_10: 1,
    char_11: 'huohuo',
    app_rate_11: '8.33%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'sparkle',
    app_rate_12: '8.33%',
    avg_round_12: 3482,
    app_flat_12: 1,
    char_13: 'hanya',
    app_rate_13: '8.33%',
    avg_round_13: 3313,
    app_flat_13: 1,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sampo',
    app: 0.08,
    char_1: 'acheron',
    app_rate_1: '50.0%',
    avg_round_1: 3176,
    app_flat_1: 5,
    char_2: 'kafka',
    app_rate_2: '41.67%',
    avg_round_2: 3441,
    app_flat_2: 1,
    char_3: 'black-swan',
    app_rate_3: '41.67%',
    avg_round_3: 3167,
    app_flat_3: 2,
    char_4: 'pela',
    app_rate_4: '33.33%',
    avg_round_4: 3270,
    app_flat_4: 3,
    char_5: 'ruan-mei',
    app_rate_5: '33.33%',
    avg_round_5: 3392,
    app_flat_5: 2,
    char_6: 'aventurine',
    app_rate_6: '16.67%',
    avg_round_6: 3184,
    app_flat_6: 2,
    char_7: 'fu-xuan',
    app_rate_7: '16.67%',
    avg_round_7: 2893,
    app_flat_7: 1,
    char_8: 'gallagher',
    app_rate_8: '8.33%',
    avg_round_8: 3206,
    app_flat_8: 1,
    char_9: 'tribbie',
    app_rate_9: '8.33%',
    avg_round_9: 3413,
    app_flat_9: 1,
    char_10: 'robin',
    app_rate_10: '8.33%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'luka',
    app_rate_11: '8.33%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'huohuo',
    app_rate_12: '8.33%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'the-herta',
    app_rate_13: '8.33%',
    avg_round_13: 3343,
    app_flat_13: 1,
    char_14: 'hanya',
    app_rate_14: '8.33%',
    avg_round_14: 3343,
    app_flat_14: 1,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '8.33%',
    avg_round_15: 3175,
    app_flat_15: 1,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.08,
    char_1: 'robin',
    app_rate_1: '45.45%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'pela',
    app_rate_2: '27.27%',
    avg_round_2: 3435,
    app_flat_2: 1,
    char_3: 'sparkle',
    app_rate_3: '27.27%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '27.27%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'sunday',
    app_rate_5: '27.27%',
    avg_round_5: 3193,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: '27.27%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '27.27%',
    avg_round_7: 3314,
    app_flat_7: 2,
    char_8: 'aventurine',
    app_rate_8: '18.18%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tribbie',
    app_rate_9: '18.18%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'bronya',
    app_rate_10: '18.18%',
    avg_round_10: 3193,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: '9.09%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'luocha',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'hanya',
    app_rate_13: '9.09%',
    avg_round_13: 3435,
    app_flat_13: 1,
    char_14: 'trailblazer-harmony',
    app_rate_14: '9.09%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.07,
    char_1: 'sunday',
    app_rate_1: '60.0%',
    avg_round_1: 3450,
    app_flat_1: 2,
    char_2: 'robin',
    app_rate_2: '40.0%',
    avg_round_2: 3494,
    app_flat_2: 1,
    char_3: 'aglaea',
    app_rate_3: '40.0%',
    avg_round_3: 3380,
    app_flat_3: 1,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '30.0%',
    avg_round_4: 3380,
    app_flat_4: 1,
    char_5: 'boothill',
    app_rate_5: '20.0%',
    avg_round_5: 3519,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: '20.0%',
    avg_round_6: 3519,
    app_flat_6: 1,
    char_7: 'feixiao',
    app_rate_7: '10.0%',
    avg_round_7: 3494,
    app_flat_7: 1,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '10.0%',
    avg_round_8: 3494,
    app_flat_8: 1,
    char_9: 'acheron',
    app_rate_9: '10.0%',
    avg_round_9: 3194,
    app_flat_9: 1,
    char_10: 'kafka',
    app_rate_10: '10.0%',
    avg_round_10: 3194,
    app_flat_10: 1,
    char_11: 'black-swan',
    app_rate_11: '10.0%',
    avg_round_11: 3194,
    app_flat_11: 1,
    char_12: 'jing-yuan',
    app_rate_12: '10.0%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'tingyun',
    app_rate_13: '10.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'the-herta',
    app_rate_14: '10.0%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'herta',
    app_rate_15: '10.0%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hook',
    app: 0.02,
    char_1: 'tribbie',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'sparkle',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'bailu',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'lingsha',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.02,
    char_1: 'ruan-mei',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'tingyun-fugue',
    app_rate_2: '33.33%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'trailblazer-harmony',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'feixiao',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'robin',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'silver-wolf',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '33.33%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.01,
    char_1: 'acheron',
    app_rate_1: '50.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'bronya',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'silver-wolf',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'yunli',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'pela',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'tribbie',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'arlan',
    app: 0.01,
    char_1: 'sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 3436,
    app_flat_1: 1,
    char_2: 'sunday',
    app_rate_2: '100.0%',
    avg_round_2: 3436,
    app_flat_2: 1,
    char_3: 'tingyun',
    app_rate_3: '100.0%',
    avg_round_3: 3436,
    app_flat_3: 1,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
