export const HSRHertaDPS = {
  V1: {
    // 1 target
    e0: 693221,
    e1: 841260,
    e2: 1273387,
    e3: 1388795,
    e4: 1463791,
    e5: 1512318,
    e6: 2026860,
    sig_e0: 792857,
    sig_e1: 962432,
    sig_e2: 1455969,
    sig_e3: 1587952,
    sig_e4: 1673685,
    sig_e5: 1729561,
    sig_e6: 2317522,
    // 3 targets
    alt_e0: 1597831,
    alt_e1: 1893909,
    alt_e2: 3079779,
    alt_e3: 3351962,
    alt_e4: 3532354,
    alt_e5: 3654503,
    alt_e6: 4602020,
    alt_sig_e0: 1827153,
    alt_sig_e1: 2166303,
    alt_sig_e2: 3523513,
    alt_sig_e3: 3835022,
    alt_sig_e4: 4041355,
    alt_sig_e5: 4182019,
    alt_sig_e6: 5265652,
    // 5 targets
    e0_5targets: 2712500,
    e1_5targets: 3254499,
    e2_5targets: 5606002,
    e3_5targets: 6090474,
    e4_5targets: 6398212,
    e5_5targets: 6627499,
    e6_5targets: 8412871,
    sig_e0_5targets: 3102085,
    sig_e1_5targets: 3722933,
    sig_e2_5targets: 6414100,
    sig_e3_5targets: 6968644,
    sig_e4_5targets: 7320679,
    sig_e5_5targets: 7584654,
    sig_e6_5targets: 9626444
  }
};
