export const HSRTribbieDPS = {
  V1: {
    // 1 target
    e0: 506047,
    e1: 627498,
    e2: 1171330,
    e3: 1309026,
    e4: 1448485,
    e5: 1486865,
    e6: 1923365,
    sig_e0: 704464,
    sig_e1: 873535,
    sig_e2: 1630600,
    sig_e3: 1822285,
    sig_e4: 2016424,
    sig_e5: 2069852,
    sig_e6: 2677500,
    // 3 targets
    alt_e0: 1397654,
    alt_e1: 1733091,
    alt_e2: 2422343,
    alt_e3: 2698554,
    alt_e4: 2986048,
    alt_e5: 3074877,
    alt_e6: 4263000,
    alt_sig_e0: 1945663,
    alt_sig_e1: 2412622,
    alt_sig_e2: 3372124,
    alt_sig_e3: 3756636,
    alt_sig_e4: 4156854,
    alt_sig_e5: 4280511,
    alt_sig_e6: 5934488,
    // 5 targets
    e0_5targets: 2112546,
    e1_5targets: 2619557,
    e2_5targets: 3430325,
    e3_5targets: 3814579,
    e4_5targets: 4220970,
    e5_5targets: 4354390,
    e6_5targets: 6294136,
    sig_e0_5targets: 2940858,
    sig_e1_5targets: 3646664,
    sig_e2_5targets: 4775327,
    sig_e3_5targets: 5310244,
    sig_e4_5targets: 5875978,
    sig_e5_5targets: 6061710,
    sig_e6_5targets: 8762016
  }
};
