import React, { useState } from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col, Accordion, Alert } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { HSRTribbieDPS } from '../dps-rankings/tribbie-array';

export const ProfileTribbieDPS: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTab, setCurrentTab] = useState('V1');
  return (
    <>
      <Alert variant="primary alert-red ">
        <p>
          <strong>
            Don't use the damage output calculations as an indication on who is
            worth pulling or investing as those are just simulations and in
            day-to-day gameplay there's a lot variables
          </strong>
          . Use the numbers only to supplement your knowledge and treat them as
          the 'floor' of a character's damage output which you can further scale
          with proper team and investment.
        </p>
      </Alert>
      <h5>Disclaimers:</h5>
      <ul>
        <li>
          All Tribbie calculations presented below are{' '}
          <strong>
            her own personal damage not including any teammates or buffs
          </strong>
          . Only use these calculations to determine how much more damage your
          Tribbie will be doing not her impact on teams. For more info refer to
          the notes below.
        </li>
        <li>Additional notes for Eidolons:</li>
        <ul>
          <li>
            <strong>E1</strong>: Tribbie's Eidolon 1 provides a 24% total damage
            amplification for the whole team (assuming you have no other sources
            of True Damage), making it more valuable than other Eidolons that
            just focus on her own damage. With that said, its value is actually
            much higher than a simple 24% as it funnels all True damage
            calculated from all enemies toward one target, making it extremely
            potent in fights with a single strong enemy and many smaller
            enemies. A lot of the most frequent end-game bosses in Star Rail
            have huge HP pools and constantly summon ads until they die - in
            these scenarios this Eidolon is far more powerful than simply a 24%
            damage boost. This being a team-wide buff and in a lot of situations
            far more powerful than meets the eye, makes this Tribbies best
            Eidolon despite how little it increases her own damage compared to
            others.
          </li>
          <li>
            <strong>E2</strong>: Tribbie's E2 is the opposite of her E1 - it
            only affects herself and not her team making it comparatively weaker
            when factoring in the rest of her allies. In addition to this her E2
            gets weaker the more enemies she is against, as the additional
            trigger of her Ultimate it grants becomes less and less impactful.
            Against 1 target E2 double the amount of triggers, against 3 it's
            33% more and against 5 it's only 20% more. E2 is still a good
            personal damage boost for Tribbie, but in the content she's best at
            it loses quite a bit of value.
          </li>
          <li>
            <strong>E6</strong>: Like Tribbie's E2, her E6 is another personal
            damage boost making it less valuable compared to her E1 and other
            team-wide boosting effects when factoring in her allies. Also of
            note is the fact that her E6 becomes stronger the more frequently
            you can use her Ultimate. If you're able to occasionally achieve 1
            action Ultimates with Tribbie, this Eidolon will perform above its
            listing here as you'll gain significantly more boosted Follow-Up
            attacks, improving its overall value.
          </li>
        </ul>
      </ul>
      <Accordion className="sim-rules">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Simulation rules</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>General:</strong>
            </p>
            <ul>
              <li>
                Combat is simulated at 5 cycles of in-game time or 550 Action
                Points,
              </li>
              <li>
                Units are considered to gain 5 additional energy per rotation
                through combat (taking a hit, killing an enemy or through party
                members and their Light Cone),
              </li>
              <li>
                Characters and Light Cones are calculated at maximum output
                starting battle with ramp time removed from stacking buffs
                (intended to give a constant evaluation of power not dependant
                on length of fight that can be used to assess maximum power).
                Additional information about loss of damage due to ramp time can
                be round attached to related Light Cones, relics and characters,
              </li>
              <li>
                Character rotations are smoothed removing breakpoints relating
                to Speed and Energy (finishing a fight with 90% energy full or 2
                AV off next ability leading to skewed results).
              </li>
            </ul>
            <p>
              <strong>Enemies:</strong>
            </p>
            <ul>
              <li>
                Enemies are considered to always have a Break Bar resulting in
                units all doing 90% of real damage,
              </li>
              <li>Enemies speed is set to 134,</li>
              <li>
                Enemies are assumed to be at 100% of HP at start of battle and
                to die at exactly at the end of combat. Which means halfway
                through the fight they are at 50% HP,
              </li>
              <li>Enemies are assumed to have 0 Resistance,</li>
              <li>
                Enemies are always considered vulnerable to the attackers
                element,
              </li>
              <li>Enemies are considered level 95 and with DEF included.</li>
            </ul>
            <p>
              <strong>Units</strong>:
            </p>
            <ul>
              <li>All units are level 80,</li>
              <li>
                Traces are all unlocked, skills at Level 10 and Basic at Level
                6,
              </li>
              <li>
                All units have fully upgraded 5 star relics with 24 desired sub
                stats out of a possible 54,
              </li>
              <li>All units use their Best In Slot 2 and 4 piece sets,</li>
              <li>
                All units use Light Cones that are realistically obtainable by
                all players (including those in Herta and FH Stores and Battle
                Pass),
              </li>
              <ul>
                <li>
                  Because the Herta Store and farmable Light Cones are easily
                  accessible to playerbase, we are simulating them at
                  Superimposition 5.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Tribbie [1 target]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e6 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e6 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e6 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e5 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e5 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e5 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e5 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e4 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e4 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e4 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e4 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e3 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e3 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e3 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e3 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e2 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e2 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e2 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e2 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e1 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e1 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e1 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e1 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e0 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].sig_e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e0 /
                            HSRTribbieDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e0 /
                            HSRTribbieDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Tribbie [3 targets]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e6 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e6 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e6 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e5 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e5 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e5 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e5 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e4 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e4 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e4 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e4 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e3 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e3 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e3 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e3 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e2 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e2 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e2 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e2 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e1 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e1 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e1 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_e1 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_sig_e0 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_sig_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].alt_sig_e0 /
                            HSRTribbieDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].alt_e0 /
                            HSRTribbieDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].alt_e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Tribbie [5 targets]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e6_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e6_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e6_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e6_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e6_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e5_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e5_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e5_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e5_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e5_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e5_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e4_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e4_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e4_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e4_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e4_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e4_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e3_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e3_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e3_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e3_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e3_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e3_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e2_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e2_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e2_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e2_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e2_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e2_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e1_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e1_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e1_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e1_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e1_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].e1_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="tribbie" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].sig_e0_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="If Time Were a Flower"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[
                          currentTab
                        ].sig_e0_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRTribbieDPS[currentTab].sig_e0_5targets /
                            HSRTribbieDPS[currentTab].e0_5targets) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRTribbieDPS[currentTab].e0_5targets /
                            HSRTribbieDPS[currentTab].sig_e6_5targets) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Dance! Dance! Dance!"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRTribbieDPS[currentTab].e0_5targets.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Poet of Mourning Collapse" compactMode />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet
                  name="Bone Collection's Serene Demesne"
                  compactMode
                />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="CRIT DMG" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Energy Regen Rate" />
                  <HSRStat stat="HP%" />
                </div>
              </div>
            </Col>
          </Row>
          <p>The Signature Light Cone uses HP% Rope while DDD uses ER Rope.</p>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>CRIT Rate (40.8%), CRIT DMG (88.2%)</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
