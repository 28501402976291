export const MoCDuos = [
  {
    char: 'the-herta',
    app: 62.77,
    char_1: 'herta',
    app_rate_1: '42.38%',
    avg_round_1: 8.49,
    app_flat_1: 2526,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '41.86%',
    avg_round_2: 8.26,
    app_flat_2: 2693,
    char_3: 'jade',
    app_rate_3: '33.21%',
    avg_round_3: 7.92,
    app_flat_3: 1720,
    char_4: 'aventurine',
    app_rate_4: '26.07%',
    avg_round_4: 8.19,
    app_flat_4: 1596,
    char_5: 'tribbie',
    app_rate_5: '24.51%',
    avg_round_5: 6.5,
    app_flat_5: 1204,
    char_6: 'robin',
    app_rate_6: '19.62%',
    avg_round_6: 8.6,
    app_flat_6: 946,
    char_7: 'lingsha',
    app_rate_7: '19.53%',
    avg_round_7: 7.48,
    app_flat_7: 1070,
    char_8: 'serval',
    app_rate_8: '19.41%',
    avg_round_8: 7.97,
    app_flat_8: 1104,
    char_9: 'huohuo',
    app_rate_9: '17.86%',
    avg_round_9: 8.32,
    app_flat_9: 1010,
    char_10: 'fu-xuan',
    app_rate_10: '13.94%',
    avg_round_10: 8.8,
    app_flat_10: 686,
    char_11: 'sunday',
    app_rate_11: '12.91%',
    avg_round_11: 8.58,
    app_flat_11: 688,
    char_12: 'gallagher',
    app_rate_12: '9.58%',
    avg_round_12: 7.73,
    app_flat_12: 662,
    char_13: 'luocha',
    app_rate_13: '6.19%',
    avg_round_13: 8.37,
    app_flat_13: 380,
    char_14: 'ruan-mei',
    app_rate_14: '3.58%',
    avg_round_14: 8.2,
    app_flat_14: 108,
    char_15: 'argenti',
    app_rate_15: '3.06%',
    avg_round_15: 6.65,
    app_flat_15: 169,
    char_16: 'sparkle',
    app_rate_16: '2.54%',
    avg_round_16: 9.02,
    app_flat_16: 80,
    char_17: 'pela',
    app_rate_17: '0.58%',
    avg_round_17: 6.27,
    app_flat_17: 22,
    char_18: 'bailu',
    app_rate_18: '0.47%',
    avg_round_18: 8.76,
    app_flat_18: 29,
    char_19: 'himeko',
    app_rate_19: '0.41%',
    avg_round_19: 8.33,
    app_flat_19: 21,
    char_20: 'jing-yuan',
    app_rate_20: '0.34%',
    avg_round_20: 9.11,
    app_flat_20: 15,
    char_21: 'bronya',
    app_rate_21: '0.33%',
    avg_round_21: 9.3,
    app_flat_21: 18,
    char_22: 'rappa',
    app_rate_22: '0.24%',
    avg_round_22: 9.4,
    app_flat_22: 9,
    char_23: 'gepard',
    app_rate_23: '0.21%',
    avg_round_23: 9.83,
    app_flat_23: 12,
    char_24: 'tingyun',
    app_rate_24: '0.19%',
    avg_round_24: 9.2,
    app_flat_24: 9,
    char_25: 'lynx',
    app_rate_25: '0.17%',
    avg_round_25: 8.57,
    app_flat_25: 11,
    char_26: 'march-7th',
    app_rate_26: '0.13%',
    avg_round_26: 7.2,
    app_flat_26: 5,
    char_27: 'jiaoqiu',
    app_rate_27: '0.11%',
    avg_round_27: 5,
    app_flat_27: 2,
    char_28: 'aglaea',
    app_rate_28: '0.1%',
    avg_round_28: 8.33,
    app_flat_28: 3,
    char_29: 'qingque',
    app_rate_29: '0.08%',
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'tingyun-fugue',
    app_rate_30: '0.07%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'ruan-mei',
    app: 53.58,
    char_1: 'tingyun-fugue',
    app_rate_1: '61.9%',
    avg_round_1: 8.28,
    app_flat_1: 1649,
    char_2: 'firefly',
    app_rate_2: '55.51%',
    avg_round_2: 9.07,
    app_flat_2: 977,
    char_3: 'lingsha',
    app_rate_3: '43.56%',
    avg_round_3: 8.57,
    app_flat_3: 1114,
    char_4: 'trailblazer-harmony',
    app_rate_4: '36.86%',
    avg_round_4: 8.39,
    app_flat_4: 996,
    char_5: 'rappa',
    app_rate_5: '26.01%',
    avg_round_5: 8.14,
    app_flat_5: 1279,
    char_6: 'gallagher',
    app_rate_6: '25.92%',
    avg_round_6: 9.05,
    app_flat_6: 872,
    char_7: 'sunday',
    app_rate_7: '4.46%',
    avg_round_7: 8.16,
    app_flat_7: 100,
    char_8: 'aventurine',
    app_rate_8: '4.24%',
    avg_round_8: 9.15,
    app_flat_8: 105,
    char_9: 'the-herta',
    app_rate_9: '4.19%',
    avg_round_9: 8.2,
    app_flat_9: 108,
    char_10: 'huohuo',
    app_rate_10: '3.18%',
    avg_round_10: 8.91,
    app_flat_10: 65,
    char_11: 'luocha',
    app_rate_11: '2.98%',
    avg_round_11: 9.03,
    app_flat_11: 62,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '2.82%',
    avg_round_12: 6.58,
    app_flat_12: 48,
    char_13: 'boothill',
    app_rate_13: '2.81%',
    avg_round_13: 8.26,
    app_flat_13: 90,
    char_14: 'jade',
    app_rate_14: '2.41%',
    avg_round_14: 8.11,
    app_flat_14: 74,
    char_15: 'imbibitor-lunae',
    app_rate_15: '2.07%',
    avg_round_15: 10.0,
    app_flat_15: 6,
    char_16: 'sparkle',
    app_rate_16: '1.71%',
    avg_round_16: 9.4,
    app_flat_16: 9,
    char_17: 'acheron',
    app_rate_17: '1.71%',
    avg_round_17: 9.2,
    app_flat_17: 9,
    char_18: 'fu-xuan',
    app_rate_18: '1.69%',
    avg_round_18: 8.55,
    app_flat_18: 22,
    char_19: 'herta',
    app_rate_19: '1.63%',
    avg_round_19: 8.5,
    app_flat_19: 34,
    char_20: 'mydei',
    app_rate_20: '1.39%',
    avg_round_20: 6.71,
    app_flat_20: 45,
    char_21: 'bronya',
    app_rate_21: '1.29%',
    avg_round_21: 6.69,
    app_flat_21: 13,
    char_22: 'jingliu',
    app_rate_22: '1.24%',
    avg_round_22: 9.67,
    app_flat_22: 17,
    char_23: 'kafka',
    app_rate_23: '1.19%',
    avg_round_23: 9.67,
    app_flat_23: 3,
    char_24: 'aglaea',
    app_rate_24: '1.16%',
    avg_round_24: 8.31,
    app_flat_24: 32,
    char_25: 'tribbie',
    app_rate_25: '1.15%',
    avg_round_25: 5.79,
    app_flat_25: 14,
    char_26: 'black-swan',
    app_rate_26: '1.09%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'jing-yuan',
    app_rate_27: '0.97%',
    avg_round_27: 9.43,
    app_flat_27: 26,
    char_28: 'jiaoqiu',
    app_rate_28: '0.97%',
    avg_round_28: 8.0,
    app_flat_28: 5,
    char_29: 'robin',
    app_rate_29: '0.95%',
    avg_round_29: 7.58,
    app_flat_29: 12,
    char_30: 'pela',
    app_rate_30: '0.75%',
    avg_round_30: 5.27,
    app_flat_30: 15
  },
  {
    char: 'robin',
    app: 52.36,
    char_1: 'aventurine',
    app_rate_1: '40.91%',
    avg_round_1: 8.66,
    app_flat_1: 1318,
    char_2: 'sunday',
    app_rate_2: '35.84%',
    avg_round_2: 7.94,
    app_flat_2: 1120,
    char_3: 'aglaea',
    app_rate_3: '24.72%',
    avg_round_3: 7.68,
    app_flat_3: 913,
    char_4: 'the-herta',
    app_rate_4: '23.53%',
    avg_round_4: 8.6,
    app_flat_4: 946,
    char_5: 'feixiao',
    app_rate_5: '22.52%',
    avg_round_5: 8.7,
    app_flat_5: 616,
    char_6: 'huohuo',
    app_rate_6: '22.15%',
    avg_round_6: 7.81,
    app_flat_6: 801,
    char_7: 'jade',
    app_rate_7: '13.44%',
    avg_round_7: 8.29,
    app_flat_7: 502,
    char_8: 'jing-yuan',
    app_rate_8: '10.61%',
    avg_round_8: 8.87,
    app_flat_8: 297,
    char_9: 'topaz',
    app_rate_9: '10.36%',
    avg_round_9: 8.35,
    app_flat_9: 197,
    char_10: 'herta',
    app_rate_10: '9.91%',
    avg_round_10: 8.91,
    app_flat_10: 411,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '9.06%',
    avg_round_11: 8.82,
    app_flat_11: 316,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '9.03%',
    avg_round_12: 8.13,
    app_flat_12: 257,
    char_13: 'fu-xuan',
    app_rate_13: '8.35%',
    avg_round_13: 8.97,
    app_flat_13: 226,
    char_14: 'lingsha',
    app_rate_14: '8.31%',
    avg_round_14: 7.73,
    app_flat_14: 303,
    char_15: 'gallagher',
    app_rate_15: '6.05%',
    avg_round_15: 7.63,
    app_flat_15: 181,
    char_16: 'luocha',
    app_rate_16: '4.8%',
    avg_round_16: 8.59,
    app_flat_16: 141,
    char_17: 'acheron',
    app_rate_17: '4.17%',
    avg_round_17: 8.14,
    app_flat_17: 42,
    char_18: 'tribbie',
    app_rate_18: '3.58%',
    avg_round_18: 6.88,
    app_flat_18: 60,
    char_19: 'yunli',
    app_rate_19: '3.35%',
    avg_round_19: 8.56,
    app_flat_19: 79,
    char_20: 'jiaoqiu',
    app_rate_20: '3.03%',
    avg_round_20: 7.97,
    app_flat_20: 35,
    char_21: 'moze',
    app_rate_21: '2.98%',
    avg_round_21: 9.23,
    app_flat_21: 94,
    char_22: 'bronya',
    app_rate_22: '2.82%',
    avg_round_22: 5.07,
    app_flat_22: 43,
    char_23: 'sparkle',
    app_rate_23: '2.81%',
    avg_round_23: 8.86,
    app_flat_23: 13,
    char_24: 'dr-ratio',
    app_rate_24: '2.73%',
    avg_round_24: 8.9,
    app_flat_24: 61,
    char_25: 'serval',
    app_rate_25: '2.55%',
    avg_round_25: 8.73,
    app_flat_25: 88,
    char_26: 'imbibitor-lunae',
    app_rate_26: '2.44%',
    avg_round_26: 9.5,
    app_flat_26: 4,
    char_27: 'jingliu',
    app_rate_27: '1.63%',
    avg_round_27: 8.4,
    app_flat_27: 40,
    char_28: 'pela',
    app_rate_28: '1.2%',
    avg_round_28: 9.5,
    app_flat_28: 14,
    char_29: 'ruan-mei',
    app_rate_29: '0.97%',
    avg_round_29: 7.58,
    app_flat_29: 12,
    char_30: 'tingyun',
    app_rate_30: '0.85%',
    avg_round_30: 8.8,
    app_flat_30: 18
  },
  {
    char: 'aventurine',
    app: 49.02,
    char_1: 'robin',
    app_rate_1: '43.7%',
    avg_round_1: 8.66,
    app_flat_1: 1318,
    char_2: 'the-herta',
    app_rate_2: '33.38%',
    avg_round_2: 8.19,
    app_flat_2: 1596,
    char_3: 'acheron',
    app_rate_3: '25.92%',
    avg_round_3: 8.7,
    app_flat_3: 539,
    char_4: 'sunday',
    app_rate_4: '20.12%',
    avg_round_4: 8.7,
    app_flat_4: 584,
    char_5: 'feixiao',
    app_rate_5: '20.05%',
    avg_round_5: 8.79,
    app_flat_5: 575,
    char_6: 'jiaoqiu',
    app_rate_6: '18.02%',
    avg_round_6: 8.58,
    app_flat_6: 457,
    char_7: 'herta',
    app_rate_7: '15.48%',
    avg_round_7: 8.56,
    app_flat_7: 775,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '14.63%',
    avg_round_8: 8.34,
    app_flat_8: 735,
    char_9: 'jade',
    app_rate_9: '13.56%',
    avg_round_9: 7.67,
    app_flat_9: 543,
    char_10: 'pela',
    app_rate_10: '12.97%',
    avg_round_10: 8.72,
    app_flat_10: 407,
    char_11: 'tribbie',
    app_rate_11: '10.79%',
    avg_round_11: 6.99,
    app_flat_11: 426,
    char_12: 'topaz',
    app_rate_12: '10.55%',
    avg_round_12: 8.33,
    app_flat_12: 193,
    char_13: 'sparkle',
    app_rate_13: '10.28%',
    avg_round_13: 9.35,
    app_flat_13: 68,
    char_14: 'jing-yuan',
    app_rate_14: '8.57%',
    avg_round_14: 8.88,
    app_flat_14: 239,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '6.88%',
    avg_round_15: 8.85,
    app_flat_15: 275,
    char_16: 'serval',
    app_rate_16: '5.21%',
    avg_round_16: 7.61,
    app_flat_16: 271,
    char_17: 'ruan-mei',
    app_rate_17: '4.63%',
    avg_round_17: 9.15,
    app_flat_17: 105,
    char_18: 'dr-ratio',
    app_rate_18: '3.06%',
    avg_round_18: 8.94,
    app_flat_18: 68,
    char_19: 'moze',
    app_rate_19: '2.96%',
    avg_round_19: 9.47,
    app_flat_19: 103,
    char_20: 'aglaea',
    app_rate_20: '2.85%',
    avg_round_20: 8.18,
    app_flat_20: 114,
    char_21: 'silver-wolf',
    app_rate_21: '2.04%',
    avg_round_21: 9.15,
    app_flat_21: 40,
    char_22: 'black-swan',
    app_rate_22: '1.62%',
    avg_round_22: 9.36,
    app_flat_22: 26,
    char_23: 'imbibitor-lunae',
    app_rate_23: '1.61%',
    avg_round_23: 9.71,
    app_flat_23: 11,
    char_24: 'bronya',
    app_rate_24: '1.47%',
    avg_round_24: 9.0,
    app_flat_24: 16,
    char_25: 'tingyun-fugue',
    app_rate_25: '1.45%',
    avg_round_25: 8.34,
    app_flat_25: 38,
    char_26: 'kafka',
    app_rate_26: '1.37%',
    avg_round_26: 9.29,
    app_flat_26: 26,
    char_27: 'rappa',
    app_rate_27: '1.09%',
    avg_round_27: 8.34,
    app_flat_27: 41,
    char_28: 'argenti',
    app_rate_28: '0.99%',
    avg_round_28: 7.05,
    app_flat_28: 42,
    char_29: 'tingyun',
    app_rate_29: '0.85%',
    avg_round_29: 9.2,
    app_flat_29: 20,
    char_30: 'yunli',
    app_rate_30: '0.78%',
    avg_round_30: 9.11,
    app_flat_30: 15
  },
  {
    char: 'sunday',
    app: 45.15,
    char_1: 'robin',
    app_rate_1: '41.56%',
    avg_round_1: 7.94,
    app_flat_1: 1120,
    char_2: 'aglaea',
    app_rate_2: '34.95%',
    avg_round_2: 7.61,
    app_flat_2: 1154,
    char_3: 'huohuo',
    app_rate_3: '29.65%',
    avg_round_3: 7.71,
    app_flat_3: 972,
    char_4: 'aventurine',
    app_rate_4: '21.84%',
    avg_round_4: 8.7,
    app_flat_4: 584,
    char_5: 'jing-yuan',
    app_rate_5: '19.6%',
    avg_round_5: 8.9,
    app_flat_5: 499,
    char_6: 'the-herta',
    app_rate_6: '17.94%',
    avg_round_6: 8.58,
    app_flat_6: 688,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '16.11%',
    avg_round_7: 7.96,
    app_flat_7: 527,
    char_8: 'luocha',
    app_rate_8: '11.03%',
    avg_round_8: 8.4,
    app_flat_8: 325,
    char_9: 'tribbie',
    app_rate_9: '10.59%',
    avg_round_9: 7,
    app_flat_9: 225,
    char_10: 'gallagher',
    app_rate_10: '10.06%',
    avg_round_10: 7.7,
    app_flat_10: 311,
    char_11: 'fu-xuan',
    app_rate_11: '9.35%',
    avg_round_11: 8.63,
    app_flat_11: 231,
    char_12: 'herta',
    app_rate_12: '7.76%',
    avg_round_12: 8.72,
    app_flat_12: 315,
    char_13: 'acheron',
    app_rate_13: '7.12%',
    avg_round_13: 8.68,
    app_flat_13: 37,
    char_14: 'mydei',
    app_rate_14: '6.74%',
    avg_round_14: 7.01,
    app_flat_14: 203,
    char_15: 'jade',
    app_rate_15: '6.56%',
    avg_round_15: 8.28,
    app_flat_15: 226,
    char_16: 'lingsha',
    app_rate_16: '6.44%',
    avg_round_16: 7.86,
    app_flat_16: 204,
    char_17: 'ruan-mei',
    app_rate_17: '5.29%',
    avg_round_17: 8.16,
    app_flat_17: 100,
    char_18: 'jiaoqiu',
    app_rate_18: '5.29%',
    avg_round_18: 7.8,
    app_flat_18: 47,
    char_19: 'imbibitor-lunae',
    app_rate_19: '4.85%',
    avg_round_19: 9.3,
    app_flat_19: 38,
    char_20: 'sparkle',
    app_rate_20: '4.5%',
    avg_round_20: 9.03,
    app_flat_20: 71,
    char_21: 'bronya',
    app_rate_21: '3.82%',
    avg_round_21: 5.24,
    app_flat_21: 76,
    char_22: 'serval',
    app_rate_22: '3.73%',
    avg_round_22: 8.68,
    app_flat_22: 148,
    char_23: 'pela',
    app_rate_23: '2.47%',
    avg_round_23: 8.33,
    app_flat_23: 17,
    char_24: 'jingliu',
    app_rate_24: '2.46%',
    avg_round_24: 8.77,
    app_flat_24: 52,
    char_25: 'yunli',
    app_rate_25: '1.44%',
    avg_round_25: 9.18,
    app_flat_25: 33,
    char_26: 'argenti',
    app_rate_26: '1.17%',
    avg_round_26: 8.88,
    app_flat_26: 31,
    char_27: 'tingyun',
    app_rate_27: '1.05%',
    avg_round_27: 9.32,
    app_flat_27: 28,
    char_28: 'bailu',
    app_rate_28: '0.79%',
    avg_round_28: 8.52,
    app_flat_28: 23,
    char_29: 'tingyun-fugue',
    app_rate_29: '0.68%',
    avg_round_29: 8.33,
    app_flat_29: 3,
    char_30: 'dr-ratio',
    app_rate_30: '0.56%',
    avg_round_30: 8.67,
    app_flat_30: 12
  },
  {
    char: 'trailblazer-remembrance',
    app: 42.4,
    char_1: 'the-herta',
    app_rate_1: '61.96%',
    avg_round_1: 8.26,
    app_flat_1: 2693,
    char_2: 'herta',
    app_rate_2: '28.45%',
    avg_round_2: 8.56,
    app_flat_2: 1276,
    char_3: 'huohuo',
    app_rate_3: '21.71%',
    avg_round_3: 8.09,
    app_flat_3: 891,
    char_4: 'sunday',
    app_rate_4: '17.16%',
    avg_round_4: 7.96,
    app_flat_4: 527,
    char_5: 'aventurine',
    app_rate_5: '16.91%',
    avg_round_5: 8.34,
    app_flat_5: 735,
    char_6: 'jade',
    app_rate_6: '16.62%',
    avg_round_6: 7.91,
    app_flat_6: 678,
    char_7: 'aglaea',
    app_rate_7: '15.41%',
    avg_round_7: 7.98,
    app_flat_7: 510,
    char_8: 'serval',
    app_rate_8: '14.75%',
    avg_round_8: 8.15,
    app_flat_8: 649,
    char_9: 'lingsha',
    app_rate_9: '12.69%',
    avg_round_9: 8.25,
    app_flat_9: 528,
    char_10: 'fu-xuan',
    app_rate_10: '12.41%',
    avg_round_10: 8.72,
    app_flat_10: 427,
    char_11: 'gallagher',
    app_rate_11: '12.35%',
    avg_round_11: 8.03,
    app_flat_11: 529,
    char_12: 'robin',
    app_rate_12: '11.15%',
    avg_round_12: 8.13,
    app_flat_12: 257,
    char_13: 'luocha',
    app_rate_13: '8.93%',
    avg_round_13: 8.35,
    app_flat_13: 327,
    char_14: 'tribbie',
    app_rate_14: '7.62%',
    avg_round_14: 5.53,
    app_flat_14: 196,
    char_15: 'mydei',
    app_rate_15: '4.73%',
    avg_round_15: 7.4,
    app_flat_15: 146,
    char_16: 'acheron',
    app_rate_16: '4.26%',
    avg_round_16: 9.2,
    app_flat_16: 58,
    char_17: 'ruan-mei',
    app_rate_17: '3.57%',
    avg_round_17: 6.58,
    app_flat_17: 48,
    char_18: 'sparkle',
    app_rate_18: '3.05%',
    avg_round_18: 8.64,
    app_flat_18: 49,
    char_19: 'jing-yuan',
    app_rate_19: '2.87%',
    avg_round_19: 9.12,
    app_flat_19: 82,
    char_20: 'feixiao',
    app_rate_20: '2.66%',
    avg_round_20: 8.67,
    app_flat_20: 58,
    char_21: 'jiaoqiu',
    app_rate_21: '2.53%',
    avg_round_21: 9.04,
    app_flat_21: 43,
    char_22: 'pela',
    app_rate_22: '2.08%',
    avg_round_22: 6.54,
    app_flat_22: 28,
    char_23: 'argenti',
    app_rate_23: '2.05%',
    avg_round_23: 6.83,
    app_flat_23: 87,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.99%',
    avg_round_24: 9.75,
    app_flat_24: 22,
    char_25: 'bronya',
    app_rate_25: '1.6%',
    avg_round_25: 8.65,
    app_flat_25: 38,
    char_26: 'yunli',
    app_rate_26: '1.58%',
    avg_round_26: 7.89,
    app_flat_26: 36,
    char_27: 'tingyun',
    app_rate_27: '0.89%',
    avg_round_27: 8.36,
    app_flat_27: 26,
    char_28: 'bailu',
    app_rate_28: '0.89%',
    avg_round_28: 8.5,
    app_flat_28: 34,
    char_29: 'jingliu',
    app_rate_29: '0.87%',
    avg_round_29: 9.62,
    app_flat_29: 16,
    char_30: 'moze',
    app_rate_30: '0.74%',
    avg_round_30: 8.71,
    app_flat_30: 13
  },
  {
    char: 'lingsha',
    app: 40.2,
    char_1: 'ruan-mei',
    app_rate_1: '58.05%',
    avg_round_1: 8.57,
    app_flat_1: 1114,
    char_2: 'tingyun-fugue',
    app_rate_2: '47.17%',
    avg_round_2: 8.48,
    app_flat_2: 903,
    char_3: 'firefly',
    app_rate_3: '38.8%',
    avg_round_3: 9.09,
    app_flat_3: 509,
    char_4: 'the-herta',
    app_rate_4: '30.49%',
    avg_round_4: 7.48,
    app_flat_4: 1070,
    char_5: 'trailblazer-harmony',
    app_rate_5: '18.01%',
    avg_round_5: 8.81,
    app_flat_5: 317,
    char_6: 'rappa',
    app_rate_6: '17.21%',
    avg_round_6: 8.1,
    app_flat_6: 614,
    char_7: 'jade',
    app_rate_7: '15.85%',
    avg_round_7: 7.15,
    app_flat_7: 495,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '13.38%',
    avg_round_8: 8.25,
    app_flat_8: 528,
    char_9: 'robin',
    app_rate_9: '10.83%',
    avg_round_9: 7.73,
    app_flat_9: 303,
    char_10: 'herta',
    app_rate_10: '10.35%',
    avg_round_10: 8.17,
    app_flat_10: 405,
    char_11: 'tribbie',
    app_rate_11: '10.08%',
    avg_round_11: 6.28,
    app_flat_11: 280,
    char_12: 'sunday',
    app_rate_12: '7.24%',
    avg_round_12: 7.86,
    app_flat_12: 204,
    char_13: 'serval',
    app_rate_13: '5.01%',
    avg_round_13: 8.0,
    app_flat_13: 179,
    char_14: 'acheron',
    app_rate_14: '3.06%',
    avg_round_14: 8.12,
    app_flat_14: 49,
    char_15: 'lingsha',
    app_rate_15: '3.03%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'aglaea',
    app_rate_16: '2.35%',
    avg_round_16: 7.87,
    app_flat_16: 79,
    char_17: 'jiaoqiu',
    app_rate_17: '1.79%',
    avg_round_17: 8.38,
    app_flat_17: 30,
    char_18: 'sparkle',
    app_rate_18: '1.74%',
    avg_round_18: 8.07,
    app_flat_18: 26,
    char_19: 'pela',
    app_rate_19: '1.23%',
    avg_round_19: 8.21,
    app_flat_19: 29,
    char_20: 'feixiao',
    app_rate_20: '0.99%',
    avg_round_20: 8.9,
    app_flat_20: 20,
    char_21: 'jing-yuan',
    app_rate_21: '0.8%',
    avg_round_21: 9.5,
    app_flat_21: 18,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '0.77%',
    avg_round_22: 9.0,
    app_flat_22: 20,
    char_23: 'argenti',
    app_rate_23: '0.72%',
    avg_round_23: 6.32,
    app_flat_23: 22,
    char_24: 'bronya',
    app_rate_24: '0.68%',
    avg_round_24: 8.58,
    app_flat_24: 12,
    char_25: 'boothill',
    app_rate_25: '0.61%',
    avg_round_25: 8.25,
    app_flat_25: 16,
    char_26: 'kafka',
    app_rate_26: '0.37%',
    avg_round_26: 5.5,
    app_flat_26: 2,
    char_27: 'jingliu',
    app_rate_27: '0.36%',
    avg_round_27: 7.83,
    app_flat_27: 6,
    char_28: 'black-swan',
    app_rate_28: '0.31%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'tingyun',
    app_rate_29: '0.24%',
    avg_round_29: 7.38,
    app_flat_29: 8,
    char_30: 'topaz',
    app_rate_30: '0.2%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'tingyun-fugue',
    app: 36.19,
    char_1: 'ruan-mei',
    app_rate_1: '91.64%',
    avg_round_1: 8.28,
    app_flat_1: 1649,
    char_2: 'firefly',
    app_rate_2: '62.61%',
    avg_round_2: 8.9,
    app_flat_2: 747,
    char_3: 'lingsha',
    app_rate_3: '52.4%',
    avg_round_3: 8.48,
    app_flat_3: 903,
    char_4: 'rappa',
    app_rate_4: '30.35%',
    avg_round_4: 7.91,
    app_flat_4: 961,
    char_5: 'trailblazer-harmony',
    app_rate_5: '25.91%',
    avg_round_5: 6.52,
    app_flat_5: 421,
    char_6: 'gallagher',
    app_rate_6: '21.82%',
    avg_round_6: 8.94,
    app_flat_6: 525,
    char_7: 'boothill',
    app_rate_7: '3.67%',
    avg_round_7: 7.94,
    app_flat_7: 70,
    char_8: 'aventurine',
    app_rate_8: '1.97%',
    avg_round_8: 8.34,
    app_flat_8: 38,
    char_9: 'acheron',
    app_rate_9: '1.74%',
    avg_round_9: 9.06,
    app_flat_9: 36,
    char_10: 'huohuo',
    app_rate_10: '1.04%',
    avg_round_10: 8.86,
    app_flat_10: 21,
    char_11: 'luocha',
    app_rate_11: '1.0%',
    avg_round_11: 9.1,
    app_flat_11: 18,
    char_12: 'bronya',
    app_rate_12: '0.89%',
    avg_round_12: 2,
    app_flat_12: 1,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '0.85%',
    avg_round_13: 7,
    app_flat_13: 9,
    char_14: 'sunday',
    app_rate_14: '0.85%',
    avg_round_14: 8.33,
    app_flat_14: 3,
    char_15: 'pela',
    app_rate_15: '0.76%',
    avg_round_15: 5.95,
    app_flat_15: 20,
    char_16: 'jiaoqiu',
    app_rate_16: '0.62%',
    avg_round_16: 8.82,
    app_flat_16: 17,
    char_17: 'fu-xuan',
    app_rate_17: '0.53%',
    avg_round_17: 9.6,
    app_flat_17: 5,
    char_18: 'tribbie',
    app_rate_18: '0.49%',
    avg_round_18: 6.83,
    app_flat_18: 6,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '0.32%',
    avg_round_19: 7.8,
    app_flat_19: 5,
    char_20: 'silver-wolf',
    app_rate_20: '0.25%',
    avg_round_20: 10.0,
    app_flat_20: 6,
    char_21: 'xueyi',
    app_rate_21: '0.23%',
    avg_round_21: 9,
    app_flat_21: 6,
    char_22: 'jade',
    app_rate_22: '0.19%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'himeko',
    app_rate_23: '0.19%',
    avg_round_23: 7.5,
    app_flat_23: 2,
    char_24: 'gepard',
    app_rate_24: '0.15%',
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'sparkle',
    app_rate_25: '0.13%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'welt',
    app_rate_26: '0.13%',
    avg_round_26: 8,
    app_flat_26: 3,
    char_27: 'the-herta',
    app_rate_27: '0.11%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'kafka',
    app_rate_28: '0.11%',
    avg_round_28: 7,
    app_flat_28: 2,
    char_29: 'robin',
    app_rate_29: '0.09%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'lynx',
    app_rate_30: '0.06%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'firefly',
    app: 31.05,
    char_1: 'ruan-mei',
    app_rate_1: '95.79%',
    avg_round_1: 9.07,
    app_flat_1: 977,
    char_2: 'tingyun-fugue',
    app_rate_2: '72.98%',
    avg_round_2: 8.9,
    app_flat_2: 747,
    char_3: 'lingsha',
    app_rate_3: '50.23%',
    avg_round_3: 9.09,
    app_flat_3: 509,
    char_4: 'trailblazer-harmony',
    app_rate_4: '45.96%',
    avg_round_4: 8.9,
    app_flat_4: 432,
    char_5: 'gallagher',
    app_rate_5: '28.39%',
    avg_round_5: 9.52,
    app_flat_5: 342,
    char_6: 'rappa',
    app_rate_6: '1.56%',
    avg_round_6: 7.67,
    app_flat_6: 15,
    char_7: 'luocha',
    app_rate_7: '0.79%',
    avg_round_7: 10.0,
    app_flat_7: 8,
    char_8: 'aventurine',
    app_rate_8: '0.71%',
    avg_round_8: 10.0,
    app_flat_8: 4,
    char_9: 'bronya',
    app_rate_9: '0.57%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '0.55%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'huohuo',
    app_rate_11: '0.51%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'tribbie',
    app_rate_12: '0.44%',
    avg_round_12: 9.0,
    app_flat_12: 5,
    char_13: 'pela',
    app_rate_13: '0.24%',
    avg_round_13: 6,
    app_flat_13: 1,
    char_14: 'fu-xuan',
    app_rate_14: '0.2%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '0.15%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'himeko',
    app_rate_16: '0.15%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'sunday',
    app_rate_17: '0.13%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'silver-wolf',
    app_rate_18: '0.11%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'gepard',
    app_rate_19: '0.09%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'welt',
    app_rate_20: '0.09%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'acheron',
    app_rate_21: '0.07%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'robin',
    app_rate_22: '0.04%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'asta',
    app_rate_23: '0.04%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'boothill',
    app_rate_24: '0.02%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'xueyi',
    app_rate_25: '0.02%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'bailu',
    app_rate_26: '0.02%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'march-7th',
    app_rate_27: '0.02%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'serval',
    app_rate_28: '0.02%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'guinaifen',
    app_rate_29: '0.02%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'sparkle',
    app_rate_30: '0.02%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'gallagher',
    app: 29.17,
    char_1: 'ruan-mei',
    app_rate_1: '47.6%',
    avg_round_1: 9.05,
    app_flat_1: 872,
    char_2: 'firefly',
    app_rate_2: '30.21%',
    avg_round_2: 9.52,
    app_flat_2: 342,
    char_3: 'tingyun-fugue',
    app_rate_3: '27.07%',
    avg_round_3: 8.94,
    app_flat_3: 525,
    char_4: 'trailblazer-harmony',
    app_rate_4: '24.68%',
    avg_round_4: 9.05,
    app_flat_4: 470,
    char_5: 'the-herta',
    app_rate_5: '20.62%',
    avg_round_5: 7.73,
    app_flat_5: 662,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '17.95%',
    avg_round_6: 8.03,
    app_flat_6: 529,
    char_7: 'rappa',
    app_rate_7: '16.94%',
    avg_round_7: 8.57,
    app_flat_7: 546,
    char_8: 'sunday',
    app_rate_8: '15.58%',
    avg_round_8: 7.7,
    app_flat_8: 311,
    char_9: 'acheron',
    app_rate_9: '12.76%',
    avg_round_9: 8.5,
    app_flat_9: 227,
    char_10: 'robin',
    app_rate_10: '10.86%',
    avg_round_10: 7.63,
    app_flat_10: 181,
    char_11: 'jiaoqiu',
    app_rate_11: '9.41%',
    avg_round_11: 8.4,
    app_flat_11: 207,
    char_12: 'herta',
    app_rate_12: '9.24%',
    avg_round_12: 8.31,
    app_flat_12: 305,
    char_13: 'tribbie',
    app_rate_13: '8.68%',
    avg_round_13: 6.98,
    app_flat_13: 208,
    char_14: 'pela',
    app_rate_14: '7.3%',
    avg_round_14: 8.59,
    app_flat_14: 187,
    char_15: 'serval',
    app_rate_15: '7.25%',
    avg_round_15: 7.66,
    app_flat_15: 225,
    char_16: 'aglaea',
    app_rate_16: '4.57%',
    avg_round_16: 7.6,
    app_flat_16: 105,
    char_17: 'jade',
    app_rate_17: '4.11%',
    avg_round_17: 7.74,
    app_flat_17: 125,
    char_18: 'sparkle',
    app_rate_18: '4.06%',
    avg_round_18: 8.82,
    app_flat_18: 31,
    char_19: 'jing-yuan',
    app_rate_19: '3.47%',
    avg_round_19: 7.67,
    app_flat_19: 63,
    char_20: 'mydei',
    app_rate_20: '3.1%',
    avg_round_20: 7.58,
    app_flat_20: 60,
    char_21: 'imbibitor-lunae',
    app_rate_21: '2.04%',
    avg_round_21: 9.14,
    app_flat_21: 11,
    char_22: 'bronya',
    app_rate_22: '1.88%',
    avg_round_22: 8.18,
    app_flat_22: 22,
    char_23: 'boothill',
    app_rate_23: '1.52%',
    avg_round_23: 9.37,
    app_flat_23: 37,
    char_24: 'argenti',
    app_rate_24: '1.24%',
    avg_round_24: 6.24,
    app_flat_24: 34,
    char_25: 'kafka',
    app_rate_25: '1.13%',
    avg_round_25: 8.86,
    app_flat_25: 7,
    char_26: 'feixiao',
    app_rate_26: '1.08%',
    avg_round_26: 8,
    app_flat_26: 15,
    char_27: 'black-swan',
    app_rate_27: '1.01%',
    avg_round_27: 10.0,
    app_flat_27: 6,
    char_28: 'silver-wolf',
    app_rate_28: '0.94%',
    avg_round_28: 8.92,
    app_flat_28: 12,
    char_29: 'tingyun',
    app_rate_29: '0.68%',
    avg_round_29: 8.44,
    app_flat_29: 18,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.63%',
    avg_round_30: 9.75,
    app_flat_30: 8
  },
  {
    char: 'huohuo',
    app: 28.59,
    char_1: 'sunday',
    app_rate_1: '46.83%',
    avg_round_1: 7.71,
    app_flat_1: 972,
    char_2: 'robin',
    app_rate_2: '40.58%',
    avg_round_2: 7.81,
    app_flat_2: 801,
    char_3: 'the-herta',
    app_rate_3: '39.21%',
    avg_round_3: 8.32,
    app_flat_3: 1010,
    char_4: 'aglaea',
    app_rate_4: '33.76%',
    avg_round_4: 7.51,
    app_flat_4: 794,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '32.2%',
    avg_round_5: 8.09,
    app_flat_5: 891,
    char_6: 'herta',
    app_rate_6: '16.38%',
    avg_round_6: 8.61,
    app_flat_6: 465,
    char_7: 'tribbie',
    app_rate_7: '15.27%',
    avg_round_7: 7.26,
    app_flat_7: 329,
    char_8: 'jade',
    app_rate_8: '12.11%',
    avg_round_8: 8.12,
    app_flat_8: 285,
    char_9: 'serval',
    app_rate_9: '9.24%',
    avg_round_9: 7.83,
    app_flat_9: 225,
    char_10: 'sparkle',
    app_rate_10: '7.42%',
    avg_round_10: 9.03,
    app_flat_10: 65,
    char_11: 'ruan-mei',
    app_rate_11: '5.96%',
    avg_round_11: 8.91,
    app_flat_11: 65,
    char_12: 'jing-yuan',
    app_rate_12: '5.87%',
    avg_round_12: 9.0,
    app_flat_12: 90,
    char_13: 'imbibitor-lunae',
    app_rate_13: '4.14%',
    avg_round_13: 9.36,
    app_flat_13: 19,
    char_14: 'yunli',
    app_rate_14: '4.14%',
    avg_round_14: 8.46,
    app_flat_14: 63,
    char_15: 'jingliu',
    app_rate_15: '2.85%',
    avg_round_15: 8.89,
    app_flat_15: 37,
    char_16: 'acheron',
    app_rate_16: '2.51%',
    avg_round_16: 8.22,
    app_flat_16: 9,
    char_17: 'tingyun',
    app_rate_17: '2.13%',
    avg_round_17: 9.0,
    app_flat_17: 26,
    char_18: 'kafka',
    app_rate_18: '2.03%',
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: '1.94%',
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'argenti',
    app_rate_20: '1.89%',
    avg_round_20: 7.43,
    app_flat_20: 46,
    char_21: 'mydei',
    app_rate_21: '1.77%',
    avg_round_21: 7.58,
    app_flat_21: 40,
    char_22: 'jiaoqiu',
    app_rate_22: '1.7%',
    avg_round_22: 7.7,
    app_flat_22: 10,
    char_23: 'bronya',
    app_rate_23: '1.6%',
    avg_round_23: 8.4,
    app_flat_23: 25,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.32%',
    avg_round_24: 8.86,
    app_flat_24: 21,
    char_25: 'rappa',
    app_rate_25: '1.24%',
    avg_round_25: 8.88,
    app_flat_25: 26,
    char_26: 'pela',
    app_rate_26: '1.22%',
    avg_round_26: 8,
    app_flat_26: 17,
    char_27: 'trailblazer-harmony',
    app_rate_27: '0.65%',
    avg_round_27: 9,
    app_flat_27: 11,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.6%',
    avg_round_28: 8.92,
    app_flat_28: 12,
    char_29: 'feixiao',
    app_rate_29: '0.57%',
    avg_round_29: 8.91,
    app_flat_29: 11,
    char_30: 'firefly',
    app_rate_30: '0.55%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'acheron',
    app: 27.91,
    char_1: 'jiaoqiu',
    app_rate_1: '64.08%',
    avg_round_1: 8.55,
    app_flat_1: 855,
    char_2: 'pela',
    app_rate_2: '46.3%',
    avg_round_2: 8.66,
    app_flat_2: 765,
    char_3: 'aventurine',
    app_rate_3: '45.54%',
    avg_round_3: 8.7,
    app_flat_3: 539,
    char_4: 'sparkle',
    app_rate_4: '31.07%',
    avg_round_4: 9.0,
    app_flat_4: 34,
    char_5: 'fu-xuan',
    app_rate_5: '22.81%',
    avg_round_5: 8.73,
    app_flat_5: 164,
    char_6: 'gallagher',
    app_rate_6: '13.34%',
    avg_round_6: 8.5,
    app_flat_6: 227,
    char_7: 'sunday',
    app_rate_7: '11.53%',
    avg_round_7: 8.68,
    app_flat_7: 37,
    char_8: 'silver-wolf',
    app_rate_8: '9.32%',
    avg_round_8: 9.22,
    app_flat_8: 79,
    char_9: 'tribbie',
    app_rate_9: '7.87%',
    avg_round_9: 7.2,
    app_flat_9: 61,
    char_10: 'robin',
    app_rate_10: '7.82%',
    avg_round_10: 8.14,
    app_flat_10: 42,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '6.47%',
    avg_round_11: 9.2,
    app_flat_11: 58,
    char_12: 'bronya',
    app_rate_12: '5.37%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'black-swan',
    app_rate_13: '5.2%',
    avg_round_13: 9.42,
    app_flat_13: 37,
    char_14: 'kafka',
    app_rate_14: '4.46%',
    avg_round_14: 8.9,
    app_flat_14: 41,
    char_15: 'lingsha',
    app_rate_15: '4.41%',
    avg_round_15: 8.12,
    app_flat_15: 49,
    char_16: 'ruan-mei',
    app_rate_16: '3.29%',
    avg_round_16: 9.2,
    app_flat_16: 9,
    char_17: 'gepard',
    app_rate_17: '3.07%',
    avg_round_17: 9.69,
    app_flat_17: 25,
    char_18: 'huohuo',
    app_rate_18: '2.57%',
    avg_round_18: 8.22,
    app_flat_18: 9,
    char_19: 'tingyun-fugue',
    app_rate_19: '2.26%',
    avg_round_19: 9.06,
    app_flat_19: 36,
    char_20: 'luocha',
    app_rate_20: '1.64%',
    avg_round_20: 8.67,
    app_flat_20: 10,
    char_21: 'bailu',
    app_rate_21: '0.37%',
    avg_round_21: 9.33,
    app_flat_21: 3,
    char_22: 'guinaifen',
    app_rate_22: '0.2%',
    avg_round_22: 9.67,
    app_flat_22: 5,
    char_23: 'welt',
    app_rate_23: '0.17%',
    avg_round_23: 9.5,
    app_flat_23: 4,
    char_24: 'feixiao',
    app_rate_24: '0.12%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'aglaea',
    app_rate_25: '0.12%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'firefly',
    app_rate_26: '0.07%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'lynx',
    app_rate_27: '0.07%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'march-7th',
    app_rate_28: '0.07%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'topaz',
    app_rate_29: '0.05%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'yunli',
    app_rate_30: '0.05%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'herta',
    app: 27.15,
    char_1: 'the-herta',
    app_rate_1: '97.98%',
    avg_round_1: 8.49,
    app_flat_1: 2526,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '44.44%',
    avg_round_2: 8.56,
    app_flat_2: 1276,
    char_3: 'aventurine',
    app_rate_3: '27.96%',
    avg_round_3: 8.56,
    app_flat_3: 775,
    char_4: 'tribbie',
    app_rate_4: '22.54%',
    avg_round_4: 6.97,
    app_flat_4: 514,
    char_5: 'robin',
    app_rate_5: '19.11%',
    avg_round_5: 8.91,
    app_flat_5: 411,
    char_6: 'huohuo',
    app_rate_6: '17.24%',
    avg_round_6: 8.61,
    app_flat_6: 465,
    char_7: 'fu-xuan',
    app_rate_7: '15.38%',
    avg_round_7: 8.95,
    app_flat_7: 340,
    char_8: 'lingsha',
    app_rate_8: '15.33%',
    avg_round_8: 8.17,
    app_flat_8: 405,
    char_9: 'sunday',
    app_rate_9: '12.91%',
    avg_round_9: 8.72,
    app_flat_9: 315,
    char_10: 'gallagher',
    app_rate_10: '9.93%',
    avg_round_10: 8.31,
    app_flat_10: 305,
    char_11: 'luocha',
    app_rate_11: '6.65%',
    avg_round_11: 8.77,
    app_flat_11: 178,
    char_12: 'ruan-mei',
    app_rate_12: '3.23%',
    avg_round_12: 8.5,
    app_flat_12: 34,
    char_13: 'sparkle',
    app_rate_13: '2.42%',
    avg_round_13: 9.15,
    app_flat_13: 38,
    char_14: 'jade',
    app_rate_14: '1.94%',
    avg_round_14: 9.1,
    app_flat_14: 41,
    char_15: 'pela',
    app_rate_15: '0.73%',
    avg_round_15: 5.75,
    app_flat_15: 12,
    char_16: 'bailu',
    app_rate_16: '0.48%',
    avg_round_16: 8.87,
    app_flat_16: 15,
    char_17: 'bronya',
    app_rate_17: '0.4%',
    avg_round_17: 9.4,
    app_flat_17: 9,
    char_18: 'gepard',
    app_rate_18: '0.25%',
    avg_round_18: 9.67,
    app_flat_18: 6,
    char_19: 'march-7th',
    app_rate_19: '0.23%',
    avg_round_19: 7.75,
    app_flat_19: 4,
    char_20: 'tingyun',
    app_rate_20: '0.2%',
    avg_round_20: 8.33,
    app_flat_20: 3,
    char_21: 'lynx',
    app_rate_21: '0.1%',
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'feixiao',
    app_rate_22: '0.08%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'jiaoqiu',
    app_rate_23: '0.08%',
    avg_round_23: 5,
    app_flat_23: 1,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.05%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'hanya',
    app_rate_25: '0.03%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'moze',
    app_rate_26: '0.03%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'silver-wolf',
    app_rate_27: '0.03%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'natasha',
    app_rate_28: '0.03%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'trailblazer-preservation',
    app_rate_29: '0.03%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.03%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'tribbie',
    app: 25.91,
    char_1: 'the-herta',
    app_rate_1: '59.38%',
    avg_round_1: 6.5,
    app_flat_1: 1204,
    char_2: 'herta',
    app_rate_2: '23.61%',
    avg_round_2: 6.97,
    app_flat_2: 514,
    char_3: 'aventurine',
    app_rate_3: '20.42%',
    avg_round_3: 6.99,
    app_flat_3: 426,
    char_4: 'jade',
    app_rate_4: '20.39%',
    avg_round_4: 6.42,
    app_flat_4: 350,
    char_5: 'sunday',
    app_rate_5: '18.46%',
    avg_round_5: 7,
    app_flat_5: 225,
    char_6: 'huohuo',
    app_rate_6: '16.85%',
    avg_round_6: 7.26,
    app_flat_6: 329,
    char_7: 'lingsha',
    app_rate_7: '15.64%',
    avg_round_7: 6.28,
    app_flat_7: 280,
    char_8: 'serval',
    app_rate_8: '14.61%',
    avg_round_8: 6.15,
    app_flat_8: 295,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '12.47%',
    avg_round_9: 5.53,
    app_flat_9: 196,
    char_10: 'fu-xuan',
    app_rate_10: '12.04%',
    avg_round_10: 7.45,
    app_flat_10: 172,
    char_11: 'gallagher',
    app_rate_11: '9.77%',
    avg_round_11: 6.98,
    app_flat_11: 208,
    char_12: 'acheron',
    app_rate_12: '8.48%',
    avg_round_12: 7.2,
    app_flat_12: 61,
    char_13: 'robin',
    app_rate_13: '7.24%',
    avg_round_13: 6.88,
    app_flat_13: 60,
    char_14: 'luocha',
    app_rate_14: '6.6%',
    avg_round_14: 7.61,
    app_flat_14: 119,
    char_15: 'mydei',
    app_rate_15: '6.44%',
    avg_round_15: 6.87,
    app_flat_15: 99,
    char_16: 'aglaea',
    app_rate_16: '5.18%',
    avg_round_16: 6.92,
    app_flat_16: 78,
    char_17: 'jing-yuan',
    app_rate_17: '4.86%',
    avg_round_17: 7.83,
    app_flat_17: 76,
    char_18: 'jiaoqiu',
    app_rate_18: '4.86%',
    avg_round_18: 6.69,
    app_flat_18: 32,
    char_19: 'sparkle',
    app_rate_19: '4.78%',
    avg_round_19: 6.96,
    app_flat_19: 27,
    char_20: 'pela',
    app_rate_20: '3.7%',
    avg_round_20: 7.42,
    app_flat_20: 35,
    char_21: 'argenti',
    app_rate_21: '3.46%',
    avg_round_21: 5.15,
    app_flat_21: 71,
    char_22: 'feixiao',
    app_rate_22: '2.98%',
    avg_round_22: 8.23,
    app_flat_22: 42,
    char_23: 'yunli',
    app_rate_23: '2.48%',
    avg_round_23: 8.12,
    app_flat_23: 30,
    char_24: 'ruan-mei',
    app_rate_24: '2.38%',
    avg_round_24: 5.79,
    app_flat_24: 14,
    char_25: 'bronya',
    app_rate_25: '1.37%',
    avg_round_25: 5.55,
    app_flat_25: 20,
    char_26: 'qingque',
    app_rate_26: '1.24%',
    avg_round_26: 7.33,
    app_flat_26: 15,
    char_27: 'jingliu',
    app_rate_27: '0.98%',
    avg_round_27: 8.17,
    app_flat_27: 12,
    char_28: 'blade',
    app_rate_28: '0.85%',
    avg_round_28: 8.43,
    app_flat_28: 7,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.85%',
    avg_round_29: 9,
    app_flat_29: 3,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.74%',
    avg_round_30: 8.77,
    app_flat_30: 13
  },
  {
    char: 'jade',
    app: 23.32,
    char_1: 'the-herta',
    app_rate_1: '89.38%',
    avg_round_1: 7.92,
    app_flat_1: 1720,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '30.22%',
    avg_round_2: 7.91,
    app_flat_2: 678,
    char_3: 'robin',
    app_rate_3: '30.16%',
    avg_round_3: 8.29,
    app_flat_3: 502,
    char_4: 'aventurine',
    app_rate_4: '28.49%',
    avg_round_4: 7.67,
    app_flat_4: 543,
    char_5: 'lingsha',
    app_rate_5: '27.32%',
    avg_round_5: 7.15,
    app_flat_5: 495,
    char_6: 'tribbie',
    app_rate_6: '22.65%',
    avg_round_6: 6.42,
    app_flat_6: 350,
    char_7: 'huohuo',
    app_rate_7: '14.85%',
    avg_round_7: 8.12,
    app_flat_7: 285,
    char_8: 'sunday',
    app_rate_8: '12.71%',
    avg_round_8: 8.28,
    app_flat_8: 226,
    char_9: 'fu-xuan',
    app_rate_9: '12.62%',
    avg_round_9: 8.59,
    app_flat_9: 202,
    char_10: 'luocha',
    app_rate_10: '6.22%',
    avg_round_10: 8.23,
    app_flat_10: 132,
    char_11: 'ruan-mei',
    app_rate_11: '5.55%',
    avg_round_11: 8.11,
    app_flat_11: 74,
    char_12: 'gallagher',
    app_rate_12: '5.13%',
    avg_round_12: 7.74,
    app_flat_12: 125,
    char_13: 'sparkle',
    app_rate_13: '3.11%',
    avg_round_13: 8.41,
    app_flat_13: 31,
    char_14: 'herta',
    app_rate_14: '2.26%',
    avg_round_14: 9.1,
    app_flat_14: 41,
    char_15: 'feixiao',
    app_rate_15: '1.88%',
    avg_round_15: 8.67,
    app_flat_15: 5,
    char_16: 'blade',
    app_rate_16: '1.06%',
    avg_round_16: 8.44,
    app_flat_16: 9,
    char_17: 'jing-yuan',
    app_rate_17: '0.82%',
    avg_round_17: 9.67,
    app_flat_17: 15,
    char_18: 'bronya',
    app_rate_18: '0.73%',
    avg_round_18: 8.4,
    app_flat_18: 10,
    char_19: 'aglaea',
    app_rate_19: '0.59%',
    avg_round_19: 8.18,
    app_flat_19: 11,
    char_20: 'bailu',
    app_rate_20: '0.53%',
    avg_round_20: 8.5,
    app_flat_20: 10,
    char_21: 'pela',
    app_rate_21: '0.5%',
    avg_round_21: 6.67,
    app_flat_21: 3,
    char_22: 'lynx',
    app_rate_22: '0.35%',
    avg_round_22: 8.5,
    app_flat_22: 8,
    char_23: 'jiaoqiu',
    app_rate_23: '0.32%',
    avg_round_23: 6,
    app_flat_23: 3,
    char_24: 'tingyun',
    app_rate_24: '0.32%',
    avg_round_24: 8.14,
    app_flat_24: 7,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.29%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'yunli',
    app_rate_26: '0.26%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'topaz',
    app_rate_27: '0.26%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'gepard',
    app_rate_28: '0.18%',
    avg_round_28: 9.67,
    app_flat_28: 5,
    char_29: 'mydei',
    app_rate_29: '0.15%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'clara',
    app_rate_30: '0.12%',
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'trailblazer-harmony',
    app: 22.04,
    char_1: 'ruan-mei',
    app_rate_1: '89.6%',
    avg_round_1: 8.39,
    app_flat_1: 996,
    char_2: 'firefly',
    app_rate_2: '64.73%',
    avg_round_2: 8.9,
    app_flat_2: 432,
    char_3: 'tingyun-fugue',
    app_rate_3: '42.53%',
    avg_round_3: 6.52,
    app_flat_3: 421,
    char_4: 'lingsha',
    app_rate_4: '32.85%',
    avg_round_4: 8.81,
    app_flat_4: 317,
    char_5: 'gallagher',
    app_rate_5: '32.66%',
    avg_round_5: 9.05,
    app_flat_5: 470,
    char_6: 'rappa',
    app_rate_6: '30.95%',
    avg_round_6: 8.2,
    app_flat_6: 669,
    char_7: 'boothill',
    app_rate_7: '2.05%',
    avg_round_7: 7.7,
    app_flat_7: 37,
    char_8: 'aventurine',
    app_rate_8: '1.52%',
    avg_round_8: 8.96,
    app_flat_8: 25,
    char_9: 'luocha',
    app_rate_9: '1.21%',
    avg_round_9: 9,
    app_flat_9: 22,
    char_10: 'huohuo',
    app_rate_10: '0.84%',
    avg_round_10: 9,
    app_flat_10: 11,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '0.68%',
    avg_round_11: 8.6,
    app_flat_11: 7,
    char_12: 'tribbie',
    app_rate_12: '0.53%',
    avg_round_12: 7.43,
    app_flat_12: 7,
    char_13: 'pela',
    app_rate_13: '0.47%',
    avg_round_13: 9.4,
    app_flat_13: 9,
    char_14: 'welt',
    app_rate_14: '0.31%',
    avg_round_14: 8,
    app_flat_14: 3,
    char_15: 'bronya',
    app_rate_15: '0.28%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'sunday',
    app_rate_16: '0.22%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'fu-xuan',
    app_rate_17: '0.19%',
    avg_round_17: 8.67,
    app_flat_17: 3,
    char_18: 'silver-wolf',
    app_rate_18: '0.16%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'xueyi',
    app_rate_19: '0.09%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'asta',
    app_rate_20: '0.06%',
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'himeko',
    app_rate_21: '0.06%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'bailu',
    app_rate_22: '0.06%',
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'the-herta',
    app_rate_23: '0.03%',
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'march-7th',
    app_rate_24: '0.03%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'robin',
    app_rate_25: '0.03%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jade',
    app_rate_26: '0.03%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'fu-xuan',
    app: 20.64,
    char_1: 'the-herta',
    app_rate_1: '42.41%',
    avg_round_1: 8.8,
    app_flat_1: 686,
    char_2: 'acheron',
    app_rate_2: '30.84%',
    avg_round_2: 8.73,
    app_flat_2: 164,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '25.5%',
    avg_round_3: 8.72,
    app_flat_3: 427,
    char_4: 'robin',
    app_rate_4: '21.19%',
    avg_round_4: 8.97,
    app_flat_4: 226,
    char_5: 'sparkle',
    app_rate_5: '20.95%',
    avg_round_5: 9.22,
    app_flat_5: 53,
    char_6: 'sunday',
    app_rate_6: '20.46%',
    avg_round_6: 8.63,
    app_flat_6: 231,
    char_7: 'herta',
    app_rate_7: '20.23%',
    avg_round_7: 8.95,
    app_flat_7: 340,
    char_8: 'jiaoqiu',
    app_rate_8: '17.11%',
    avg_round_8: 8.56,
    app_flat_8: 134,
    char_9: 'tribbie',
    app_rate_9: '15.12%',
    avg_round_9: 7.45,
    app_flat_9: 172,
    char_10: 'pela',
    app_rate_10: '14.89%',
    avg_round_10: 8.71,
    app_flat_10: 134,
    char_11: 'jade',
    app_rate_11: '14.26%',
    avg_round_11: 8.59,
    app_flat_11: 202,
    char_12: 'aglaea',
    app_rate_12: '9.81%',
    avg_round_12: 8.26,
    app_flat_12: 121,
    char_13: 'serval',
    app_rate_13: '8.32%',
    avg_round_13: 8.51,
    app_flat_13: 138,
    char_14: 'silver-wolf',
    app_rate_14: '5.97%',
    avg_round_14: 9.46,
    app_flat_14: 23,
    char_15: 'ruan-mei',
    app_rate_15: '4.38%',
    avg_round_15: 8.55,
    app_flat_15: 22,
    char_16: 'jing-yuan',
    app_rate_16: '4.08%',
    avg_round_16: 8.77,
    app_flat_16: 44,
    char_17: 'bronya',
    app_rate_17: '3.02%',
    avg_round_17: 8.44,
    app_flat_17: 16,
    char_18: 'feixiao',
    app_rate_18: '2.69%',
    avg_round_18: 8.57,
    app_flat_18: 21,
    char_19: 'qingque',
    app_rate_19: '2.02%',
    avg_round_19: 8.78,
    app_flat_19: 15,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '1.82%',
    avg_round_20: 8.33,
    app_flat_20: 15,
    char_21: 'black-swan',
    app_rate_21: '1.79%',
    avg_round_21: 9.0,
    app_flat_21: 4,
    char_22: 'yunli',
    app_rate_22: '1.56%',
    avg_round_22: 7.78,
    app_flat_22: 9,
    char_23: 'kafka',
    app_rate_23: '1.53%',
    avg_round_23: 8.8,
    app_flat_23: 7,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.53%',
    avg_round_24: 9.67,
    app_flat_24: 5,
    char_25: 'tingyun',
    app_rate_25: '1.29%',
    avg_round_25: 9.36,
    app_flat_25: 11,
    char_26: 'argenti',
    app_rate_26: '0.93%',
    avg_round_26: 8.86,
    app_flat_26: 13,
    char_27: 'tingyun-fugue',
    app_rate_27: '0.93%',
    avg_round_27: 9.6,
    app_flat_27: 5,
    char_28: 'seele',
    app_rate_28: '0.8%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'jingliu',
    app_rate_29: '0.8%',
    avg_round_29: 7,
    app_flat_29: 2,
    char_30: 'topaz',
    app_rate_30: '0.56%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'aglaea',
    app: 19.03,
    char_1: 'sunday',
    app_rate_1: '82.95%',
    avg_round_1: 7.61,
    app_flat_1: 1154,
    char_2: 'robin',
    app_rate_2: '68.02%',
    avg_round_2: 7.68,
    app_flat_2: 913,
    char_3: 'huohuo',
    app_rate_3: '50.72%',
    avg_round_3: 7.51,
    app_flat_3: 794,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '34.35%',
    avg_round_4: 7.98,
    app_flat_4: 510,
    char_5: 'fu-xuan',
    app_rate_5: '10.65%',
    avg_round_5: 8.26,
    app_flat_5: 121,
    char_6: 'bronya',
    app_rate_6: '10.22%',
    avg_round_6: 6.29,
    app_flat_6: 95,
    char_7: 'aventurine',
    app_rate_7: '7.34%',
    avg_round_7: 8.18,
    app_flat_7: 114,
    char_8: 'tribbie',
    app_rate_8: '7.05%',
    avg_round_8: 6.92,
    app_flat_8: 78,
    char_9: 'gallagher',
    app_rate_9: '7.01%',
    avg_round_9: 7.6,
    app_flat_9: 105,
    char_10: 'luocha',
    app_rate_10: '6.01%',
    avg_round_10: 7.64,
    app_flat_10: 83,
    char_11: 'lingsha',
    app_rate_11: '4.96%',
    avg_round_11: 7.87,
    app_flat_11: 79,
    char_12: 'ruan-mei',
    app_rate_12: '3.27%',
    avg_round_12: 8.31,
    app_flat_12: 32,
    char_13: 'tingyun',
    app_rate_13: '1.62%',
    avg_round_13: 9.08,
    app_flat_13: 23,
    char_14: 'sparkle',
    app_rate_14: '1.51%',
    avg_round_14: 8.08,
    app_flat_14: 12,
    char_15: 'bailu',
    app_rate_15: '1.26%',
    avg_round_15: 8,
    app_flat_15: 14,
    char_16: 'jade',
    app_rate_16: '0.72%',
    avg_round_16: 8.18,
    app_flat_16: 11,
    char_17: 'the-herta',
    app_rate_17: '0.32%',
    avg_round_17: 8.33,
    app_flat_17: 3,
    char_18: 'pela',
    app_rate_18: '0.29%',
    avg_round_18: 7.0,
    app_flat_18: 4,
    char_19: 'gepard',
    app_rate_19: '0.25%',
    avg_round_19: 8,
    app_flat_19: 3,
    char_20: 'asta',
    app_rate_20: '0.22%',
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'feixiao',
    app_rate_21: '0.22%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'lynx',
    app_rate_22: '0.18%',
    avg_round_22: 9,
    app_flat_22: 3,
    char_23: 'acheron',
    app_rate_23: '0.18%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.11%',
    avg_round_24: 2,
    app_flat_24: 1,
    char_25: 'natasha',
    app_rate_25: '0.11%',
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'hanya',
    app_rate_26: '0.07%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'tingyun-fugue',
    app_rate_27: '0.04%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'march-7th',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'black-swan',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.04%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'jiaoqiu',
    app: 18.9,
    char_1: 'acheron',
    app_rate_1: '94.64%',
    avg_round_1: 8.55,
    app_flat_1: 855,
    char_2: 'aventurine',
    app_rate_2: '46.76%',
    avg_round_2: 8.58,
    app_flat_2: 457,
    char_3: 'pela',
    app_rate_3: '31.84%',
    avg_round_3: 8.5,
    app_flat_3: 623,
    char_4: 'sparkle',
    app_rate_4: '28.14%',
    avg_round_4: 8.64,
    app_flat_4: 28,
    char_5: 'fu-xuan',
    app_rate_5: '18.69%',
    avg_round_5: 8.56,
    app_flat_5: 134,
    char_6: 'gallagher',
    app_rate_6: '14.52%',
    avg_round_6: 8.4,
    app_flat_6: 207,
    char_7: 'sunday',
    app_rate_7: '12.64%',
    avg_round_7: 7.8,
    app_flat_7: 47,
    char_8: 'robin',
    app_rate_8: '8.4%',
    avg_round_8: 7.97,
    app_flat_8: 35,
    char_9: 'tribbie',
    app_rate_9: '6.66%',
    avg_round_9: 6.69,
    app_flat_9: 32,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '5.69%',
    avg_round_10: 9.04,
    app_flat_10: 43,
    char_11: 'bronya',
    app_rate_11: '4.13%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'lingsha',
    app_rate_12: '3.8%',
    avg_round_12: 8.38,
    app_flat_12: 30,
    char_13: 'luocha',
    app_rate_13: '3.33%',
    avg_round_13: 8,
    app_flat_13: 18,
    char_14: 'silver-wolf',
    app_rate_14: '3.04%',
    avg_round_14: 8.5,
    app_flat_14: 32,
    char_15: 'ruan-mei',
    app_rate_15: '2.75%',
    avg_round_15: 8.0,
    app_flat_15: 5,
    char_16: 'huohuo',
    app_rate_16: '2.57%',
    avg_round_16: 7.7,
    app_flat_16: 10,
    char_17: 'mydei',
    app_rate_17: '1.74%',
    avg_round_17: 6.29,
    app_flat_17: 17,
    char_18: 'black-swan',
    app_rate_18: '1.67%',
    avg_round_18: 9.33,
    app_flat_18: 12,
    char_19: 'gepard',
    app_rate_19: '1.59%',
    avg_round_19: 9.62,
    app_flat_19: 16,
    char_20: 'kafka',
    app_rate_20: '1.38%',
    avg_round_20: 8.43,
    app_flat_20: 14,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.2%',
    avg_round_21: 8.82,
    app_flat_21: 17,
    char_22: 'dr-ratio',
    app_rate_22: '0.98%',
    avg_round_22: 9.29,
    app_flat_22: 7,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.76%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jade',
    app_rate_24: '0.4%',
    avg_round_24: 6,
    app_flat_24: 3,
    char_25: 'bailu',
    app_rate_25: '0.36%',
    avg_round_25: 9.33,
    app_flat_25: 3,
    char_26: 'the-herta',
    app_rate_26: '0.36%',
    avg_round_26: 5,
    app_flat_26: 2,
    char_27: 'feixiao',
    app_rate_27: '0.33%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'jing-yuan',
    app_rate_28: '0.33%',
    avg_round_28: 6,
    app_flat_28: 1,
    char_29: 'tingyun',
    app_rate_29: '0.18%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'topaz',
    app_rate_30: '0.14%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'sparkle',
    app: 16.71,
    char_1: 'acheron',
    app_rate_1: '51.88%',
    avg_round_1: 9.0,
    app_flat_1: 34,
    char_2: 'jiaoqiu',
    app_rate_2: '31.82%',
    avg_round_2: 8.64,
    app_flat_2: 28,
    char_3: 'aventurine',
    app_rate_3: '30.14%',
    avg_round_3: 9.35,
    app_flat_3: 68,
    char_4: 'fu-xuan',
    app_rate_4: '25.88%',
    avg_round_4: 9.22,
    app_flat_4: 53,
    char_5: 'imbibitor-lunae',
    app_rate_5: '18.59%',
    avg_round_5: 9.43,
    app_flat_5: 45,
    char_6: 'pela',
    app_rate_6: '14.29%',
    avg_round_6: 9.8,
    app_flat_6: 7,
    char_7: 'huohuo',
    app_rate_7: '12.69%',
    avg_round_7: 9.03,
    app_flat_7: 65,
    char_8: 'sunday',
    app_rate_8: '12.16%',
    avg_round_8: 9.03,
    app_flat_8: 71,
    char_9: 'the-herta',
    app_rate_9: '9.54%',
    avg_round_9: 9.02,
    app_flat_9: 80,
    char_10: 'robin',
    app_rate_10: '8.8%',
    avg_round_10: 8.86,
    app_flat_10: 13,
    char_11: 'luocha',
    app_rate_11: '8.31%',
    avg_round_11: 8.53,
    app_flat_11: 30,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '7.74%',
    avg_round_12: 8.64,
    app_flat_12: 49,
    char_13: 'tribbie',
    app_rate_13: '7.41%',
    avg_round_13: 6.96,
    app_flat_13: 27,
    char_14: 'gallagher',
    app_rate_14: '7.08%',
    avg_round_14: 8.82,
    app_flat_14: 31,
    char_15: 'silver-wolf',
    app_rate_15: '5.98%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'ruan-mei',
    app_rate_16: '5.49%',
    avg_round_16: 9.4,
    app_flat_16: 9,
    char_17: 'qingque',
    app_rate_17: '4.63%',
    avg_round_17: 8.85,
    app_flat_17: 40,
    char_18: 'jade',
    app_rate_18: '4.34%',
    avg_round_18: 8.41,
    app_flat_18: 31,
    char_19: 'lingsha',
    app_rate_19: '4.18%',
    avg_round_19: 8.07,
    app_flat_19: 26,
    char_20: 'mydei',
    app_rate_20: '3.97%',
    avg_round_20: 7.8,
    app_flat_20: 40,
    char_21: 'herta',
    app_rate_21: '3.93%',
    avg_round_21: 9.15,
    app_flat_21: 38,
    char_22: 'tingyun',
    app_rate_22: '3.11%',
    avg_round_22: 8.8,
    app_flat_22: 10,
    char_23: 'yunli',
    app_rate_23: '2.66%',
    avg_round_23: 9.18,
    app_flat_23: 11,
    char_24: 'jing-yuan',
    app_rate_24: '1.76%',
    avg_round_24: 8.9,
    app_flat_24: 10,
    char_25: 'aglaea',
    app_rate_25: '1.72%',
    avg_round_25: 8.08,
    app_flat_25: 12,
    char_26: 'serval',
    app_rate_26: '1.68%',
    avg_round_26: 9.27,
    app_flat_26: 11,
    char_27: 'gepard',
    app_rate_27: '1.47%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'seele',
    app_rate_28: '1.27%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'bronya',
    app_rate_29: '1.02%',
    avg_round_29: 7.33,
    app_flat_29: 3,
    char_30: 'jingliu',
    app_rate_30: '0.9%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'rappa',
    app: 15.39,
    char_1: 'ruan-mei',
    app_rate_1: '90.53%',
    avg_round_1: 8.14,
    app_flat_1: 1279,
    char_2: 'tingyun-fugue',
    app_rate_2: '71.37%',
    avg_round_2: 7.91,
    app_flat_2: 961,
    char_3: 'lingsha',
    app_rate_3: '44.95%',
    avg_round_3: 8.1,
    app_flat_3: 614,
    char_4: 'trailblazer-harmony',
    app_rate_4: '44.33%',
    avg_round_4: 8.2,
    app_flat_4: 669,
    char_5: 'gallagher',
    app_rate_5: '32.1%',
    avg_round_5: 8.57,
    app_flat_5: 546,
    char_6: 'aventurine',
    app_rate_6: '3.47%',
    avg_round_6: 8.34,
    app_flat_6: 41,
    char_7: 'firefly',
    app_rate_7: '3.16%',
    avg_round_7: 7.67,
    app_flat_7: 15,
    char_8: 'huohuo',
    app_rate_8: '2.31%',
    avg_round_8: 8.88,
    app_flat_8: 26,
    char_9: 'luocha',
    app_rate_9: '2.05%',
    avg_round_9: 8.88,
    app_flat_9: 30,
    char_10: 'the-herta',
    app_rate_10: '0.98%',
    avg_round_10: 9.4,
    app_flat_10: 9,
    char_11: 'tribbie',
    app_rate_11: '0.84%',
    avg_round_11: 7.75,
    app_flat_11: 8,
    char_12: 'pela',
    app_rate_12: '0.84%',
    avg_round_12: 8.86,
    app_flat_12: 13,
    char_13: 'fu-xuan',
    app_rate_13: '0.58%',
    avg_round_13: 9,
    app_flat_13: 4,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '0.49%',
    avg_round_14: 7.67,
    app_flat_14: 6,
    char_15: 'sunday',
    app_rate_15: '0.4%',
    avg_round_15: 9.33,
    app_flat_15: 3,
    char_16: 'silver-wolf',
    app_rate_16: '0.27%',
    avg_round_16: 10,
    app_flat_16: 3,
    char_17: 'robin',
    app_rate_17: '0.22%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'boothill',
    app_rate_18: '0.22%',
    avg_round_18: 7,
    app_flat_18: 2,
    char_19: 'sparkle',
    app_rate_19: '0.09%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'jiaoqiu',
    app_rate_20: '0.09%',
    avg_round_20: 7,
    app_flat_20: 1,
    char_21: 'gepard',
    app_rate_21: '0.09%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'himeko',
    app_rate_22: '0.09%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'bronya',
    app_rate_23: '0.09%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'lynx',
    app_rate_24: '0.09%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'asta',
    app_rate_25: '0.04%',
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'jade',
    app_rate_26: '0.04%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'bailu',
    app_rate_27: '0.04%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'march-7th',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'welt',
    app_rate_30: '0.04%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'pela',
    app: 14.01,
    char_1: 'acheron',
    app_rate_1: '92.23%',
    avg_round_1: 8.66,
    app_flat_1: 765,
    char_2: 'aventurine',
    app_rate_2: '45.38%',
    avg_round_2: 8.72,
    app_flat_2: 407,
    char_3: 'jiaoqiu',
    app_rate_3: '42.94%',
    avg_round_3: 8.5,
    app_flat_3: 623,
    char_4: 'fu-xuan',
    app_rate_4: '21.93%',
    avg_round_4: 8.71,
    app_flat_4: 134,
    char_5: 'sparkle',
    app_rate_5: '17.05%',
    avg_round_5: 9.8,
    app_flat_5: 7,
    char_6: 'gallagher',
    app_rate_6: '15.19%',
    avg_round_6: 8.59,
    app_flat_6: 187,
    char_7: 'sunday',
    app_rate_7: '7.96%',
    avg_round_7: 8.33,
    app_flat_7: 17,
    char_8: 'tribbie',
    app_rate_8: '6.84%',
    avg_round_8: 7.42,
    app_flat_8: 35,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '6.3%',
    avg_round_9: 6.54,
    app_flat_9: 28,
    char_10: 'silver-wolf',
    app_rate_10: '5.08%',
    avg_round_10: 9.55,
    app_flat_10: 44,
    char_11: 'robin',
    app_rate_11: '4.49%',
    avg_round_11: 9.5,
    app_flat_11: 14,
    char_12: 'bronya',
    app_rate_12: '4.05%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'gepard',
    app_rate_13: '3.62%',
    avg_round_13: 9.5,
    app_flat_13: 18,
    char_14: 'lingsha',
    app_rate_14: '3.52%',
    avg_round_14: 8.21,
    app_flat_14: 29,
    char_15: 'ruan-mei',
    app_rate_15: '2.88%',
    avg_round_15: 5.27,
    app_flat_15: 15,
    char_16: 'the-herta',
    app_rate_16: '2.59%',
    avg_round_16: 6.27,
    app_flat_16: 22,
    char_17: 'huohuo',
    app_rate_17: '2.49%',
    avg_round_17: 8,
    app_flat_17: 17,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.95%',
    avg_round_18: 5.95,
    app_flat_18: 20,
    char_19: 'black-swan',
    app_rate_19: '1.61%',
    avg_round_19: 9.46,
    app_flat_19: 13,
    char_20: 'luocha',
    app_rate_20: '1.56%',
    avg_round_20: 9.17,
    app_flat_20: 10,
    char_21: 'herta',
    app_rate_21: '1.42%',
    avg_round_21: 5.75,
    app_flat_21: 12,
    char_22: 'kafka',
    app_rate_22: '1.27%',
    avg_round_22: 8.8,
    app_flat_22: 9,
    char_23: 'rappa',
    app_rate_23: '0.93%',
    avg_round_23: 8.86,
    app_flat_23: 13,
    char_24: 'jade',
    app_rate_24: '0.83%',
    avg_round_24: 6.67,
    app_flat_24: 3,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.73%',
    avg_round_25: 9.4,
    app_flat_25: 9,
    char_26: 'boothill',
    app_rate_26: '0.68%',
    avg_round_26: 4.38,
    app_flat_26: 8,
    char_27: 'dr-ratio',
    app_rate_27: '0.68%',
    avg_round_27: 9.6,
    app_flat_27: 5,
    char_28: 'firefly',
    app_rate_28: '0.54%',
    avg_round_28: 6,
    app_flat_28: 1,
    char_29: 'serval',
    app_rate_29: '0.54%',
    avg_round_29: 7.17,
    app_flat_29: 6,
    char_30: 'mydei',
    app_rate_30: '0.44%',
    avg_round_30: 9.33,
    app_flat_30: 5
  },
  {
    char: 'feixiao',
    app: 12.63,
    char_1: 'robin',
    app_rate_1: '93.34%',
    avg_round_1: 8.7,
    app_flat_1: 616,
    char_2: 'aventurine',
    app_rate_2: '77.79%',
    avg_round_2: 8.79,
    app_flat_2: 575,
    char_3: 'topaz',
    app_rate_3: '37.87%',
    avg_round_3: 8.32,
    app_flat_3: 176,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '36.73%',
    avg_round_4: 8.82,
    app_flat_4: 321,
    char_5: 'moze',
    app_rate_5: '12.24%',
    avg_round_5: 9.37,
    app_flat_5: 92,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '8.94%',
    avg_round_6: 8.67,
    app_flat_6: 58,
    char_7: 'tribbie',
    app_rate_7: '6.12%',
    avg_round_7: 8.23,
    app_flat_7: 42,
    char_8: 'fu-xuan',
    app_rate_8: '4.39%',
    avg_round_8: 8.57,
    app_flat_8: 21,
    char_9: 'jade',
    app_rate_9: '3.47%',
    avg_round_9: 8.67,
    app_flat_9: 5,
    char_10: 'lingsha',
    app_rate_10: '3.14%',
    avg_round_10: 8.9,
    app_flat_10: 20,
    char_11: 'gallagher',
    app_rate_11: '2.49%',
    avg_round_11: 8,
    app_flat_11: 15,
    char_12: 'luocha',
    app_rate_12: '2.33%',
    avg_round_12: 8.64,
    app_flat_12: 14,
    char_13: 'bronya',
    app_rate_13: '2.0%',
    avg_round_13: 8.8,
    app_flat_13: 5,
    char_14: 'sunday',
    app_rate_14: '1.95%',
    avg_round_14: 9.6,
    app_flat_14: 7,
    char_15: 'ruan-mei',
    app_rate_15: '1.57%',
    avg_round_15: 10,
    app_flat_15: 4,
    char_16: 'huohuo',
    app_rate_16: '1.3%',
    avg_round_16: 8.91,
    app_flat_16: 11,
    char_17: 'sparkle',
    app_rate_17: '0.76%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'yunli',
    app_rate_18: '0.6%',
    avg_round_18: 9.33,
    app_flat_18: 5,
    char_19: 'jiaoqiu',
    app_rate_19: '0.49%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'gepard',
    app_rate_20: '0.43%',
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'aglaea',
    app_rate_21: '0.33%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '0.27%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'clara',
    app_rate_23: '0.22%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'herta',
    app_rate_24: '0.16%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'dr-ratio',
    app_rate_25: '0.16%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'bailu',
    app_rate_26: '0.16%',
    avg_round_26: 9,
    app_flat_26: 2,
    char_27: 'pela',
    app_rate_27: '0.11%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'lynx',
    app_rate_28: '0.11%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'the-herta',
    app_rate_29: '0.11%',
    avg_round_29: 8,
    app_flat_29: 1,
    char_30: 'silver-wolf',
    app_rate_30: '0.05%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'serval',
    app: 12.59,
    char_1: 'the-herta',
    app_rate_1: '96.74%',
    avg_round_1: 7.97,
    app_flat_1: 1104,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '49.67%',
    avg_round_2: 8.15,
    app_flat_2: 649,
    char_3: 'tribbie',
    app_rate_3: '30.05%',
    avg_round_3: 6.15,
    app_flat_3: 295,
    char_4: 'huohuo',
    app_rate_4: '20.98%',
    avg_round_4: 7.83,
    app_flat_4: 225,
    char_5: 'aventurine',
    app_rate_5: '20.27%',
    avg_round_5: 7.61,
    app_flat_5: 271,
    char_6: 'gallagher',
    app_rate_6: '16.79%',
    avg_round_6: 7.66,
    app_flat_6: 225,
    char_7: 'lingsha',
    app_rate_7: '15.98%',
    avg_round_7: 8.0,
    app_flat_7: 179,
    char_8: 'fu-xuan',
    app_rate_8: '13.64%',
    avg_round_8: 8.51,
    app_flat_8: 138,
    char_9: 'sunday',
    app_rate_9: '13.37%',
    avg_round_9: 8.68,
    app_flat_9: 148,
    char_10: 'robin',
    app_rate_10: '10.6%',
    avg_round_10: 8.73,
    app_flat_10: 88,
    char_11: 'luocha',
    app_rate_11: '5.0%',
    avg_round_11: 8.3,
    app_flat_11: 61,
    char_12: 'sparkle',
    app_rate_12: '2.23%',
    avg_round_12: 9.27,
    app_flat_12: 11,
    char_13: 'ruan-mei',
    app_rate_13: '1.3%',
    avg_round_13: 7,
    app_flat_13: 3,
    char_14: 'tingyun',
    app_rate_14: '1.2%',
    avg_round_14: 8.75,
    app_flat_14: 20,
    char_15: 'pela',
    app_rate_15: '0.6%',
    avg_round_15: 7.17,
    app_flat_15: 6,
    char_16: 'bailu',
    app_rate_16: '0.49%',
    avg_round_16: 9.17,
    app_flat_16: 6,
    char_17: 'lynx',
    app_rate_17: '0.22%',
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'bronya',
    app_rate_18: '0.16%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'gepard',
    app_rate_19: '0.16%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.11%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'hanya',
    app_rate_21: '0.11%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'jade',
    app_rate_22: '0.05%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'natasha',
    app_rate_23: '0.05%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'acheron',
    app_rate_24: '0.05%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'firefly',
    app_rate_25: '0.05%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'asta',
    app_rate_26: '0.05%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'himeko',
    app_rate_27: '0.05%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luocha',
    app: 11.48,
    char_1: 'sunday',
    app_rate_1: '43.38%',
    avg_round_1: 8.4,
    app_flat_1: 325,
    char_2: 'the-herta',
    app_rate_2: '33.85%',
    avg_round_2: 8.37,
    app_flat_2: 380,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '32.96%',
    avg_round_3: 8.35,
    app_flat_3: 327,
    char_4: 'robin',
    app_rate_4: '21.87%',
    avg_round_4: 8.59,
    app_flat_4: 141,
    char_5: 'mydei',
    app_rate_5: '16.92%',
    avg_round_5: 7.79,
    app_flat_5: 130,
    char_6: 'herta',
    app_rate_6: '15.73%',
    avg_round_6: 8.77,
    app_flat_6: 178,
    char_7: 'tribbie',
    app_rate_7: '14.9%',
    avg_round_7: 7.61,
    app_flat_7: 119,
    char_8: 'ruan-mei',
    app_rate_8: '13.89%',
    avg_round_8: 9.03,
    app_flat_8: 62,
    char_9: 'jade',
    app_rate_9: '12.63%',
    avg_round_9: 8.23,
    app_flat_9: 132,
    char_10: 'sparkle',
    app_rate_10: '12.1%',
    avg_round_10: 8.53,
    app_flat_10: 30,
    char_11: 'imbibitor-lunae',
    app_rate_11: '11.98%',
    avg_round_11: 9.67,
    app_flat_11: 15,
    char_12: 'aglaea',
    app_rate_12: '9.95%',
    avg_round_12: 7.64,
    app_flat_12: 83,
    char_13: 'jing-yuan',
    app_rate_13: '7.99%',
    avg_round_13: 9.1,
    app_flat_13: 59,
    char_14: 'bronya',
    app_rate_14: '5.6%',
    avg_round_14: 8.62,
    app_flat_14: 32,
    char_15: 'serval',
    app_rate_15: '5.48%',
    avg_round_15: 8.3,
    app_flat_15: 61,
    char_16: 'jiaoqiu',
    app_rate_16: '5.48%',
    avg_round_16: 8,
    app_flat_16: 18,
    char_17: 'acheron',
    app_rate_17: '3.99%',
    avg_round_17: 8.67,
    app_flat_17: 10,
    char_18: 'tingyun-fugue',
    app_rate_18: '3.16%',
    avg_round_18: 9.1,
    app_flat_18: 18,
    char_19: 'jingliu',
    app_rate_19: '2.86%',
    avg_round_19: 9,
    app_flat_19: 10,
    char_20: 'rappa',
    app_rate_20: '2.74%',
    avg_round_20: 8.88,
    app_flat_20: 30,
    char_21: 'feixiao',
    app_rate_21: '2.56%',
    avg_round_21: 8.64,
    app_flat_21: 14,
    char_22: 'trailblazer-harmony',
    app_rate_22: '2.32%',
    avg_round_22: 9,
    app_flat_22: 22,
    char_23: 'tingyun',
    app_rate_23: '2.26%',
    avg_round_23: 9.33,
    app_flat_23: 10,
    char_24: 'firefly',
    app_rate_24: '2.15%',
    avg_round_24: 10.0,
    app_flat_24: 8,
    char_25: 'pela',
    app_rate_25: '1.91%',
    avg_round_25: 9.17,
    app_flat_25: 10,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '1.79%',
    avg_round_26: 9.4,
    app_flat_26: 9,
    char_27: 'argenti',
    app_rate_27: '1.43%',
    avg_round_27: 8.2,
    app_flat_27: 15,
    char_28: 'yunli',
    app_rate_28: '1.19%',
    avg_round_28: 8.38,
    app_flat_28: 8,
    char_29: 'blade',
    app_rate_29: '1.13%',
    avg_round_29: 9,
    app_flat_29: 3,
    char_30: 'kafka',
    app_rate_30: '1.01%',
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'jing-yuan',
    app: 9.23,
    char_1: 'sunday',
    app_rate_1: '95.92%',
    avg_round_1: 8.9,
    app_flat_1: 499,
    char_2: 'robin',
    app_rate_2: '60.24%',
    avg_round_2: 8.87,
    app_flat_2: 297,
    char_3: 'aventurine',
    app_rate_3: '45.55%',
    avg_round_3: 8.88,
    app_flat_3: 239,
    char_4: 'huohuo',
    app_rate_4: '18.18%',
    avg_round_4: 9.0,
    app_flat_4: 90,
    char_5: 'tribbie',
    app_rate_5: '13.65%',
    avg_round_5: 7.83,
    app_flat_5: 76,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '13.2%',
    avg_round_6: 9.12,
    app_flat_6: 82,
    char_7: 'gallagher',
    app_rate_7: '10.98%',
    avg_round_7: 7.67,
    app_flat_7: 63,
    char_8: 'luocha',
    app_rate_8: '9.94%',
    avg_round_8: 9.1,
    app_flat_8: 59,
    char_9: 'fu-xuan',
    app_rate_9: '9.12%',
    avg_round_9: 8.77,
    app_flat_9: 44,
    char_10: 'ruan-mei',
    app_rate_10: '5.64%',
    avg_round_10: 9.43,
    app_flat_10: 26,
    char_11: 'lingsha',
    app_rate_11: '3.49%',
    avg_round_11: 9.5,
    app_flat_11: 18,
    char_12: 'sparkle',
    app_rate_12: '3.19%',
    avg_round_12: 8.9,
    app_flat_12: 10,
    char_13: 'the-herta',
    app_rate_13: '2.3%',
    avg_round_13: 9.11,
    app_flat_13: 15,
    char_14: 'tingyun',
    app_rate_14: '2.15%',
    avg_round_14: 9.33,
    app_flat_14: 12,
    char_15: 'jade',
    app_rate_15: '2.08%',
    avg_round_15: 9.67,
    app_flat_15: 15,
    char_16: 'bronya',
    app_rate_16: '1.11%',
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'jiaoqiu',
    app_rate_17: '0.67%',
    avg_round_17: 6,
    app_flat_17: 1,
    char_18: 'bailu',
    app_rate_18: '0.59%',
    avg_round_18: 9,
    app_flat_18: 2,
    char_19: 'pela',
    app_rate_19: '0.52%',
    avg_round_19: 9,
    app_flat_19: 3,
    char_20: 'gepard',
    app_rate_20: '0.45%',
    avg_round_20: 9,
    app_flat_20: 2,
    char_21: 'lynx',
    app_rate_21: '0.15%',
    avg_round_21: 9,
    app_flat_21: 2,
    char_22: 'topaz',
    app_rate_22: '0.15%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'moze',
    app_rate_23: '0.15%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: '0.07%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.07%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'hanya',
    app_rate_26: '0.07%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'march-7th',
    app_rate_27: '0.07%',
    avg_round_27: 7,
    app_flat_27: 1,
    char_28: 'aglaea',
    app_rate_28: '0.07%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'feixiao',
    app_rate_29: '0.07%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'tingyun-fugue',
    app_rate_30: '0.07%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 5.76,
    char_1: 'robin',
    app_rate_1: '94.29%',
    avg_round_1: 8.35,
    app_flat_1: 197,
    char_2: 'aventurine',
    app_rate_2: '89.89%',
    avg_round_2: 8.33,
    app_flat_2: 193,
    char_3: 'feixiao',
    app_rate_3: '83.12%',
    avg_round_3: 8.32,
    app_flat_3: 176,
    char_4: 'dr-ratio',
    app_rate_4: '11.41%',
    avg_round_4: 8.55,
    app_flat_4: 19,
    char_5: 'yunli',
    app_rate_5: '2.14%',
    avg_round_5: 6,
    app_flat_5: 1,
    char_6: 'fu-xuan',
    app_rate_6: '2.02%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '1.78%',
    avg_round_7: 10,
    app_flat_7: 2,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '1.78%',
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'lingsha',
    app_rate_9: '1.43%',
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'sunday',
    app_rate_10: '1.31%',
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'tribbie',
    app_rate_11: '1.19%',
    avg_round_11: 8,
    app_flat_11: 3,
    char_12: 'luocha',
    app_rate_12: '1.07%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'jade',
    app_rate_13: '1.07%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'clara',
    app_rate_14: '0.95%',
    avg_round_14: 9.33,
    app_flat_14: 3,
    char_15: 'moze',
    app_rate_15: '0.83%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'gallagher',
    app_rate_16: '0.83%',
    avg_round_16: 8,
    app_flat_16: 3,
    char_17: 'huohuo',
    app_rate_17: '0.71%',
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'sparkle',
    app_rate_18: '0.71%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'bronya',
    app_rate_19: '0.59%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'jiaoqiu',
    app_rate_20: '0.48%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'gepard',
    app_rate_21: '0.36%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '0.24%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '0.24%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'jing-yuan',
    app_rate_24: '0.24%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'lynx',
    app_rate_25: '0.24%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'bailu',
    app_rate_26: '0.24%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'the-herta',
    app_rate_27: '0.24%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'silver-wolf',
    app_rate_28: '0.12%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'tingyun-fugue',
    app_rate_29: '0.12%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jingliu',
    app_rate_30: '0.12%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'bronya',
    app: 5.47,
    char_1: 'aglaea',
    app_rate_1: '35.5%',
    avg_round_1: 6.29,
    app_flat_1: 95,
    char_2: 'sunday',
    app_rate_2: '31.5%',
    avg_round_2: 5.24,
    app_flat_2: 76,
    char_3: 'acheron',
    app_rate_3: '27.38%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'robin',
    app_rate_4: '27.0%',
    avg_round_4: 5.07,
    app_flat_4: 43,
    char_5: 'jiaoqiu',
    app_rate_5: '14.25%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'aventurine',
    app_rate_6: '13.12%',
    avg_round_6: 9.0,
    app_flat_6: 16,
    char_7: 'ruan-mei',
    app_rate_7: '12.62%',
    avg_round_7: 6.69,
    app_flat_7: 13,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '12.38%',
    avg_round_8: 8.65,
    app_flat_8: 38,
    char_9: 'luocha',
    app_rate_9: '11.75%',
    avg_round_9: 8.62,
    app_flat_9: 32,
    char_10: 'fu-xuan',
    app_rate_10: '11.38%',
    avg_round_10: 8.44,
    app_flat_10: 16,
    char_11: 'pela',
    app_rate_11: '10.38%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'gallagher',
    app_rate_12: '10.0%',
    avg_round_12: 8.18,
    app_flat_12: 22,
    char_13: 'mydei',
    app_rate_13: '8.88%',
    avg_round_13: 8.19,
    app_flat_13: 41,
    char_14: 'huohuo',
    app_rate_14: '8.38%',
    avg_round_14: 8.4,
    app_flat_14: 25,
    char_15: 'tribbie',
    app_rate_15: '6.5%',
    avg_round_15: 5.55,
    app_flat_15: 20,
    char_16: 'tingyun-fugue',
    app_rate_16: '5.88%',
    avg_round_16: 2,
    app_flat_16: 1,
    char_17: 'jingliu',
    app_rate_17: '5.62%',
    avg_round_17: 10,
    app_flat_17: 3,
    char_18: 'lingsha',
    app_rate_18: '5.0%',
    avg_round_18: 8.58,
    app_flat_18: 12,
    char_19: 'feixiao',
    app_rate_19: '4.62%',
    avg_round_19: 8.8,
    app_flat_19: 5,
    char_20: 'the-herta',
    app_rate_20: '3.75%',
    avg_round_20: 9.3,
    app_flat_20: 18,
    char_21: 'boothill',
    app_rate_21: '3.62%',
    avg_round_21: 6.67,
    app_flat_21: 3,
    char_22: 'firefly',
    app_rate_22: '3.25%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'jade',
    app_rate_23: '3.12%',
    avg_round_23: 8.4,
    app_flat_23: 10,
    char_24: 'sparkle',
    app_rate_24: '3.12%',
    avg_round_24: 7.33,
    app_flat_24: 3,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '3.0%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'gepard',
    app_rate_26: '2.5%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'blade',
    app_rate_27: '2.5%',
    avg_round_27: 8.5,
    app_flat_27: 2,
    char_28: 'silver-wolf',
    app_rate_28: '2.0%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'herta',
    app_rate_29: '2.0%',
    avg_round_29: 9.4,
    app_flat_29: 9,
    char_30: 'jing-yuan',
    app_rate_30: '1.88%',
    avg_round_30: 8,
    app_flat_30: 2
  },
  {
    char: 'march-7th-swordmaster',
    app: 5.21,
    char_1: 'robin',
    app_rate_1: '90.94%',
    avg_round_1: 8.82,
    app_flat_1: 316,
    char_2: 'feixiao',
    app_rate_2: '88.98%',
    avg_round_2: 8.82,
    app_flat_2: 321,
    char_3: 'aventurine',
    app_rate_3: '64.7%',
    avg_round_3: 8.85,
    app_flat_3: 275,
    char_4: 'fu-xuan',
    app_rate_4: '7.22%',
    avg_round_4: 8.33,
    app_flat_4: 15,
    char_5: 'lingsha',
    app_rate_5: '5.91%',
    avg_round_5: 9.0,
    app_flat_5: 20,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '4.46%',
    avg_round_6: 8.29,
    app_flat_6: 7,
    char_7: 'luocha',
    app_rate_7: '3.94%',
    avg_round_7: 9.4,
    app_flat_7: 9,
    char_8: 'tribbie',
    app_rate_8: '3.67%',
    avg_round_8: 8.77,
    app_flat_8: 13,
    char_9: 'ruan-mei',
    app_rate_9: '3.67%',
    avg_round_9: 8.6,
    app_flat_9: 7,
    char_10: 'gallagher',
    app_rate_10: '3.54%',
    avg_round_10: 9.75,
    app_flat_10: 8,
    char_11: 'huohuo',
    app_rate_11: '3.28%',
    avg_round_11: 8.92,
    app_flat_11: 12,
    char_12: 'bronya',
    app_rate_12: '3.15%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'trailblazer-harmony',
    app_rate_13: '2.89%',
    avg_round_13: 8.6,
    app_flat_13: 7,
    char_14: 'tingyun-fugue',
    app_rate_14: '2.23%',
    avg_round_14: 7.8,
    app_flat_14: 5,
    char_15: 'yunli',
    app_rate_15: '1.97%',
    avg_round_15: 9.0,
    app_flat_15: 5,
    char_16: 'dr-ratio',
    app_rate_16: '1.71%',
    avg_round_16: 8.67,
    app_flat_16: 6,
    char_17: 'sunday',
    app_rate_17: '1.05%',
    avg_round_17: 7,
    app_flat_17: 2,
    char_18: 'clara',
    app_rate_18: '0.92%',
    avg_round_18: 8.5,
    app_flat_18: 2,
    char_19: 'firefly',
    app_rate_19: '0.92%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'moze',
    app_rate_20: '0.79%',
    avg_round_20: 10,
    app_flat_20: 2,
    char_21: 'gepard',
    app_rate_21: '0.66%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'jade',
    app_rate_22: '0.52%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.39%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'the-herta',
    app_rate_24: '0.39%',
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'topaz',
    app_rate_25: '0.26%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'bailu',
    app_rate_26: '0.26%',
    avg_round_26: 8,
    app_flat_26: 1,
    char_27: 'welt',
    app_rate_27: '0.26%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'boothill',
    app_rate_28: '0.26%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'yanqing',
    app_rate_29: '0.13%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jing-yuan',
    app_rate_30: '0.13%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 4.65,
    char_1: 'sparkle',
    app_rate_1: '66.86%',
    avg_round_1: 9.43,
    app_flat_1: 45,
    char_2: 'sunday',
    app_rate_2: '47.13%',
    avg_round_2: 9.3,
    app_flat_2: 38,
    char_3: 'luocha',
    app_rate_3: '29.6%',
    avg_round_3: 9.67,
    app_flat_3: 15,
    char_4: 'robin',
    app_rate_4: '27.54%',
    avg_round_4: 9.5,
    app_flat_4: 4,
    char_5: 'huohuo',
    app_rate_5: '25.48%',
    avg_round_5: 9.36,
    app_flat_5: 19,
    char_6: 'ruan-mei',
    app_rate_6: '23.86%',
    avg_round_6: 10.0,
    app_flat_6: 6,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '18.11%',
    avg_round_7: 9.75,
    app_flat_7: 22,
    char_8: 'aventurine',
    app_rate_8: '16.94%',
    avg_round_8: 9.71,
    app_flat_8: 11,
    char_9: 'gallagher',
    app_rate_9: '12.81%',
    avg_round_9: 9.14,
    app_flat_9: 11,
    char_10: 'tingyun',
    app_rate_10: '11.34%',
    avg_round_10: 9.67,
    app_flat_10: 5,
    char_11: 'fu-xuan',
    app_rate_11: '6.77%',
    avg_round_11: 9.67,
    app_flat_11: 5,
    char_12: 'tribbie',
    app_rate_12: '4.71%',
    avg_round_12: 9,
    app_flat_12: 3,
    char_13: 'jiaoqiu',
    app_rate_13: '3.09%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'lingsha',
    app_rate_14: '1.47%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'bronya',
    app_rate_15: '0.88%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'pela',
    app_rate_16: '0.74%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '0.44%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'hanya',
    app_rate_18: '0.44%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'bailu',
    app_rate_19: '0.29%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'lynx',
    app_rate_20: '0.29%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'mydei',
    app_rate_21: '0.15%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'gepard',
    app_rate_22: '0.15%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'the-herta',
    app_rate_23: '0.15%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: '0.15%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jingliu',
    app_rate_25: '0.15%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'dan-heng',
    app_rate_26: '0.15%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'jade',
    app_rate_27: '0.15%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'clara',
    app_rate_28: '0.15%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'mydei',
    app: 4.29,
    char_1: 'sunday',
    app_rate_1: '70.97%',
    avg_round_1: 7.01,
    app_flat_1: 203,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '46.73%',
    avg_round_2: 7.4,
    app_flat_2: 146,
    char_3: 'luocha',
    app_rate_3: '45.3%',
    avg_round_3: 7.79,
    app_flat_3: 130,
    char_4: 'tribbie',
    app_rate_4: '38.92%',
    avg_round_4: 6.87,
    app_flat_4: 99,
    char_5: 'gallagher',
    app_rate_5: '21.05%',
    avg_round_5: 7.58,
    app_flat_5: 60,
    char_6: 'ruan-mei',
    app_rate_6: '17.38%',
    avg_round_6: 6.71,
    app_flat_6: 45,
    char_7: 'sparkle',
    app_rate_7: '15.47%',
    avg_round_7: 7.8,
    app_flat_7: 40,
    char_8: 'huohuo',
    app_rate_8: '11.8%',
    avg_round_8: 7.58,
    app_flat_8: 40,
    char_9: 'bronya',
    app_rate_9: '11.32%',
    avg_round_9: 8.19,
    app_flat_9: 41,
    char_10: 'jiaoqiu',
    app_rate_10: '7.66%',
    avg_round_10: 6.29,
    app_flat_10: 17,
    char_11: 'lynx',
    app_rate_11: '3.19%',
    avg_round_11: 7.25,
    app_flat_11: 8,
    char_12: 'robin',
    app_rate_12: '3.03%',
    avg_round_12: 7.33,
    app_flat_12: 6,
    char_13: 'lingsha',
    app_rate_13: '1.75%',
    avg_round_13: 8.12,
    app_flat_13: 8,
    char_14: 'pela',
    app_rate_14: '1.44%',
    avg_round_14: 9.33,
    app_flat_14: 5,
    char_15: 'jade',
    app_rate_15: '0.8%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'aventurine',
    app_rate_16: '0.48%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '0.48%',
    avg_round_17: 8,
    app_flat_17: 1,
    char_18: 'the-herta',
    app_rate_18: '0.32%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'imbibitor-lunae',
    app_rate_19: '0.16%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'dr-ratio',
    app_rate_20: '0.16%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'fu-xuan',
    app_rate_21: '0.16%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'feixiao',
    app_rate_22: '0.16%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: '0.16%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'tingyun',
    app_rate_24: '0.16%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'hanya',
    app_rate_25: '0.16%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 3.02,
    char_1: 'acheron',
    app_rate_1: '86.17%',
    avg_round_1: 9.22,
    app_flat_1: 79,
    char_2: 'fu-xuan',
    app_rate_2: '40.82%',
    avg_round_2: 9.46,
    app_flat_2: 23,
    char_3: 'sparkle',
    app_rate_3: '33.11%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'aventurine',
    app_rate_4: '33.11%',
    avg_round_4: 9.15,
    app_flat_4: 40,
    char_5: 'pela',
    app_rate_5: '23.58%',
    avg_round_5: 9.55,
    app_flat_5: 44,
    char_6: 'jiaoqiu',
    app_rate_6: '19.05%',
    avg_round_6: 8.5,
    app_flat_6: 32,
    char_7: 'gallagher',
    app_rate_7: '9.07%',
    avg_round_7: 8.92,
    app_flat_7: 12,
    char_8: 'sunday',
    app_rate_8: '6.12%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'robin',
    app_rate_9: '4.99%',
    avg_round_9: 6,
    app_flat_9: 2,
    char_10: 'gepard',
    app_rate_10: '4.76%',
    avg_round_10: 8.67,
    app_flat_10: 3,
    char_11: 'tribbie',
    app_rate_11: '4.08%',
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'bronya',
    app_rate_12: '3.63%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'dr-ratio',
    app_rate_13: '3.4%',
    avg_round_13: 6,
    app_flat_13: 2,
    char_14: 'huohuo',
    app_rate_14: '3.17%',
    avg_round_14: 9,
    app_flat_14: 2,
    char_15: 'tingyun-fugue',
    app_rate_15: '2.95%',
    avg_round_15: 10.0,
    app_flat_15: 6,
    char_16: 'ruan-mei',
    app_rate_16: '2.72%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'lingsha',
    app_rate_17: '2.72%',
    avg_round_17: 8.4,
    app_flat_17: 5,
    char_18: 'seele',
    app_rate_18: '2.27%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'qingque',
    app_rate_19: '2.27%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'trailblazer-remembrance',
    app_rate_20: '2.04%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'luocha',
    app_rate_21: '2.04%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'rappa',
    app_rate_22: '1.36%',
    avg_round_22: 10,
    app_flat_22: 3,
    char_23: 'trailblazer-harmony',
    app_rate_23: '1.13%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'firefly',
    app_rate_24: '1.13%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jade',
    app_rate_25: '0.68%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'the-herta',
    app_rate_26: '0.45%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'yunli',
    app_rate_27: '0.45%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'feixiao',
    app_rate_28: '0.23%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'topaz',
    app_rate_29: '0.23%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'boothill',
    app_rate_30: '0.23%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'yunli',
    app: 2.48,
    char_1: 'robin',
    app_rate_1: '70.52%',
    avg_round_1: 8.56,
    app_flat_1: 79,
    char_2: 'huohuo',
    app_rate_2: '47.66%',
    avg_round_2: 8.46,
    app_flat_2: 63,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '27.0%',
    avg_round_3: 7.89,
    app_flat_3: 36,
    char_4: 'sunday',
    app_rate_4: '26.17%',
    avg_round_4: 9.18,
    app_flat_4: 33,
    char_5: 'tribbie',
    app_rate_5: '25.9%',
    avg_round_5: 8.12,
    app_flat_5: 30,
    char_6: 'tingyun',
    app_rate_6: '18.46%',
    avg_round_6: 8.62,
    app_flat_6: 16,
    char_7: 'sparkle',
    app_rate_7: '17.91%',
    avg_round_7: 9.18,
    app_flat_7: 11,
    char_8: 'aventurine',
    app_rate_8: '15.43%',
    avg_round_8: 9.11,
    app_flat_8: 15,
    char_9: 'fu-xuan',
    app_rate_9: '12.95%',
    avg_round_9: 7.78,
    app_flat_9: 9,
    char_10: 'luocha',
    app_rate_10: '5.51%',
    avg_round_10: 8.38,
    app_flat_10: 8,
    char_11: 'topaz',
    app_rate_11: '4.96%',
    avg_round_11: 6,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '4.13%',
    avg_round_12: 9.0,
    app_flat_12: 5,
    char_13: 'gallagher',
    app_rate_13: '3.86%',
    avg_round_13: 9.5,
    app_flat_13: 4,
    char_14: 'ruan-mei',
    app_rate_14: '3.31%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'feixiao',
    app_rate_15: '3.03%',
    avg_round_15: 9.33,
    app_flat_15: 5,
    char_16: 'lingsha',
    app_rate_16: '2.75%',
    avg_round_16: 9,
    app_flat_16: 4,
    char_17: 'jade',
    app_rate_17: '2.48%',
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'lynx',
    app_rate_18: '1.65%',
    avg_round_18: 8,
    app_flat_18: 4,
    char_19: 'bronya',
    app_rate_19: '0.83%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'the-herta',
    app_rate_20: '0.83%',
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'bailu',
    app_rate_21: '0.83%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '0.55%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'clara',
    app_rate_23: '0.55%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: '0.55%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.28%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'himeko',
    app_rate_26: '0.28%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'argenti',
    app_rate_27: '0.28%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'moze',
    app_rate_28: '0.28%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'pela',
    app_rate_29: '0.28%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'trailblazer-preservation',
    app_rate_30: '0.28%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    app: 2.4,
    char_1: 'kafka',
    app_rate_1: '63.43%',
    avg_round_1: 9.43,
    app_flat_1: 14,
    char_2: 'acheron',
    app_rate_2: '60.57%',
    avg_round_2: 9.42,
    app_flat_2: 37,
    char_3: 'aventurine',
    app_rate_3: '33.14%',
    avg_round_3: 9.36,
    app_flat_3: 26,
    char_4: 'ruan-mei',
    app_rate_4: '24.29%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'huohuo',
    app_rate_5: '23.14%',
    avg_round_5: 9,
    app_flat_5: 2,
    char_6: 'robin',
    app_rate_6: '15.43%',
    avg_round_6: 9.5,
    app_flat_6: 2,
    char_7: 'fu-xuan',
    app_rate_7: '15.43%',
    avg_round_7: 9.0,
    app_flat_7: 4,
    char_8: 'jiaoqiu',
    app_rate_8: '13.14%',
    avg_round_8: 9.33,
    app_flat_8: 12,
    char_9: 'gallagher',
    app_rate_9: '12.29%',
    avg_round_9: 10.0,
    app_flat_9: 6,
    char_10: 'pela',
    app_rate_10: '9.43%',
    avg_round_10: 9.46,
    app_flat_10: 13,
    char_11: 'sparkle',
    app_rate_11: '5.71%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'lingsha',
    app_rate_12: '5.14%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'luocha',
    app_rate_13: '4.29%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'tribbie',
    app_rate_14: '3.43%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'bailu',
    app_rate_15: '2.57%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'gepard',
    app_rate_16: '2.57%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'trailblazer-remembrance',
    app_rate_17: '1.71%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'bronya',
    app_rate_18: '1.14%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'sunday',
    app_rate_19: '1.14%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.57%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'asta',
    app_rate_21: '0.57%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'tingyun',
    app_rate_22: '0.29%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'aglaea',
    app_rate_23: '0.29%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jingliu',
    app_rate_24: '0.29%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'kafka',
    app: 2.3,
    char_1: 'black-swan',
    app_rate_1: '66.07%',
    avg_round_1: 9.43,
    app_flat_1: 14,
    char_2: 'acheron',
    app_rate_2: '54.17%',
    avg_round_2: 8.9,
    app_flat_2: 41,
    char_3: 'aventurine',
    app_rate_3: '29.17%',
    avg_round_3: 9.29,
    app_flat_3: 26,
    char_4: 'ruan-mei',
    app_rate_4: '27.68%',
    avg_round_4: 9.67,
    app_flat_4: 3,
    char_5: 'huohuo',
    app_rate_5: '25.3%',
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: 'robin',
    app_rate_6: '15.18%',
    avg_round_6: 9.33,
    app_flat_6: 3,
    char_7: 'gallagher',
    app_rate_7: '14.29%',
    avg_round_7: 8.86,
    app_flat_7: 7,
    char_8: 'fu-xuan',
    app_rate_8: '13.69%',
    avg_round_8: 8.8,
    app_flat_8: 7,
    char_9: 'jiaoqiu',
    app_rate_9: '11.31%',
    avg_round_9: 8.43,
    app_flat_9: 14,
    char_10: 'pela',
    app_rate_10: '7.74%',
    avg_round_10: 8.8,
    app_flat_10: 9,
    char_11: 'tribbie',
    app_rate_11: '6.55%',
    avg_round_11: 7,
    app_flat_11: 1,
    char_12: 'lingsha',
    app_rate_12: '6.55%',
    avg_round_12: 5.5,
    app_flat_12: 2,
    char_13: 'luocha',
    app_rate_13: '5.06%',
    avg_round_13: 8,
    app_flat_13: 1,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '4.17%',
    avg_round_14: 9,
    app_flat_14: 3,
    char_15: 'sparkle',
    app_rate_15: '3.27%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'bailu',
    app_rate_16: '2.68%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'gepard',
    app_rate_17: '2.38%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.79%',
    avg_round_18: 7,
    app_flat_18: 2,
    char_19: 'tingyun',
    app_rate_19: '0.89%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'asta',
    app_rate_20: '0.6%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'jade',
    app_rate_21: '0.6%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'aglaea',
    app_rate_22: '0.3%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'the-herta',
    app_rate_23: '0.3%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'sunday',
    app_rate_24: '0.3%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 2.22,
    char_1: 'the-herta',
    app_rate_1: '86.73%',
    avg_round_1: 6.65,
    app_flat_1: 169,
    char_2: 'tribbie',
    app_rate_2: '40.43%',
    avg_round_2: 5.15,
    app_flat_2: 71,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '39.2%',
    avg_round_3: 6.83,
    app_flat_3: 87,
    char_4: 'huohuo',
    app_rate_4: '24.38%',
    avg_round_4: 7.43,
    app_flat_4: 46,
    char_5: 'sunday',
    app_rate_5: '23.77%',
    avg_round_5: 8.88,
    app_flat_5: 31,
    char_6: 'aventurine',
    app_rate_6: '21.91%',
    avg_round_6: 7.05,
    app_flat_6: 42,
    char_7: 'gallagher',
    app_rate_7: '16.36%',
    avg_round_7: 6.24,
    app_flat_7: 34,
    char_8: 'lingsha',
    app_rate_8: '12.96%',
    avg_round_8: 6.32,
    app_flat_8: 22,
    char_9: 'robin',
    app_rate_9: '10.8%',
    avg_round_9: 9.0,
    app_flat_9: 11,
    char_10: 'fu-xuan',
    app_rate_10: '8.64%',
    avg_round_10: 8.86,
    app_flat_10: 13,
    char_11: 'luocha',
    app_rate_11: '7.41%',
    avg_round_11: 8.2,
    app_flat_11: 15,
    char_12: 'sparkle',
    app_rate_12: '2.47%',
    avg_round_12: 8,
    app_flat_12: 4,
    char_13: 'ruan-mei',
    app_rate_13: '1.23%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jiaoqiu',
    app_rate_14: '0.93%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'tingyun',
    app_rate_15: '0.93%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'jade',
    app_rate_16: '0.62%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'bronya',
    app_rate_17: '0.31%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'yunli',
    app_rate_18: '0.31%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'feixiao',
    app_rate_19: '0.31%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'bailu',
    app_rate_20: '0.31%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'tingyun',
    app: 2.03,
    char_1: 'huohuo',
    app_rate_1: '30.07%',
    avg_round_1: 9.0,
    app_flat_1: 26,
    char_2: 'imbibitor-lunae',
    app_rate_2: '26.01%',
    avg_round_2: 9.67,
    app_flat_2: 5,
    char_3: 'sparkle',
    app_rate_3: '25.68%',
    avg_round_3: 8.8,
    app_flat_3: 10,
    char_4: 'sunday',
    app_rate_4: '23.31%',
    avg_round_4: 9.32,
    app_flat_4: 28,
    char_5: 'yunli',
    app_rate_5: '22.64%',
    avg_round_5: 8.62,
    app_flat_5: 16,
    char_6: 'robin',
    app_rate_6: '21.96%',
    avg_round_6: 8.8,
    app_flat_6: 18,
    char_7: 'aventurine',
    app_rate_7: '20.61%',
    avg_round_7: 9.2,
    app_flat_7: 20,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '18.58%',
    avg_round_8: 8.36,
    app_flat_8: 26,
    char_9: 'aglaea',
    app_rate_9: '15.2%',
    avg_round_9: 9.08,
    app_flat_9: 23,
    char_10: 'fu-xuan',
    app_rate_10: '13.18%',
    avg_round_10: 9.36,
    app_flat_10: 11,
    char_11: 'luocha',
    app_rate_11: '12.84%',
    avg_round_11: 9.33,
    app_flat_11: 10,
    char_12: 'jing-yuan',
    app_rate_12: '9.8%',
    avg_round_12: 9.33,
    app_flat_12: 12,
    char_13: 'gallagher',
    app_rate_13: '9.8%',
    avg_round_13: 8.44,
    app_flat_13: 18,
    char_14: 'serval',
    app_rate_14: '7.43%',
    avg_round_14: 8.75,
    app_flat_14: 20,
    char_15: 'the-herta',
    app_rate_15: '5.74%',
    avg_round_15: 9.2,
    app_flat_15: 9,
    char_16: 'lingsha',
    app_rate_16: '4.73%',
    avg_round_16: 7.38,
    app_flat_16: 8,
    char_17: 'ruan-mei',
    app_rate_17: '4.05%',
    avg_round_17: 5,
    app_flat_17: 1,
    char_18: 'jade',
    app_rate_18: '3.72%',
    avg_round_18: 8.14,
    app_flat_18: 7,
    char_19: 'tribbie',
    app_rate_19: '3.38%',
    avg_round_19: 6,
    app_flat_19: 4,
    char_20: 'herta',
    app_rate_20: '2.7%',
    avg_round_20: 8.33,
    app_flat_20: 3,
    char_21: 'clara',
    app_rate_21: '2.36%',
    avg_round_21: 8.25,
    app_flat_21: 4,
    char_22: 'seele',
    app_rate_22: '2.03%',
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'qingque',
    app_rate_23: '2.03%',
    avg_round_23: 5.67,
    app_flat_23: 3,
    char_24: 'jingliu',
    app_rate_24: '2.03%',
    avg_round_24: 8,
    app_flat_24: 1,
    char_25: 'jiaoqiu',
    app_rate_25: '1.69%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'bailu',
    app_rate_26: '1.35%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'dr-ratio',
    app_rate_27: '1.35%',
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'kafka',
    app_rate_28: '1.01%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'argenti',
    app_rate_29: '1.01%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'bronya',
    app_rate_30: '0.68%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'jingliu',
    app: 1.9,
    char_1: 'sunday',
    app_rate_1: '58.27%',
    avg_round_1: 8.77,
    app_flat_1: 52,
    char_2: 'robin',
    app_rate_2: '44.96%',
    avg_round_2: 8.4,
    app_flat_2: 40,
    char_3: 'huohuo',
    app_rate_3: '42.81%',
    avg_round_3: 8.89,
    app_flat_3: 37,
    char_4: 'ruan-mei',
    app_rate_4: '34.89%',
    avg_round_4: 9.67,
    app_flat_4: 17,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '19.42%',
    avg_round_5: 9.62,
    app_flat_5: 16,
    char_6: 'luocha',
    app_rate_6: '17.27%',
    avg_round_6: 9,
    app_flat_6: 10,
    char_7: 'bronya',
    app_rate_7: '16.19%',
    avg_round_7: 10,
    app_flat_7: 3,
    char_8: 'tribbie',
    app_rate_8: '13.31%',
    avg_round_8: 8.17,
    app_flat_8: 12,
    char_9: 'gallagher',
    app_rate_9: '8.99%',
    avg_round_9: 8.92,
    app_flat_9: 12,
    char_10: 'fu-xuan',
    app_rate_10: '8.63%',
    avg_round_10: 7,
    app_flat_10: 2,
    char_11: 'sparkle',
    app_rate_11: '7.91%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'aventurine',
    app_rate_12: '7.55%',
    avg_round_12: 9.33,
    app_flat_12: 3,
    char_13: 'lingsha',
    app_rate_13: '7.55%',
    avg_round_13: 7.83,
    app_flat_13: 6,
    char_14: 'bailu',
    app_rate_14: '3.24%',
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'tingyun',
    app_rate_15: '2.16%',
    avg_round_15: 8,
    app_flat_15: 1,
    char_16: 'pela',
    app_rate_16: '1.08%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'acheron',
    app_rate_17: '0.72%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'gepard',
    app_rate_18: '0.72%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'blade',
    app_rate_19: '0.72%',
    avg_round_19: 8,
    app_flat_19: 1,
    char_20: 'lynx',
    app_rate_20: '0.72%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'imbibitor-lunae',
    app_rate_21: '0.36%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'seele',
    app_rate_22: '0.36%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.36%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: '0.36%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: '0.36%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'trailblazer-preservation',
    app_rate_26: '0.36%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'moze',
    app_rate_27: '0.36%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'dr-ratio',
    app_rate_28: '0.36%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 1.83,
    char_1: 'robin',
    app_rate_1: '85.39%',
    avg_round_1: 9.23,
    app_flat_1: 94,
    char_2: 'feixiao',
    app_rate_2: '84.64%',
    avg_round_2: 9.37,
    app_flat_2: 92,
    char_3: 'aventurine',
    app_rate_3: '79.4%',
    avg_round_3: 9.47,
    app_flat_3: 103,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '17.23%',
    avg_round_4: 8.71,
    app_flat_4: 13,
    char_5: 'dr-ratio',
    app_rate_5: '10.86%',
    avg_round_5: 9.33,
    app_flat_5: 12,
    char_6: 'tribbie',
    app_rate_6: '3.37%',
    avg_round_6: 10,
    app_flat_6: 2,
    char_7: 'topaz',
    app_rate_7: '2.62%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '2.25%',
    avg_round_8: 10,
    app_flat_8: 2,
    char_9: 'sunday',
    app_rate_9: '2.25%',
    avg_round_9: 10,
    app_flat_9: 2,
    char_10: 'gallagher',
    app_rate_10: '1.5%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: '1.5%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'luocha',
    app_rate_12: '1.12%',
    avg_round_12: 8,
    app_flat_12: 1,
    char_13: 'jiaoqiu',
    app_rate_13: '1.12%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jade',
    app_rate_14: '1.12%',
    avg_round_14: 7,
    app_flat_14: 1,
    char_15: 'jing-yuan',
    app_rate_15: '0.75%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'gepard',
    app_rate_16: '0.75%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'ruan-mei',
    app_rate_17: '0.75%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'lingsha',
    app_rate_18: '0.75%',
    avg_round_18: 7,
    app_flat_18: 1,
    char_19: 'clara',
    app_rate_19: '0.37%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'the-herta',
    app_rate_20: '0.37%',
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'herta',
    app_rate_21: '0.37%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'yanqing',
    app_rate_22: '0.37%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'yunli',
    app_rate_23: '0.37%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'bronya',
    app_rate_24: '0.37%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jingliu',
    app_rate_25: '0.37%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 1.72,
    char_1: 'aventurine',
    app_rate_1: '86.9%',
    avg_round_1: 8.94,
    app_flat_1: 68,
    char_2: 'robin',
    app_rate_2: '82.94%',
    avg_round_2: 8.9,
    app_flat_2: 61,
    char_3: 'topaz',
    app_rate_3: '38.1%',
    avg_round_3: 8.55,
    app_flat_3: 19,
    char_4: 'sunday',
    app_rate_4: '14.68%',
    avg_round_4: 8.67,
    app_flat_4: 12,
    char_5: 'moze',
    app_rate_5: '11.51%',
    avg_round_5: 9.33,
    app_flat_5: 12,
    char_6: 'jiaoqiu',
    app_rate_6: '10.71%',
    avg_round_6: 9.29,
    app_flat_6: 7,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '7.14%',
    avg_round_7: 9.8,
    app_flat_7: 7,
    char_8: 'silver-wolf',
    app_rate_8: '5.95%',
    avg_round_8: 6,
    app_flat_8: 2,
    char_9: 'pela',
    app_rate_9: '5.56%',
    avg_round_9: 9.6,
    app_flat_9: 5,
    char_10: 'tribbie',
    app_rate_10: '5.16%',
    avg_round_10: 8,
    app_flat_10: 5,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '5.16%',
    avg_round_11: 8.67,
    app_flat_11: 6,
    char_12: 'ruan-mei',
    app_rate_12: '4.37%',
    avg_round_12: 8.33,
    app_flat_12: 3,
    char_13: 'gallagher',
    app_rate_13: '3.97%',
    avg_round_13: 8,
    app_flat_13: 1,
    char_14: 'luocha',
    app_rate_14: '2.78%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'fu-xuan',
    app_rate_15: '2.78%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'sparkle',
    app_rate_16: '1.59%',
    avg_round_16: 8,
    app_flat_16: 2,
    char_17: 'tingyun',
    app_rate_17: '1.59%',
    avg_round_17: 8,
    app_flat_17: 1,
    char_18: 'bronya',
    app_rate_18: '1.19%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'huohuo',
    app_rate_19: '1.19%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'jade',
    app_rate_20: '1.19%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'feixiao',
    app_rate_21: '1.19%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'clara',
    app_rate_22: '1.19%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'gepard',
    app_rate_23: '0.79%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'mydei',
    app_rate_24: '0.4%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'lingsha',
    app_rate_25: '0.4%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: '0.4%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'bailu',
    app_rate_27: '0.4%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'yukong',
    app_rate_28: '0.4%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'jingliu',
    app_rate_29: '0.4%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'boothill',
    app: 1.62,
    char_1: 'ruan-mei',
    app_rate_1: '93.22%',
    avg_round_1: 8.26,
    app_flat_1: 90,
    char_2: 'tingyun-fugue',
    app_rate_2: '82.2%',
    avg_round_2: 7.94,
    app_flat_2: 70,
    char_3: 'trailblazer-harmony',
    app_rate_3: '27.97%',
    avg_round_3: 7.7,
    app_flat_3: 37,
    char_4: 'gallagher',
    app_rate_4: '27.54%',
    avg_round_4: 9.37,
    app_flat_4: 37,
    char_5: 'lingsha',
    app_rate_5: '15.25%',
    avg_round_5: 8.25,
    app_flat_5: 16,
    char_6: 'sunday',
    app_rate_6: '14.41%',
    avg_round_6: 7.67,
    app_flat_6: 3,
    char_7: 'bronya',
    app_rate_7: '12.29%',
    avg_round_7: 6.67,
    app_flat_7: 3,
    char_8: 'aventurine',
    app_rate_8: '6.36%',
    avg_round_8: 9,
    app_flat_8: 5,
    char_9: 'pela',
    app_rate_9: '5.93%',
    avg_round_9: 4.38,
    app_flat_9: 8,
    char_10: 'luocha',
    app_rate_10: '4.24%',
    avg_round_10: 9.67,
    app_flat_10: 3,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '3.39%',
    avg_round_11: 6.5,
    app_flat_11: 4,
    char_12: 'rappa',
    app_rate_12: '2.12%',
    avg_round_12: 7,
    app_flat_12: 2,
    char_13: 'fu-xuan',
    app_rate_13: '1.27%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'huohuo',
    app_rate_14: '0.85%',
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '0.85%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'firefly',
    app_rate_16: '0.42%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'silver-wolf',
    app_rate_17: '0.42%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'lynx',
    app_rate_18: '0.42%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'welt',
    app_rate_19: '0.42%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'tribbie',
    app_rate_20: '0.42%',
    avg_round_20: 4,
    app_flat_20: 1,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 1.2,
    char_1: 'acheron',
    app_rate_1: '71.02%',
    avg_round_1: 9.69,
    app_flat_1: 25,
    char_2: 'pela',
    app_rate_2: '42.05%',
    avg_round_2: 9.5,
    app_flat_2: 18,
    char_3: 'jiaoqiu',
    app_rate_3: '25.0%',
    avg_round_3: 9.62,
    app_flat_3: 16,
    char_4: 'sparkle',
    app_rate_4: '20.45%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'robin',
    app_rate_5: '17.05%',
    avg_round_5: 9.67,
    app_flat_5: 12,
    char_6: 'sunday',
    app_rate_6: '15.34%',
    avg_round_6: 8.67,
    app_flat_6: 6,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '13.64%',
    avg_round_7: 10.0,
    app_flat_7: 12,
    char_8: 'silver-wolf',
    app_rate_8: '11.93%',
    avg_round_8: 8.67,
    app_flat_8: 3,
    char_9: 'bronya',
    app_rate_9: '11.36%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'the-herta',
    app_rate_10: '10.8%',
    avg_round_10: 9.83,
    app_flat_10: 12,
    char_11: 'ruan-mei',
    app_rate_11: '7.95%',
    avg_round_11: 9,
    app_flat_11: 2,
    char_12: 'herta',
    app_rate_12: '5.68%',
    avg_round_12: 9.67,
    app_flat_12: 6,
    char_13: 'black-swan',
    app_rate_13: '5.11%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'feixiao',
    app_rate_14: '4.55%',
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'tingyun-fugue',
    app_rate_15: '4.55%',
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'tribbie',
    app_rate_16: '4.55%',
    avg_round_16: 9.33,
    app_flat_16: 3,
    char_17: 'kafka',
    app_rate_17: '4.55%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'aglaea',
    app_rate_18: '3.98%',
    avg_round_18: 8,
    app_flat_18: 3,
    char_19: 'jade',
    app_rate_19: '3.41%',
    avg_round_19: 9.67,
    app_flat_19: 5,
    char_20: 'jing-yuan',
    app_rate_20: '3.41%',
    avg_round_20: 9,
    app_flat_20: 2,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '2.84%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'firefly',
    app_rate_22: '2.27%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'topaz',
    app_rate_23: '1.7%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'serval',
    app_rate_24: '1.7%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'dr-ratio',
    app_rate_25: '1.14%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'jingliu',
    app_rate_26: '1.14%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'rappa',
    app_rate_27: '1.14%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'moze',
    app_rate_28: '1.14%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.57%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.93,
    char_1: 'trailblazer-remembrance',
    app_rate_1: '40.44%',
    avg_round_1: 8.5,
    app_flat_1: 34,
    char_2: 'sunday',
    app_rate_2: '38.24%',
    avg_round_2: 8.52,
    app_flat_2: 23,
    char_3: 'robin',
    app_rate_3: '32.35%',
    avg_round_3: 8.69,
    app_flat_3: 16,
    char_4: 'the-herta',
    app_rate_4: '31.62%',
    avg_round_4: 8.76,
    app_flat_4: 29,
    char_5: 'aglaea',
    app_rate_5: '25.74%',
    avg_round_5: 8,
    app_flat_5: 14,
    char_6: 'herta',
    app_rate_6: '13.97%',
    avg_round_6: 8.87,
    app_flat_6: 15,
    char_7: 'jade',
    app_rate_7: '13.24%',
    avg_round_7: 8.5,
    app_flat_7: 10,
    char_8: 'acheron',
    app_rate_8: '11.03%',
    avg_round_8: 9.33,
    app_flat_8: 3,
    char_9: 'ruan-mei',
    app_rate_9: '9.56%',
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'sparkle',
    app_rate_10: '8.09%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'jiaoqiu',
    app_rate_11: '7.35%',
    avg_round_11: 9.33,
    app_flat_11: 3,
    char_12: 'kafka',
    app_rate_12: '6.62%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'black-swan',
    app_rate_13: '6.62%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'serval',
    app_rate_14: '6.62%',
    avg_round_14: 9.17,
    app_flat_14: 6,
    char_15: 'jingliu',
    app_rate_15: '6.62%',
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'jing-yuan',
    app_rate_16: '5.88%',
    avg_round_16: 9,
    app_flat_16: 2,
    char_17: 'pela',
    app_rate_17: '5.15%',
    avg_round_17: 8,
    app_flat_17: 1,
    char_18: 'bronya',
    app_rate_18: '5.15%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'tribbie',
    app_rate_19: '4.41%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'tingyun',
    app_rate_20: '2.94%',
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'yunli',
    app_rate_21: '2.21%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'feixiao',
    app_rate_22: '2.21%',
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'mydei',
    app_rate_23: '2.21%',
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.47%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'trailblazer-harmony',
    app_rate_25: '1.47%',
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'blade',
    app_rate_26: '1.47%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '1.47%',
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'topaz',
    app_rate_28: '1.47%',
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'firefly',
    app_rate_29: '0.74%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'rappa',
    app_rate_30: '0.74%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'qingque',
    app: 0.89,
    char_1: 'sparkle',
    app_rate_1: '86.92%',
    avg_round_1: 8.85,
    app_flat_1: 40,
    char_2: 'fu-xuan',
    app_rate_2: '46.92%',
    avg_round_2: 8.78,
    app_flat_2: 15,
    char_3: 'tribbie',
    app_rate_3: '36.15%',
    avg_round_3: 7.33,
    app_flat_3: 15,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '30.77%',
    avg_round_4: 8.91,
    app_flat_4: 21,
    char_5: 'sunday',
    app_rate_5: '20.0%',
    avg_round_5: 9.67,
    app_flat_5: 12,
    char_6: 'aventurine',
    app_rate_6: '16.92%',
    avg_round_6: 9.33,
    app_flat_6: 12,
    char_7: 'huohuo',
    app_rate_7: '16.92%',
    avg_round_7: 8.64,
    app_flat_7: 11,
    char_8: 'robin',
    app_rate_8: '13.85%',
    avg_round_8: 7.67,
    app_flat_8: 3,
    char_9: 'silver-wolf',
    app_rate_9: '7.69%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'gallagher',
    app_rate_10: '6.15%',
    avg_round_10: 7.5,
    app_flat_10: 6,
    char_11: 'the-herta',
    app_rate_11: '5.38%',
    avg_round_11: 9.33,
    app_flat_11: 3,
    char_12: 'tingyun',
    app_rate_12: '4.62%',
    avg_round_12: 5.67,
    app_flat_12: 3,
    char_13: 'luocha',
    app_rate_13: '3.85%',
    avg_round_13: 9.5,
    app_flat_13: 2,
    char_14: 'lingsha',
    app_rate_14: '1.54%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'ruan-mei',
    app_rate_15: '0.77%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'bronya',
    app_rate_16: '0.77%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'lynx',
    app_rate_17: '0.77%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 0.53,
    char_1: 'robin',
    app_rate_1: '75.64%',
    avg_round_1: 9.22,
    app_flat_1: 15,
    char_2: 'aventurine',
    app_rate_2: '33.33%',
    avg_round_2: 8.67,
    app_flat_2: 6,
    char_3: 'huohuo',
    app_rate_3: '23.08%',
    avg_round_3: 8.75,
    app_flat_3: 4,
    char_4: 'sparkle',
    app_rate_4: '20.51%',
    avg_round_4: 8.67,
    app_flat_4: 3,
    char_5: 'sunday',
    app_rate_5: '19.23%',
    avg_round_5: 9.33,
    app_flat_5: 5,
    char_6: 'tribbie',
    app_rate_6: '19.23%',
    avg_round_6: 9.67,
    app_flat_6: 3,
    char_7: 'fu-xuan',
    app_rate_7: '17.95%',
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'luocha',
    app_rate_8: '12.82%',
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'topaz',
    app_rate_9: '10.26%',
    avg_round_9: 9.33,
    app_flat_9: 3,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '10.26%',
    avg_round_10: 9.5,
    app_flat_10: 2,
    char_11: 'march-7th-swordmaster',
    app_rate_11: '8.97%',
    avg_round_11: 8.5,
    app_flat_11: 2,
    char_12: 'tingyun',
    app_rate_12: '8.97%',
    avg_round_12: 8.25,
    app_flat_12: 4,
    char_13: 'gallagher',
    app_rate_13: '6.41%',
    avg_round_13: 8.25,
    app_flat_13: 4,
    char_14: 'jade',
    app_rate_14: '5.13%',
    avg_round_14: 8.5,
    app_flat_14: 2,
    char_15: 'lynx',
    app_rate_15: '5.13%',
    avg_round_15: 9.67,
    app_flat_15: 3,
    char_16: 'feixiao',
    app_rate_16: '5.13%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'ruan-mei',
    app_rate_17: '3.85%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'dr-ratio',
    app_rate_18: '3.85%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'yunli',
    app_rate_19: '2.56%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'moze',
    app_rate_20: '1.28%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'lingsha',
    app_rate_21: '1.28%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.28%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'the-herta',
    app_rate_23: '1.28%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'herta',
    app_rate_24: '1.28%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'silver-wolf',
    app_rate_25: '1.28%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.5,
    char_1: 'trailblazer-remembrance',
    app_rate_1: '35.62%',
    avg_round_1: 8.29,
    app_flat_1: 14,
    char_2: 'sunday',
    app_rate_2: '35.62%',
    avg_round_2: 7.86,
    app_flat_2: 14,
    char_3: 'mydei',
    app_rate_3: '27.4%',
    avg_round_3: 7.25,
    app_flat_3: 8,
    char_4: 'tribbie',
    app_rate_4: '27.4%',
    avg_round_4: 8.38,
    app_flat_4: 8,
    char_5: 'the-herta',
    app_rate_5: '21.92%',
    avg_round_5: 8.57,
    app_flat_5: 11,
    char_6: 'jade',
    app_rate_6: '16.44%',
    avg_round_6: 8.5,
    app_flat_6: 8,
    char_7: 'robin',
    app_rate_7: '16.44%',
    avg_round_7: 8.17,
    app_flat_7: 6,
    char_8: 'sparkle',
    app_rate_8: '15.07%',
    avg_round_8: 10,
    app_flat_8: 2,
    char_9: 'ruan-mei',
    app_rate_9: '12.33%',
    avg_round_9: 10,
    app_flat_9: 2,
    char_10: 'blade',
    app_rate_10: '9.59%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'yunli',
    app_rate_11: '8.22%',
    avg_round_11: 8,
    app_flat_11: 4,
    char_12: 'bronya',
    app_rate_12: '6.85%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'aglaea',
    app_rate_13: '6.85%',
    avg_round_13: 9,
    app_flat_13: 3,
    char_14: 'serval',
    app_rate_14: '5.48%',
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'clara',
    app_rate_15: '5.48%',
    avg_round_15: 9.67,
    app_flat_15: 3,
    char_16: 'herta',
    app_rate_16: '5.48%',
    avg_round_16: 8.5,
    app_flat_16: 2,
    char_17: 'tingyun-fugue',
    app_rate_17: '4.11%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'acheron',
    app_rate_18: '4.11%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'pela',
    app_rate_19: '4.11%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'jiaoqiu',
    app_rate_20: '4.11%',
    avg_round_20: 4.5,
    app_flat_20: 2,
    char_21: 'imbibitor-lunae',
    app_rate_21: '2.74%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'jing-yuan',
    app_rate_22: '2.74%',
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'jingliu',
    app_rate_23: '2.74%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'rappa',
    app_rate_24: '2.74%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'feixiao',
    app_rate_25: '2.74%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'topaz',
    app_rate_26: '2.74%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'asta',
    app_rate_27: '2.74%',
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'tingyun',
    app_rate_28: '2.74%',
    avg_round_28: 10,
    app_flat_28: 2,
    char_29: 'aventurine',
    app_rate_29: '1.37%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'boothill',
    app_rate_30: '1.37%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'blade',
    app: 0.49,
    char_1: 'jade',
    app_rate_1: '50.0%',
    avg_round_1: 8.44,
    app_flat_1: 9,
    char_2: 'tribbie',
    app_rate_2: '44.44%',
    avg_round_2: 8.43,
    app_flat_2: 7,
    char_3: 'sunday',
    app_rate_3: '29.17%',
    avg_round_3: 8,
    app_flat_3: 4,
    char_4: 'bronya',
    app_rate_4: '27.78%',
    avg_round_4: 8.5,
    app_flat_4: 2,
    char_5: 'luocha',
    app_rate_5: '26.39%',
    avg_round_5: 9,
    app_flat_5: 3,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '23.61%',
    avg_round_6: 9.5,
    app_flat_6: 4,
    char_7: 'gallagher',
    app_rate_7: '19.44%',
    avg_round_7: 7.75,
    app_flat_7: 4,
    char_8: 'huohuo',
    app_rate_8: '16.67%',
    avg_round_8: 9,
    app_flat_8: 5,
    char_9: 'fu-xuan',
    app_rate_9: '11.11%',
    avg_round_9: 8,
    app_flat_9: 1,
    char_10: 'ruan-mei',
    app_rate_10: '9.72%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'lynx',
    app_rate_11: '9.72%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'sparkle',
    app_rate_12: '8.33%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'robin',
    app_rate_13: '8.33%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'lingsha',
    app_rate_14: '4.17%',
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'aventurine',
    app_rate_15: '4.17%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'jingliu',
    app_rate_16: '2.78%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '2.78%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'herta',
    app_rate_18: '1.39%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'himeko',
    app: 0.35,
    char_1: 'the-herta',
    app_rate_1: '74.51%',
    avg_round_1: 8.33,
    app_flat_1: 21,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '33.33%',
    avg_round_2: 8.4,
    app_flat_2: 10,
    char_3: 'aventurine',
    app_rate_3: '23.53%',
    avg_round_3: 7.5,
    app_flat_3: 8,
    char_4: 'fu-xuan',
    app_rate_4: '21.57%',
    avg_round_4: 9.33,
    app_flat_4: 5,
    char_5: 'lingsha',
    app_rate_5: '19.61%',
    avg_round_5: 9,
    app_flat_5: 3,
    char_6: 'tingyun-fugue',
    app_rate_6: '19.61%',
    avg_round_6: 7.5,
    app_flat_6: 2,
    char_7: 'ruan-mei',
    app_rate_7: '19.61%',
    avg_round_7: 10,
    app_flat_7: 2,
    char_8: 'tribbie',
    app_rate_8: '17.65%',
    avg_round_8: 6.75,
    app_flat_8: 4,
    char_9: 'firefly',
    app_rate_9: '13.73%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'gallagher',
    app_rate_10: '9.8%',
    avg_round_10: 7.8,
    app_flat_10: 5,
    char_11: 'robin',
    app_rate_11: '7.84%',
    avg_round_11: 6.33,
    app_flat_11: 3,
    char_12: 'huohuo',
    app_rate_12: '7.84%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'sunday',
    app_rate_13: '5.88%',
    avg_round_13: 10,
    app_flat_13: 3,
    char_14: 'luocha',
    app_rate_14: '5.88%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'trailblazer-harmony',
    app_rate_15: '3.92%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'sparkle',
    app_rate_16: '3.92%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'rappa',
    app_rate_17: '3.92%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'yunli',
    app_rate_18: '1.96%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'asta',
    app_rate_19: '1.96%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'herta',
    app_rate_20: '1.96%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'serval',
    app_rate_21: '1.96%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seele',
    app: 0.31,
    char_1: 'sparkle',
    app_rate_1: '67.39%',
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: '52.17%',
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'robin',
    app_rate_3: '47.83%',
    avg_round_3: 7,
    app_flat_3: 1,
    char_4: 'sunday',
    app_rate_4: '26.09%',
    avg_round_4: 7,
    app_flat_4: 1,
    char_5: 'silver-wolf',
    app_rate_5: '21.74%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '17.39%',
    avg_round_6: 6,
    app_flat_6: 1,
    char_7: 'tribbie',
    app_rate_7: '13.04%',
    avg_round_7: 6,
    app_flat_7: 1,
    char_8: 'tingyun',
    app_rate_8: '13.04%',
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'gallagher',
    app_rate_9: '10.87%',
    avg_round_9: 6,
    app_flat_9: 1,
    char_10: 'huohuo',
    app_rate_10: '10.87%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'aventurine',
    app_rate_11: '10.87%',
    avg_round_11: 7,
    app_flat_11: 1,
    char_12: 'luocha',
    app_rate_12: '4.35%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '2.17%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jingliu',
    app_rate_14: '2.17%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.22,
    char_1: 'ruan-mei',
    app_rate_1: '62.5%',
    avg_round_1: 8.17,
    app_flat_1: 6,
    char_2: 'trailblazer-harmony',
    app_rate_2: '31.25%',
    avg_round_2: 8,
    app_flat_2: 3,
    char_3: 'sunday',
    app_rate_3: '25.0%',
    avg_round_3: 8.33,
    app_flat_3: 3,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '25.0%',
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'acheron',
    app_rate_5: '21.88%',
    avg_round_5: 9.5,
    app_flat_5: 4,
    char_6: 'sparkle',
    app_rate_6: '21.88%',
    avg_round_6: 9,
    app_flat_6: 2,
    char_7: 'tingyun-fugue',
    app_rate_7: '21.88%',
    avg_round_7: 8,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: '12.5%',
    avg_round_8: 9,
    app_flat_8: 1,
    char_9: 'firefly',
    app_rate_9: '12.5%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'pela',
    app_rate_10: '9.38%',
    avg_round_10: 9.5,
    app_flat_10: 2,
    char_11: 'aventurine',
    app_rate_11: '9.38%',
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'robin',
    app_rate_12: '6.25%',
    avg_round_12: 6,
    app_flat_12: 1,
    char_13: 'tribbie',
    app_rate_13: '6.25%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'jiaoqiu',
    app_rate_14: '6.25%',
    avg_round_14: 9.5,
    app_flat_14: 2,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '6.25%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'gallagher',
    app_rate_16: '3.12%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'lingsha',
    app_rate_17: '3.12%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'boothill',
    app_rate_18: '3.12%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'the-herta',
    app_rate_19: '3.12%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'herta',
    app_rate_20: '3.12%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'luocha',
    app_rate_21: '3.12%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'rappa',
    app_rate_22: '3.12%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.15,
    char_1: 'ruan-mei',
    app_rate_1: '54.55%',
    avg_round_1: 9,
    app_flat_1: 5,
    char_2: 'tingyun-fugue',
    app_rate_2: '54.55%',
    avg_round_2: 9,
    app_flat_2: 6,
    char_3: 'lingsha',
    app_rate_3: '31.82%',
    avg_round_3: 9,
    app_flat_3: 6,
    char_4: 'tribbie',
    app_rate_4: '31.82%',
    avg_round_4: 4,
    app_flat_4: 1,
    char_5: 'robin',
    app_rate_5: '22.73%',
    avg_round_5: 4,
    app_flat_5: 1,
    char_6: 'trailblazer-harmony',
    app_rate_6: '13.64%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'jade',
    app_rate_7: '13.64%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'fu-xuan',
    app_rate_8: '13.64%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'aventurine',
    app_rate_9: '13.64%',
    avg_round_9: 4,
    app_flat_9: 1,
    char_10: 'huohuo',
    app_rate_10: '9.09%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'firefly',
    app_rate_11: '4.55%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'luocha',
    app_rate_12: '4.55%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '4.55%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'sunday',
    app_rate_14: '4.55%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '4.55%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '4.55%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th',
    app_rate_17: '4.55%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'gallagher',
    app_rate_18: '4.55%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'the-herta',
    app_rate_19: '4.55%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.14,
    char_1: 'the-herta',
    app_rate_1: '57.14%',
    avg_round_1: 7.2,
    app_flat_1: 5,
    char_2: 'herta',
    app_rate_2: '42.86%',
    avg_round_2: 7.75,
    app_flat_2: 4,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '33.33%',
    avg_round_3: 7,
    app_flat_3: 4,
    char_4: 'robin',
    app_rate_4: '19.05%',
    avg_round_4: 5,
    app_flat_4: 1,
    char_5: 'tribbie',
    app_rate_5: '19.05%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'ruan-mei',
    app_rate_6: '14.29%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'jade',
    app_rate_7: '14.29%',
    avg_round_7: 5,
    app_flat_7: 1,
    char_8: 'acheron',
    app_rate_8: '14.29%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'pela',
    app_rate_9: '14.29%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'tingyun-fugue',
    app_rate_10: '9.52%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'feixiao',
    app_rate_11: '4.76%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'aglaea',
    app_rate_12: '4.76%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'bronya',
    app_rate_13: '4.76%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'firefly',
    app_rate_14: '4.76%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'trailblazer-harmony',
    app_rate_15: '4.76%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'jing-yuan',
    app_rate_16: '4.76%',
    avg_round_16: 7,
    app_flat_16: 1,
    char_17: 'sunday',
    app_rate_17: '4.76%',
    avg_round_17: 7,
    app_flat_17: 1,
    char_18: 'xueyi',
    app_rate_18: '4.76%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'rappa',
    app_rate_19: '4.76%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'huohuo',
    app_rate_20: '4.76%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'asta',
    app_rate_21: '4.76%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'jiaoqiu',
    app_rate_22: '4.76%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'sparkle',
    app_rate_23: '4.76%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.1,
    char_1: 'aglaea',
    app_rate_1: '40.0%',
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'sunday',
    app_rate_2: '26.67%',
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'fu-xuan',
    app_rate_3: '20.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: '20.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'trailblazer-harmony',
    app_rate_5: '13.33%',
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: 'huohuo',
    app_rate_6: '13.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'robin',
    app_rate_7: '13.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'kafka',
    app_rate_8: '13.33%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'black-swan',
    app_rate_9: '13.33%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'firefly',
    app_rate_10: '13.33%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'lynx',
    app_rate_11: '13.33%',
    avg_round_11: 9,
    app_flat_11: 2,
    char_12: 'rappa',
    app_rate_12: '6.67%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'lingsha',
    app_rate_13: '6.67%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '6.67%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'gallagher',
    app_rate_15: '6.67%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'himeko',
    app_rate_16: '6.67%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'aventurine',
    app_rate_17: '6.67%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'tingyun-fugue',
    app_rate_18: '6.67%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'serval',
    app_rate_19: '6.67%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'tingyun',
    app_rate_20: '6.67%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'the-herta',
    app_rate_21: '6.67%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'herta',
    app_rate_22: '6.67%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'march-7th',
    app_rate_23: '6.67%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'acheron',
    app_rate_24: '6.67%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jiaoqiu',
    app_rate_25: '6.67%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'sparkle',
    app_rate_26: '6.67%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.08,
    char_1: 'sunday',
    app_rate_1: '33.33%',
    avg_round_1: 9,
    app_flat_1: 1,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '33.33%',
    avg_round_2: 10,
    app_flat_2: 2,
    char_3: 'the-herta',
    app_rate_3: '33.33%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'fu-xuan',
    app_rate_4: '25.0%',
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'imbibitor-lunae',
    app_rate_5: '25.0%',
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'aglaea',
    app_rate_6: '16.67%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'robin',
    app_rate_7: '16.67%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'luocha',
    app_rate_8: '16.67%',
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'aventurine',
    app_rate_9: '16.67%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'serval',
    app_rate_10: '16.67%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'herta',
    app_rate_11: '8.33%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'jing-yuan',
    app_rate_12: '8.33%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'jade',
    app_rate_13: '8.33%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'lingsha',
    app_rate_14: '8.33%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'feixiao',
    app_rate_15: '8.33%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'topaz',
    app_rate_16: '8.33%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'mydei',
    app_rate_17: '8.33%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'gallagher',
    app_rate_18: '8.33%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'guinaifen',
    app: 0.06,
    char_1: 'acheron',
    app_rate_1: '88.89%',
    avg_round_1: 9.67,
    app_flat_1: 5,
    char_2: 'aventurine',
    app_rate_2: '77.78%',
    avg_round_2: 9.67,
    app_flat_2: 5,
    char_3: 'pela',
    app_rate_3: '33.33%',
    avg_round_3: 8.67,
    app_flat_3: 3,
    char_4: 'bronya',
    app_rate_4: '22.22%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'jiaoqiu',
    app_rate_5: '22.22%',
    avg_round_5: 10,
    app_flat_5: 2,
    char_6: 'fu-xuan',
    app_rate_6: '11.11%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '11.11%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'firefly',
    app_rate_8: '11.11%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'tingyun-fugue',
    app_rate_9: '11.11%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'lingsha',
    app_rate_10: '11.11%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanqing',
    app: 0.05,
    char_1: 'aventurine',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'robin',
    app_rate_2: '57.14%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'sunday',
    app_rate_3: '57.14%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'tribbie',
    app_rate_4: '28.57%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '14.29%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'moze',
    app_rate_6: '14.29%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '14.29%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '14.29%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.04,
    char_1: 'sunday',
    app_rate_1: '66.67%',
    avg_round_1: 7,
    app_flat_1: 1,
    char_2: 'aglaea',
    app_rate_2: '50.0%',
    avg_round_2: 7,
    app_flat_2: 1,
    char_3: 'the-herta',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'tribbie',
    app_rate_4: '33.33%',
    avg_round_4: 7,
    app_flat_4: 1,
    char_5: 'jade',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'herta',
    app_rate_6: '16.67%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'serval',
    app_rate_7: '16.67%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'robin',
    app_rate_8: '16.67%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '16.67%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'arlan',
    app: 0.03,
    char_1: 'tribbie',
    app_rate_1: '75.0%',
    avg_round_1: 5,
    app_flat_1: 1,
    char_2: 'aventurine',
    app_rate_2: '75.0%',
    avg_round_2: 5,
    app_flat_2: 1,
    char_3: 'sunday',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'fu-xuan',
    app_rate_4: '25.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'acheron',
    app_rate_5: '25.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'jiaoqiu',
    app_rate_6: '25.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'bronya',
    app_rate_7: '25.0%',
    avg_round_7: 5,
    app_flat_7: 1,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.02,
    char_1: 'robin',
    app_rate_1: '66.67%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'sunday',
    app_rate_2: '66.67%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'luocha',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'aventurine',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'bronya',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'ruan-mei',
    app_rate_6: '33.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: '33.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.02,
    char_1: 'pela',
    app_rate_1: '66.67%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'tribbie',
    app_rate_2: '66.67%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'herta',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'jingliu',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'ruan-mei',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'sunday',
    app_rate_6: '33.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'yunli',
    app_rate_7: '33.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.01,
    char_1: 'imbibitor-lunae',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'sparkle',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'sunday',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yukong',
    app: 0.01,
    char_1: 'dr-ratio',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'tribbie',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'aventurine',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hook',
    app: 0.01,
    char_1: 'sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'tribbie',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'fu-xuan',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
